import React, {
  useState,
} from 'react';
import './settings.css';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const CustomListItemButton = styled(ListItemButton)`
  padding: 8px;
  min-width: 194px;

  &.active {
    border-radius: 4px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #315285;
    
    .MuiTypography-root {
        color: #315285 !important;
        font-weight: 600 !important;
    }
  }

  &:hover {
    border-radius: 4px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #315285;
  }
`;

const CustomListItemText = styled(ListItemText)`
  margin: 0;
  
  & > .MuiTypography-root {
    color: #546077;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;

function SideBar({ handleSelect, listItemTexts }) {
  const [selectedItem, setSelectedItem] = useState(1);

  const handleClick = (value) => {
    setSelectedItem(value);
    handleSelect(value);
  };

  return (
    <Box
      sx={{
        p: 2,
        borderRight: '1px solid var(--Border-Border-01, #E2E2E2)',
        minHeight: 'calc(100vh - 261px)',
      }}
      className="d-flex flex-column"
    >
      <List
        sx={{ p: 0 }}
        component="nav"
      >
        {
          listItemTexts.map((listItemText, index) => (
            <CustomListItemButton
              key={index}
              onClick={() => handleClick(index + 1)}
              className={selectedItem === index + 1 ? 'active' : ''}
            >
              <CustomListItemText primary={listItemText} />
            </CustomListItemButton>
          ))
        }
      </List>
    </Box>
  );
}

SideBar.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  listItemTexts: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SideBar;

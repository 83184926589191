import React from 'react';
import { styled } from '@mui/system';
import {
  Card, CardContent, CardHeader,
} from '@mui/material';
import CustomTable from '../../components/table/CustomTable';
import Recycle from '../../images/recycle.png';

const CustomCard = styled(Card)`
    borderRadius: "8px",
    width: 30%,
    border: "1px solid var(--Dark-Dark-06, #E0E1E6)",
    boxShadow: "none"
`;

const FIXED_TABLE_HEADERS = [
  {
    label: 'Organization Name',
    sortable: false,
  },
  {
    label: 'Renewal Date',
    sortable: false,
  },
  {
    label: 'Contact',
    sortable: false,
  },
  {
    label: 'Amount Due',
    sortable: false,
  },
  {
    label: 'Status',
    sortable: false,
  },
];

const tableData = [
  {
    organizationName: 'XYZ Corporation LLC',
    renewalDate: '07/21/2024',
    contact: 'John Smith',
    amountDue: '$1200.00',
    status: 'Pending',
  },
  {
    organizationName: 'ABC Inc',
    renewalDate: '07/21/2024',
    contact: 'Jane Doe',
    amountDue: '$2500.00',
    status: 'Pending',
  },
  {
    organizationName: 'Bleckley Memorial LLC',
    renewalDate: '07/21/2024',
    contact: 'Michael Lee',
    amountDue: '$900.00',
    status: 'Pending',
  },
  {
    organizationName: 'Bleckley Memorial LLC',
    renewalDate: '07/21/2024',
    contact: 'Michael Lee',
    amountDue: '$900.00',
    status: 'Pending',
  },
  {
    organizationName: 'ABC Inc',
    renewalDate: '07/21/2024',
    contact: 'Jane Doe',
    amountDue: '$2500.00',
    status: 'Pending',
  },
];

function RenewalsCard() {
  return (
    <CustomCard className="daily-activities-card">
      <CardHeader
        className="table-card-header hours-card-header"
        title={(
          <div className="table-card-header-title d-flex gap-1 justify-content-between align-items-center">
            <div className="table-card-logo d-flex gap-1">
              <img
                src={Recycle}
                alt="info icon"
                style={{
                  width: '23px',
                  height: '34px',
                  marginTop: '4px',
                }}
              />
              <p>Upcoming Renewals</p>
            </div>
          </div>
        )}
      />
      <CardContent className="table-card-content d-flex flex-column justify-content-between">
        <CustomTable headers={FIXED_TABLE_HEADERS} items={tableData} />
      </CardContent>
    </CustomCard>
  );
}

export default RenewalsCard;

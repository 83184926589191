/* eslint-disable react/prop-types */
import * as React from 'react';
import {
  BarChart, Bar, XAxis, Tooltip, ResponsiveContainer,
  Cell,
} from 'recharts';
import './stack.css';

function CustomTooltip({ active, payload, label }) {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          position: 'relative',
          backgroundColor: '#fff',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '10px',
          marginLeft: '6px',
        }}
      >
        <p className="label">{`${label}`}</p>
        {payload.map((entry, index) => (
          <div
            className="tooltip-content-line"
            key={`item-${index}`}
            style={{ color: '#0E1E40', margin: '5px 0' }}
          >
            <div className="tooltip-index-circle" style={{ backgroundColor: entry.color }} />
            <div className="tooltip-text">
              <p>{entry.name}</p>
              <p>{entry.value}</p>
            </div>
          </div>
        ))}
      </div>
    );
  }

  return null;
}

function SimpleBar({ data, color, tooltip }) {
  return (
    <ResponsiveContainer width="100%" height={100}>
      <BarChart data={data} barCategoryGap="30%">
        <XAxis dataKey="month" hide />
        <Bar dataKey={tooltip} fill={color} minPointSize={5} barSize={30}>
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={entry[tooltip] === 0 ? '#E0E0E0' : color}
              opacity={entry[tooltip] === 0 ? 0.5 : 1}
            />
          ))}
        </Bar>
        <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />} />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default SimpleBar;

/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Box, Button, Grid,
} from '@mui/material';
import { getTimeString } from '../../utils/time';
import { Description, StyledCard } from '../../components/styledComponents';

function ViewRequestModal({
  closeModal, request, handleDeny, handleApprove,
}) {
  const startDate = new Date(request.startDate);
  const endDate = new Date(request.endDate);
  const currentBalance = request.member.ptoBalances.length
    ? request.member.ptoBalances[0].balance : 0;
  const remainingBalance = currentBalance - request.totalHours;

  const currentBalanceStr = getTimeString(currentBalance);
  const requestedTimeStr = getTimeString(request.totalHours);
  const remainingBalanceStr = getTimeString(remainingBalance);

  const dateOptions = {
    weekday: 'short',
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  };

  return (
    <Modal open onClose={() => closeModal(false)} className="flex-row align-c jstfy-c create-team-modal">
      <Box style={{
        width: 1044,
        padding: 32,
        background: '#fff',
        borderRadius: 8,
      }}
      >
        <div className="modal-body" style={{ padding: 0 }}>
          <Description
            fontSize={20}
            fontWeight={600}
            color="#0E1E40"
            marginTop={0}
          >
            Time Off Request
          </Description>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Description
                fontSize={14}
                fontWeight={500}
                color="#293855"
                marginTop={16}
              >
                Policy
              </Description>
              <Description
                fontSize={16}
                fontWeight={500}
                color="#0E1E40"
                marginTop={8}
              >
                {request.offTimePolicy.name}
              </Description>
              <Description
                fontSize={14}
                fontWeight={500}
                color="#293855"
                marginTop={24}
              >
                Time Span
              </Description>
              <Box sx={{ display: 'flex', alignItems: 'center', margin: '8px 0px' }}>
                <Description
                  fontSize={16}
                  fontWeight={500}
                  color="#0E1E40"
                  marginTop={0}
                >
                  {startDate.toLocaleDateString('en-US', dateOptions)}
                </Description>
                <span style={{ margin: '0px 8px' }}>to</span>
                <Description
                  fontSize={16}
                  fontWeight={500}
                  color="#0E1E40"
                  marginTop={0}
                >
                  {endDate.toLocaleDateString('en-US', dateOptions)}
                </Description>
              </Box>
              <Description
                fontSize={14}
                fontWeight={500}
                color="#293855"
                marginTop={24}
              >
                Total Time Off Hours
              </Description>
              <Description
                fontSize={16}
                fontWeight={500}
                color="#0E1E40"
                marginTop={8}
              >
                {requestedTimeStr}
              </Description>
              <Description
                fontSize={14}
                fontWeight={500}
                color="#293855"
                marginTop={24}
              >
                Time Off Reason
              </Description>
              <Description
                fontSize={16}
                fontWeight={500}
                color="#0E1E40"
                marginTop={8}
              >
                {request.reason}
              </Description>
              <Description
                fontSize={14}
                fontWeight={500}
                color="#293855"
                marginTop={24}
              >
                Status
              </Description>
              <Description
                fontSize={16}
                fontWeight={500}
                color="#0E1E40"
                marginTop={8}
              >
                {request.status}
              </Description>
              {request.status === 'Denied' && (
                <>
                  <Description
                    fontSize={14}
                    fontWeight={500}
                    color="#293855"
                    marginTop={24}
                  >
                    Reason for Denial
                  </Description>
                  <Description
                    fontSize={16}
                    fontWeight={500}
                    color="#0E1E40"
                    marginTop={8}
                  >
                    {request.denyReason}
                  </Description>
                </>
              )}
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ paddingLeft: '24px', paddingTop: '38px', borderLeft: '1px solid #E0E1E6' }}>
                <Box sx={{ paddingBottom: '16px', borderBottom: '1px solid #E0E1E6' }}>
                  <StyledCard>
                    <span>Current Balance</span>
                    <span>{currentBalanceStr}</span>
                  </StyledCard>
                  <StyledCard>
                    <span>Hours Requested</span>
                    <span>{requestedTimeStr}</span>
                  </StyledCard>
                </Box>
                <Box sx={{ paddingTop: '16px' }}>
                  <StyledCard>
                    <span>Remaining Balance</span>
                    <span>{remainingBalanceStr}</span>
                  </StyledCard>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
        <div
          className="modal-footer-customized"
          style={{
            display: 'flex',
            justifyContent: 'right',
          }}
        >
          <Button
            sx={{
              marginRight: 2,
              textTransform: 'none',
              fontSize: '16px',
              height: '40px',
              width: '101px',
            }}
            variant="outlined"
            onClick={() => {
              closeModal();
              handleDeny();
            }}
            color="error"
            disabled={request.status === 'Denied'}
          >
            Deny
          </Button>
          <Button
            sx={{
              textTransform: 'none',
              fontSize: '16px',
              height: '40px',
              width: '154px',
              backgroundColor: 'var(--green-3, #53A551)',
              color: '#FFFFFF',
              '&:hover': { backgroundColor: 'var(--green-3, #53A551)' },
            }}
            variant="contained"
            onClick={() => {
              closeModal();
              handleApprove();
            }}
            disabled={request.status === 'Approved'}
          >
            Approve
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

ViewRequestModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  handleDeny: PropTypes.func.isRequired,
  handleApprove: PropTypes.func.isRequired,
  request: PropTypes.shape({
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    totalHours: PropTypes.number.isRequired,
    offTimePolicy: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    status: PropTypes.string.isRequired,
    denyReason: PropTypes.string.isRequired,
    reason: PropTypes.string.isRequired,
    member: PropTypes.shape({
      ptoBalances: PropTypes.arrayOf(
        PropTypes.shape({
          balance: PropTypes.number.isRequired,
        }),
      ),
    }),
  }).isRequired,
};

export default ViewRequestModal;

import React, { useEffect, useState } from 'react';
import {
  FormControl, FormLabel, Box, Button,
} from '@mui/material';
import PropTypes from 'prop-types';
import TimeSelector from '../../components/timeSelect';

function SelectTimeBox({
  id, timeStatus, startTime, endTime, convertTime, saveTime,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [onMouse, setOnMouse] = useState(false);
  const convertedStartTime = startTime ? convertTime(startTime, 'Etc/UTC') : '';
  const convertedEndTime = endTime ? convertTime(endTime, 'Etc/UTC') : '';
  const [currentStartTime, setCurrentStartTime] = useState(convertedStartTime);
  const [currentEndTime, setCurrentEndTime] = useState(convertedEndTime);

  useEffect(() => {
    setCurrentStartTime(convertedStartTime);
    setCurrentEndTime(convertedEndTime);
  }, [convertTime]);

  const handleButtonClick = () => {
    setIsEditing(!isEditing);
  };

  return (
    <div
      className="position-relative flex-row align-items-center"
      onMouseEnter={() => setOnMouse(true)}
      onMouseLeave={() => setOnMouse(false)}
    >
      <p className="mr-1">
        {startTime && endTime
          ? `${convertedStartTime} - ${convertedEndTime}`
          : 'N/A'}
      </p>
      <Button
        sx={{
          marginLeft: '4px',
          font: 'Inter',
          fontSize: '12px',
          lineHeight: '12px',
          fontWeight: '500',
          padding: '2px',
          visibility: (onMouse || isEditing) ? 'visible' : 'hidden',
        }}
        variant="outlined"
        color={isEditing ? 'error' : 'success'}
        onClick={handleButtonClick}
      >
        {isEditing ? 'Cancel' : 'Edit'}
      </Button>
      {isEditing && (
        <Box
          width={312}
          height={182}
          sx={{
            padding: '16px',
            border: '1px solid #E0E1E6',
            position: 'absolute',
            top: '36px',
            backgroundColor: 'white',
            zIndex: '1',
            borderRadius: '4px',
          }}
        >
          <h5>{timeStatus === 'workingHours' ? 'Work Schedule' : 'Work Break Schedule'}</h5>
          <FormControl sx={{ width: '50%' }}>
            <FormLabel
              sx={{
                color: 'var(--dark-2)',
              }}
            >
              Starts from
            </FormLabel>
            <TimeSelector
              time={currentStartTime}
              setTime={setCurrentStartTime}
            />
          </FormControl>
          <FormControl sx={{ width: '50%' }}>
            <FormLabel
              sx={{
                color: 'var(--dark-2)',
                fontSize: '14px',
              }}
            >
              Ends at
            </FormLabel>
            <TimeSelector time={currentEndTime} setTime={setCurrentEndTime} />
          </FormControl>
          <Button
            sx={{
              font: 'Inter',
              fontSize: '16px',
              lineHeight: '16px',
              fontWeight: '500',
              border: '1px solid',
              textTransform: 'none',
              borderRadius: '4px',
              backgroundColor: 'var(--green-3, #53A551)',
              opacity: currentStartTime && currentEndTime ? '1' : '0.5',
              color: '#FFFFFF',
              padding: '12px 24px',
              width: '100%',
              marginTop: '8px',
            }}
            onClick={() => {
              setIsEditing(!isEditing);
              saveTime({
                timeStatus,
                startTime: currentStartTime,
                endTime: currentEndTime,
                memberId: id,
              });
            }}
            variant="link"
            component="span"
            disabled={!(currentStartTime && currentEndTime)}
          >
            Save
          </Button>
        </Box>
      )}
    </div>
  );
}

SelectTimeBox.propTypes = {
  id: PropTypes.number.isRequired,
  timeStatus: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  convertTime: PropTypes.func.isRequired,
  saveTime: PropTypes.func.isRequired,
};

export default SelectTimeBox;

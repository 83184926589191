const accountTypeLevel = {
  'Primary owner': 5,
  'Workspace owner': 4,
  'Workspace admin': 3,
  'Team Leader': 2,
  'Team member': 1,
};

const PRIMARY_OWNER = 'Primary owner';
const WORKSPACE_OWNER = 'Workspace owner';
const WORKSPACE_ADMIN = 'Workspace admin';
const TEAM_LEADER = 'Team Leader';
const TEAM_MEMBER = 'Team member';

module.exports = {
  accountTypeLevel,
  PRIMARY_OWNER,
  WORKSPACE_OWNER,
  WORKSPACE_ADMIN,
  TEAM_LEADER,
  TEAM_MEMBER,
};

/* eslint-disable react/jsx-props-no-spreading, react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import OutlinedSelect from '../muiCustomized/OutlinedSelect';

const renderTimezoneOptions = ({ heading, description, value }) => (
  <MenuItem value={value} key={`${heading}-${value}`}>
    <div className="d-flex flex-column">
      <p className="fw-bold fc-light size-xxs">{heading}</p>
      <p>{description}</p>
    </div>
  </MenuItem>
);

function TimezoneSelect({
  value, onChange, options, ...props
}) {
  return (
    <OutlinedSelect
      value={value}
      onChange={onChange}
      SelectProps={{
        renderValue: (option) => option,
      }}
      {...props}
    >
      {options.map(renderTimezoneOptions)}
    </OutlinedSelect>
  );
}

const TimezoneMenuItemProps = PropTypes.shape({
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

TimezoneSelect.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(TimezoneMenuItemProps),
};

export default TimezoneSelect;

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const AvatarWrapper = styled.div`
    padding: ${(props) => (props.outline ? '4px' : 0)};
    border: ${(props) => (props.outline ? `1.5px solid ${props.borderColor}` : 'none')};
    border-radius: 100%;
`;

const NameAvatar = styled.div`
  padding: 10px;
  border-radius: 50%;
  width: ${(props) => `${props.width}px` || '32px'};
  height: ${(props) => `${props.height}px` || '32px'};
  font-size: ${(props) => (props.width > 32 ? '16px' : '10px')};
  color: white;
  text-align: center;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
`;

function Avatar({
  src,
  name,
  width,
  height,
  color,
  borderColor,
  backgroundColor,
  outline = false,
}) {
  return (
    <AvatarWrapper outline={outline} borderColor={borderColor}>
      {
            src ? <img src={src} width={width} height={height} style={{ borderRadius: '100%' }} alt={name} />
              : (
                <NameAvatar
                  width={width}
                  height={height}
                  backgroundColor={backgroundColor}
                  color={color}
                >
                  {name}
                </NameAvatar>
              )
        }
    </AvatarWrapper>
  );
}

Avatar.propTypes = {
  src: PropTypes.string,
  name: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  borderColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  outline: PropTypes.bool,
};

export default Avatar;

import React, {
  useState, useMemo, useContext, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import './settings.css';
import CustomTable from '../../components/table/CustomTable';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { UserContext } from '../../contexts/User';
import { AccrualSchedule } from '../../constants/offTimePolicy';
import { NotificationContext } from '../../contexts/Notification';
import TabPageHeader from './TabPageHeader';
import ArchiveModal from './ArchiveModal';

function TimeoffArchivePanel({
  getPolicies, isLoading, policies,
}) {
  const { activeWorkspace } = useContext(UserContext);
  const {
    displayNotification,
  } = useContext(NotificationContext);
  const [selectedPolicy, setSelectedPolicy] = useState({});
  const [showModal, setShowModal] = useState(false);

  const handleClick = (policy) => {
    setSelectedPolicy(policy);
    setShowModal(true);
  };

  const restoreTimeOffPolicy = async () => {
    const res = await sendRequest({
      method: 'put',
      url: API.WORKSPACE.restoreOffTimePolicy(activeWorkspace.id, selectedPolicy.id),
    });
    if (res.success) {
      displayNotification({
        message: 'Successfully restored a PTO policy.',
        severity: 'success',
      });
      setShowModal(false);
      getPolicies();
    } else {
      displayNotification({
        message: 'An error occurs while restoring a PTO policy.',
        severity: 'error',
      });
    }
  };

  // eslint-disable-next-line react/prop-types
  const items = useMemo(() => policies.map((policy) => {
    if (policy.isArchived) {
      return {
        name: policy.name,
        members: policy.members.length,
        teams: policy.teams.length,
        accrual_schedule: AccrualSchedule[policy.scheduleOfAccrual].text,
        action: (
          <Button
            sx={{
              font: 'Inter',
              fontSize: '16px',
              lineHeight: '16px',
              fontWeight: '500',
              border: '1px solid',
              textTransform: 'none',
              borderRadius: '4px',
              borderColor: 'lightgrey',
              backgroundColor: '#ffffff',
              color: 'rgba(0, 0, 0, 0.87)',
              padding: '12px 24px',
              height: '40px',
            }}
            variant="link"
            component="span"
            onClick={() => handleClick(policy)}
          >
            Restore
          </Button>
        ),
      };
    }
    return {};
  }), [policies]);

  useEffect(() => {
    getPolicies();
  }, []);

  const FIXED_TABLE_HEADERS = [
    {
      label: 'Policy Name',
      sortable: true,
    },
    {
      label: 'Members',
      sortable: true,
    },
    {
      label: 'Teams',
      sortable: true,
    },
    {
      label: 'Accrual Schedule',
      sortable: true,
    },
    {
      label: 'Action',
    },
  ];
  return (
    <Box
      sx={{
        marginTop: '-3px',
      }}
    >
      <TabPageHeader
        title="Archived Time off policies"
        subtitle="Restore below time off policies to use again"
      />
      <Box className="mt-4">
        <CustomTable
          headers={FIXED_TABLE_HEADERS}
          items={items}
          loading={isLoading}
        />
      </Box>
      {showModal && (
        <ArchiveModal
          title="Are you sure to restore this policy?"
          description="The policy will be restored and can be used again to add new members"
          policyName={selectedPolicy.name}
          setShowModal={setShowModal}
          callback={restoreTimeOffPolicy}
          buttonName="Restore"
        />
      )}
    </Box>
  );
}

TimeoffArchivePanel.propTypes = {
  policies: PropTypes.shape({ }),
  getPolicies: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default TimeoffArchivePanel;

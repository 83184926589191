/* eslint-disable no-nested-ternary */
import React, {
  useState, useContext, useEffect,
} from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, Slide, Chip,
} from '@mui/material';
import { UnfoldMore } from '@mui/icons-material/';
import { useLocation, useHistory } from 'react-router-dom';
import { styled } from '@mui/system';
import PlacesAutocomplete from '../../components/PlacesAutocomplete';
import './geoDashboard.css';
import API from '../../api/endpoints';
import { UserContext } from '../../contexts/User';
import { sendRequest } from '../../api/shootAPI';
import LocationHistory from '../../components/LocationHistory';
import NavigationLayout from '../../components/NavigationLayout';
import CustomTable from '../../components/table/CustomTable';
import useDebounce from '../../hooks/useDebounce';
import MemberNameEmailColumn from '../../components/table/MemberNameEmailColumn';

/* eslint-disable react/jsx-props-no-spreading */
const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const TABLE_HEADERS = [
  {
    label: 'User',
    sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'name',
    colSpan: 1,
  },
  {
    label: 'Home Address',
    colSpan: 2,
  },
  {
    label: 'Unresolved',
    sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'unresolved',
    colSpan: 0.5,
  },
  {
    label: 'View Location History',
    colSpan: 0.5,
  },
];

const CustomTableStyles = {
  container: {
    marginTop: 1,
  },
  header: {
    backgroundColor: 'var(--dark-10)',
    fontSize: '14px',
    '.MuiTableSortLabel-iconDirectionAsc': {
      transform: 'rotate(0deg) !important',
    },
    '&:nth-child(3)': {
      textAlign: 'center',
    },
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff !important',
    },
    '&:hover': {
      backgroundColor: 'var(--dark-10) !important',
    },
  },
  cell: {
    button: {
      textWrap: 'nowrap',
      color: 'var(--dark-2)',
      borderColor: 'var(--dark-4) !important',
      '&:disabled': {
        color: 'var(--dark-5)',
        borderColor: 'var(--dark-7) !important',
        cursor: 'not-allowed',
        pointerEvents: 'all !important',
      },
    },
    '&:nth-child(3)': {
      textAlign: 'center',
    },
  },
};

const StyledChip = styled(Chip)(({ type }) => {
  const colorStyle = {
    danger: {
      backgroundColor: 'rgba(221, 73, 73, 0.2)',
      color: 'rgba(221, 73, 73, 1)',
    },
    warning: {
      backgroundColor: '#FFF4E5',
      color: '#ED6C02',
    },
    success: {
      backgroundColor: 'rgba(83, 165, 81, 0.2)',
      color: 'rgba(83, 165, 81, 1)',
    },
  };
  return {
    ...colorStyle[type],
    fontWeight: 500,
    width: 'max-content',
  };
});

const getChipLabelType = (addressSaved, totalCount) => {
  if (!addressSaved) {
    return {
      chipLabel: 'No Address Saved',
      chipType: 'warning',
    };
  }
  if (!totalCount) {
    return {
      chipLabel: 'None',
      chipType: 'success',
    };
  }

  return {
    chipLabel: `${totalCount} Away`,
    chipType: 'danger',
  };
};

function GeoDashboard() {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const page = query.get('page');
  const history = useHistory();
  const { debounce } = useDebounce();

  const { activeWorkspace } = useContext(UserContext);
  const [members, setMembers] = useState([]);
  const [sortKey, setSortKey] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [currentPage, setCurrentPage] = useState(page ? parseInt(page, 10) : 1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedMember, setSelectedMember] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenUpdateModal, setIsOpenUpdateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [homeAddress, setHomeAddress] = useState({
    latlng: null,
    location: null,
  });

  const onClickHistory = (member) => {
    setSelectedMember(member);
    setIsOpenModal(true);
  };

  const handleClose = () => {
    setIsOpenModal(false);
  };

  const handleCloseUpdateAddressModal = () => {
    setIsOpenUpdateModal(false);
    setHomeAddress({
      latlng: null,
      location: null,
    });
  };

  const onClickUpdateAddress = (member) => {
    setSelectedMember(member);
    setIsOpenUpdateModal(true);
  };

  const getMembers = async (params) => {
    setIsLoading(true);
    const response = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.membersGeolocations(activeWorkspace.id),
      params: {
        pageSize: 10,
        ...params,
      },
    });
    if (response.success) {
      const mappedMembers = response.data?.list.map((member) => {
        const addressSaved = !!member?.user.userLocation?.formattedAddress;
        const { chipLabel, chipType } = getChipLabelType(
          addressSaved,
          member?.locationViolations?.length,
        );
        return ({
          user: <MemberNameEmailColumn
            profilePicture={member?.user?.profilePicture}
            firstName={member.haveJoined ? member?.user?.firstName : ''}
            lastName={member.haveJoined ? member?.user?.lastName : ''}
            email={member?.user?.email}
            component="div"
          />,
          homeAddress: member?.user?.userLocation?.formattedAddress
            ?? (
            <button
              onClick={() => onClickUpdateAddress(member)}
              style={{
                textDecoration: 'underline',
              }}
              type="button"
              data-testid="update-address"
            >
              Updates Address
            </button>
            ),
          unresolved: <StyledChip
            label={chipLabel}
            type={chipType}
          />,
          actions: (
            <Button
              type="button"
              className={`inactive-status${addressSaved ? '' : ' disabled'}`}
              onClick={() => onClickHistory(member)}
              disabled={!addressSaved}
              variant="outlined"
              data-testid="view-history"
            >
              View History
            </Button>
          ),
        });
      });
      setMembers(mappedMembers);
      setIsLoading(false);
      if (response.data?.list && selectedMember) {
        response.data?.list.forEach((member) => {
          if (member.id === selectedMember.id) {
            setSelectedMember(member);
          }
        });
      }
      setTotalPages(response.data?.totalPages ?? 1);
    } else {
      // handle API errors
    }
  };

  const handleUpdateAddress = async () => {
    const response = await sendRequest({
      method: 'post',
      url: API.USER.updateAddress,
      data: { ...homeAddress, userId: selectedMember.user.id },
    });
    if (response.success) {
      getMembers({
        page: currentPage,
        sortKey,
        sortOrder,
      });
      handleCloseUpdateAddressModal();
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    history.push(`?page=${page}`);
  };

  const handleSort = debounce((sortKey, sortOrder) => {
    setIsLoading(true);
    setSortKey(sortKey);
    setSortOrder(sortOrder);
    return getMembers({
      page: currentPage,
      sortKey,
      sortOrder,
    });
  }, 250);

  useEffect(() => {
    getMembers({
      page: currentPage,
      sortKey,
      sortOrder,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <div>
      <NavigationLayout title="Geo Dashboard" subtitle="View work location history and violations">
        <CustomTable
          headers={TABLE_HEADERS}
          items={members}
          loading={isLoading}
          pages={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          handleSort={handleSort}
          styles={CustomTableStyles}
        />
        <Dialog
          fullScreen
          open={isOpenModal}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <LocationHistory
            user={selectedMember?.user}
            handleClose={handleClose}
            onResolve={getMembers}
            locationViolations={selectedMember?.locationViolations}
          />
        </Dialog>
        <Dialog
          open={isOpenUpdateModal}
          onClose={handleCloseUpdateAddressModal}
          TransitionComponent={Transition}
          maxWidth="sm"
          fullWidth
          data-testid="update-address-modal"
        >
          <DialogTitle data-testid="modal-title">Update Home Address</DialogTitle>
          <DialogContent>
            <PlacesAutocomplete
              location={homeAddress.location}
              onChange={setHomeAddress}
              textfieldType="standard"
              label="Home Address"
              placeholder="Enter Street Address"
              data-testid="home-address"
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseUpdateAddressModal}
              variant="outlined"
              color="secondary"
              data-testid="cancel"
            >
              Cancel
            </Button>
            <Button
              onClick={handleUpdateAddress}
              variant="contained"
              color="success"
              data-testid="update"
              disabled={!homeAddress.latlng}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </NavigationLayout>
    </div>
  );
}

GeoDashboard.propTypes = {

};

export default GeoDashboard;

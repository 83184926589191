import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Box, Button } from '@mui/material';
import TrashIcon from '../../images/trash.png';
import { Description } from '../../components/styledComponents';

function DeleteModal({
  policyName, setShowModal, callback, type, date = '',
}) {
  return (
    <Modal open onClose={() => setShowModal(false)} className="flex-row align-c jstfy-c create-team-modal">
      <Box style={{
        width: 462,
        padding: 32,
        background: '#fff',
        borderRadius: 8,
      }}
      >
        <img src={TrashIcon} width="48" height="48" alt="trash" />
        <div className="modal-body" style={{ padding: 0 }}>
          <Description
            fontSize={20}
            fontWeight={600}
            color="#0E1E40"
            marginTop={16}
          >
            Are you sure to delete this
            {' '}
            {type}
            ?
          </Description>
          <Description
            fontSize={14}
            fontWeight={400}
            color="#293855"
            marginTop={6}
          >
            You’re about to delete the “
            <span style={{ fontWeight: 600 }}>{policyName}</span>
            ”
            {' '}
            {type}
            {' '}
            {date}
            {' '}
            and this action can not be undone.
          </Description>
        </div>

        <div className="modal-footer-customized">
          <Button
            sx={{
              marginRight: 2,
              textTransform: 'none',
              fontSize: '16px',
              height: '40px',
              width: '101px',
            }}
            variant="outlined"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            sx={{
              textTransform: 'none',
              fontSize: '16px',
              height: '40px',
              width: '101px',
              backgroundColor: '#CC4525',
              '&:hover': { backgroundColor: '#CC4525' },
            }}
            variant="contained"
            onClick={() => callback()}
          >
            Delete
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

DeleteModal.propTypes = {
  policyName: PropTypes.string.isRequired,
  setShowModal: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  date: PropTypes.string,
};

export default DeleteModal;

import React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import styled from '@emotion/styled';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormControl, InputLabel } from '@mui/material';
import { useJsApiLoader } from '@react-google-maps/api';

const Required = styled.span`
  color: 'red';
  font-size: '20px';
  margin-left: '4px'
`;

function PlacesAutocompleteComponent({
  label, location, onChange, inputRef, onKeyDown, required, textfieldType, placeholder,
}) {
  const {
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete({
    debounce: 300,
  });

  return (
    <FormControl sx={{ marginBottom: '16px' }} variant="standard" fullWidth>
      {label
        && (
          <InputLabel sx={{ fontSize: '18px', color: '#212529' }} shrink htmlFor="location-autocomplete">
            {label}
            {required && <Required>*</Required>}
          </InputLabel>
        )}
      <Autocomplete
        id="home-address"
        size="small"
        getOptionLabel={(option) => option?.description ?? ''}
        filterOptions={(x) => x}
        options={status === 'OK' ? data : []}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={location}
        noOptionsText="No locations"
        data-testid="home-address"
        sx={
          (theme) => ({
            'label + &': {
              marginTop: theme.spacing(3),
            },
            '& .Mui-focused': {
              '& .MuiOutlinedInput-notchedOutline': {
                boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderWidth: '1px !important',
                borderColor: theme.palette.primary.main,
              },
            },
          })
        }
        onChange={(event, newValue) => {
          if (newValue) {
            getGeocode({ address: newValue.description }).then((results) => {
              const { lat, lng } = getLatLng(results[0]);
              onChange((prevState) => ({ ...prevState, latlng: { lat, lng }, location: newValue }));
            });
          } else {
            onChange((prevState) => ({ ...prevState, latlng: null, location: null }));
          }
        }}
        onInputChange={(event, newInputValue) => {
          setValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            fullWidth
            inputRef={inputRef}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            variant={textfieldType}
          />
        )}
        renderOption={(props, option) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.main_text}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )}
        isOptionEqualToValue={(option, value) => (
          option.description === value.description
        )}
      />
    </FormControl>
  );
}

PlacesAutocompleteComponent.propTypes = {
  label: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  inputRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  onKeyDown: PropTypes.func,
  required: PropTypes.bool,
  textfieldType: PropTypes.string,
  placeholder: PropTypes.string,
};

function PlacesAutocomplete(props) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  });

  if (isLoaded) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <PlacesAutocompleteComponent {...props} />;
  }
  return null;
}

PlacesAutocomplete.propTypes = {
};

export default PlacesAutocomplete;

/* eslint-disable no-console */
import React, { useState } from 'react';
import {
  TextField, FormControl, RadioGroup, FormControlLabel, Radio, Box, Button, Grid, IconButton, InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import API from '../../api/endpoints';
import { sendRequest } from '../../api/shootAPI';

function CreateOrganizationForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [selectedOption, setSelectedOption] = useState('');

  // State for form data
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    homeAddress: '',
    email: '',
    password: '',
    confirmPassword: '',
    organizationName: '',
    workspaceName: '',
    workspaceDescription: '',
    awsS3Bucket: '',
    awsAccessKey: '',
    awsSecretKey: '',
  });

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async () => {
    console.log('Create Organization');
    const payload = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      homeAddress: formData.homeAddress,
      email: formData.email,
      password: formData.password,
      name: formData.organizationName,
      ...(selectedOption === 'shootStore' && {
        workspaceName: formData.workspaceName,
        workspaceDescription: formData.workspaceDescription,
      }),
      ...(selectedOption === 'awsStore' && {
        awsBucketName: formData.awsS3Bucket,
        awsAccessKeyId: formData.awsAccessKey,
        awsSecretAccessKey: formData.awsSecretKey,
        workspaceName: formData.workspaceName,
        workspaceDescription: formData.workspaceDescription,
      }),
    };

    try {
      await sendRequest({
        method: 'post',
        url: API.ORGANIZATION.create,
        data: payload, // Send the collected data as payload
      });
      console.log('Organization created successfully');
    } catch (error) {
      console.log('Organization creation failed', error);
    }
  };

  return (
    <div className="create-organization-container">
      <p className="title-text">Create an organization</p>
      <p className="small-text">Please enter below information to create a new organization</p>

      <form>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <p className="explain-text">First Name</p>
            <TextField
              required
              label="First Name"
              name="firstName"
              variant="outlined"
              fullWidth
              value={formData.firstName}
              onChange={handleInputChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  backgroundColor: '#f5f5f5',
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <p className="explain-text">Last Name</p>
            <TextField
              required
              label="Last Name"
              name="lastName"
              variant="outlined"
              fullWidth
              value={formData.lastName}
              onChange={handleInputChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  backgroundColor: '#f5f5f5',
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <p className="explain-text">Home Address</p>
            <TextField
              required
              label="Home Address"
              name="homeAddress"
              variant="outlined"
              fullWidth
              value={formData.homeAddress}
              onChange={handleInputChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  backgroundColor: '#f5f5f5',
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <p className="explain-text">Email Address</p>
            <TextField
              required
              label="Email"
              name="email"
              variant="outlined"
              fullWidth
              value={formData.email}
              onChange={handleInputChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  backgroundColor: '#f5f5f5',
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <p className="explain-text">Password</p>
            <TextField
              required
              label="Password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              variant="outlined"
              fullWidth
              value={formData.password}
              onChange={handleInputChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  backgroundColor: '#f5f5f5',
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <p className="explain-text">Confirm Password</p>
            <TextField
              required
              label="Confirm Password"
              name="confirmPassword"
              type={showConfirmPassword ? 'text' : 'password'}
              variant="outlined"
              fullWidth
              value={formData.confirmPassword}
              onChange={handleInputChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  backgroundColor: '#f5f5f5',
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <p className="explain-text">Organization Name</p>
            <TextField
              required
              label="Organization Name"
              name="organizationName"
              variant="outlined"
              fullWidth
              value={formData.organizationName}
              onChange={handleInputChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  backgroundColor: '#f5f5f5',
                },
              }}
            />
          </Grid>
          <p className="final-text">Screenshots and Webcam Data</p>
          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <RadioGroup
                aria-label="screenshot-webcam-data"
                name="screenshot-webcam-data"
                value={selectedOption}
                onChange={handleChange}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  ml: 0,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    padding: '8px',
                    marginBottom: '8px',
                    width: '100%',
                    '&:hover': {
                      borderColor: '#999',
                    },
                  }}
                >
                  <FormControlLabel
                    value="shootStore"
                    control={<Radio />}
                    label="Shoot can store the data on organization's behalf."
                    sx={{ margin: 0 }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    padding: '8px',
                    width: '100%',
                    '&:hover': {
                      borderColor: '#999',
                    },
                  }}
                >
                  <FormControlLabel
                    value="awsStore"
                    control={<Radio />}
                    label="Organization will use AWS to store their own data."
                    sx={{ margin: 0 }}
                  />
                </Box>
              </RadioGroup>

              {selectedOption === 'awsStore' && (
                <div id="content-awsStore" className="option">
                  <Grid item xs={12}>
                    <p className="explain-text">AWS S3 Bucket Name</p>
                    <TextField
                      required
                      label="AWS S3 Bucket Name"
                      name="awsS3Bucket"
                      variant="outlined"
                      fullWidth
                      value={formData.awsS3Bucket}
                      onChange={handleInputChange}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '12px',
                          backgroundColor: '#f5f5f5',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <p className="explain-text">AWS Access Key ID</p>
                    <TextField
                      required
                      label="AWS Access Key ID"
                      name="awsAccessKey"
                      variant="outlined"
                      fullWidth
                      value={formData.awsAccessKey}
                      onChange={handleInputChange}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '12px',
                          backgroundColor: '#f5f5f5',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <p className="explain-text">AWS Secret Access Key</p>
                    <TextField
                      required
                      label="AWS Secret Access Key"
                      name="awsSecretKey"
                      type={showPassword ? 'text' : 'password'}
                      variant="outlined"
                      fullWidth
                      value={formData.awsSecretKey}
                      onChange={handleInputChange}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '12px',
                          backgroundColor: '#f5f5f5',
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </div>
              )}
              <Grid item xs={12}>
                <p className="explain-text">Workspace Name</p>
                <TextField
                  required
                  label="Workspace Name"
                  name="workspaceName"
                  variant="outlined"
                  fullWidth
                  value={formData.workspaceName}
                  onChange={handleInputChange}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '12px',
                      backgroundColor: '#f5f5f5',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <p className="explain-text">Workspace Description</p>
                <TextField
                  required
                  label="Workspace Description"
                  name="workspaceDescription"
                  variant="outlined"
                  fullWidth
                  value={formData.workspaceDescription}
                  onChange={handleInputChange}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '12px',
                      backgroundColor: '#f5f5f5',
                    },
                  }}
                />
              </Grid>
            </FormControl>
          </Grid>
        </Grid>
      </form>
      <div className="two-buttons">
        <Link to="/admin/dashboard">
          <Button
            variant="contained"
            sx={{
              width: 100,
              height: 40,
              fontSize: 14,
              textTransform: 'none',
              marginRight: '50px',
              border: '2px solid #E2E2E2',
              borderRadius: '5px',
              backgroundColor: 'transparent',
              color: '#53A551',
              '&:hover': {
                backgroundColor: '#53A551',
                color: 'white',
              },
            }}
            data-testid="add-member"
          >
            Back
          </Button>
        </Link>
        <Button
          variant="contained"
          sx={{
            width: 250,
            height: 40,
            fontSize: 14,
            textTransform: 'none',
            marginRight: '20px',
            backgroundColor: '#86D384',
          }}
          data-testid="add-member"
          onClick={handleSubmit}
        >
          Create Organization
        </Button>
      </div>
    </div>
  );
}

export default CreateOrganizationForm;

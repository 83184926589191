import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Description } from '../../components/styledComponents';
import VectorTran from '../../images/Vector-tran.svg';
import VectorGreen from '../../images/Vector-green.svg';

function FilterModal({ handleSearch, teams, members }) {
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [teamId, setTeamId] = useState(0);
  const [memberId, setMemberId] = useState(0);

  const openFilter = Boolean(filterAnchorEl);
  const teamEditId = openFilter ? 'simple-popover' : undefined;

  const handleReset = () => {
    setTeamId(0);
    setMemberId(0);
    handleSearch(0, 0);
  };

  const clickSearch = () => {
    handleSearch(teamId, memberId);
  };

  return (
    <div>
      {openFilter ? (
        <Button
          className="filter-btn open-filter-btn"
          onClick={(e) => setFilterAnchorEl(e.currentTarget)}
        >
          <p>Filters</p>
          <img src={VectorTran} width="20" height="20" alt="filter" />
        </Button>
      ) : (
        <Button
          className="filter-btn close-filter-btn"
          onClick={(e) => setFilterAnchorEl(e.currentTarget)}
        >
          <p>Filters</p>
          <img src={VectorGreen} width="20" height="20" alt="filter" />
        </Button>
      )}
      <Popover
        id={teamEditId}
        open={openFilter}
        anchorEl={filterAnchorEl}
        onClose={() => setFilterAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box style={{
          width: 473,
          padding: 32,
          background: '#fff',
          borderRadius: 8,
        }}
        >
          <Description
            fontSize={20}
            fontWeight={600}
            color="#0E1E40"
            marginTop={-5}
          >
            Filters
          </Description>
          <Description
            fontSize={14}
            fontWeight={500}
            color="#293855"
            marginTop={18}
          >
            Team Name
          </Description>
          <FormControl sx={{ width: 409, height: 46 }}>
            <Select
              value={teamId}
              onChange={(e) => setTeamId(e.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value={0}>All</MenuItem>
              {teams.map((item) => (<MenuItem value={item.id}>{item.name}</MenuItem>))}
            </Select>
          </FormControl>
          <Description
            fontSize={14}
            fontWeight={500}
            color="#293855"
            marginTop={18}
          >
            Member Name
          </Description>
          <FormControl sx={{ width: 409, height: 46 }}>
            <Select
              value={memberId}
              onChange={(e) => setMemberId(e.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value={0}>All</MenuItem>
              {members.map((item) => (
                <MenuItem value={item.id}>
                  {item.user.firstName}
                  &nbsp;
                  {item.user.lastName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className="filter-footer">
            <Button
              sx={{
                marginRight: 2,
                textTransform: 'none',
                fontSize: '16px',
                height: '40px',
                width: '76px',
              }}
              variant="outlined"
              onClick={() => handleReset()}
              color="secondary"
            >
              Reset
            </Button>
            <Button
              sx={{
                textTransform: 'none',
                fontSize: '16px',
                height: '40px',
                width: '86px',
                backgroundColor: '#53A551',
                '&:hover': { backgroundColor: '#53A551' },
              }}
              disabled={(teamId && memberId) || (!teamId && !memberId)}
              variant="contained"
              onClick={() => clickSearch()}
            >
              Search
            </Button>
          </div>
        </Box>
      </Popover>
    </div>
  );
}

export default FilterModal;

FilterModal.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  teams: PropTypes.arrayOf({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  members: PropTypes.arrayOf({
    id: PropTypes.number.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }),
  }),
};

import React, { useState } from 'react';
import './organizationSignup.css';
import CreateAccountForm from './CreateAccountForm';
import SetupOrganizationForm from './SetupOrganizationForm';
import CreateWorkspaceForm from './CreateWorkspaceForm';
import InviteTeam from './InviteTeam';

const STEPS = {
  'Create Account': 1,
  'Setup Organization': 2,
  'Create Workspace': 3,
  'Invite Team': 4,
};

function OrganizationSignup() {
  const [currentStep, setCurrentStep] = useState({ value: STEPS['Create Account'], isReturning: false });
  const [formData, setFormData] = useState({
    organizationName: '',
    avatar: '',
    awsBucketName: '',
    awsAccessKeyId: '',
    awsSecretAccessKey: '',
    workspaceName: '',
    workspaceDescription: '',
    workspaceMembers: [],
    code: '',
    hasError: false,
  });

  return (
    <div>
      {currentStep.value === STEPS['Create Account']
        && (
          <CreateAccountForm
            setFormData={setFormData}
            formData={formData}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        )}
      {currentStep.value === STEPS['Setup Organization']
        && (
          <SetupOrganizationForm
            setFormData={setFormData}
            formData={formData}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        )}
      {currentStep.value === STEPS['Create Workspace']
        && (
          <CreateWorkspaceForm
            setFormData={setFormData}
            formData={formData}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        )}
      {currentStep.value === STEPS['Invite Team']
        && (
          <InviteTeam
            setFormData={setFormData}
            formData={formData}
            currentStepVal={currentStep.value}
          />
        )}
    </div>
  );
}

export default OrganizationSignup;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import './submenu.css';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const MenuLink = styled.div`
  ${(props) => !props.enabled && css`
  cursor: disabled;
  pointer-events: none;
  opacity: 0.3;
  `};
  cursor: pointer;
`;

const SubMenuHeader = styled.span`
  font-weight: 600;
  color: var(--blue-1);
`;

function SubLink({ subRoute }) {
  const location = useLocation();
  const isActive = location?.pathname?.startsWith(subRoute.routeName);
  return (
    <MenuLink enabled={subRoute?.enabled} key={subRoute.routeName} className="subMenuItem">
      <NavLink
        to={subRoute.routeName}
        className={`link-text ${isActive ? 'active-link' : ''}`}
      >
        {subRoute?.text}
      </NavLink>
    </MenuLink>
  );
}

SubLink.propTypes = {
  subRoute: PropTypes.shape({
    text: PropTypes.string.isRequired,
    routeName: PropTypes.string.isRequired,
    enabled: PropTypes.bool,
  }).isRequired,
};

function SubMenu({ linkInfo }) {
  const [showSubMenu, setShowSubMenu] = useState(true);
  return (
    <li className="link-item">
      <MenuLink enabled={linkInfo?.enabled} onClick={() => setShowSubMenu(!showSubMenu)}>
        <span className="link-text">
          <SubMenuHeader>{linkInfo.text}</SubMenuHeader>
          <i className="fa fa-caret-down" />
        </span>
      </MenuLink>

      {showSubMenu && (
        <ul className="subMenuUl">
          {
            linkInfo?.subRoutes?.map((subRoute) => <SubLink subRoute={subRoute} key={subRoute.routeName} />)
          }
        </ul>
      )}
    </li>
  );
}

export default SubMenu;

SubMenu.propTypes = {
  linkInfo: PropTypes.shape({
    enabled: PropTypes.bool,
    routeName: PropTypes.string,
    subRoutes: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string.isRequired,
      routeName: PropTypes.string.isRequired,
      enabled: PropTypes.bool,
    }).isRequired).isRequired,
    text: PropTypes.string,
    submenuLocation: PropTypes.string,
  }),
};

import React, {
  useContext, useEffect, useState, useRef,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import './twoFactor.css';
import { UserContext } from '../../contexts/User';
import API from '../../api/endpoints';
import { sendRequest } from '../../api/shootAPI';
import { NotificationContext } from '../../contexts/Notification';
import useQueryParams from '../../hooks/useQueryParams';
import FormLayout from '../../components/FormLayout';
import { TextInput } from '../../components/muiCustomized/TextInput';

function TwoFactorAuthentication() {
  const {
    twoFactorToken, qrCode, redirect,
    signIn, updateTokens, setWorkspaceMember,
  } = useContext(UserContext);
  const history = useHistory();
  const queryParams = useQueryParams();
  const { displayNotification } = useContext(NotificationContext);
  const submitBtnRef = useRef(null);
  const [formData, setFormData] = useState({ code: '', qrCode: !!qrCode });
  const [formErrors, setFormErrors] = useState();

  const handleRedirect = (role) => {
    // If redirect query param is passed, there is another route that requires the user to
    // sign in before proceeding. After signing in, we should redirect back to this route
    // For example, the organization signup page requires a user to sign in before creating a new organization
    // After signing in, we redirect back to the organization sign up page
    const redirect = queryParams.get('redirect');
    if (redirect) return history.push(`/${redirect}`);
    if (role === 'Superadmin') return history.push('/admin/dashboard');
    return history.push('/workspaces');
  };

  // Redirect depending on logged in state
  useEffect(() => {
    if (!twoFactorToken) redirect('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeTwoFactor = (e) => {
    setFormErrors({});
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const sendNewTwoFactorCheck = async () => {
    const response = await sendRequest({ method: 'post', url: API.TWO_FACTOR.verify, data: formData });
    if (response.success) {
      const {
        accessToken, refreshToken, userToken, role,
      } = response.data;
      if (userToken) signIn({ userToken });
      else {
        updateTokens({ accessToken, refreshToken });
        setWorkspaceMember(accessToken);
      }
      return handleRedirect(role);
    }
    return setFormErrors(response.data);
  };

  const sendNewTwoFactorCode = async () => {
    const response = await sendRequest({ method: 'post', url: API.TWO_FACTOR.renew, data: formData });
    if (response.success) {
      setFormErrors({});
      displayNotification({
        message: 'Successfully sent a new two factor code to your email. Please check your inbox or spam.',
        severity: 'success',
        verticalAlign: 'top',
      });
    } else {
      displayNotification({
        message: 'Unable to send a new two factor code. Please try again later.',
        severity: 'error',
        verticalAlign: 'top',
      });
    }
  };

  return (
    <FormLayout
      heading={qrCode ? 'Scan this QrCode' : 'Check your email'}
      subheading={qrCode ? '' : 'We sent a code to confirm your email.'}
    >
      {qrCode && qrCode !== true && <img src={qrCode} alt="QR Code" className="mb-3" />}
      <TextInput
        name="code"
        value={formData?.code}
        onChange={handleChangeTwoFactor}
        onKeyDown={(e) => {
          const key = e.keyCode || e.which;
          if (key === 13) {
            e.preventDefault();
            submitBtnRef.current.click();
          }
        }}
        label="Two Factor Code"
        placeholder="Enter Code"
        data-testid="code-input"
        error={!!formErrors?.code}
        helperText={formErrors?.code}
      />
      {!qrCode && (
      <p style={{
        width: '100%',
        fontSize: '12px',
        color: 'rgba(0, 0, 0, 0.54)',
        margin: '-16px 8px 20px 30px',
        textAlign: 'left',
      }}
      >
        Enter the code sent to your email address or
        {' '}
        <button
          type="button"
          onClick={sendNewTwoFactorCode}
          className="btn-link"
          style={{
            margin: 0,
            padding: 0,
            fontSize: '12px',
          }}
        >
          request a new code.
        </button>
      </p>
      )}
      <Button
        ref={submitBtnRef}
        fullWidth
        variant="contained"
        className="nextStep-btn"
        disabled={!formData?.code || formData?.code.length < 6}
        onClick={sendNewTwoFactorCheck}
      >
        Submit
      </Button>
      {formData.hasError && (
        <p className="text-danger" style={{ marginTop: 16 }}>
          Something went wrong. Please try again.
        </p>
      )}
    </FormLayout>
  );
}

export default TwoFactorAuthentication;

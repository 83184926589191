import React, { useState, useContext, useEffect } from 'react';
import { styled } from '@mui/system';
import {
  Card, CardContent, CardHeader, Button,
} from '@mui/material';
import CustomTable from '../../components/table/CustomTable';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { NotificationContext } from '../../contexts/Notification';
import { formatDate } from '../../utils/time';
import Work from '../../images/work.png';

const CustomCard = styled(Card)`
    borderRadius: "8px",
    width: 30%,
    border: "1px solid var(--Dark-Dark-06, #E0E1E6)",
    boxShadow: "none"
`;

const FIXED_TABLE_HEADERS = [
  {
    label: 'Workspace Name',
    sortable: false,
  },
  {
    label: 'Created Date',
    sortable: false,
  },
  {
    label: 'Organization Name',
    sortable: false,
  },
  {
    label: 'Users',
    sortable: false,
  },
  {
    label: 'Status',
    sortable: false,
  },
];

function WorkspacesCard() {
  const { displayNotification } = useContext(NotificationContext);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchWorkspaces = async () => {
    setLoading(true);
    const { success, data } = await sendRequest({
      method: 'post',
      url: API.WORKSPACE.getAllWorkspaces(),
      timeout: 30000,
    });
    if (success && Array.isArray(data.workspaces)) {
      const tempData = [];
      data.workspaces.forEach((item, index) => {
        if (index < 3) {
          const datum = {
            workspaceName: item.name,
            createdDate: formatDate(item.createdAt),
            organizationName: item.organization.name,
            users: item.usersCount,
            status: item.status ? 'Active' : 'Inactive',
          };
          tempData.push(datum);
        }
      });
      setTableData(tempData);
      setLoading(false);
    } else {
      displayNotification({
        message: 'There was an issue fetching workspace data. Please try again',
        severity: 'error',
      });
    }
  };

  useEffect(() => {
    fetchWorkspaces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomCard className="daily-activities-card">
      <CardHeader
        className="table-card-header hours-card-header"
        title={(
          <div className="table-card-header-title d-flex gap-1 justify-content-between align-items-center">
            <div className="table-card-logo d-flex gap-1">
              <img
                src={Work}
                alt="info icon"
                style={{
                  width: '23px',
                  height: '34px',
                  marginTop: '4px',
                }}
              />
              <p>Workspaces</p>
            </div>
            <Button sx={{ border: '2px solid #E0E1E6', height: '24px', color: '#4469A1' }}>View All</Button>
          </div>
        )}
      />
      <CardContent className="table-card-content d-flex flex-column justify-content-between">
        <CustomTable headers={FIXED_TABLE_HEADERS} items={tableData} loading={loading} />
      </CardContent>
    </CustomCard>
  );
}

export default WorkspacesCard;

import React, {
  useState,
} from 'react';
import './settings.css';
import SideBar from './SideBar';
import TimeoffPolicyPanel from './TimeoffPolicyPanel';
import HolidaysPanel from './HolidaysPanel';
import TimeoffBalancePanel from './TimeOffBalancePanel';

function TimeOff() {
  const [selectedItem, setSelectedItem] = useState(1);

  const handleSelect = async (index) => {
    setSelectedItem(index);
  };

  const listItemTexts = [
    'Time off policies',
    'Holidays',
    'Time off balances',
  ];
  return (
    <div className="d-flex">
      <SideBar handleSelect={handleSelect} listItemTexts={listItemTexts} />
      <div className="flex-grow-1">
        { selectedItem === 1 && <TimeoffPolicyPanel /> }
        { selectedItem === 2 && <HolidaysPanel /> }
        { selectedItem === 3 && <TimeoffBalancePanel /> }
      </div>
    </div>
  );
}

export default TimeOff;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal, Box, Button } from '@mui/material';
import { NotificationContext } from '../../contexts/Notification';
import TrashIcon from '../../images/trash.png';

function RequestDeleteModal({
  children,
  closeModal,
  handleDelete,
  successMessage,
  errorMessage,
}) {
  const { displayNotification } = useContext(NotificationContext);

  const handleDeleteRequest = async () => {
    const success = await handleDelete(true);
    if (success) {
      setTimeout(() => {
        displayNotification({
          message: successMessage,
          severity: 'success',
        });
      }, 500);
    } else {
      displayNotification({
        message: errorMessage,
        severity: 'error',
      });
    }
  };

  return (
    <Modal open onClose={closeModal} className="flex-row align-c jstfy-c create-team-modal">
      <Box style={{
        width: 462,
        padding: 32,
        background: '#fff',
        borderRadius: 8,
      }}
      >
        <img src={TrashIcon} width="48" height="48" alt="trash" />
        <div className="modal-body" style={{ padding: 0 }}>
          {children}
        </div>

        <div className="modal-footer-customized">
          <Button
            sx={{
              marginRight: 2,
              textTransform: 'none',
              fontSize: '16px',
              height: '40px',
              width: '101px',
            }}
            variant="outlined"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            sx={{
              textTransform: 'none',
              fontSize: '16px',
              height: '40px',
              width: '101px',
              backgroundColor: '#CC4525',
              '&:hover': { backgroundColor: '#CC4525' },
            }}
            variant="contained"
            onClick={handleDeleteRequest}
          >
            Delete
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

RequestDeleteModal.propTypes = {
  children: PropTypes.node.isRequired,
  handleDelete: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default RequestDeleteModal;

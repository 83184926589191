import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { Card, CardContent, CardHeader } from '@mui/material';
import DownSVG from '../../images/dropdown-down.svg';
import RightSVG from '../../images/dropdown-right.svg';

const CustomCard = styled(Card)`
    borderRadius: "8px",
    width: 30%,
    border: "1px solid var(--Dark-Dark-06, #E0E1E6)",
    boxShadow: "none"
`;

function LocationCard({ data }) {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <CustomCard className="daily-activities-card">
      <CardHeader
        className="hours-card-header"
        title={(
          <div className="hours-card-header-title d-flex gap-1 align-items-center">
            {data.date}
          </div>
        )}
      />
      <CardContent className="activity-card-content d-flex flex-column justify-content-between">
        <div
          className="daily-activities-column daily-activities-menu"
          onClick={toggleOpen}
          onKeyDown={(e) => { if (e.key === 'Enter') toggleOpen(); }}
          role="button"
          tabIndex={0}
          style={{ borderBottom: '1px solid #E0E1E6' }}
        >
          <p className="daily-activities-first">
            {open ? (<img src={DownSVG} alt="info icon" />) : (<img src={RightSVG} alt="info icon" />)}
            Total
          </p>
          <p className="daily-activities-second">{data.violations.length}</p>
          <p className="daily-activities-third">
            {data.violationMembers.length}
          </p>
        </div>
        {open && (
          <div>
            {data.violations.map((violation, index) => (
              <div key={index} className="daily-activities-column" style={{ padding: '10px 0px' }}>
                <div className="daily-activities-first daily-activities-category">
                  <p>{violation.date}</p>
                </div>
                <p className="daily-activities-second">{violation.violationType}</p>
                <p className="daily-activities-third">{violation.user}</p>
              </div>
            ))}
          </div>
        )}
      </CardContent>
    </CustomCard>
  );
}

LocationCard.propTypes = {
  data: PropTypes.shape({
    date: PropTypes.string.isRequired,
    violations: PropTypes.arrayOf(PropTypes.shape({
      date: PropTypes.string,
      violationType: PropTypes.string.isRequired,
      user: PropTypes.string.isRequired,
    })),
    violationMembers: PropTypes.arrayOf(PropTypes.number.isRequired),
  }),
};

export default LocationCard;

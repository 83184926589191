/* eslint-disable react/prop-types */
import * as React from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import { convertSecToTimeFormat } from '../../utils/time';
import './stack.css';

function CustomTooltip({ active, payload, label }) {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          position: 'relative',
          backgroundColor: '#fff',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '10px',
          marginLeft: '6px',
        }}
      >
        <p className="label">{`${label}`}</p>
        {payload.map((entry, index) => (
          <div
            className="tooltip-content-line"
            key={`item-${index}`}
            style={{ color: '#0E1E40', margin: '5px 0' }}
          >
            <div className="tooltip-index-circle" style={{ backgroundColor: entry.color }} />
            <div className="tooltip-text">
              <p>{entry.name}</p>
              <p>{convertSecToTimeFormat(entry.value)}</p>
            </div>
          </div>
        ))}
      </div>
    );
  }

  return null;
}

function StackBars({ data }) {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={data}
        barCategoryGap="10%"
      >
        <CartesianGrid strokeLinearray="3 3" vertical={false} stroke="#ECECEC" />
        <XAxis
          dataKey="date"
          axisLine={{ stroke: '#ECECEC' }}
          tickLine={false}
        />
        <YAxis
          tickCount={10}
          axisLine={{ stroke: '#ECECEC' }}
          tickLine={false}
        />
        <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />} />
        <Legend wrapperStyle={{ color: '#0E1E40', bottom: 0 }} />
        <Bar dataKey="Deep Focused" barSize={12} stackId="a" fill="#213D69" />
        <Bar dataKey="Focused" barSize={12} stackId="a" fill="#5A81BC" />
        <Bar dataKey="Active" barSize={12} stackId="a" fill="#B5BAC4" />
        <Bar dataKey="Work Break" barSize={12} stackId="a" fill="#F19E41" />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default StackBars;

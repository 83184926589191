import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { OverlayView } from '@react-google-maps/api';
import Map from './Map';
import Sidebar, { IconWrapper } from './Sidebar';
import HomeIcon from '../../images/icon-house.svg';
import LocationViolationIcon from '../../images/icon-locationViolationMarker.svg';
import AlertIcon from '../../images/icon-alert.svg';
import CloseIcon from '../../images/icon-close.svg';
// import FilterIcon from '../../images/icon-filter.svg';
import { LocationViolationType, UserType } from '../../propTypes';
import { ADDRESSS_HOME, ADDRESS_AWAY } from '../../constants/geolocation';

const InfoWindowWrapper = styled.div`
  display: flex;
  gap: 9px;
  border-radius: 10px;
  background: var(--Dark-Dark-11-White, #FFF);
  padding: 10px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.3);
  min-width: 300px;
  transform: translate(-40px, calc(-100% - 15px));
  &::before {
    content: '';
    position: absolute;
    top: 95%;
    left: 40px;
    width: 0;
    height: 0;
    border-top: 13px solid white;
    border-bottom: 13px solid transparent;
    border-right: 13px solid transparent;
    border-left: 13px solid transparent;
    transform: translateX(-50%);
  }
`;

function AddressInfoWindow({ title, address, type }) {
  return (
    <InfoWindowWrapper>
      <IconWrapper
        width="40px"
        height="40px"
        backgroundColor={
          type === ADDRESS_AWAY ? 'var(--Red-Red-01, #B0361A)' : 'var(--Green-Green-04, #64BA62)'
        }
      >
        {
          type === ADDRESSS_HOME
            ? <img src={HomeIcon} alt="home" />
            : <img src={AlertIcon} alt="away from home" style={{ width: '21.67px' }} />
        }
      </IconWrapper>
      <Box>
        <Typography sx={{
          color: type === ADDRESS_AWAY ? 'var(--Red-Red-01, #B0361A)' : 'var(--Green-Green-01, #256D23)',
          fontFamily: 'Inter',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '20px',
        }}
        >
          {title}
        </Typography>
        <Typography sx={{
          color: '#545454',
          fontFamily: 'Inter',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '14px',
          marginTop: '4px',
        }}
        >
          {address}
        </Typography>
      </Box>
    </InfoWindowWrapper>
  );
}

AddressInfoWindow.propTypes = {
  title: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  type: PropTypes.oneOf([ADDRESSS_HOME, ADDRESS_AWAY]),
};

const LocationViolationMarkerWrapper = styled.div`
  position: absolute;
  width: 31px;
  transform: translate(-15.5px, -100%);
  cursor: pointer;
`;

const AlertImg = styled.img`
  left: -50%;
  width: 17px;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 43%;
  position: absolute;
`;
function LocationViolationMarker({ locationViolation, clickLocation }) {
  return (
    <OverlayView
      position={{ lat: locationViolation.latitude, lng: locationViolation.longitude }}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <LocationViolationMarkerWrapper onClick={() => clickLocation(locationViolation, ADDRESS_AWAY)}>
        <img src={LocationViolationIcon} alt="location violation" />
        <AlertImg src={AlertIcon} />
      </LocationViolationMarkerWrapper>
    </OverlayView>
  );
}

LocationViolationMarker.propTypes = {
  locationViolation: LocationViolationType,
  clickLocation: PropTypes.func.isRequired,
};

const CloseBtn = styled.div`
  background: white;
  filter: drop-shadow(0px 0.864px 3.458px rgba(0, 0, 0, 0.25));
  border-radius: 100px;
  padding: 10px;
  position: absolute;
  right: 33px;
  top: 33px;
  cursor: pointer;
`;

// const FilterBtn = styled.div`
//   background: white;
//   border-radius: 3.239px;
//   background: var(--Dark-Dark-11-White, #FFF);
//   box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
//   padding: 10px;
//   position: absolute;
//   left: 39px;
//   top: 37px;
//   cursor: pointer;
//   display: flex;
//   font-family: Inter;
// font-size: 16px;
// font-style: normal;
// font-weight: 500;
// line-height: 12.956px;
// color: var(--dark-02, #293855);
// align-items: center;
// `;

function LocationHistory({
  user, locationViolations, handleClose, onResolve,
}) {
  const { latitude, longitude } = user.userLocation;
  const [mapCenter, setMapCenter] = useState({ lat: latitude, lng: longitude });
  const [selectedLocation, setSelectedLocation] = useState(-1);
  const clickLocation = (selected, type) => {
    if (type === ADDRESSS_HOME) {
      setSelectedLocation(-1);
    } else {
      setSelectedLocation(selected.id);
    }
    setMapCenter({ lat: selected.latitude, lng: selected.longitude });
  };
  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar
        user={user}
        locationViolations={locationViolations}
        handleClose={handleClose}
        clickLocation={clickLocation}
        onResolve={onResolve}
        selectedLocation={selectedLocation}
      />
      <Map lat={mapCenter.lat} lng={mapCenter.lng} style={{ position: 'relative' }}>
        <OverlayView
          position={{ lat: latitude, lng: longitude }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          {selectedLocation === -1
            ? (
              <AddressInfoWindow
                title={`${user.firstName} ${user.lastName}`}
                address={user.userLocation.formattedAddress}
                type={ADDRESSS_HOME}
              />
            )
            : (
              <Box sx={{
                transform: 'translate(-20px, -100%)',
              }}
              >
                <IconWrapper
                  width="40px"
                  height="40px"
                  backgroundColor="var(--Green-Green-04, #64BA62)"
                  onClick={() => clickLocation(user.userLocation, ADDRESSS_HOME)}
                  style={{ cursor: 'pointer' }}
                >
                  <img src={HomeIcon} alt="home" />
                </IconWrapper>
              </Box>
            )}
        </OverlayView>
        {
          locationViolations.map((locationViolation) => {
            if (locationViolation.id === selectedLocation) {
              return (
                <OverlayView
                  position={{ lat: locationViolation.latitude, lng: locationViolation.longitude }}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  key={`locationViolation${locationViolation.id}`}
                >
                  <AddressInfoWindow
                    title="Away from home"
                    address={locationViolation.formattedAddress}
                    type={ADDRESS_AWAY}
                  />
                </OverlayView>
              );
            }
            return (
              <LocationViolationMarker
                locationViolation={locationViolation}
                key={`locationViolation${locationViolation.id}`}
                clickLocation={clickLocation}
              />
            );
          })
        }
        {/* <FilterBtn>
          <img src={FilterIcon} alt="location filter" style={{ marginRight: '7px' }} />
          Filter Violations
        </FilterBtn> */}
        <CloseBtn onClick={() => { handleClose(); }} data-testid="close-btn">
          <img src={CloseIcon} alt="close icon" />
        </CloseBtn>
      </Map>
    </Box>
  );
}

LocationHistory.propTypes = {
  user: UserType,
  locationViolations: PropTypes.arrayOf(LocationViolationType),
  handleClose: PropTypes.func.isRequired,
  onResolve: PropTypes.func.isRequired,
};

export default LocationHistory;

import React, {
  useState, useRef,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { TextInput } from '../../components/muiCustomized/TextInput';
import FormLayout from './FormLayout';
import WorkspaceCard from '../workspaces/WorkspaceCard';
import { FormDataType } from '../../propTypes';
import config from '../../config';
import API from '../../api/endpoints';
import { UserContext } from '../../contexts/User';

const MENU = ['Workspace Home', 'Workspace Name'];

function WorkspaceHome({ setMenu, currentStepVal, setCurrentStep }) {
  return (
    <FormLayout
      heading="Create a workspace"
      subheading="Invite your team to your first workspace."
      currentStep={currentStepVal}
    >
      <div className="selectStorage-content">
        <p className="text-600 text-center">Setting up your workspace</p>
        <p className="text-center m-t4" style={{ color: '#546077', marginBottom: '30px' }}>
          Workspaces allow you to track productivity within your organization.
          Workspaces provide an activity summary for all members in the workspace.
        </p>
      </div>
      <WorkspaceCard
        name="Shoot Company Workspace"
        description="Workspace for Shoot Company"
      />
      <Button
        fullWidth
        variant="contained"
        className="nextStep-btn"
        onClick={() => setMenu(MENU[1])}
        data-testid="next-step-btn"
      >
        Continue
      </Button>
      <button
        type="button"
        className="back-link"
        onClick={() => setCurrentStep({ value: currentStepVal - 1, isReturning: true })}
      >
        Go back
      </button>
    </FormLayout>
  );
}

WorkspaceHome.propTypes = {
  setMenu: PropTypes.func.isRequired,
  currentStepVal: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
};

function WorkspaceName({
  formData, setFormData, setMenu, currentStepVal, setCurrentStep,
}) {
  const [submitLoading, setSubmitLoading] = useState(false);
  const { setActiveWorkspace, userToken, updateTokens } = useContext(UserContext);
  const workspaceDescriptionRef = useRef(null);
  const continueBtnRef = useRef(null);

  const createWorkspace = async () => {
    setSubmitLoading(true);
    const request = {
      name: formData.organizationName,
      avatar: formData.avatar,
      awsBucketName: formData.awsBucketName,
      awsAccessKeyId: formData.awsAccessKeyId,
      awsSecretAccessKey: formData.awsSecretAccessKey,
      workspaceName: formData.workspaceName,
      workspaceDescription: formData.workspaceDescription,
      members: formData.workspaceMembers,
    };
    const _formData = new FormData();
    Object.keys(request).forEach((field) => {
      _formData.append(field, request[field]);
    });

    try {
      const response = await fetch(config.BaseUrl + API.ORGANIZATION.create, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        body: _formData,
      });
      const {
        workspace, member, accessToken, refreshToken,
      } = await response.json();

      if (response.ok) {
        setActiveWorkspace({ ...workspace, member });
        updateTokens({ accessToken, refreshToken });
        setCurrentStep({ value: currentStepVal + 1, isReturning: false });
      } else {
        setFormData({ ...formData, hasError: true });
      }
      setSubmitLoading(false);
    } catch (e) {
      console.error('workspace sign-up error: ', e);
    }
  };

  return (
    <FormLayout
      heading="Create a workspace"
      subheading="Invite your team to your first workspace."
      currentStep={currentStepVal}
    >
      <div style={{ width: 420, marginBottom: '24px' }}>
        <p className="m-b12 text-left size-xs">How your workspace will appear</p>
        <WorkspaceCard
          image={formData.avatar ? URL.createObjectURL(formData.avatar) : ''}
          name={formData?.workspaceName || 'Workspace Name'}
          description={formData?.workspaceDescription || 'Description of your workspace'}
        />
      </div>
      <TextInput
        name="workspaceName"
        value={formData?.workspaceName}
        onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
        onKeyDown={(e) => {
          const key = e.keyCode || e.which;
          if (key === 13) {
            e.preventDefault();
            workspaceDescriptionRef.current.focus();
          }
        }}
        label="Workspace Name"
        placeholder="Workspace Name"
      />
      <TextInput
        inputRef={workspaceDescriptionRef}
        name="workspaceDescription"
        value={formData?.workspaceDescription}
        onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
        onKeyDown={(e) => {
          const key = e.keyCode || e.which;
          if (key === 13) {
            e.preventDefault();
            continueBtnRef.current.click();
          }
        }}
        label="Workspace Description"
        placeholder="Description of your workspace"
      />
      <Button
        ref={continueBtnRef}
        fullWidth
        variant="contained"
        className="nextStep-btn"
        disabled={!(formData?.workspaceName && formData?.workspaceDescription) || submitLoading}
        onClick={createWorkspace}
        data-testid="next-step-btn"
      >
        {submitLoading ? 'Creating...' : 'Continue'}
      </Button>
      <button type="button" className="back-link" onClick={() => setMenu(MENU[0])}>
        Go back
      </button>
    </FormLayout>
  );
}

WorkspaceName.propTypes = {
  formData: FormDataType,
  setFormData: PropTypes.func.isRequired,
  setMenu: PropTypes.func.isRequired,
  currentStepVal: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
};

function CreateWorkspaceForm({
  formData, setFormData, currentStep, setCurrentStep,
}) {
  const [menu, setMenu] = useState(currentStep.isReturning ? MENU[2] : MENU[0]);

  return (
    <div>
      {menu === MENU[0] && (
        <WorkspaceHome
          formData={formData}
          setFormData={setFormData}
          setMenu={setMenu}
          currentStepVal={currentStep.value}
          setCurrentStep={setCurrentStep}
        />
      )}
      {menu === MENU[1] && (
        <WorkspaceName
          formData={formData}
          setFormData={setFormData}
          setMenu={setMenu}
          currentStepVal={currentStep.value}
          setCurrentStep={setCurrentStep}
        />
      )}
    </div>
  );
}

CreateWorkspaceForm.propTypes = {
  formData: FormDataType,
  setFormData: PropTypes.func.isRequired,
  currentStep: PropTypes.shape({
    value: PropTypes.number,
    isReturning: PropTypes.bool,
  }),
  setCurrentStep: PropTypes.func.isRequired,
};

export default CreateWorkspaceForm;

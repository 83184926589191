import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Link } from 'react-router-dom';
import DeleteConfirmationModal from './DeleteConfirmationModal';

// eslint-disable-next-line react/prop-types
function Action({ label, menuItems = [], type }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [modalAction, setModalAction] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = (action) => {
    setModalAction(() => action);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalAction(null);
  };

  const handleDelete = () => {
    if (modalAction) modalAction();
    setOpenModal(false);
  };

  return (
    <>
      <Button
        aria-controls={open ? 'action-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        size="small"
        onClick={handleClick}
        sx={{
          textTransform: 'none',
          color: '#0E1E40',
          borderColor: '#E0E1E6',
          padding: '3px 6px',
        }}
        endIcon={<ArrowDropDownIcon />}
      >
        Actions
      </Button>
      <Menu
        id="action-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          style: {
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
            borderRadius: '4px',
            padding: '4px 0',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {/* Map over the menuItems to dynamically create each action */}
        {menuItems.map((item, index) => (
          item.link ? (
            <Link to={item.link} key={index} style={{ textDecoration: 'none', color: 'inherit' }}>
              <MenuItem
                onClick={handleClose}
                sx={{ fontWeight: 400, padding: '6px 16px', color: '#213D69' }}
              >
                {item.label}
              </MenuItem>
            </Link>
          ) : (
            <MenuItem
              key={index}
              onClick={() => {
                handleClose();
                if (item.modal) {
                  handleDeleteClick(item.onClick);
                } else {
                  item.onClick();
                }
              }}
              sx={{ fontWeight: 400, padding: '8px 16px', color: item.color || '#213D69' }}
            >
              {item.label}
            </MenuItem>
          )
        ))}
      </Menu>

      <DeleteConfirmationModal
        open={openModal}
        handleClose={handleCloseModal}
        label={label}
        type={type}
        handleDelete={handleDelete}
      />
    </>
  );
}

export default Action;

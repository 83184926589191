import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Button from './Button';
import useDebounce from '../hooks/useDebounce';

const Container = styled.div`
  display: grid;
  grid-template-columns: ${({ items }) => '1fr '.repeat(items)};
  align-items: center;
  background-color: var(--dark-8);
  border-radius: 8px;
  padding: 2px;
  overflow: hidden;
  position: relative;
`;

const Slider = styled.div`
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
  background: #fff;
  padding: 16px 0;
  margin-top: 2px;
  border-radius: 8px;
  position: absolute;
  transition: left 0.5s;
  margin-left: 1%;
`;

function getSliderPercent(index, length) {
  return `${(((index) / length) * 100)}%`;
}
function Toggle({ options = [], activeLabel, delay = 1000 }) {
  const [sliderPosition, setSliderPosition] = useState(
    getSliderPercent(options.findIndex((o) => o.label === activeLabel), options.length),
  );
  const { debounce } = useDebounce();
  const handleClick = debounce((onClick) => onClick(), delay);

  return (
    <Container items={options?.length || 1}>
      <Slider style={{
        left: sliderPosition,
        width: `${((1 / options.length) * 100) - 2}%`,
      }}
      />
      {options.map(({ label, onClick }, index) => (
        <Button
          key={label}
          onClick={() => {
            setSliderPosition(getSliderPercent(index, options.length));
            handleClick(onClick);
          }}
          variant="toggle"
        >
          {label}
        </Button>
      ))}
    </Container>
  );
}

Toggle.propTypes = {
  /** Controls which option is active by looking for the matching label */
  activeLabel: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }).isRequired).isRequired,
  delay: PropTypes.number,
};

export default Toggle;

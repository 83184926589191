import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, Select, MenuItem } from '@mui/material';
import AlarmIcon from '@mui/icons-material/Alarm';

function TimeSelector({
  time, setTime,
}) {
  const options = [];
  for (let hour = 0; hour < 24; hour += 1) {
    const period = hour < 12 ? 'AM' : 'PM';
    const displayHour = hour % 12 === 0 ? 12 : hour % 12;
    options.push(
      `${displayHour}:00 ${period}`,
      `${displayHour}:30 ${period}`,
    );
  }
  return (
    <FormControl>
      <Select
        id="time-select"
        sx={{
          width: '130px',
          height: '40px',
          borderRadius: '8px',
        }}
        value={time}
        onChange={(e) => setTime(e.target.value)}
        IconComponent={AlarmIcon}
        displayEmpty
      >
        <MenuItem value="" disabled selected hidden key="placeholder">HH:MM</MenuItem>
        {options.map((option, index) => (
          <MenuItem value={option} key={index}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

TimeSelector.propTypes = {
  time: PropTypes.string.isRequired,
  setTime: PropTypes.func.isRequired,
};

export default TimeSelector;

import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ChildrenType } from '../propTypes';
import Error from './Error';
import ShootLogo from '../images/blue-shoot-logo.png';

export const StyledCard = styled(Card)`
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border: none;
  max-width: 560px;
  margin: 2rem auto;
  padding: 40px 41px;
  text-align: center;
  @media only screen and (min-width: 1024px) {
    margin: 6rem auto 0 auto;
  }
`;

export const Title = styled('h3')`
  font-weight: 800;
  line-height: 40,
  margin-bottom: 8,
`;

export const Description = styled('h4')`
  font-size: 14px;
  line-height: 20px;
`;

function CenterCardLayout({
  title, description, children, errors, headerImage, testId, className,
}) {
  return (
    <>
      <div className="flex-row justify-content-center p-3">
        <img src={ShootLogo} alt="logo" height="33" style={{ margin: 'auto' }} />
      </div>
      <StyledCard className={className} data-testid={testId}>
        {headerImage}
        <CardContent className="p-0">
          <Title>{title}</Title>
          <Description>{description}</Description>
          <div className="m-t24">
            {children}
          </div>
          {errors?.map((msg) => (
            <Error msg={msg} />
          ))}
        </CardContent>
      </StyledCard>
    </>
  );
}

CenterCardLayout.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  children: ChildrenType,
  headerImage: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.shape({})),
  testId: PropTypes.string,
  className: PropTypes.string,
};

export default CenterCardLayout;

import React from 'react';
import { useLocation } from 'react-router-dom';

// Get query parameters from URL by using useQuery().get("param-name")
export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const openDesktopLink = () => (process.env.REACT_APP_NODE_ENV === 'production'
  ? 'shoot://'
  : `shoot-${process.env.REACT_APP_NODE_ENV}://`);

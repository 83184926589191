import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { isEmail } from 'validator';
import {
  Modal,
  Box,
  Button,
  CircularProgress,
  InputLabel,
  FormControl,
} from '@mui/material';
import { TextInput } from '../../components/muiCustomized/TextInput';
import { ChipInput } from '../../components/muiCustomized/ChipInput';
import DatePicker from '../../components/datePicker';
import { UserContext } from '../../contexts/User';
import { NotificationContext } from '../../contexts/Notification';
import { timeToUTCDateObject } from '../../utils/time';
import API from '../../api/endpoints';
import { sendRequest } from '../../api/shootAPI';

const TextMD = styled.p`
    color: var(--dark-1, #0E1E40);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
`;

function EditTeamModal({
  closeModal,
  teamId,
}) {
  const { user } = useContext(UserContext);
  const [id, setId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [emailAddresses, setEmailAddresses] = useState([user.email]);
  const [subject, setSubject] = useState('Team Engagement Dashboard Daily Report');
  const [body, setBody] = useState('Please download the attached Team Engagement Dashboard Daily Report');
  const [deliveryTime, setDeliveryTime] = useState(null);
  const [emailError, setEmailError] = useState('');
  const { displayNotification } = useContext(NotificationContext);

  const fetchData = async () => {
    setIsLoading(true);
    const res = await sendRequest({
      method: 'get',
      url: API.TEAM.emailSchedule(teamId),
    });

    if (res.success) {
      const { emailSchedule } = res.data;
      if (emailSchedule) {
        setId(emailSchedule.id);
        setEmailAddresses(emailSchedule.emailAddresses.split(','));
        setSubject(emailSchedule.subject);
        setBody(emailSchedule.body);
        setDeliveryTime(timeToUTCDateObject(emailSchedule.deliveryTime));
      }
    } else {
      displayNotification({
        message: res.data.error,
        severity: 'error',
      });
    }
    setIsLoading(false);
  };

  const handleSubmit = async () => {
    setIsUpdating(true);
    const formData = {
      emailAddresses: emailAddresses.toString(),
      subject,
      body,
      deliveryTime: deliveryTime
        .toISOString().slice(11, 16),
    };

    const method = id ? 'put' : 'post';
    const res = await sendRequest({
      method,
      url: API.TEAM.emailSchedule(teamId),
      data: formData,
    });

    if (res.success) {
      setTimeout(() => {
        displayNotification({
          message: 'Email scheduled successfully.',
          severity: 'success',
        });
        closeModal();
      }, 500);
    } else {
      displayNotification({
        message: 'There was an issue scheduling email.',
        severity: 'error',
      });
    }
    setIsUpdating(false);
  };

  const validateEmails = (emails) => {
    const invalidEmails = emails.filter((email) => !isEmail(email));
    if (invalidEmails.length > 0) {
      const validEmails = emails.filter((email) => isEmail(email));
      setEmailAddresses(validEmails);
      setEmailError(`Invalid email: ${invalidEmails.join(', ')}`);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    validateEmails(emailAddresses);
  }, [emailAddresses]);

  return (
    <Modal open onClose={closeModal} className="flex-row align-c jstfy-c create-team-modal">
      <Box style={{
        width: 462,
        padding: 32,
        background: '#fff',
        borderRadius: 8,
      }}
      >
        <div className="modal-body p-0" style={{ minHeight: 200 }}>
          <TextMD className="mb-3">
            Schedule Email
          </TextMD>
          {isLoading && (
            <div className="position-relative">
              <div className="edit-modal loader-container">
                <CircularProgress />
              </div>
            </div>
          )}
          {!isLoading && (
            <>
              <ChipInput
                id="ChipInput"
                name="ChipInput"
                label="Email Addresses"
                helperText={emailError || 'Enter email addresses, separated by commas'}
                values={emailAddresses}
                setValues={setEmailAddresses}
                error={!!emailError}
              />
              <TextInput
                id="subject"
                name="subject"
                value={subject}
                label="Subject"
                variant="outlined"
                background="#F9F9F9"
                onChange={(e) => setSubject(e.target.value)}
                sx={{
                  padding: '0',
                  '& .MuiInputBase-input': {
                    border: '1.5px solid',
                    borderColor: 'var(--sds-input-border)',
                  },
                }}
                disabled
              />
              <TextInput
                id="body"
                name="body"
                value={body}
                label="Email Body"
                variant="outlined"
                background="#F9F9F9"
                multiline
                rows={3}
                onChange={(e) => setBody(e.target.value)}
                sx={{
                  padding: '0',
                  '& .MuiInputBase-input': {
                    border: '1.5px solid',
                    borderColor: 'var(--sds-input-border)',
                  },
                }}
              />
              <FormControl sx={{ marginBottom: '16px' }} variant="standard" fullWidth>
                <InputLabel sx={{ fontSize: '18px', color: '#212529', margin: 0 }} shrink htmlFor="delivery-time">
                  Delivery Time
                </InputLabel>
                <div className="hide-date-icon">
                  <DatePicker
                    format="HH:mm"
                    id="delivery-time"
                    name="delivery-time"
                    value={deliveryTime}
                    className="delivery-time"
                    onChange={(value) => setDeliveryTime(value)}
                    placeholder="Delivery Time"
                    placement="auto"
                  />
                </div>
              </FormControl>
            </>
          )}
        </div>

        <div className="modal-footer justify-content-end border-0 p-0 mt-2">
          <Button
            sx={{
              marginRight: 2,
              textTransform: 'none',
              fontSize: '16px',
              height: '40px',
              padding: '12px 16px 12px 16px',
            }}
            variant="outlined"
            onClick={closeModal}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            sx={{
              textTransform: 'none',
              fontSize: '16px',
              height: '40px',
              padding: '12px 16px 12px 16px',
              backgroundColor: '#429256',
              '&:hover': { backgroundColor: '#429256' },
            }}
            variant="contained"
            onClick={handleSubmit}
            disabled={isUpdating || !(emailAddresses.length && subject && body && deliveryTime)}
          >
            {isUpdating ? 'Saving...' : 'Save Schedule'}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

EditTeamModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  teamId: PropTypes.string.isRequired,
};

export default EditTeamModal;

import React, {
  useState, useMemo, useRef, useContext,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Button } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { isEmail } from 'validator';
import { Redirect } from 'react-router';
import config from '../../config';
import FormLayout from '../../components/FormLayout';
import { Images } from '../../common';
import API from '../../api/endpoints';
import { FormDataType } from '../../propTypes';
import { TextInput } from '../../components/muiCustomized/TextInput';
import { UserContext } from '../../contexts/User';
import { LinkWithQueryParams } from '../../components/utils/LinkWithQueryParams';
import useQueryParams from '../../hooks/useQueryParams';
import { useQuery } from '../../utils/routing';
import PlacesAutocomplete from '../../components/PlacesAutocomplete';
import UploadResizedPhoto from '../../components/UploadResizedPhoto';

const MENU = ['Personal Details', 'Email Password'];

function PersonalDetails({
  formData, setFormData, setMenu,
}) {
  const { user, userToken, activeWorkspace } = useContext(UserContext);
  const lastNameRef = useRef(null);
  const locationRef = useRef(null);
  const continueBtnRef = useRef(null);
  if (user && userToken) {
    if (activeWorkspace) {
      return <Redirect to="/workspaces" />;
    }
    return <Redirect to="/members/manage" />;
  }

  const onChangeHandler = (name, value) => {
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <FormLayout
      heading="Create your Shoot account"
      subheading="Enter your name and the timezone you typically work in"
    >
      <UploadResizedPhoto
        onChange={(resizedImage) => onChangeHandler('avatar', resizedImage)}
        label="Upload Profile Photo"
      />
      <TextInput
        name="first"
        value={formData?.first}
        onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
        onKeyDown={(e) => {
          const key = e.keyCode || e.which;
          if (key === 13) {
            e.preventDefault();
            lastNameRef.current.focus();
          }
        }}
        label="First Name"
        data-testid="first-name"
        placeholder="Enter your first name"
      />
      <TextInput
        inputRef={lastNameRef}
        name="last"
        value={formData?.last}
        onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
        onKeyDown={(e) => {
          const key = e.keyCode || e.which;
          if (key === 13) {
            e.preventDefault();
            locationRef.current.focus();
          }
        }}
        label="Last Name"
        data-testid="last-name"
        placeholder="Enter your last name"
      />
      <PlacesAutocomplete
        location={formData.location}
        onChange={setFormData}
        inputRef={locationRef}
        onKeyDown={(e) => {
          const key = e.keyCode || e.which;
          if (key === 13) {
            e.preventDefault();
            continueBtnRef.current.click();
          }
        }}
        label="Home Address"
        placeholder="Enter street address"
      />
      <Button
        ref={continueBtnRef}
        fullWidth
        className="nextStep-btn"
        variant="contained"
        disabled={!(formData.first && formData.last && formData.location)}
        onClick={() => setMenu(MENU[1])}
      >
        Continue
      </Button>
      <LinkWithQueryParams type="button" className="back-link" to="/">
        Sign in with existing account
      </LinkWithQueryParams>
    </FormLayout>
  );
}

PersonalDetails.propTypes = {
  formData: FormDataType,
  setFormData: PropTypes.func.isRequired,
  setMenu: PropTypes.func.isRequired,
};

const PasswordRequirement = styled.li`
  color: ${(props) => (props.valid ? 'var(--success)' : 'var(--dark-5)')};
  padding-left: 0px !important;
  transition: all ease-in-out 100ms;
`;

function EmailPassword({
  formData, setFormData, setMenu, invitedEmail,
}) {
  const queryParams = useQueryParams();
  const { startTwoFactor } = useContext(UserContext);
  const { email, password, confirmPassword } = formData;
  const { checkIcon } = Images;
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const continueBtnRef = useRef(null);
  const [formErrors, setFormErrors] = useState({});

  const emailRequirement = useMemo(() => ({
    valid: isEmail(email),
  }), [email]);

  const passwordRequirements = useMemo(() => ({
    characterMin: password && /^(?=.{8,})/.test(password),
    oneLowerAndUpper: /^(?=.*[a-z])(?=.*[A-Z])/.test(password),
    oneNumber: /^(?=.*[0-9])/.test(password),
    confirm: (password && confirmPassword && password === confirmPassword),
  }), [password, confirmPassword]);

  const handleChangeEmail = (e) => {
    setFormErrors({});
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const _onSubmit = async () => {
    const _formData = new FormData();
    Object.keys(formData).forEach((field) => {
      _formData.append(
        field,
        field === 'location' || field === 'latlng'
          ? JSON.stringify(formData[field])
          : formData[field],
      );
    });
    try {
      const response = await fetch(config.BaseUrl + API.USER.signup, {
        method: 'POST',
        body: _formData,
      });
      const data = await response.json();

      if (response.ok) {
        const { twoFactorToken } = data;
        const redirect = queryParams.get('redirect');
        startTwoFactor({ twoFactorToken, redirect });
      } else {
        setFormErrors(data);
      }
    } catch (e) {
      console.error('signup error: ', e);
    }
  };

  return (
    <FormLayout
      heading="Create your personal login"
      subheading="Enter the email and password you will use to sign in."
    >
      <div className="email-input-wrapper">
        <TextInput
          name="email"
          onChange={handleChangeEmail}
          onKeyDown={(e) => {
            const key = e.keyCode || e.which;
            if (key === 13) {
              e.preventDefault();
              passwordRef.current.focus();
            }
          }}
          value={invitedEmail ?? formData?.email}
          disabled={!!invitedEmail}
          label="Email"
          placeholder="Enter your work email"
          data-testid="email-input"
          error={!!formErrors?.email}
          helperText={formErrors?.email}
        />
        <img
          style={{ opacity: emailRequirement.valid ? 1 : 0.4 }}
          src={checkIcon}
          width="20"
          height="20"
          className="d-inline-block align-top email-check-icon"
          alt="check-mark"
        />
      </div>
      <TextInput
        inputRef={passwordRef}
        type="password"
        name="password"
        value={password}
        onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
        onKeyDown={(e) => {
          const key = e.keyCode || e.which;
          if (key === 13) {
            e.preventDefault();
            confirmPasswordRef.current.focus();
          }
        }}
        label="Password"
        data-testid="password"
        placeholder="Enter your password"
      />
      <TextInput
        inputRef={confirmPasswordRef}
        type="password"
        name="confirmPassword"
        value={confirmPassword}
        onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
        onKeyDown={(e) => {
          const key = e.keyCode || e.which;
          if (key === 13) {
            e.preventDefault();
            continueBtnRef.current.click();
          }
        }}
        label="Confirm Password"
        data-testid="confirm-password"
        placeholder="Confirm password"
      />
      <ul style={{
        fontSize: 13, marginLeft: '', marginTop: 8, margin: '-12px 0 16px -150px', padding: 0, textAlign: 'left',
      }}
      >
        <PasswordRequirement valid={passwordRequirements.characterMin}>
          At least 8 characters
        </PasswordRequirement>
        <PasswordRequirement valid={passwordRequirements.oneLowerAndUpper}>
          One lowercase & one uppercase letter
        </PasswordRequirement>
        <PasswordRequirement valid={passwordRequirements.oneNumber}>
          At least one number
        </PasswordRequirement>
        <PasswordRequirement valid={passwordRequirements.confirm}>
          Passwords match
        </PasswordRequirement>
      </ul>
      <Button
        ref={continueBtnRef}
        fullWidth
        className="nextStep-btn"
        variant="contained"
        onClick={_onSubmit}
        disabled={
          !emailRequirement.valid || !Object.values(passwordRequirements).every((requirement) => !!requirement)
        }
      >
        Create Account
      </Button>
      <button
        type="button"
        data-testid="go-back"
        className="back-link"
        onClick={() => setMenu(MENU[0])}
      >
        Go back
      </button>
    </FormLayout>
  );
}

EmailPassword.propTypes = {
  formData: FormDataType,
  setFormData: PropTypes.func.isRequired,
  setMenu: PropTypes.func.isRequired,
  invitedEmail: PropTypes.string.isRequired,
};

function Signup() {
  const {
    user, accessToken, activeWorkspace, redirect,
  } = useContext(UserContext);
  const [menu, setMenu] = useState(MENU[0]);
  const query = useQuery();
  // Get the user's email from the query parameter present went they click an invite link
  const invitedEmail = query.get('email');
  const [formData, setFormData] = useState({
    first: '',
    last: '',
    avatar: null,
    location: null,
    latlng: null,
    email: invitedEmail ?? '',
    password: '',
    confirmPassword: '',
  });

  useEffect(() => {
    if (user && accessToken) {
      // Don't redirect if the user has already signed in
      if (activeWorkspace) return redirect('/members/manage');
      return redirect('/workspaces');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {menu === MENU[0] && (
        <PersonalDetails
          formData={formData}
          setFormData={setFormData}
          setMenu={setMenu}
        />
      )}
      {menu === MENU[1] && (
        <EmailPassword
          formData={formData}
          setFormData={setFormData}
          setMenu={setMenu}
          invitedEmail={invitedEmail}
        />
      )}
    </div>
  );
}

export default Signup;

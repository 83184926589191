import React from 'react';
import { useLocation } from 'react-router-dom';
import CenterCardLayout from '../../components/CenterCardLayout';

function InvitationExpired() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ownerEmail = queryParams.get('ownerEmail');
  return (
    <CenterCardLayout
      title="Invitation Expired"
      description="Your invitation has expired. Request a new invitation from your workspace owner and try again."
    >
      <a href={`mailto:${ownerEmail}`}>
        Request to your workspace owner.
      </a>
    </CenterCardLayout>
  );
}

export default InvitationExpired;

/* eslint-disable react/jsx-props-no-spreading, no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ChildrenType } from '../propTypes';

const StyledButton = styled.button`
  // "fullWidth" - Makes the button 100% width of parent:
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  flex: 1;
  
  // Button "variant" prop options:
  // toggle - Button used within a toggle or switch:
  ${(props) => props.variant === 'toggle' && css`
    background: transparent;
    border: none;
    border-radius: 8px;
    padding: 8px 20px;
    font-size: 14px;
    position: relative
    ${props.active && css`
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
      background: #fff;
      font-weight: 500;
    `}
  `}
  // table - Buttons used within tables:
  ${(props) => props.variant === 'table' && css`
    border: 1px solid var(--dark-5);
    color: var(--dark-3);
    padding: 9px 22px;
    border-radius: 3px;
    background: #fff;
    font-weight: 500;
  `}

  ${(props) => props.variant === 'green' && css`
    background: #8BC53F;
    color: #fff;
    line-height: 1.2rem;
    font-weight: 900;
    border-radius: .25rem;
    padding: 14px 32px 16px;
    font-size: 20px;
  `}

  ${(props) => props.variant === 'link' && css`
    background: none;
    color: #007bff;
    font-size: 16px;
  `}
`;

function Button({
  children,
  variant,
  active,
  fullWidth,
  ...props
}) {
  return (
    <StyledButton variant={variant} active={active} fullWidth={fullWidth} {...props}>
      {children}
    </StyledButton>
  );
}

Button.propTypes = {
  children: ChildrenType,
  variant: PropTypes.string,
  active: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export default Button;

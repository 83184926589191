import React, { useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Box, Grid, Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import API from '../../api/endpoints';
import { sendRequest } from '../../api/shootAPI';
import { NotificationContext } from '../../contexts/Notification';
import PasswordInput from '../../components/muiCustomized/PasswordInput';

const CustomizedButton = styled(Button)`
  height: 51px;
  font-size: 16px;
  font-weight: 500;
  text-transform: none;
  margin-top: 32px;
`;

function ChangePasswordModal({ email, closeModal }) {
  const { displayNotification } = useContext(NotificationContext);
  const [password, setPassword] = useState({ current: '', new: '', confirm: '' });
  const [saving, setSaving] = useState(false);

  const passwordRequirements = useMemo(() => ({
    characterMin: password.new && /^(?=.{8,})/.test(password.new),
    oneLowerAndUpper: /^(?=.*[a-z])(?=.*[A-Z])/.test(password.new),
    oneNumber: /^(?=.*[0-9])/.test(password.new),
    confirm: (password.new && password.confirm && password.new === password.confirm),
  }), [password.new, password.confirm]);

  const handlePassword = (e) => {
    setPassword({ ...password, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    setSaving(true);
    const response = await sendRequest({
      method: 'put',
      url: API.USER.changePassword,
      data: {
        email,
        currentPassword: password.current,
        newPassword: password.new,
      },
    });
    if (response.success) {
      displayNotification({
        message: 'Password changed successfully!!!',
        severity: 'success',
      });
      closeModal();
    } else {
      displayNotification({
        message: response.data.error,
        severity: 'error',
      });
    }
    setSaving(false);
  };

  return (
    <Modal
      open
      onClose={closeModal}
      className="flex-row align-c jstfy-c"
      data-testid="change-password-modal"
    >
      <Box
        style={{
          width: 514,
          padding: 24,
          background: '#fff',
          borderRadius: 8,
        }}
      >
        <h3 className="m-b4 text-600" data-testid="modal-title">
          Change Password
        </h3>
        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>
            <PasswordInput
              label="Current Password"
              id="change-password"
              name="current"
              value={password.current}
              onChange={handlePassword}
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordInput
              label="New Password"
              id="new-password"
              name="new"
              value={password.new}
              onChange={handlePassword}
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordInput
              label="Confirm New Password"
              id="confirm-password"
              name="confirm"
              value={password.confirm}
              onChange={handlePassword}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomizedButton
              color="secondary"
              sx={{
                marginRight: '8px',
                border: 'inset',
                borderWidth: 1,
                borderColor: '#7D8698',
              }}
              fullWidth
              onClick={closeModal}
            >
              Cancel
            </CustomizedButton>
          </Grid>
          <Grid item xs={6}>
            <CustomizedButton
              variant="contained"
              fullWidth
              disabled={
                !(
                  password.current
                  && Object.values(passwordRequirements).every((requirement) => !!requirement)
                )
              }
              onClick={handleSubmit}
            >
              { saving ? 'Saving...' : 'Save Changes' }
            </CustomizedButton>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

ChangePasswordModal.propTypes = {
  email: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ChangePasswordModal;

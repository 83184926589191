import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { Box } from '@mui/material';
import { ArrowDropUpOutlined } from '@mui/icons-material';

function DropDown({ title, options }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleMenuItemClick = (index, handleClick) => {
    setOpen(false);
    handleClick();
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        sx={{
          boxShadow: 'none',
        }}
        variant="contained"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
      >
        <Button
          sx={{
            borderRadius: '4px',
            border: '1px solid var(--Dark-Dark-06, #E0E1E6)',
            backgroundColor: '#FFF',
            padding: '6px 8px 6px 16px',
            color: 'var(--Dark-Dark-01, #0E1E40)',
            textAlign: 'center',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            '&:hover': {
              backgroundColor: '#fff',
            },
          }}
          onClick={handleToggle}
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
        >
          <Box className="d-flex align-items-center justify-content-between">
            {title}
            {open
              ? <ArrowDropDownIcon sx={{ width: '16px', height: '16px' }} />
              : <ArrowDropUpOutlined sx={{ width: '16px', height: '16px' }} />}
          </Box>
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
          // eslint-disable-next-line react/jsx-props-no-spreading
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'right top' : 'right bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.text}
                      onClick={() => handleMenuItemClick(index, option.handleClick)}
                      sx={{ color: option.fontColor }}
                    >
                      {option.text}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

DropDown.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
    fontColor: PropTypes.string.isRequired,
  })),
};

export default DropDown;

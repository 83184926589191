import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';

function HomeWork({ color = 'green' }) {
  // eslint-disable-next-line no-nested-ternary
  const color1 = color === 'green' ? '#53A551' : color === 'yellow' ? '#F19E41' : '#CC4525';
  // eslint-disable-next-line no-nested-ternary
  const color2 = color === 'green' ? '#A6EAA5' : color === 'yellow' ? '#FBE48E' : '#F5C0B0';
  const title = color === 'green' ? 'Location - No Violation' : 'Location - With Violation';

  return (
    <Tooltip title={title}>
      <svg
        width="32"
        height="32"
        style={{ cursor: 'pointer' }}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="16" cy="16" r="15.5" fill={color1} />
        <circle cx="16" cy="16" r="15.5" fill="white" fillOpacity="0.9" />
        <circle cx="16" cy="16" r="15.5" stroke={color2} />
        <g clipPath="url(#clip0_7541_11572)">
          <path
            d={`M20.1669 18.5007H21.8335V20.1673H20.1669V18.5007ZM20.1669
                15.1673H21.8335V16.834H20.1669V15.1673ZM20.1669
                11.834H21.8335V13.5007H20.1669V11.834ZM17.4502
                11.834L18.5002 12.534V11.834H17.4502Z`}
            fill={color1}
          />
          <path
            d="M14.333 8.5V9.75833L15.9997 10.8667V10.1667H23.4997V21.8333H20.1663V23.5H25.1663V8.5H14.333Z"
            fill={color1}
          />
          <path
            d={`M12.808 10.75L18.4997 14.5417V23.5H6.83301V14.7333L12.808 10.75ZM14.333
            21.8333H16.833V15.3L12.808 12.7417L8.49967
            15.4833V21.8333H10.9997V16.8333H14.333V21.8333Z`}
            fill={color1}
          />
        </g>
        <defs>
          <clipPath id="clip0_7541_11572">
            <rect width="20" height="20" fill="white" transform="translate(6 6)" />
          </clipPath>
        </defs>
      </svg>
    </Tooltip>
  );
}

HomeWork.propTypes = {
  color: PropTypes.oneOf(['green', 'yellow', 'red']),
};

export default HomeWork;

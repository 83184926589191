import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { Card, CardContent, CardHeader } from '@mui/material';
import { convertSecToTimeFormat } from '../../utils/time';
import DownSVG from '../../images/dropdown-down.svg';
import RightSVG from '../../images/dropdown-right.svg';

const CustomCard = styled(Card)`
    borderRadius: "8px",
    width: 30%,
    border: "1px solid var(--Dark-Dark-06, #E0E1E6)",
    boxShadow: "none"
`;

function ActivityCard({ data }) {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <CustomCard className="daily-activities-card">
      <CardHeader
        className="hours-card-header"
        title={(
          <div className="hours-card-header-title d-flex gap-1 align-items-center">
            {data.date}
          </div>
        )}
      />
      <CardContent className="activity-card-content d-flex flex-column justify-content-between">
        <div
          className="daily-activities-column daily-activities-menu"
          onClick={toggleOpen}
          onKeyDown={(e) => { if (e.key === 'Enter') toggleOpen(); }}
          role="button"
          tabIndex={0}
          style={{ borderBottom: '1px solid #E0E1E6' }}
        >
          <p className="daily-activities-first">
            {open ? (<img src={DownSVG} alt="info icon" />) : (<img src={RightSVG} alt="info icon" />)}
            Total
          </p>
          <p className="daily-activities-second">{convertSecToTimeFormat(data.totalSeconds)}</p>
          <p className="daily-activities-third">
            {data.totalActivity}
            %
          </p>
        </div>
        {open && (
          <div>
            <div className="daily-activities-column" style={{ padding: '10px 0px' }}>
              <div className="daily-activities-first daily-activities-category">
                <div style={{ backgroundColor: '#213D69' }}>D</div>
                <p>Deep Focused</p>
              </div>
              <p className="daily-activities-second">{convertSecToTimeFormat(data.deepFocused)}</p>
              <p className="daily-activities-third">
                {data.deepFocusedActivity}
                %
              </p>
            </div>
            <div className="daily-activities-column" style={{ padding: '10px 0px' }}>
              <div className="daily-activities-first daily-activities-category">
                <div style={{ backgroundColor: '#5A81BC' }}>F</div>
                <p>Focused</p>
              </div>
              <p className="daily-activities-second">{convertSecToTimeFormat(data.focused)}</p>
              <p className="daily-activities-third">
                {data.focusedActivity}
                %
              </p>
            </div>
            <div className="daily-activities-column" style={{ padding: '10px 0px' }}>
              <div className="daily-activities-first daily-activities-category">
                <div style={{ backgroundColor: '#B5BAC4' }}>F</div>
                <p>Active</p>
              </div>
              <p className="daily-activities-second">{convertSecToTimeFormat(data.active)}</p>
              <p className="daily-activities-third">
                {data.activeActivity}
                %
              </p>
            </div>
            <div className="daily-activities-column" style={{ padding: '10px 0px' }}>
              <div className="daily-activities-first daily-activities-category">
                <div style={{ backgroundColor: '#F19E41' }}>F</div>
                <p>Work Break</p>
              </div>
              <p className="daily-activities-second">{convertSecToTimeFormat(data.workBreak)}</p>
              <p className="daily-activities-third">
                -
              </p>
            </div>
          </div>
        )}
      </CardContent>
    </CustomCard>
  );
}

ActivityCard.propTypes = {
  data: PropTypes.shape({
    date: PropTypes.string.isRequired,
    active: PropTypes.number.isRequired,
    activeActivity: PropTypes.number.isRequired,
    focused: PropTypes.number.isRequired,
    focusedActivity: PropTypes.number.isRequired,
    deepFocused: PropTypes.number.isRequired,
    deepFocusedActivity: PropTypes.number.isRequired,
    totalSeconds: PropTypes.number.isRequired,
    totalActivity: PropTypes.number.isRequired,
    workBreak: PropTypes.number.isRequired,
  }),
};

export default ActivityCard;

import styled from '@emotion/styled';

export const Description = styled.p`
    font-size: ${(props) => `${props.fontSize}px`};
    color: ${(props) => props.color};
    font-weight: ${(props) => props.fontWeight};
    margin-top: ${(props) => `${props.marginTop}px`};
`;

export const StyledInput = styled.input`
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  padding: 13px 16px;
  border-radius: 8px;
  color: #0E1E40;
  border: 1px solid #E0E1E6;
  margin-bottom: 12px;

  &:focus {
    border-color: #315285;
  }
`;

export const StyledText = styled.textarea`
  width: 100%;
  height: 89px;
  font-size: 16px;
  font-weight: 400;
  padding: 13px 16px;
  border-radius: 8px;
  color: #0E1E40;
  border: 1px solid #E0E1E6;
  margin-bottom: 12px;
  &:focus {
    border-color: #315285;
  }
`;

export const StyledCard = styled.div`
  width: 100%;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

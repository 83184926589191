/* eslint-disable max-len */
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Box, Button, FormControl, FormLabel, Grid, Checkbox, MenuItem, Select,
} from '@mui/material';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { UserContext } from '../../contexts/User';
import { NotificationContext } from '../../contexts/Notification';
import DatePicker from '../../components/datePicker';
import { getTimeString } from '../../utils/time';
import {
  Description, StyledCard, StyledInput, StyledText,
} from '../../components/styledComponents';

function RequestModal({
  closeModal, policies, balances, requests, setLoading,
}) {
  const { member } = useContext(UserContext);
  const {
    displayNotification,
  } = useContext(NotificationContext);

  const getHours = (value) => {
    const inputs = value.split(':');
    if (inputs[0] && inputs[1]) {
      return (parseInt(inputs[0], 10) * 60 + parseInt(inputs[1], 10));
    }
    return 0;
  };

  const [policy, setPolicy] = useState(policies.length > 0 ? policies[0].id : 0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [allDay, setAllDay] = useState(true);
  const [totalHours, setTotalHours] = useState('08:00');
  const [reason, setReason] = useState('');
  const [currentBalance, setCurrentBalance] = useState(getTimeString(0));
  const [pendingHours, setPendingHours] = useState(getTimeString(0));
  const [remaining, setRemaining] = useState(getTimeString(0));

  useEffect(() => {
    let pending = 0;
    requests.forEach((item) => {
      if (item.policyId === policy && item.status === 'Submitted') { pending += item.totalHours; }
    });
    setPendingHours(getTimeString(pending));
    const requested = getHours(totalHours);
    const balance = balances.find((item) => item.policyId === policy);
    if (balance) {
      setCurrentBalance(getTimeString(balance.balance));
      setRemaining(getTimeString(balance.balance - pending - requested));
    } else {
      setCurrentBalance(getTimeString(0));
      setRemaining(getTimeString(0));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policy, totalHours]);

  const handleSubmit = async () => {
    const submitData = {
      policyId: policy,
      totalHours: getHours(totalHours),
      startDate,
      endDate,
      allDay,
      status: 'Submitted',
      reason,
    };
    if (member) {
      setLoading(true);
      const response = await sendRequest({
        method: 'post',
        url: API.WORKSPACE.createOffTimeRequest(member.workspaceId, member.id),
        data: submitData,
      });
      if (response.success) {
        displayNotification({
          message: 'Time off request submitted successfully.',
          severity: 'success',
        });
        closeModal();
      } else {
        displayNotification({
          message: 'An error occurs while submitting data.',
          severity: 'error',
        });
      }
      setLoading(false);
    }
  };

  return (
    <Modal open onClose={() => closeModal(false)} className="flex-row align-c jstfy-c create-team-modal">
      <Box style={{
        width: 1044,
        padding: 32,
        background: '#fff',
        borderRadius: 8,
      }}
      >
        <div className="modal-body" style={{ padding: 0 }}>
          <Description
            fontSize={20}
            fontWeight={600}
            color="#0E1E40"
            marginTop={0}
          >
            Request Time Off
          </Description>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Description
                fontSize={14}
                fontWeight={500}
                color="#293855"
                marginTop={12}
              >
                Policy
              </Description>
              <FormControl sx={{ width: '100%', marginTop: '12px' }}>
                <Select
                  defaultValue={policy}
                  onChange={(e) => setPolicy(e.target.value)}
                >
                  {policies.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Description
                fontSize={14}
                fontWeight={500}
                color="#293855"
                marginTop={12}
              >
                Time Span
              </Description>
              <Box sx={{ display: 'flex', alignItems: 'center', margin: '12px 0px' }}>
                <DatePicker
                  oneTap
                  value={startDate}
                  onChange={(_date) => setStartDate(_date)}
                  isDisabledPast
                />
                <span style={{ margin: '0px 8px' }}>to</span>
                <DatePicker
                  oneTap
                  value={endDate}
                  onChange={(_date) => setEndDate(_date)}
                  isDisabledPast
                />
              </Box>
              <Box sx={{ display: 'flex', margin: '12px 0px' }}>
                <Checkbox sx={{ padding: '0px' }} onChange={() => setAllDay(!allDay)} checked={allDay} />
                <Description
                  fontSize={14}
                  fontWeight={600}
                >
                  All Day
                </Description>
              </Box>
              <FormControl sx={{ width: '100%' }}>
                <FormLabel
                  sx={{
                    color: '#293855',
                    fontSize: '14px',
                  }}
                >
                  Total Time Off Hours
                </FormLabel>
                <StyledInput onChange={(e) => setTotalHours(e.target.value)} value={totalHours} />
              </FormControl>
              <FormControl sx={{ width: '100%' }}>
                <FormLabel
                  sx={{
                    color: '#293855',
                    fontSize: '14px',
                  }}
                >
                  Time Off Reason
                </FormLabel>
                <StyledText onChange={(e) => setReason(e.target.value)} value={reason} placeholder="Required" />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ paddingLeft: '24px', paddingTop: '38px', borderLeft: '1px solid #E0E1E6' }}>
                <Box sx={{ paddingBottom: '16px', borderBottom: '1px solid #E0E1E6' }}>
                  <StyledCard>
                    <span>Current Balance</span>
                    <span>{currentBalance}</span>
                  </StyledCard>
                  <StyledCard>
                    <span>Hours Requested</span>
                    <span>{totalHours}</span>
                  </StyledCard>
                  <StyledCard>
                    <span>Other Pending Approval</span>
                    <span>{pendingHours}</span>
                  </StyledCard>
                </Box>
                <Box sx={{ paddingTop: '16px' }}>
                  <StyledCard>
                    <span>Remaining Balance</span>
                    <span>{remaining}</span>
                  </StyledCard>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
        <div
          className="modal-footer-customized"
          style={{
            display: 'flex',
            justifyContent: 'right',
          }}
        >
          <Button
            sx={{
              marginRight: 2,
              textTransform: 'none',
              fontSize: '16px',
              height: '40px',
              width: '101px',
            }}
            variant="outlined"
            onClick={() => closeModal(false)}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            sx={{
              textTransform: 'none',
              fontSize: '16px',
              height: '40px',
              width: '154px',
              backgroundColor: 'var(--green-3, #53A551)',
              color: '#FFFFFF',
              '&:hover': { backgroundColor: 'var(--green-3, #53A551)' },
            }}
            variant="contained"
            onClick={() => handleSubmit()}
          >
            Submit Request
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

RequestModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  policies: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired),
  balances: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    balance: PropTypes.number.isRequired,
    policyId: PropTypes.number.isRequired,
  }).isRequired),
  requests: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    totalHours: PropTypes.number.isRequired,
    policyId: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired),
  setLoading: PropTypes.func,
};

export default RequestModal;

/* eslint-disable no-fallthrough */
/* eslint-disable max-len */
import React, {
  useState, useEffect, useContext, useMemo,
} from 'react';
import {
  Box,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import NavigationLayout from '../../components/NavigationLayout';
import CustomTable from '../../components/table/CustomTable';
import DropDown from '../../components/dropdown';
import theme from './theme';
import TabsWithPanels from '../../components/tabsWithPanel';
import { UserContext } from '../../contexts/User';
import ViewRequestModal from './ViewRequestModal';
import ApproveRequestModal from './ApproveRequestModal';
import DenyRequestModal from './DenyRequestModal';

const FIXED_TABLE_HEADERS = [
  {
    label: 'Member',
    sortable: true,
  },
  {
    label: 'Policy Name',
    sortable: true,
  },
  {
    label: 'Reason',
  },
  {
    label: 'Start',
  },
  {
    label: 'End',
  },
  {
    label: 'Hours Requested',
    sortable: true,
  },
  {
    label: 'Status',
    sortable: true,
  },
  {
    label: 'Requested On',
  },
  {
    label: 'Action',
  },
];

function PanelAction() {
  return (
    <Box alignSelf="center" marginLeft="auto">
      <div className="searchBox" style={{ marginRight: '8px' }}>
        <input type="text" placeholder="Search Team Members" onChange={(e) => console.log(e)} />
        <i className="fa fa-search searchIcon" style={{ color: 'black !important' }} />
      </div>
    </Box>
  );
}

function MembersPto() {
  const { member } = useContext(UserContext);
  const [requests, setRequests] = useState([]);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showDenyModal, setShowDenyModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState();

  const getData = async () => {
    const response = await sendRequest({
      method: 'get', url: API.WORKSPACE.getTeamOffTimeData(member.workspaceId, member.id),
    });
    if (response.data) {
      setRequests(response.data.offTimeRequests);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableData = useMemo(() => {
    const allData = [];
    const submittedData = [];
    const approvedData = [];
    const deniedData = [];
    requests.forEach((request) => {
      const dateOptions = {
        weekday: 'short',
        month: 'short',
        day: '2-digit',
        year: 'numeric',
      };
      const startDate = new Date(request.startDate);
      const endDate = new Date(request.endDate);
      const createDate = new Date(request.createdAt);
      const hours = Math.floor(request.totalHours / 60) < 10 ? `0${Math.floor(request.totalHours / 60)}` : Math.floor(request.totalHours / 60);
      const minutes = request.totalHours % 60 < 10 ? `0${request.totalHours % 60}` : request.totalHours % 60;
      const options = [
        {
          text: 'View Request',
          fontColor: '#061232',
          handleClick: () => {
            setSelectedRequest(request);
            setShowViewModal(true);
          },
        },
        {
          text: 'Approve',
          fontColor: '#061232',
          handleClick: () => {
            setSelectedRequest(request);
            setShowApproveModal(true);
          },
        },
        {
          text: 'Deny Request',
          fontColor: '#CC4525',
          handleClick: () => {
            setSelectedRequest(request);
            setShowDenyModal(true);
          },
        },
      ];

      if (request.status === 'Approved') {
        options.splice(1, 1);
      } else if (request.status === 'Denied') {
        options.splice(2, 1);
      }

      const datum = {
        member: `${request.member.user.firstName} ${request.member.user.lastName}`,
        policyName: request.offTimePolicy.name,
        reason: request.reason,
        start: startDate.toLocaleDateString('en-US', dateOptions),
        emd: endDate.toLocaleDateString('en-US', dateOptions),
        hours: `${hours}:${minutes}`,
        status: request.status,
        requestOn: createDate.toLocaleDateString('en-US', dateOptions),
        action: (<DropDown title="Actions" options={options} />),
      };
      allData.push(datum);
      switch (request.status) {
        case 'Submitted':
          submittedData.push(datum);
          break;
        case 'Approved':
          approvedData.push(datum);
          break;
        default:
          deniedData.push(datum);
      }
    });
    return {
      allData,
      submittedData,
      approvedData,
      deniedData,
    };
  }, [requests]);

  const TABS = [
    { label: 'Submitted', length: tableData.submittedData.length },
    { label: 'Approved', length: tableData.approvedData.length },
    { label: 'Denied', length: tableData.deniedData.length },
    { label: 'All', length: tableData.allData.length },
  ];
  const tabPanels = TABS.map((tab) => {
    switch (tab.label) {
      case 'Submitted':
        return <CustomTable headers={FIXED_TABLE_HEADERS} items={tableData.submittedData} />;
      case 'Approved':
        return <CustomTable headers={FIXED_TABLE_HEADERS} items={tableData.approvedData} />;
      case 'Denied':
        return <CustomTable headers={FIXED_TABLE_HEADERS} items={tableData.deniedData} />;
      default:
        return <CustomTable headers={FIXED_TABLE_HEADERS} items={tableData.allData} />;
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <NavigationLayout title="Team Member's Time Off">
        <TabsWithPanels tabItems={TABS} tabPanels={tabPanels} PanelAction={PanelAction} />
      </NavigationLayout>
      {
        showViewModal && (
        <ViewRequestModal
          closeModal={() => setShowViewModal(false)}
          request={selectedRequest}
          handleApprove={() => setShowApproveModal(true)}
          handleDeny={() => setShowDenyModal(true)}
        />
        )
      }
      {
        showApproveModal && (
        <ApproveRequestModal
          closeModal={() => setShowApproveModal(false)}
          request={selectedRequest}
          getData={getData}
        />
        )
      }
      {
        showDenyModal && (
        <DenyRequestModal
          closeModal={() => setShowDenyModal(false)}
          request={selectedRequest}
          getData={getData}
        />
        )
      }
    </ThemeProvider>
  );
}

MembersPto.propTypes = {

};

export default MembersPto;

/* eslint-disable no-nested-ternary */
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import UnfoldMore from '@mui/icons-material/UnfoldMore';
import NewMemberModal from './NewMemberModal';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { UserContext } from '../../contexts/User';
import './members.css';
import DeleteMemberConfirmationModal from './DeleteMemberConfirmationModal';
import NavigationLayout from '../../components/NavigationLayout';
import EditMemberModal from './EditMemberModal';
import Pagination from '../../components/pagination';
import useDebounce from '../../hooks/useDebounce';
import CustomTable from '../../components/table/CustomTable';
import MemberNameEmailColumn from '../../components/table/MemberNameEmailColumn';
import UserEditColumn from '../../components/table/UserEditColumn';

const TABLE_HEADERS = [
  {
    label: 'Name',
    sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'name',
    colSpan: 1.5,
  },
  {
    label: 'Account Type',
    sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'accountType',
  },
  {
    label: 'Department',
    sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'department',
  },
  {
    label: 'Designation',
    sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'designation',
  },
  {
    label: 'Status',
  },
  {
    label: '',
    colSpan: 0.5,
  },
];

const CustomTableStyles = {
  header: {
    backgroundColor: 'var(--dark-10)',
    fontSize: '16px',
    '.MuiTableSortLabel-iconDirectionAsc': {
      transform: 'rotate(0deg) !important',
    },
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff !important',
    },
    '&:hover': {
      backgroundColor: 'var(--dark-10) !important',
    },
    border: 'none',
    '& td': {
      border: 'none',
    },
  },
};

function Members() {
  const { search } = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(search);
  const page = query.get('page');
  const { activeWorkspace } = useContext(UserContext);
  const [showNewMemberModal, setShowNewMemberModal] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState(null);
  const [memberToEdit, setMemberToEdit] = useState(null);
  const [members, setMembers] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [totalCounts, setTotalCounts] = useState(0);
  const [currentPage, setCurrentPage] = useState(page ? parseInt(page, 10) : 1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { debounce } = useDebounce();

  const getMembers = useCallback(async (params) => {
    if (activeWorkspace) {
      setIsLoading(true);
      const response = await sendRequest({
        method: 'get',
        url: API.WORKSPACE.members(activeWorkspace.id),
        params: {
          ...params,
        },
      });

      if (response.success) {
        if (response.data?.members?.length) {
          const mappedMembers = response.data?.members.map((member) => ({
            name: <MemberNameEmailColumn
              profilePicture={member?.user?.profilePicture}
              firstName={member.haveJoined ? member?.user?.firstName : ''}
              lastName={member.haveJoined ? member?.user?.lastName : ''}
              email={member?.user?.email}
              component="div"
            />,
            accountType: member.accountType,
            department: member.department,
            designation: member.designation,
            status: member.haveJoined ? 'Active' : 'Invited',
            actions: <UserEditColumn
              member={member}
              deactivateMember={() => setMemberToDelete(member)}
              editMember={() => setMemberToEdit(member)}
              style={{ float: 'right' }}
            />,
          }));
          setMembers(mappedMembers);
        }
        setTotalPages(response.data?.pageInfo.totalPages ?? 1);
        setTotalCounts(response.data?.pageInfo.totalCounts ?? 0);
        setIsLoading(false);
      }
    }
  }, [activeWorkspace]);

  const fetchResults = async () => {
    const params = {
      page: currentPage,
      name: searchQuery,
      pageSize,
    };
    await getMembers(params);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(debounce((query) => setSearchQuery(query), 500), []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    history.push(`?page=${page}`);
  };

  const handlePageSizeChange = (pageSize) => {
    setCurrentPage(1);
    setTotalPages(Math.ceil(totalCounts / pageSize));
    setPageSize(pageSize);
  };

  const handleSort = debounce((sortKey, sortOrder) => {
    setIsLoading(true);
    return getMembers({
      page: currentPage,
      name: searchQuery,
      sortKey,
      sortOrder,
    });
  }, 250);

  useEffect(() => {
    fetchResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchQuery, pageSize]);

  return (
    <div>
      {showNewMemberModal
        && (
          <NewMemberModal
            closeModal={() => setShowNewMemberModal(false)}
            getMembers={getMembers}
          />
        )}
      {memberToDelete
        && (
          <DeleteMemberConfirmationModal
            member={memberToDelete}
            getMembers={getMembers}
            closeModal={() => setMemberToDelete(null)}
          />
        )}
      {memberToEdit
        && (
          <EditMemberModal
            member={memberToEdit}
            getMembers={getMembers}
            closeModal={() => setMemberToEdit(null)}
          />
        )}
      <NavigationLayout title="Workspace Members" subtitle="Manage members within your workspace">
        <div className="flex-row justify-content-between" style={{ marginTop: 28 }}>
          <div className="searchBox">
            <input
              type="text"
              placeholder="Search for user"
              onChange={(e) => handleSearch(e.target.value)}
              data-testid="search"
            />
            <i className="fa fa-search searchIcon" />
          </div>
          <Button
            variant="contained"
            onClick={() => {
              setShowNewMemberModal(true);
            }}
            startIcon={<Add fontSize="small" />}
            data-testid="new-member"
          >
            New Member
          </Button>
        </div>
        <CustomTable
          headers={TABLE_HEADERS}
          items={members}
          loading={isLoading}
          handleSort={handleSort}
          styles={CustomTableStyles}
        />
        <Pagination
          currentPage={currentPage}
          pageSize={pageSize}
          totalPages={totalPages}
          totalCounts={totalCounts}
          onPageChange={handlePageChange}
          setPageSize={handlePageSizeChange}
          styles={{ margin: 0 }}
        />
      </NavigationLayout>
    </div>
  );
}

export default Members;

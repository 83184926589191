import React, { useState, useEffect, useContext } from 'react';
import { Button, Box, Typography } from '@mui/material';
import { UserContext } from '../../contexts/User';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import './settings.css';

function SettingsTab() {
  const { activeWorkspace, setActiveWorkspace } = useContext(UserContext);
  const [avatar, setAvatar] = useState('');

  useEffect(() => {
    (async () => {
      const response = await sendRequest({
        method: 'get', url: API.WORKSPACE.getWorkspacePicture(activeWorkspace.id),
      });
      setAvatar(response.data.picture);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleImageUpload = async (event) => {
    const _formData = new FormData();
    _formData.append('avatar', event.target.files[0]);
    try {
      const response = await sendRequest({
        method: 'put',
        url: API.WORKSPACE.updateWorkspacePicture(activeWorkspace.id),
        data: _formData,
      });
      if (response.success) {
        setActiveWorkspace({
          ...activeWorkspace,
          picture: response.data.picture,
        });
        setAvatar(response.data.picture);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('workspace picture update error:', e);
    }
  };

  const handleImageRemove = async () => {
    try {
      const response = await sendRequest({
        method: 'delete',
        url: API.WORKSPACE.removeWorkspacePicture(activeWorkspace.id),
      });
      if (response.success) {
        setAvatar('');
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('workspace picture remove error:', e);
    }
  };

  return (
    <>
      <Box className="logo-wrapper">
        <Typography>Workspace Logo</Typography>
        {avatar ? (
          <Box
            sx={{
              width: '132px',
              height: '132px',
              borderRadius: '8px',
              bgcolor: '#F4F6F7',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '16px',
              marginTop: '16px',
            }}
          >
            <img src={avatar} alt="Profile" className="photo-preview" />
          </Box>
        ) : (
          // eslint-disable-next-line react/no-unescaped-entities
          <p className="no-logo">You didn't upload any logo yet.</p>
        )}
        <input
          type="file"
          accept="originalImage/*"
          onChange={handleImageUpload}
          className="photo-upload-btn"
          id="contained-button-file"
        />
        <label htmlFor="contained-button-file">
          {avatar ? (
            <Button
              sx={{
                marginLeft: 'auto',
                font: 'Inter',
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: '500',
                border: '1px solid',
                textTransform: 'none',
                borderRadius: '4px',
                borderColor: '#213D069',
                color: '#213D69',
                padding: '7px 29px',
              }}
              variant="link"
              color="primary"
              component="span"
            >
              Change Logo
            </Button>
          ) : (
            <Button
              sx={{
                marginLeft: 'auto',
                font: 'Inter',
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: '500',
                border: '1px solid',
                textTransform: 'none',
                borderRadius: '4px',
                borderColor: '#213D069',
                color: '#213D69',
                padding: '7px 29px',
              }}
              variant="link"
              color="primary"
              component="span"
            >
              Upload Logo
            </Button>
          )}
        </label>
        {avatar && (
          <Button
            sx={{
              marginLeft: '20px',
              font: 'Inter',
              fontSize: '16px',
              lineHeight: '24px',
              fontWeight: '500',
              border: '1px solid',
              textTransform: 'none',
              borderRadius: '4px',
              backgroundColor: '#CC4525',
              color: '#FFFFFF',
              padding: '7px 29px',
            }}
            variant="link"
            onClick={handleImageRemove}
            className="button-remove"
            color="primary"
            component="span"
          >
            Remove
          </Button>
        )}
      </Box>
      <Box className="workspace-delete">
        <Typography>Delete Workspace</Typography>
        <p className="headline-delete">Deleting a Shoot workspace can’t be undone.</p>
        <Button variant="contained" className="button-delete" disableRipple>Delete Workspace</Button>
      </Box>
    </>
  );
}

export default SettingsTab;

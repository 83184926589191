import React, {
  createContext, useMemo, useState, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { sendRequest, shootAPI } from '../../api/shootAPI';
import API from '../../api/endpoints';

const savedUserToken = localStorage.getItem('shoot_user_token') ?? null;
const savedToken = localStorage.getItem('shoot_auth_token') ?? null;
const savedRefreshToken = localStorage.getItem('shoot_refresh_token') ?? null;
if (savedToken) shootAPI.defaults.headers.common.Authorization = `Bearer ${savedToken}`;
else {
  // If a full auth token wasn't found, check for an incomplete sign in (2FA token)
  const savedTwofactorToken = localStorage.getItem('shoot_twofactor_token') ?? null;
  if (savedToken) shootAPI.defaults.headers.common.Authorization = `Bearer ${savedTwofactorToken}`;
  else if (savedUserToken) shootAPI.defaults.headers.common.Authorization = `Bearer ${savedUserToken}`;
}

const savedUser = localStorage.getItem('shoot_user') ? JSON.parse(localStorage.getItem('shoot_user')) : null;
const savedMember = localStorage.getItem('shoot_member') ? JSON.parse(localStorage.getItem('shoot_member')) : null;
const savedWorkspaces = localStorage.getItem('shoot_workspaces')
  ? JSON.parse(localStorage.getItem('shoot_workspaces')) : null;
const savedActiveWorkspace = localStorage.getItem('shoot_active_workspace')
  ? JSON.parse(localStorage.getItem('shoot_active_workspace')) : null;

const DEFAULT_DATA = {
  user: savedUser, // data of currently logged in user
  member: savedMember,
  twoFactorToken: null, // auth token for generating and validating 2FA codes through the Shoot API
  workspaces: savedWorkspaces, // list of available workspaces associated with user
  activeWorkspace: savedActiveWorkspace, // the active workspace
  refreshSessionTimeout: null,
  qrCode: null,
};

const DEFAULT_TOKENS = {
  refreshToken: savedRefreshToken,
  accessToken: savedToken,
  userToken: savedUserToken,
};

export const UserContext = createContext();

export function UserProvider({ children }) {
  const history = useHistory();
  const [data, setData] = useState(DEFAULT_DATA);
  const [tokens, setTokens] = useState(DEFAULT_TOKENS);

  const redirect = (route) => history.push(route);

  const setWorkspaceMember = (accessToken) => {
    const { user, member, workspace } = jwtDecode(accessToken);
    setData({
      ...data, user, member, activeWorkspace: workspace,
    });
    localStorage.setItem('shoot_user', JSON.stringify(user));
    localStorage.setItem('shoot_member', JSON.stringify(member));
    localStorage.setItem('shoot_active_workspace', JSON.stringify(workspace));
  };

  const updateTokens = useCallback(({
    refreshToken, accessToken, userToken, twoFactorToken,
  }) => {
    // add token to all future requests to Shoot API
    if (twoFactorToken) shootAPI.defaults.headers.common.Authorization = `Bearer ${twoFactorToken}`;
    else if (accessToken) shootAPI.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    else if (userToken) shootAPI.defaults.headers.common.Authorization = `Bearer ${userToken}`;
    if (accessToken) {
      localStorage.setItem('shoot_auth_token', accessToken);
      setWorkspaceMember(accessToken);
    }
    if (refreshToken) localStorage.setItem('shoot_refresh_token', refreshToken);
    if (userToken) localStorage.setItem('shoot_user_token', userToken);
    setTokens((prev) => ({
      accessToken: accessToken ?? prev.accessToken,
      refreshToken: refreshToken ?? prev.refreshToken,
      userToken: userToken ?? prev.userToken,
      twoFactorToken: twoFactorToken ?? prev.twoFactorToken,
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signIn = ({ userToken, refreshToken }) => {
    if (userToken && refreshToken) {
      updateTokens({ userToken, refreshToken });
      const { user } = jwtDecode(userToken);
      setData({
        ...data, user,
      });
      localStorage.setItem('shoot_user', JSON.stringify(user));
    }
  };

  const selectWorkspace = async (workspaceId, userInfo) => {
    let query = { workspaceId };
    if (data.user.role === 'Superadmin' && userInfo) {
      query = { ...query, userInfo };
    }
    try {
      const res = await sendRequest({
        method: 'post',
        url: API.AUTH.workspace,
        data: query,
      });
      if (res.success) {
        const {
          accessToken, refreshToken, twoFactorToken, qrCode,
        } = res.data;
        updateTokens({ accessToken, refreshToken, twoFactorToken });
        if (qrCode) {
          return {
            success: true, qrCode, twoFactorToken, accessToken: null,
          };
        }
        if (twoFactorToken) { // Two-factor method of this workspace is active
          return { success: true, twoFactorToken, accessToken: null };
        }

        setWorkspaceMember(accessToken);
        return { success: true, twoFactorToken: null, accessToken };
      }
      return { success: false };
    } catch {
      // eslint-disable-next-line no-console
      console.log('Sign in workspace failed.');
      return { success: false };
    }
  };

  const signOut = useCallback(({ redirectToLogin = true } = {}) => {
    try {
      sendRequest({
        method: 'post',
        url: API.AUTH.logout,
        headers: { Authorization: `Bearer ${tokens.refreshToken}` },
      });
    } catch {
      // eslint-disable-next-line no-console
      console.log('Refresh token logout failed.');
    }
    // We must clear the timeout, otherwise we will continue trying to refresh the session

    // add token to all future requests to Shoot API
    shootAPI.defaults.headers.common.Authorization = null;
    setData({
      user: null,
      member: null,
      twoFactorToken: null,
      workspaces: null,
      activeWorkspace: null,
    });
    setTokens({ accessToken: null, refreshToken: null });
    localStorage.removeItem('shoot_user_token');
    localStorage.removeItem('shoot_auth_token');
    localStorage.removeItem('shoot_refresh_token');
    localStorage.removeItem('shoot_twofactor_token');
    localStorage.removeItem('shoot_user');
    localStorage.removeItem('shoot_member');
    localStorage.removeItem('shoot_workspaces');
    localStorage.removeItem('shoot_active_workspace');
    if (redirectToLogin) history.push('/');
  }, [history, tokens.refreshToken]);

  // A twofactor auth token was received upon signing in--redirect user to enter their code
  const startTwoFactor = ({ twoFactorToken = null, qrCode = null, redirect }) => {
    if (twoFactorToken || qrCode) {
      localStorage.setItem('shoot_twofactor_token', twoFactorToken);
      shootAPI.defaults.headers.common.Authorization = `Bearer ${twoFactorToken}`;
      setData({ ...data, twoFactorToken, qrCode });
      history.push(`/twoFactor${redirect ? `?redirect=${redirect}` : ''}`);
    }
  };

  // =========== Setters ===========
  const setWorkspaces = (workspaces) => {
    localStorage.setItem('shoot_workspaces', JSON.stringify(workspaces));
    setData({ ...data, workspaces });
  };

  const setActiveWorkspace = (activeWorkspace) => {
    localStorage.setItem('shoot_active_workspace', JSON.stringify(activeWorkspace));
    setData({ ...data, activeWorkspace });
  };

  const setUserAccount = (userToken) => {
    // updateTokens({ userToken });
    const { user } = jwtDecode(userToken);
    setData({
      ...data, user,
    });
    localStorage.setItem('shoot_user', JSON.stringify(user));
  };

  const contextValue = useMemo(() => ({
    ...data,
    ...tokens,
    redirect,
    signIn,
    signOut,
    selectWorkspace,
    startTwoFactor,
    setActiveWorkspace,
    setWorkspaces,
    setWorkspaceMember,
    setUserAccount,
    updateTokens,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [data, tokens, signOut]);

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

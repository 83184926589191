/* eslint-disable max-len */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Box, Button, FormHelperText,
} from '@mui/material';
import CancelIcon from '../../images/cancel.svg';
import { sendRequest } from '../../api/shootAPI';
import { Description, StyledText } from '../../components/styledComponents';
import API from '../../api/endpoints';
import { UserContext } from '../../contexts/User';
import { NotificationContext } from '../../contexts/Notification';

function DenyRequestModal({
  closeModal, request, getData,
}) {
  const { member } = useContext(UserContext);
  const {
    displayNotification,
  } = useContext(NotificationContext);
  const [saving, setSaving] = useState(false);
  const [denyReason, setDenyReason] = useState('');
  const [error, setError] = useState({});
  const startDate = new Date(request.startDate);
  const endDate = new Date(request.endDate);

  const dateOptions = {
    weekday: 'short',
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  };

  const handleApprove = async () => {
    setSaving(true);
    const response = await sendRequest({
      method: 'put',
      url: API.WORKSPACE.denyOffTimeRequest(member.workspaceId, request.id),
      data: { denyReason },
    });
    if (response.success) {
      displayNotification({
        message: 'Time off request denied successfully.',
        severity: 'success',
      });
      getData();
      closeModal();
    } else {
      displayNotification({
        message: 'An error occurs while denying data.',
        severity: 'error',
      });
      setError(response.data);
    }
    setSaving(false);
  };

  return (
    <Modal open onClose={closeModal} className="flex-row align-c jstfy-c create-team-modal">
      <Box style={{
        width: 519,
        padding: 32,
        background: '#fff',
        borderRadius: 8,
      }}
      >
        <div className="modal-body" style={{ padding: 0 }}>
          <img src={CancelIcon} alt="cancel icon" />
          <Description
            fontSize={20}
            fontWeight={600}
            color="#0E1E40"
            marginTop={16}
          >
            Are you sure you want to deny this request?
          </Description>
          <Description
            fontSize={14}
            fontWeight={400}
            color="#293855"
            marginTop={8}
          >
            You’re about to deny the “
            <span className="text-600">{startDate.toLocaleDateString('en-US', dateOptions)}</span>
            {' '}
            to
            <span className="text-600">{endDate.toLocaleDateString('en-US', dateOptions)}</span>
            ” requested PTO for “
            <span className="text-600">{`${request.member.user.firstName} ${request.member.user.lastName}`}</span>
            ”. You can approve this request again if needed.
          </Description>
          <Description
            fontSize={14}
            fontWeight={500}
            color="#293855"
            marginTop={8}
          >
            Reason for Denial
          </Description>
          <div className="mt-2">
            <StyledText
              onChange={(e) => setDenyReason(e.target.value)}
              value={denyReason}
              placeholder="Required"
              style={{ marginBottom: 0 }}
            />
            <FormHelperText error={!!error} style={{ margin: '0px 4px 0' }}>
              {error.denyReason}
            </FormHelperText>
          </div>
        </div>
        <div
          className="modal-footer-customized d-flex justify-content-start mt-3"
        >
          <Button
            sx={{
              marginRight: 2,
              textTransform: 'none',
              fontSize: '16px',
              height: '40px',
              width: '101px',
            }}
            variant="outlined"
            onClick={() => {
              closeModal();
            }}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            sx={{
              textTransform: 'none',
              fontSize: '16px',
              height: '40px',
              width: '110px',
              color: '#FFFFFF',
            }}
            variant="contained"
            disabled={saving}
            onClick={async () => {
              await handleApprove();
            }}
            color="error"
          >
            {saving ? 'Denying...' : 'Deny'}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

DenyRequestModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  getData: PropTypes.func.isRequired,
  request: PropTypes.shape({
    id: PropTypes.number.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    member: PropTypes.shape({
      user: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
};

export default DenyRequestModal;

import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { Card, CardContent, CardHeader } from '@mui/material';

const CustomCard = styled(Card)`
    borderRadius: "8px",
    width: 30%,
    border: "1px solid var(--Dark-Dark-06, #E0E1E6)",
    boxShadow: "none"
`;

function TimeCard({
  icon, title, color, value,
}) {
  return (
    <CustomCard className="hours-card">
      <CardHeader
        className="hours-card-header"
        title={(
          <div className="hours-card-header-title d-flex gap-1 align-items-center">
            {title}
          </div>
        )}
      />
      <CardContent
        sx={{ height: '72px' }}
        className="hours-card-content d-flex flex-column justify-content-between"
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="total-hours-label mt-1" style={{ color }}>
            {value}
          </div>
          <img src={icon} width="48" height="48" alt="timer" />
        </div>
      </CardContent>
    </CustomCard>
  );
}

TimeCard.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default TimeCard;

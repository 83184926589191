import React, {
  useState, useContext,
} from 'react';
import './settings.css';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import API from '../../api/endpoints';
import { UserContext } from '../../contexts/User';
import { NotificationContext } from '../../contexts/Notification';
import { sendRequest } from '../../api/shootAPI';

function Authentication() {
  const {
    activeWorkspace,
    setActiveWorkspace,
  } = useContext(UserContext);
  const {
    displayNotification,
  } = useContext(NotificationContext);
  const [
    checked, setChecked,
  ] = useState(activeWorkspace.authentication && activeWorkspace.authentication !== 'None');
  const [authentication, setAuthentication] = useState(activeWorkspace.authentication);
  const sendAuthenticationRequest = async (data) => {
    const res = await sendRequest({
      method: 'put',
      url: API.WORKSPACE.authentication(activeWorkspace.id),
      data,
    });
    if (res.success) {
      displayNotification({
        message: 'Authentication has been set up successfully.',
        severity: 'success',
      });
      return true;
    }
    displayNotification({
      message: 'An error occurs while setting up authentication. Please try again.',
      severity: 'error',
    });

    return false;
  };
  const handleChange = async (event) => {
    const isChecked = event.target.checked;
    const data = {
      authentication: isChecked ? 'Email' : 'None',
    };
    const res = await sendAuthenticationRequest(data);
    if (res) {
      setChecked(isChecked);
      setAuthentication(data.authentication);
      setActiveWorkspace({
        ...activeWorkspace,
        authentication: data.authentication,
      });
    }
  };
  const handleSelectChange = async (event) => {
    const data = {
      authentication: event.target.value,
    };
    const res = await sendAuthenticationRequest(data);
    if (res) {
      setAuthentication(event.target.value);
      setActiveWorkspace({
        ...activeWorkspace,
        authentication: event.target.value,
      });
    }
  };

  return (
    <div className="d-flex flex-column">
      <h5 className="fw-semibold">Authentication</h5>
      <FormControlLabel
        label={(
          <p className="fw-normal">
            {`Require two factor authentication (via 
                ${authentication !== 'None' ? authentication.toLowerCase() : 'email'})
                 for admins`}
          </p>
        )}
        control={(
          <Checkbox
            checked={checked}
            onChange={handleChange}
            sx={{
              color: 'var(--blue-3)',
              '&.Mui-checked': {
                color: 'var(--blue-3)',
              },
            }}
          />
        )}
      />
      {
        checked
          && (
            <div className="d-flex align-items-center two-factor-row">
              <p className="mr-3">Two factor method:</p>
              <FormControl sx={{ minWidth: 250 }} size="small">
                <Select
                  value={authentication}
                  onChange={handleSelectChange}
                  input={<OutlinedInput />}
                >
                  <MenuItem value="Email">
                    Email (default)
                  </MenuItem>
                  <MenuItem value="Google Authenticator">
                    Google Authenticator
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          )
        }
    </div>
  );
}

export default Authentication;

/* eslint-disable max-len */
import React, { useContext } from 'react';
import { AppBar, Box, Button } from '@mui/material';
import './CreateOrganization.css';
import styled from '@emotion/styled';
import ShootLogo from '../../images/blue-shoot-logo.png';
import UserSVG from '../../images/user.svg';
import { UserContext } from '../../contexts/User';

const Avatar = styled.div`
  border-radius: 50%;
  width: 39px;
  height: 39px;
  background: var(--dark-7);
  color: var(--dark-3);
  ${(props) => props.src && `
    background-size: cover;
    background-image: url(${props.src});
  `}
`;

export function SuperNavbar() {
  const {
    user,
  } = useContext(UserContext);
  return (
    <div className="navbar-holder flex-row justify-content-between">
      <AppBar
        position="static"
        sx={{ boxShadow: 'none' }}
        color="inherit"
      >
        <div className="navbar-between">
          <img
            src={ShootLogo}
            height="33"
            className="d-inline-block align-top"
            alt="shoot-logo"
          />
          <div className="user-menu">
            <Box alignSelf="center" marginLeft="auto">
              <div className="searchBox" style={{ marginRight: '20px' }}>
                <input type="text" placeholder="Search" style={{ height: '32px' }} onChange={(e) => console.log(e)} />
                <i className="fa fa-search searchIcon" style={{ color: 'black !important', top: 16 }} />
              </div>
            </Box>
            <Button
              variant="contained"
              sx={{
                width: 250,
                height: 32,
                fontSize: 14,
                textTransform: 'none',
                marginRight: '20px',
                backgroundColor: '#86D384',
              }}
              data-testid="add-member"
            >
              Create Organization
            </Button>
            <div className="content">
              <h3 style={{
                textAlign: 'right', marginTop: 3, fontSize: 14, color: '#0E1E40',
              }}
              >
                {`${user.firstName} ${user.lastName}`}
                {/* JOHN CANALAS */}
              </h3>
              <h3 style={{ textAlign: 'right', marginTop: 6 }}>SUPER ADMIN</h3>
            </div>
            <div className="user-menu-avatar">
              <Avatar className="flex-row align-c jstfy-c" src={user.profilePicture || UserSVG} />
            </div>
          </div>
        </div>
      </AppBar>
    </div>
  );
}

import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button } from '@mui/material';
import { UserContext } from '../../contexts/User';
import HomeWork from '../../components/icons/HomeWork';
import AccessTime from '../../components/icons/AccessTime';
import CameraAt from '../../components/icons/CameraAt';
import TeamEditColumn from './TeamEditColumn';
import DeleteTeamModal from './DeleteTeamModal';
import EditTeamModal from './EditTeamModal';
import { accountTypeLevel } from '../../constants/accountTypes';

function TeamCard({
  name,
  percentage,
  managers,
  members,
  teamId,
  locationViolations,
  webcamViolations,
  onTeamDeleted,
  workspaceId,
  onTeamEdited,
}) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const { redirect, member } = useContext(UserContext);
  const isWorkspaceAdmin = accountTypeLevel[member.accountType] >= accountTypeLevel['Workspace admin'];
  const managerNames = useMemo(() => managers.map(({ name }) => name), [managers]);
  // eslint-disable-next-line no-nested-ternary
  const activityColor = percentage >= 51
    ? 'green'
    : percentage >= 21
      ? 'yellow'
      : 'red';
  // eslint-disable-next-line no-nested-ternary
  const percentageColor = activityColor === 'green'
    ? '#53A551'
    : activityColor === 'yellow'
      ? '#F19E41'
      : '#CC4525';

  const deleteTeam = () => {
    setShowDeleteModal(true);
  };

  const editTeam = () => {
    setShowEditModal(true);
  };

  return (
    <Box
      sx={{
        minWidth: '364px',
        minHeight: '161px',
        borderRadius: '8px',
        border: '1px',
        borderColor: '#ECECF0',
        boxShadow: 4,
        bgcolor: '#fff',
        flex: 1,
      }}
    >
      {showDeleteModal && (
        <DeleteTeamModal
          closeModal={() => setShowDeleteModal(false)}
          onTeamDeleted={onTeamDeleted}
          name={name}
          members={members}
          managers={managers}
          teamId={teamId}
          workspaceId={workspaceId}
        />
      )}
      {showEditModal && (
        <EditTeamModal
          closeModal={() => setShowEditModal(false)}
          onTeamEdited={onTeamEdited}
          name={name}
          members={members}
          managers={managers}
          teamId={teamId}
        />
      )}
      <div className="card-container">
        <div className="card-row">
          <div className="card-col">
            <Typography
              component="p"
              sx={{
                lineHeight: '20px',
                fontSize: '14px',
                fontWeight: 600,
                color: '#545454',
                paddingBottom: '8px',
              }}
            >
              {name}
            </Typography>
            <Typography
              component="p"
              sx={{
                lineHeight: '29px',
                fontSize: '24px!important',
                fontWeight: '600!important',
                color: percentageColor,
                paddingBottom: '8px',
              }}
            >
              {percentage}
              %
            </Typography>
            <div className="card-col-textfield">
              <Typography
                component="p"
                sx={{
                  lineHeight: '20px',
                  fontSize: '14px',
                  fontWeight: 400,
                  color: '#757575',
                }}
              >
                Leaders:
              </Typography>
              &nbsp;&nbsp;
              <Typography
                component="p"
                sx={{
                  lineHeight: '20px',
                  fontSize: '14px',
                  fontWeight: 500,
                  color: '#0E1E40',
                }}
              >
                {managerNames.join(', ')}
              </Typography>
            </div>
            <div className="card-col-textfield">
              <Typography
                component="p"
                sx={{
                  lineHeight: '20px',
                  fontSize: '14px',
                  fontWeight: 400,
                  color: '#757575',
                }}
              >
                Members:
              </Typography>
              &nbsp;
              <Typography
                component="p"
                sx={{
                  lineHeight: '20px',
                  fontSize: '14px',
                  fontWeight: 500,
                  color: '#0E1E40',
                }}
              >
                {members.length}
              </Typography>
            </div>
          </div>
          <div className="card-col">
            <div className="card-col-img">
              <HomeWork color={locationViolations ? 'red' : 'green'} />
              <AccessTime color={activityColor} />
              <CameraAt color={webcamViolations ? 'red' : 'green'} />
              {isWorkspaceAdmin && <TeamEditColumn deleteTeam={deleteTeam} editTeam={editTeam} />}
            </div>
          </div>
        </div>
        <div className="card-row">
          <Button
            sx={{
              marginLeft: 'auto',
              font: 'Inter',
              fontSize: '14px',
              lineHeight: '20px',
              fontWeight: '600',
              textTransform: 'none',
              border: '1px solid',
              borderRadius: '4px',
              borderColor: '#D8E6FC',
              color: '#4469A1',
              padding: '4px 8px',
              variant: 'primary',
            }}
            variant="link"
            color="primary"
            onClick={() => { redirect(`/members/team-engagement/${teamId}`); }}
          >
            View Team
          </Button>
        </div>
      </div>
    </Box>
  );
}

TeamCard.propTypes = {
  name: PropTypes.string.isRequired,
  percentage: PropTypes.number.isRequired,
  managers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired),
  members: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired),
  teamId: PropTypes.number.isRequired,
  workspaceId: PropTypes.number.isRequired,
  locationViolations: PropTypes.bool,
  webcamViolations: PropTypes.bool,
  onTeamDeleted: PropTypes.func.isRequired,
  onTeamEdited: PropTypes.func.isRequired,
};

export default TeamCard;

/* eslint-disable import/no-unresolved */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable max-len */
import React, { useContext } from 'react';
import {
  AppBar,
  Box,
  Button,
  Menu,
  MenuItem,
} from '@mui/material';
import './navbar.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import ShootLogo from '../../images/blue-shoot-logo.png';
import UserSVG from '../../images/user.svg';
import { UserContext } from '../../contexts/User';
import MyAccount from '../../images/myAccount.svg';
import SwitchAccount from '../../images/switchAccount.svg';
import LogOut from '../../images/logout.svg';

const Avatar = styled.div`
  border-radius: 50%;
  width: 39px;
  height: 39px;
  background: var(--dark-7);
  color: var(--dark-3);
  ${(props) => props.src && `
    background-size: cover;
    background-image: url(${props.src});
  `}
`;

export function SuperNavbar() {
  const {
    user, signOut,
  } = useContext(UserContext);
  // console.log(user);
  return (
    <div className="navbar-holder flex-row justify-content-between">
      <AppBar
        position="static"
        sx={{ boxShadow: 'none' }}
        color="inherit"
      >
        <div className="navbar-between">
          <img
            src={ShootLogo}
            height="33"
            className="d-inline-block align-top"
            alt="shoot-logo"
          />
          <div className="user-menu">
            <Box alignSelf="center" marginLeft="auto">
              <div className="searchBox" style={{ marginRight: '20px' }}>
                <input type="text" placeholder="Search" style={{ height: '32px' }} onChange={(e) => console.log(e)} />
                <i className="fa fa-search searchIcon" style={{ color: 'black !important', top: 16 }} />
              </div>
            </Box>
            <Link to="/admin/organization/create">
              <Button
                variant="contained"
                sx={{
                  width: 250,
                  height: 32,
                  fontSize: 14,
                  textTransform: 'none',
                  marginRight: '20px',
                }}
                data-testid="add-organization"
              >
                Create Organization
              </Button>
            </Link>
            <div className="content">
              <h3 style={{
                textAlign: 'right', marginTop: 3, fontSize: 14, color: '#0E1E40',
              }}
              >
                {`${user.firstName} ${user.lastName}`}
              </h3>
              <h3 style={{ textAlign: 'right', marginTop: 6 }}>SUPER ADMIN</h3>
            </div>

            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <>
                  <Button
                    variant="contained"
                    {...bindTrigger(popupState)}
                    sx={{
                      width: '10px',
                      height: '27px',
                      background: 'white',
                      '&:hover': {
                        background: 'white',
                        boxShadow: 'none',
                      },
                    }}
                  >
                    <div className="user-menu-avatar">
                      <Avatar className="flex-row align-c jstfy-c" src={user.profilePicture || UserSVG} />
                    </div>
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem onClick={popupState.close}>
                      <img src={MyAccount} alt="my account" style={{ marginRight: '2px' }} />
                      Profile
                    </MenuItem>
                    <MenuItem onClick={popupState.close}>
                      <img src={SwitchAccount} alt="switch accounts" style={{ marginRight: '2px' }} />
                      My workspace
                    </MenuItem>
                    <MenuItem onClick={signOut}>
                      <img src={LogOut} alt="log out" style={{ marginRight: '2px' }} />
                      Logout
                    </MenuItem>
                  </Menu>
                </>
              )}
            </PopupState>
          </div>
        </div>
      </AppBar>
    </div>
  );
}

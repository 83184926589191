import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomCard = styled(Card)`
    borderRadius: "8px",
    border: "1px solid var(--Dark-Dark-06, #E0E1E6)",
    boxShadow: "none"
`;

export default CustomCard;

/* eslint-disable no-nested-ternary */
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
// import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Button } from '@mui/material';
import UnfoldMore from '@mui/icons-material/UnfoldMore';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { UserContext } from '../../contexts/User';
import './timesheets.css';
import ExportAllModal from './ExportAllModal';
import Pagination from '../../components/pagination';
import { Navbar, Sidebar } from '../../components';
import useDebounce from '../../hooks/useDebounce';
import MemberNameEmailColumn from '../../components/table/MemberNameEmailColumn';
import { formatActivityPercentage } from '../../utils/activity';
import { formatSeconds, getDatesInRange } from '../../utils/time';
import CustomTable from '../../components/table/CustomTable';
import DateRangePicker from '../../components/dateRangePicker';

const TABLE_HEADERS = [
  {
    label: 'User',
    sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'name',
    colSpan: 1.5,
  },
  {
    label: 'Total Time',
    sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'activitySeconds',
  },
  {
    label: 'Activity Level',
    sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'activityPercentage',
  },
  {
    label: '',
    colSpan: 0.5,
  },
];

const CustomTableStyles = {
  container: {
    marginTop: 1,
  },
  header: {
    backgroundColor: 'var(--dark-10)',
    fontSize: '16px',
    '.MuiTableSortLabel-iconDirectionAsc': {
      transform: 'rotate(0deg) !important',
    },
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff !important',
    },
    '&:hover': {
      backgroundColor: 'var(--dark-10) !important',
    },
  },
  cell: {
    button: {
      textWrap: 'nowrap',
      color: 'var(--dark-2)',
      borderColor: 'var(--dark-4) !important',
    },
  },
};

function Timesheets() {
  const today = new Date();
  const { search } = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(search);
  const page = query.get('page');
  const { activeWorkspace, user } = useContext(UserContext);
  const [members, setMembers] = useState([]);
  const [currentPage, setCurrentPage] = useState(page ? parseInt(page, 10) : 1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCounts, setTotalCounts] = useState(0);
  const [date, setDate] = useState([today, today]);
  const [showExportAllModal, setShowExportAllModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { debounce } = useDebounce();
  const [sort, setSort] = useState(false);
  const [sortKey, setSortKey] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');

  const handleSelectDate = async () => {
    const [startDate, endDate] = date;
    const dates = getDatesInRange(startDate, endDate);
    const startOfDay = moment(dates[0]).tz(user.timezone).startOf('day').utc()
      .format('YYYY-MM-DD HH:mm:ss');
    const endOfDay = moment(dates.at(-1)).tz(user.timezone).endOf('day').utc()
      .format('YYYY-MM-DD HH:mm:ss');

    const baseParams = {
      start: startOfDay,
      end: endOfDay,
      page: currentPage,
      name: searchQuery,
      sortKey,
      sortOrder,
      pageSize,
    };

    setIsLoading(true);
    const response = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.membersActivity(activeWorkspace.id),
      timeout: 60000,
      params: {
        ...baseParams,
      },
    });
    if (response.success) {
      const mappedMembers = response.data?.members.map((member) => {
        const { activityPercentage, secondsElapsed } = member;
        return ({
          name: <MemberNameEmailColumn
            profilePicture={member?.user?.profilePicture}
            firstName={member.haveJoined ? member?.user?.firstName : ''}
            lastName={member.haveJoined ? member?.user?.lastName : ''}
            email={member?.user?.email}
            component="div"
          />,
          totalTime: formatSeconds(secondsElapsed ?? 0),
          activityLabel: `${activityPercentage ? parseFloat(activityPercentage * 100).toFixed(0) : '--'}%`,
          actions: (
            <Link to={{
              pathname: '/members/activity/view',
              search: `?member=${member.id}`,
              state: {
                workspaceMember: member,
                previouslySelectedDate: date[0],
              },
            }}
            >
              <Button
                variant="outlined"
                style={{ textWrap: 'no-wrap' }}
              >
                View Activity
              </Button>
            </Link>
          ),
        });
      });

      const totalRow = {
        name: <p className="fw-medium fc-medium" style={{ padding: '8px 0px', display: 'block' }}> Total </p>,
        totalTime: formatSeconds(response.data?.totalSecondsElapsed),
        activityLabel: `${formatActivityPercentage(
          response.data?.totalActiveSeconds,
          response.data?.totalSecondsElapsed,
        ) ?? '--'}%`,
        actions: <span />,
      };

      setMembers([totalRow, ...mappedMembers]);
      setTotalPages(response.data?.pageInfo.totalPages ?? 1);
      setTotalCounts(response.data?.pageInfo.totalCounts ?? 0);
    } else {
      // Handle API errors
    }
    setIsLoading(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(debounce((query) => setSearchQuery(query), 500), []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    history.push(`?page=${page}`);
  };

  const handlePageSizeChange = (pageSize) => {
    setCurrentPage(1);
    setTotalPages(Math.ceil(totalCounts / pageSize));
    setPageSize(pageSize);
  };

  useEffect(() => {
    handleSelectDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchQuery, sort, pageSize, date]);

  const handleSort = debounce((sortKey, sortOrder) => {
    setIsLoading(true);
    setSort(!sort);
    setSortKey(sortKey);
    setSortOrder(sortOrder);
  }, 250);

  return (
    <div>
      {showExportAllModal && <ExportAllModal closeModal={() => setShowExportAllModal(false)} />}
      <Navbar />
      <Sidebar />
      <div className="screen-wrapper">
        <div className="screen-header">
          <div className="screen-header-container d-flex justify-content-between position-relative">
            <div className="flex-row align-c w-100 justify-content-between">
              <h3
                className="header-manage-member"
                style={{ fontWeight: 600, width: 'auto' }}
                data-testid="title"
              >
                Timesheets
              </h3>
              <Button
                sx={{ marginLeft: 'auto' }}
                variant="outlined"
                onClick={() => {
                  setShowExportAllModal(true);
                }}
                data-testid="export-all-btn"
              >
                Export All
              </Button>
            </div>
          </div>
          <p
            className="main-sub-text"
            data-testid="subtitle"
          >
            View time spent working and activity
          </p>
          <div className="flex-row align-c justify-content-between" style={{ gap: 20, marginTop: 28 }}>
            <DateRangePicker
              value={date}
              onChange={(_date) => setDate(_date)}
              className="w-auto"
              placement="bottomStart"
            />
            <div className="searchBox">
              <input
                type="text"
                placeholder="Search for user"
                onChange={(e) => handleSearch(e.target.value)}
                data-testid="search"
              />
              <i className="fa fa-search searchIcon" />
            </div>
          </div>
          <CustomTable
            headers={TABLE_HEADERS}
            items={members}
            loading={isLoading}
            handleSort={handleSort}
            styles={CustomTableStyles}
          />
          <Pagination
            currentPage={currentPage}
            pageSize={pageSize}
            totalPages={totalPages}
            totalCounts={totalCounts}
            onPageChange={handlePageChange}
            setPageSize={handlePageSizeChange}
            styles={{ margin: 0 }}
          />
        </div>
      </div>
    </div>
  );
}

Timesheets.propTypes = {

};

export default Timesheets;

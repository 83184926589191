import React, { Component } from 'react';
import { styled } from '@mui/system';
import {
  Card, CardContent, CardHeader, Button,
} from '@mui/material';
import PropTypes from 'prop-types';
import CustomTable from '../table/CustomTable';
import Pagination from '../pagination';
import FilterSearch from './FilterSearch';

const CustomCard = styled(Card)`
  borderRadius: "8px",
  width: 100%,  // Make it full width
  border: "1px solid var(--Dark-Dark-06, #E0E1E6)",
  boxShadow: "none"
`;

class AdminDataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleFilterClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleFilterClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const {
      data,
      title,
      headers,
      loading,
      icon,
      currentPage,
      totalPages,
      totalCounts,
      pageSize,
      onPageSize,
      onCurrentPage,
      filterConfig,
    } = this.props;

    return (
      <CustomCard className="daily-activities-card">

        <CardHeader
          className="table-card-header hours-card-header"
          title={(
            <div className="table-card-header-title d-flex gap-1 justify-content-between align-items-center">
              <div className="table-card-logo d-flex gap-1">
                <img src={icon} alt="info icon" style={{ width: '3%' }} />
                <p>{title}</p>
              </div>
              <Button
                sx={{
                  border: '2px solid #E0E1E6',
                  height: '24px',
                  color: '#4469A1',
                  right: '1%',
                  position: 'relative',
                }}
                onClick={this.handleFilterClick}
                variant="outlined"
              >
                Filter & Search
              </Button>
            </div>
          )}
        />
        <CardContent className="table-card-content d-flex flex-column justify-content-between">
          <CustomTable headers={headers} items={data} loading={loading} />
          <Pagination
            currentPage={currentPage}
            pageSize={pageSize}
            totalPages={totalPages}
            totalCounts={totalCounts}
            onPageChange={onCurrentPage}
            setPageSize={onPageSize}
            styles={{ margin: 0 }}
          />
        </CardContent>

        <FilterSearch
          anchorEl={anchorEl}
          handleClose={this.handleFilterClose}
          filterConfig={filterConfig}
        />
      </CustomCard>
    );
  }
}

AdminDataTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    createdAt: PropTypes.string,
  })),
  title: PropTypes.string.isRequired,
  headers: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    sortable: PropTypes.bool,
  })),
  loading: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalCounts: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageSize: PropTypes.func.isRequired,
  onCurrentPage: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  filterConfig: PropTypes.object.isRequired,
};

export default AdminDataTable;

import moment from 'moment-timezone';
import React from 'react';
import PropTypes from 'prop-types';
import Audit from './Audit';
import { WebcamAuditType } from '../../propTypes';

function AuditRow({ dayBlock, webcamAudits }) {
  return (
    <div className="snapshot-row">
      <div style={{ marginLeft: -19, display: 'flex', gap: 10 }}>
        <div className="time-bubble" />
        <p className="snap-text">{moment(dayBlock).format('dddd, MMM DD, yyyy')}</p>
      </div>
      <div className="small-snapshot-grid snapshot-grid">
        {webcamAudits.map((webcamAudit) => (
          <Audit key={webcamAudit?.data?.id} webcamAudit={webcamAudit} />
        ))}
      </div>
    </div>
  );
}

export default AuditRow;

AuditRow.propTypes = {
  dayBlock: PropTypes.string.isRequired,
  webcamAudits: PropTypes.arrayOf(PropTypes.shape({
    data: WebcamAuditType,
    localTime: PropTypes.string.isRequired,
  })),
};

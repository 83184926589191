import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import FormLayout from './FormLayout';
import { UserContext } from '../../contexts/User';
import WorkspaceCard from '../workspaces/WorkspaceCard';

function CreateNewAccount({ currentStepVal }) {
  const history = useHistory();
  return (
    <FormLayout
      heading="Create a workspace"
      subheading="Start by creating your personal login.
      If you already have an account, you can sign in with your email and password."
      currentStep={currentStepVal}
    >
      <Button
        fullWidth
        className="nextStep-btn"
        variant="contained"
        onClick={() => { history.push('/signup?redirect=organization-signup'); }}
      >
        Create a new Shoot account
      </Button>
      <Link className="back-link" to="/?redirect=organization-signup">
        Sign in with an existing account
      </Link>
    </FormLayout>
  );
}

CreateNewAccount.propTypes = {
  currentStepVal: PropTypes.number.isRequired,
};

function UseExistingAccount({
  currentStepVal, setCurrentStep,
}) {
  const history = useHistory();
  const { user, signOut } = useContext(UserContext);

  const continueWithExistingAccount = () => {
    setCurrentStep({ value: currentStepVal + 1, isReturning: false });
  };

  const cancelAndCreateNewAccount = () => {
    signOut({ redirectToLogin: false });
    history.push('/signup?redirect=organization-signup');
  };

  return (
    <FormLayout
      heading="Your account"
      subheading="You are currently signed in with an existing Shoot account.
      By continuing, your new organization will be associated with this email and password."
      currentStep={currentStepVal}
    >
      <WorkspaceCard name={`${user.firstName} ${user.lastName}`} description={user.email} />
      <Button
        fullWidth
        className="nextStep-btn"
        variant="contained"
        onClick={continueWithExistingAccount}
        data-testid="next-step-btn"
      >
        Continue
      </Button>
      <button type="button" className="back-link" onClick={cancelAndCreateNewAccount}>
        Sign out and create new account
      </button>
    </FormLayout>
  );
}

UseExistingAccount.propTypes = {
  currentStepVal: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
};

function CreateAccountForm({
  currentStep, setCurrentStep,
}) {
  const { user } = useContext(UserContext);

  if (user) {
    return (
      <div>
        <UseExistingAccount
          currentStepVal={currentStep.value}
          setCurrentStep={setCurrentStep}
        />
      </div>
    );
  }
  return (
    <div>
      <CreateNewAccount
        currentStepVal={currentStep.value}
      />
    </div>
  );
}

CreateAccountForm.propTypes = {
  currentStep: PropTypes.shape({
    value: PropTypes.number,
    isReturning: PropTypes.bool,
  }),
  setCurrentStep: PropTypes.func.isRequired,
};

export default CreateAccountForm;

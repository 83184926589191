import React, {
  useState, useEffect, useContext, useCallback,
} from 'react';
import { Button, Box } from '@mui/material';
import UnfoldMore from '@mui/icons-material/UnfoldMore';
import { UserContext } from '../../../contexts/User';
import { sendRequest } from '../../../api/shootAPI';
import API from '../../../api/endpoints';
import '../settings.css';
import CustomTable from '../../../components/table/CustomTable';
import useDebounce from '../../../hooks/useDebounce';
import DropDown from '../../../components/dropdown';
import EditDesignationModal from './EditModal';
import DesignationDeleteModal from './DeleteModal';

const TABLE_HEADERS = [
  {
    label: 'Designation Name',
    sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'name',
    colSpan: 1.5,
  },
  {
    label: 'Date Created',
    sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'createdAt',
  },
  {
    label: 'Action',
    sortable: false,
  },
];

const CustomTableStyles = {
  container: {
    marginTop: 1,
  },
  header: {
    backgroundColor: 'var(--dark-10)',
    fontSize: '16px',
    '.MuiTableSortLabel-iconDirectionAsc': {
      transform: 'rotate(0deg) !important',
    },
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff !important',
    },
    '&:hover': {
      backgroundColor: 'var(--dark-10) !important',
    },
  },
  cell: {
    button: {
      textWrap: 'nowrap',
      color: 'var(--dark-2)',
      borderColor: 'var(--dark-4) !important',
    },
  },
};

function DesignationsTab() {
  const { activeWorkspace } = useContext(UserContext);
  const [designations, setDesignations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [selectedDesignation, setSelectedDesignation] = useState({});
  const { debounce } = useDebounce();

  const getDesignations = useCallback(async (params) => {
    if (activeWorkspace) {
      setIsLoading(true);
      const response = await sendRequest({
        method: 'get',
        url: API.WORKSPACE.designations(activeWorkspace.id),
        params,
      });

      if (response.success) {
        if (response.data?.designations?.length) {
          const mappedDesignations = response.data?.designations.map((designation) => ({
            name: designation.name,
            createdAt: designation.createdAt,
            actions: <DropDown
              title="Actions"
              options={[
                {
                  text: 'Edit Designation',
                  fontColor: '#061232',
                  handleClick: () => {
                    setSelectedDesignation(designation);
                    setIsEditing(true);
                    setShowEditModal(true);
                  },
                },
                {
                  text: 'Delete Designation',
                  fontColor: '#CC4525',
                  handleClick: () => {
                    setSelectedDesignation(designation);
                    setShowDeleteConfirmModal(true);
                  },
                },
              ]}
            />,
          }));
          setDesignations(mappedDesignations);
        }
        setIsLoading(false);
      }
    }
  }, [activeWorkspace]);

  const handleSort = debounce((sortKey, sortOrder) => getDesignations({
    name: searchQuery,
    sortKey,
    sortOrder,
  }), 250);

  const fetchResults = async () => {
    const params = {
      name: searchQuery,
    };
    await getDesignations(params);
  };

  const handleSearch = useCallback(debounce((query) => setSearchQuery(query), 500), []);

  useEffect(() => {
    fetchResults();
  }, [searchQuery]);

  return (
    <>
      {
        showEditModal && (
        <EditDesignationModal
          designation={selectedDesignation}
          isEditing={isEditing}
          closeModal={() => setShowEditModal(false)}
          onDesignationSaved={() => fetchResults()}
          workspaceId={activeWorkspace.id}
        />
        )
      }
      {
        showDeleteConfirmModal && (
        <DesignationDeleteModal
          designation={selectedDesignation}
          closeModal={() => setShowDeleteConfirmModal(false)}
          getData={() => fetchResults()}
        />
        )
      }
      <Box className="px-3 py-2 d-flex justify-content-between align-items-center">
        <Box>
          <Box className="size-s text-600 lh-base fc-dark">
            Designations
          </Box>
          <Box className="size-xs text-400 lh-sm fc-dark">
            A designation indicates the role of the team member in your organization
          </Box>
        </Box>
        <Box className="d-flex align-items-center">
          <div className="searchBox mr-2">
            <input
              type="text"
              placeholder="Search Designations"
              onChange={(e) => handleSearch(e.target.value)}
            />
            <i className="fa fa-search searchIcon" />
          </div>
          <Button
            variant="contained"
            onClick={() => {
              setIsEditing(false);
              setShowEditModal(true);
              setSelectedDesignation({});
            }}
            data-testid="new-designation"
          >
            Add Designation
          </Button>
        </Box>
      </Box>
      <CustomTable
        headers={TABLE_HEADERS}
        items={designations}
        loading={isLoading}
        handleSort={handleSort}
        styles={CustomTableStyles}
      />
    </>
  );
}

export default DesignationsTab;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  OutlinedInput,
  InputLabel,
  InputAdornment,
  IconButton,
} from '@mui/material';
import {
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';

function PasswordInput({
  label,
  id = 'password-input',
  ...props
}) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div style={{ marginTop: '20px' }}>
      <InputLabel
        htmlFor={id}
        sx={{
          lineHeight: '20px',
          fontWeight: '500',
        }}
      >
        {label}
      </InputLabel>
      <OutlinedInput
        id={id}
        type={showPassword ? 'text' : 'password'}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword((show) => !show)}
              onMouseDown={(event) => event.preventDefault()}
              onMouseUp={(event) => event.preventDefault()}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )}
        sx={{ height: '48px' }}
        fullWidth
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </div>
  );
}

PasswordInput.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
};

export default PasswordInput;

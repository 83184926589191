/* eslint-disable no-nested-ternary */
import React, {
  useContext, useEffect, useState,
} from 'react';
import { styled } from '@mui/system';
import {
  Card, CardContent, CardHeader, Button,
} from '@mui/material';
import moment from 'moment-timezone';
import { CSVLink } from 'react-csv';
import './reports.css';
import { Navbar, Sidebar } from '../../components';
import StackBars from '../../components/stackChart';
import DateRangePicker from '../../components/dateRangePicker';
import ActivityCard from './ActivityCard';
import TimeCard from './TimeCard';
import FilterModal from './FilterModal';
import { UserContext } from '../../contexts/User';
import { NotificationContext } from '../../contexts/Notification';
import { sendRequest } from '../../api/shootAPI';
import { getDatesInRange, convertSecToTimeFormat } from '../../utils/time';
import {
  getTotalHours, generateActivityReport, transformToGraphData, transformToTableData, exportTableData,
} from '../../utils/report';
import API from '../../api/endpoints';
import TimerIcon1 from '../../images/timer-2-line-3.png';
import TimerIcon2 from '../../images/timer-2-line-2.png';
import TimerIcon3 from '../../images/timer-2-line-1.png';

const CustomCard = styled(Card)`
    borderRadius: "8px",
    width: 30%,
    border: "1px solid var(--Dark-Dark-06, #E0E1E6)",
    boxShadow: "none"
`;

const headers = [
  {
    label: 'Date',
    key: 'date',
  },
  {
    label: 'Total Hours',
    key: 'totalSeconds',
  },
  {
    label: 'Total Activity',
    key: 'totalActivity',
  },
  {
    label: 'Deep Focused Hours',
    key: 'deepFocused',
  },
  {
    label: 'Deep Focused Activity',
    key: 'deepFocusedActivity',
  },
  {
    label: 'Focused Hours',
    key: 'focused',
  },
  {
    label: 'Focused Activity',
    key: 'focusedActivity',
  },
  {
    label: 'Active Hours',
    key: 'active',
  },
  {
    label: 'Active Activity',
    key: 'activeActivity',
  },
  {
    label: 'Work Break Hours',
    key: 'workBreak',
  },
];

function TimeWorked() {
  const today = new Date();
  const { displayNotification } = useContext(NotificationContext);
  const { activeWorkspace } = useContext(UserContext);
  const [date, setDate] = useState([new Date(moment(today).subtract(6, 'days')), today]);
  const [members, setMembers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [teamId, setTeamId] = useState(0);
  const [memberId, setMemberId] = useState(0);
  const [totalHours, setTotalHours] = useState('');
  const [qualityHours, setQualityHours] = useState('');
  const [engageScore, setEngageScore] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [displayText, setDisplayText] = useState('All');

  const fetchTeams = async () => {
    const [startDate, endDate] = date;
    const dates = getDatesInRange(startDate, endDate);
    const startOfDay = moment(dates[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const endOfDay = moment(dates.at(-1)).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    const { success, data } = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.createTeam(activeWorkspace.id),
      timeout: 30000,
      params: {
        start: startOfDay,
        end: endOfDay,
        searchQuery: '',
      },
    });
    if (success) {
      setTeams(data.teams);
    } else {
      displayNotification({
        message: 'There was an issue fetching team data in this workspace. Please try again',
        severity: 'error',
      });
    }
  };

  const fetchMembers = async () => {
    const [startDate, endDate] = date;
    const dates = getDatesInRange(startDate, endDate);
    const startOfDay = moment(dates[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const endOfDay = moment(dates.at(-1)).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    const { success, data } = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.membersDailyActivity(activeWorkspace.id),
      params: {
        start: startOfDay,
        end: endOfDay,
      },
    });
    if (success) {
      setMembers(data.members);
    } else {
      displayNotification({
        message: 'There was an issue fetching members data in this workspace. Please try again',
        severity: 'error',
      });
    }
  };

  const handleSearch = (filterTeamId, filterMemberId) => {
    setTeamId(filterTeamId);
    setMemberId(filterMemberId);
    if (filterTeamId === 0 && filterMemberId === 0) {
      setFilteredMembers(members);
      setDisplayText('All');
    } else if (filterTeamId !== 0) {
      const filteredTeam = teams.find((team) => team.id === filterTeamId);
      setDisplayText(filteredTeam.name);
      const filterMembers = [];
      filteredTeam.teamMates.forEach((member) => {
        const filterMember = members.find((item) => item.id === member.memberId);
        filterMembers.push(filterMember);
      });
      setFilteredMembers(filterMembers);
    } else {
      const filterMembers = [];
      const filterMember = members.find((item) => item.id === filterMemberId);
      setDisplayText(`${filterMember.user.firstName} ${filterMember.user.lastName}`);
      filterMembers.push(filterMember);
      setFilteredMembers(filterMembers);
    }
  };

  useEffect(() => {
    fetchTeams();
    fetchMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    if (teamId === 0 && memberId === 0) {
      setFilteredMembers(members);
    } else if (teamId !== 0) {
      const filteredTeam = teams.find((team) => team.id === teamId);
      const filterMembers = [];
      filteredTeam.teamMates.forEach((member) => {
        const filterMember = members.find((item) => item.id === member.memberId);
        filterMembers.push(filterMember);
      });
      setFilteredMembers(filterMembers);
    } else {
      const filterMembers = [];
      const filterMember = members.find((item) => item.id === memberId);
      filterMembers.push(filterMember);
      setFilteredMembers(filterMembers);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members]);

  useEffect(() => {
    const { totalSeconds, activeSeconds } = getTotalHours(filteredMembers);
    setTotalHours(convertSecToTimeFormat(totalSeconds));
    setQualityHours(convertSecToTimeFormat(activeSeconds));
    let score = 0;
    if (totalSeconds > 0) {
      score = Math.round((activeSeconds * 100) / totalSeconds);
    }
    setEngageScore(score);

    const result = generateActivityReport(filteredMembers, date[0], date[1]);
    setChartData(transformToGraphData(result));
    setExportData(exportTableData(result));
    setTableData(transformToTableData(result));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredMembers]);

  return (
    <>
      <Navbar />
      <Sidebar />
      <div className="screen-wrapper">
        <div className="screen-header">
          <div className="screen-header-container d-flex justify-content-between position-relative">
            <div className="w-100 d-flex justify-content-between">
              <h3 className="header-manage-member w-100" style={{ fontWeight: 600 }}>
                Time Worked:
                &nbsp;
                {displayText}
              </h3>
              <div className="team-engagement-header-actions">
                <DateRangePicker
                  value={date}
                  onChange={(_date) => setDate(_date)}
                />
                <FilterModal
                  handleSearch={handleSearch}
                  members={members}
                  teams={teams}
                />
              </div>
            </div>
          </div>
          <div className="cards-container d-flex justify-content-between position-relative">
            <TimeCard
              title="TOTAL HOURS"
              value={totalHours}
              color="#53A551"
              icon={TimerIcon1}
              key={1}
            />
            <TimeCard
              title="QUALITY HOURS"
              value={qualityHours}
              color={engageScore > 50 ? '#53A551' : '#F3AF3D'}
              icon={TimerIcon2}
              key={2}
            />
            <TimeCard
              title="ENGAGEMENT SCORE"
              value={`${engageScore}%`}
              color={engageScore > 50 ? '#53A551' : '#F3AF3D'}
              icon={TimerIcon3}
              key={3}
            />
          </div>
          <div className="chart-card">
            <CustomCard>
              <CardHeader
                className="hours-card-header"
                title={(
                  <div className="hours-card-header-title d-flex gap-1 align-items-center">
                    TOTAL HOURS WORKED PER DAY
                  </div>
                )}
              />
              <CardContent
                sx={{ height: '323px' }}
                className="d-flex flex-column justify-content-between"
              >
                <StackBars data={chartData} />
              </CardContent>
            </CustomCard>
          </div>
          <div className="screen-header-container reports-header d-flex justify-content-between position-relative">
            <div className="w-100 d-flex justify-content-between">
              <h4
                className="header-manage-member w-100"
                style={{ fontWeight: 500, fontSize: 28 }}
              >
                Daily Activity
              </h4>
              <CSVLink
                data={exportData}
                headers={headers}
                filename="Report.csv"
              >
                <Button className="export-table-btn">Export Table</Button>
              </CSVLink>
            </div>
          </div>
          <div className="daily-activities-container">
            <div className="daily-activities-header">
              <p className="daily-activities-first">Project/Policy</p>
              <p className="daily-activities-second">Duration</p>
              <p className="daily-activities-third">Engagement Score</p>
            </div>
            {tableData.length > 0 && tableData.map((data, index) => (
              <ActivityCard data={data} key={index} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default TimeWorked;

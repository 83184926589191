import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ImageViewer from 'react-simple-image-viewer';
import { WebcamAuditType } from '../../propTypes';

const getStatusColor = (status) => {
  switch (status) {
    case 'Complete':
      return '#53A551';
    case 'Error':
      return '#B0361A';
    case 'No Camera':
      return '#F19235';
    case 'Permission Error':
      return '#7D8698';
    case 'Upload Error':
      return '#0E1E40';
    default:
      return '';
  }
};

function Audit({ webcamAudit }) {
  const { images, status } = webcamAudit.data;
  const [webcamsAreOpen, setWebcamsAreOpen] = useState(false); // Webcams are being viewed (full screen)

  const auditUrls = useMemo(() => images.map((webcam) => webcam?.url), []);

  return (
    <>
      <article className="snapshot flex-col">
        <div
          style={{
            position: 'relative',
            height: '100%',
            width: '100%',
            background: 'var(--dark-5)',
            color: 'var(--dark-4)',
          }}
          className="snapshot-img-view flex-row align-c jstfy-c"
          onClick={() => setWebcamsAreOpen(true)}
          onKeyDown={() => setWebcamsAreOpen(true)}
          role="button"
          tabIndex={0}
        >
          <div className="snapshot-img-cover" />
          {images[0]?.url
            && (
              <img
                src={images[0]?.url}
                width="100%"
                alt="Webcam Capture"
                style={{ borderRadius: '4px 4px 0 0', minHeight: 68 }}
              />
            )}
        </div>
        <div className="webcam-details" style={{ marginTop: 'auto' }}>
          <div
            style={{
              fontSize: 12,
              fontWeight: 600,
              color: getStatusColor(status),
            }}
          >
            {status}
          </div>
          <div className="snap-text" style={{ padding: '4px 0px' }}>
            {webcamAudit.localTime}
          </div>
        </div>
      </article>
      {webcamsAreOpen
        && (
          <ImageViewer
            disableScroll
            src={auditUrls}
            currentIndex={0}
            onClose={() => setWebcamsAreOpen(false)}
            backgroundStyle={{
              backgroundColor: 'rgba(0,0,0,0.9)',
              zIndex: 20,
            }}
            closeOnClickOutside
          />
        )}
    </>
  );
}

export default Audit;

Audit.propTypes = {
  webcamAudit: PropTypes.shape({
    data: WebcamAuditType,
    localTime: PropTypes.string.isRequired,
  }),
};

export const filterConfigs = [
  {
    path: '/admin/organizations',
    key: {
      status: true,
    },
  },
  {
    path: '/admin/workspaces',
    key: {
      status: true,
      organization: true,
    },
  },
  {
    path: '/admin/users',
    key: {
      status: true,
      organization: true,
      workspace: true,
      team: true,
    },
  },
  {
    path: '/admin/teams',
    key: {
      status: true,
      organization: true,
      workspace: true,
      leader: true,
    },
  },
];

export const getFilterConfig = (pathname) => {
  if (!pathname) {
    return '';
  }
  const matchConfig = filterConfigs.find((config) => config.path === pathname);
  return matchConfig.key;
};

export const getFilterValueForStatus = (status) => {
  if (status.active && !status.nonActive) {
    return 1;
  }
  if (!status.active && status.nonActive) {
    return 0;
  }
  return null;
};
export const getFilterValueForSearchQuery = (searchQuery) => {
  if (!searchQuery) {
    return null;
  }
  return searchQuery;
};

export const getFilterValueForMultiSelected = (keys) => {
  if (!keys || keys.length === 0) {
    return null;
  }
  const values = keys?.map((key) => key.id);

  return values.join(',');
};

export const parseFilterParams = (query, model) => {
  const filterKeys = {
    status: {
      type: 'main', model, field: 'status', operator: 'eq',
    },
    search: {
      type: 'main', model, field: 'name', operator: 'like',
    },
    organization: {
      type: 'include', model: 'organization', field: 'id', operator: 'in',
    },
    workspace: {
      type: 'include', model: 'workspace', field: 'id', operator: 'in',
    },
    team: {
      type: 'include', model: 'team', field: 'id', operator: 'in',
    },
    leader: {
      type: 'include', model: 'leader', field: 'id', operator: 'in',
    },
  };

  const filters = [];

  Object.keys(filterKeys).forEach((key) => {
    const queryValue = query.get(key);
    if (queryValue) {
      let value;

      if (key !== 'status' && key !== 'search') {
        value = queryValue.split(',');
      } else {
        value = queryValue;
      }

      filters.push({
        model: filterKeys[key].model,
        field: filterKeys[key].field,
        value,
        type: filterKeys[key].type,
        operator: filterKeys[key].operator,
      });
    }
  });

  return filters;
};

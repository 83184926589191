/* eslint-disable no-nested-ternary */
import React, {
  useContext, useEffect, useState,
} from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { sendRequest } from '../../../api/shootAPI';
import API from '../../../api/endpoints';
import { NotificationContext } from '../../../contexts/Notification';
import { formatDate } from '../../../utils/time';
import TotalCard from '../../../components/admin/TotalCard';
import AdminDataTable from '../../../components/admin/AdminDataTable';
import UserspaceImage from '../../../images/UserspaceM.png';
import { USERS_TABLE_HEADERS } from '../../../utils/adminDataTableHeaders';
import Action from '../../../components/admin/Action';
import { Layout } from '../Layout';
import { parseFilterParams } from '../../../utils/fillter';

function Users() {
  const userMenuItems = [
    {
      label: 'Switch to Workspaces',
      link: '#',
    },
    {
      label: 'Delete',
      modal: true,
      // eslint-disable-next-line no-console
      onClick: () => console.log('Organization deleted'),
      color: '#E53935',
    },
  ];
  const { displayNotification } = useContext(NotificationContext);
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const page = query.get('page');
  const filterParams = parseFilterParams(query, 'user');
  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState([]);
  const [activeUsers, setActiveUsers] = useState([]);
  const [nonActiveUsers, setNonActiveUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(page ? parseInt(page, 10) : 1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCounts, setTotalCounts] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    history.push(`?page=${page}`);
  };

  const fetchUsers = async () => {
    setLoading(true);
    const { success, data } = await sendRequest({
      method: 'post',
      url: API.USER.users(),
      data: {
        pageSize,
        page: currentPage,
        filters: filterParams,
      },
      timeout: 30000,
    });
    if (success) {
      const filteredUsers = data.users?.map((user) => ({
        fullName: user.fullName,
        teamsCount: <Link to={`/admin/teams?userId=${user.id}`}>{user.teamsCount}</Link>,
        membersCount: <Link to={`/admin/workspaces?userId=${user.id}`}>{user.membersCount}</Link>,
        organizationCount: <Link to={`/admin/organizations?userId=${user.id}`}>{user.organizationCount}</Link>,
        status: user.status ? 'Active' : 'InActive',
        updateAt: formatDate(user.updatedAt),
        action: <Action label={user.fullName} menuItems={userMenuItems} type="user" />,
      }));
      setUsers(filteredUsers);
      setTotalPages(data?.pageInfo.totalPages ?? 1);
      setTotalCounts(data?.pageInfo.totalCounts ?? 0);
      setLoading(false);
      setTotalUsers(data.totalUsers);
      setActiveUsers(data.totalUsers?.filter((user) => user.status));
      setNonActiveUsers(data.totalUsers?.filter((user) => !user.status));
    } else {
      displayNotification({
        message: 'There was an issue fetching user data. Please try again',
        severity: 'error',
      });
    }
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, currentPage, search]);

  const cardData = [
    {
      id: 1,
      data: totalUsers,
      title: 'Total Users',
      tooltip: 'Total Users',
      color: '#0064FF',
    },
    {
      id: 2,
      data: activeUsers,
      title: 'Total Active Users',
      tooltip: 'Total Active Users',
      color: '#F19235',
    },
    {
      id: 3,
      data: nonActiveUsers,
      title: 'Total Non-Active Users',
      tooltip: 'Total Non-Active Users',
      color: '#9457A5',
    },
    {
      id: 4,
      data: nonActiveUsers,
      title: 'Avg. Activity Percentage',
      tooltip: 'Avg. Activity Percentage',
      color: '#FF6A6A',
    },
  ];

  return (
    <Layout>
      <div className="screen-wrapper">
        <div className="screen-header">
          <div className="total-card-wrapper">
            {cardData && cardData.map((item, index) => (
              <TotalCard
                key={index}
                data={item.data}
                title={item.title}
                tooltip={item.tooltip}
                color={item.color}
              />
            ))}
          </div>
          <div className="one-card-container">
            <AdminDataTable
              heading="users"
              data={users}
              title="User Management"
              icon={UserspaceImage}
              headers={USERS_TABLE_HEADERS}
              loading={loading}
              currentPage={currentPage}
              totalPages={totalPages}
              totalCounts={totalCounts}
              pageSize={pageSize}
              onPageSize={setPageSize}
              onCurrentPage={handlePageChange}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Users;

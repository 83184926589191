import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Radio } from '@mui/material';
import { Link } from 'react-router-dom';
import FormLayout from './FormLayout';
import { FormDataType } from '../../propTypes';
import { Images } from '../../common';
import { TextInput } from '../../components/muiCustomized/TextInput';
import UploadResizedPhoto from '../../components/UploadResizedPhoto';
import AWSLogoSVG from '../../images/awsLogo.svg';

const MENU = ['Organization Name', 'Select Storage', 'Setup Storage'];

function OrganizationName({
  formData, setFormData, setMenu, currentStepVal, setCurrentStep,
}) {
  const continueBtnRef = useRef(null);

  return (
    <FormLayout
      heading="Create your organization"
      subheading="Enter the name of your organization."
      currentStep={currentStepVal}
    >
      <UploadResizedPhoto
        onChange={(resizedImage) => setFormData({ ...formData, avatar: resizedImage })}
        label="Upload Organization Photo"
      />
      <TextInput
        name="organizationName"
        value={formData?.organizationName}
        onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
        onKeyDown={(e) => {
          const key = e.keyCode || e.which;
          if (key === 13) {
            e.preventDefault();
            continueBtnRef.current.click();
          }
        }}
        label="Organization Name"
        placeholder="Shoot Company"
        helperText="This is the company name your employees will see when they are invited to a workspace"
      />
      <Button
        ref={continueBtnRef}
        fullWidth
        className="nextStep-btn"
        variant="contained"
        disabled={!formData?.organizationName}
        onClick={() => setMenu(MENU[1])}
        data-testid="next-step-btn"
      >
        Continue
      </Button>
      <button
        type="button"
        className="back-link"
        onClick={() => setCurrentStep({ value: currentStepVal - 1, isReturning: true })}
      >
        Go back
      </button>
    </FormLayout>
  );
}

OrganizationName.propTypes = {
  formData: FormDataType,
  setFormData: PropTypes.func.isRequired,
  setMenu: PropTypes.func.isRequired,
  currentStepVal: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
};

function SelectStorage({
  formData, setFormData, setMenu, currentStepVal, setCurrentStep,
}) {
  const { shootLogo } = Images;

  const handleContinue = () => {
    if (formData?.storageOption === 'AWS') {
      setMenu(MENU[2]);
    } else {
      setCurrentStep({ value: currentStepVal + 1, isReturning: false });
    }
  };

  return (
    <FormLayout
      heading="Data storage"
      subheading="Choose where your data will be stored."
      currentStep={currentStepVal}
    >
      <p className="text-600 text-center">Where should we store screenshots and webcam data?</p>
      <p className="text-center m-t4" style={{ color: '#546077' }}>
        When enabled, Shoot will capture sensitive images such as screenshots & webcam pictures from your team.
      </p>
      <div className="storage-options-container">
        <div style={{ position: 'relative' }}>
          <Radio
            name="storageOption"
            classes={{ root: 'storage-option-input' }}
            checked={formData?.storageOption === 'Shoot'}
            onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
            value="Shoot"
            data-testid="shoot"
          />
          <div className={`storage-option ${formData?.storageOption === 'Shoot' && 'selected'}`}>
            <img
              src={shootLogo}
              width="64"
              height="64"
              className="d-inline-block align-top"
              alt="shoot-logo"
            />
            <p className="size-xs text-400 text-center">
              Shoot can store our data on our behalf.
            </p>
          </div>
        </div>
        <div style={{ position: 'relative' }}>
          <Radio
            name="storageOption"
            classes={{ root: 'storage-option-input' }}
            checked={formData?.storageOption === 'AWS'}
            onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
            value="AWS"
            data-testid="aws"
          />
          <div className={`storage-option ${formData?.storageOption === 'AWS' && 'selected'}`}>
            <img
              src={AWSLogoSVG}
              width="64"
              className="d-inline-block align-top m-t16"
              alt="shoot-logo"
            />
            <p className="size-xs text-400 text-center">
              We will use AWS to store our own data.
            </p>
          </div>
        </div>
      </div>
      <Button
        fullWidth
        variant="contained"
        className="nextStep-btn"
        disabled={!formData?.storageOption}
        onClick={handleContinue}
        data-testid="next-step-btn"
      >
        Continue
      </Button>
      <button type="button" className="back-link" onClick={() => setMenu(MENU[0])}>
        Go back
      </button>
    </FormLayout>
  );
}

SelectStorage.propTypes = {
  formData: FormDataType,
  setFormData: PropTypes.func.isRequired,
  setMenu: PropTypes.func.isRequired,
  currentStepVal: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
};

function SetupStorage({
  formData, setFormData, setMenu, currentStepVal, setCurrentStep,
}) {
  const awsSecretAccessKeyRef = useRef(null);
  const accessKeyRef = useRef(null);
  const continueBtnRef = useRef(null);

  return (
    <FormLayout
      heading="Setup your storage"
      subheading="Setup your data storage through AWS."
      currentStep={currentStepVal}
    >
      <Link
        className="aws-guide"
        to="/organization-signup/aws-setup"
        target="_blank"
        rel="noopener noreferrer"
        data-testid="aws-guide"
      >
        <p className="size-xs text-600">Follow our guide to setup your AWS data storage</p>
      </Link>
      <TextInput
        type="text"
        name="awsBucketName"
        autoComplete="off"
        value={formData?.awsBucketName}
        onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
        onKeyDown={(e) => {
          const key = e.keyCode || e.which;
          if (key === 13) {
            e.preventDefault();
            accessKeyRef.current.click();
          }
        }}
        label="AWS S3 Bucket Name"
        placeholder="Enter your S3 bucket name"
      />
      <TextInput
        inputRef={accessKeyRef}
        name="awsAccessKeyId"
        value={formData?.awsAccessKeyId}
        autoComplete="off"
        onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
        onKeyDown={(e) => {
          const key = e.keyCode || e.which;
          if (key === 13) {
            e.preventDefault();
            awsSecretAccessKeyRef.current.focus();
          }
        }}
        label="AWS Access Key ID"
        placeholder="Enter your AWS Access Key ID"
      />
      <TextInput
        inputRef={awsSecretAccessKeyRef}
        type="password"
        name="awsSecretAccessKey"
        autoComplete="new-password"
        value={formData?.awsSecretAccessKey}
        onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
        onKeyDown={(e) => {
          const key = e.keyCode || e.which;
          if (key === 13) {
            e.preventDefault();
            continueBtnRef.current.click();
          }
        }}
        label="AWS Secret Access Key"
        placeholder="Enter your AWS Secret Access Key"
      />
      <Button
        ref={continueBtnRef}
        fullWidth
        variant="contained"
        className="nextStep-btn"
        onClick={() => setCurrentStep({ value: currentStepVal + 1, isReturning: false })}
        disabled={!(formData.awsAccessKeyId && formData.awsSecretAccessKey)}
        data-testid="next-step-btn"
      >
        Create Account
      </Button>
      <button type="button" className="back-link" onClick={() => setMenu(MENU[1])} data-testid="go-back">
        Go back
      </button>
    </FormLayout>
  );
}

SetupStorage.propTypes = {
  formData: FormDataType,
  setFormData: PropTypes.func.isRequired,
  setMenu: PropTypes.func.isRequired,
  currentStepVal: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
};

function SetupOrganizationForm({
  formData, setFormData, currentStep, setCurrentStep,
}) {
  // eslint-disable-next-line no-nested-ternary
  const [menu, setMenu] = useState(currentStep.isReturning
    ? formData.storageOption === 'Shoot'
      ? MENU[1] : MENU[2] : MENU[0]);

  return (
    <div>
      {menu === MENU[0] && (
        <OrganizationName
          formData={formData}
          setFormData={setFormData}
          setMenu={setMenu}
          currentStepVal={currentStep.value}
          setCurrentStep={setCurrentStep}
        />
      )}
      {menu === MENU[1] && (
        <SelectStorage
          formData={formData}
          setFormData={setFormData}
          setMenu={setMenu}
          currentStepVal={currentStep.value}
          setCurrentStep={setCurrentStep}
        />
      )}
      {menu === MENU[2] && (
        <SetupStorage
          formData={formData}
          setFormData={setFormData}
          setMenu={setMenu}
          currentStepVal={currentStep.value}
          setCurrentStep={setCurrentStep}
        />
      )}
    </div>
  );
}

SetupOrganizationForm.propTypes = {
  formData: FormDataType,
  setFormData: PropTypes.func.isRequired,
  currentStep: PropTypes.shape({
    value: PropTypes.number,
    isReturning: PropTypes.bool,
  }),
  setCurrentStep: PropTypes.func.isRequired,
};

export default SetupOrganizationForm;

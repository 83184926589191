import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: '4px !important',
    position: 'relative',
    backgroundColor: 'transparent !important',
    padding: '2px 4px',
    fontSize: 14,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25) !important',
    },
  },
}));

export function Dropdown({
  children, value, setValue, ...props
}) {
  return (
    <FormControl variant="standard" fullWidth>
      <Select
        value={value}
        onChange={(e) => setValue(e.target.value)}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        input={<BootstrapInput />}
      >
        {children}
      </Select>
    </FormControl>
  );
}

Dropdown.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  setValue: PropTypes.func,
  noResultText: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
};

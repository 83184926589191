import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import MemberNameEmailColumn from '../../components/table/MemberNameEmailColumn';

const TableButton = styled.button`
  border: 1px solid var(--dark-5);
  color: var(--dark-3);
  padding: 9px 22px;
  border-radius: 3px;
  background: #fff;
`;

function MemberInfoRows({ workspaceMembers }) {
  return workspaceMembers.map((workspaceMember) => {
    const {
      id, firstName, lastName, email,
    } = workspaceMember.user;
    return (
      <tr key={id}>
        <MemberNameEmailColumn firstName={firstName} lastName={lastName} email={email} />
        <td className="text-center">
          <Link to={{ pathname: '/members/activity/view', state: { workspaceMember } }}>
            <TableButton>View Activity</TableButton>
          </Link>
        </td>
      </tr>
    );
  });
}

MemberInfoRows.propTypes = {
  workspaceMembers: PropTypes.arrayOf(PropTypes.shape({

  })),
};

export default MemberInfoRows;

import React from 'react';
import { Button, Box } from '@mui/material';
import PropTypes from 'prop-types';

function TabPageHeader({
  title, subtitle, buttonName, onClickBtn,
}) {
  return (
    <Box className="d-flex justify-content-between align-items-center">
      <div>
        <h6 className="time-off-subheader">
          {title}
        </h6>
        <p className="mt-1">
          {subtitle}
        </p>
      </div>
      {buttonName && (
        <Button
          sx={{
            marginLeft: '20px',
            font: 'Inter',
            fontSize: '16px',
            lineHeight: '16px',
            fontWeight: '500',
            border: '1px solid',
            textTransform: 'none',
            borderRadius: '4px',
            backgroundColor: 'var(--green-3, #53A551)',
            color: '#FFFFFF',
            padding: '12px 24px',
            height: '40px',
          }}
          variant="link"
          component="span"
          onClick={() => onClickBtn()}
        >
          {buttonName}
        </Button>
      )}
    </Box>
  );
}

TabPageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  buttonName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  onClickBtn: PropTypes.func.isRequired,
};

export default TabPageHeader;

import React, { useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Button, Box, Grid, FormControlLabel, Checkbox, InputLabel,
} from '@mui/material';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { UserContext } from '../../contexts/User';
import { TextInput } from '../../components/muiCustomized/TextInput';
import { ChipSelect } from '../../components/muiCustomized/ChipSelect';
import DatePicker from '../../components/datePicker';
import { HolidayType } from '../../types';
import { timeToDateObject } from '../../utils/time';

const FORM_DEFAULT_VALUES = {
  name: '',
  date: null,
  paidHours: null,
  members: [],
  occursAnnually: true,
};
function HolidayModal({
  closeModal, members, getHolidays, selectedHoliday,
}) {
  const { activeWorkspace } = useContext(UserContext);
  const [formData, setFormData] = useState(selectedHoliday ? {
    name: selectedHoliday?.name,
    date: new Date(selectedHoliday?.date),
    paidHours: timeToDateObject(selectedHoliday?.paidHours),
    members: selectedHoliday?.members,
    occursAnnually: selectedHoliday?.occursAnnually,

  } : FORM_DEFAULT_VALUES);
  const [formErrors, setFormErrors] = useState({});
  const [saving, setSaving] = useState(false);
  const [successMessage, setSuccessMessage] = useState();

  const isEdit = !!selectedHoliday;

  const disableSaveBtn = useMemo(() => (
    !(formData.name
    && formData.date
    && formData.paidHours
    && members.length) || saving
  ), [formData, saving, members]);

  const setFormValues = (e) => setFormData({
    ...formData,
    [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
  });

  const holidayRequest = async ({ operation, message }) => {
    setSaving(true);
    setFormErrors({});
    sendRequest({
      ...operation,
      data: {
        ...formData,
        paidHours: formData
          .paidHours
          .toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' }),
      },
    }).then((res) => {
      if (res.success) {
        setSuccessMessage(message);
        setFormData(FORM_DEFAULT_VALUES);
        getHolidays();
      } else {
        setFormErrors(res.data);
      }
    }).finally(() => {
      setSaving(false);
      closeModal();
    });
  };

  const handleCreateHoliday = () => {
    const operation = {
      method: 'POST',
      url: API.WORKSPACE.createHoliday(activeWorkspace.id),
    };
    const message = 'Created holiday.';
    holidayRequest({
      operation,
      message,
    });
  };

  const handleEditHoliday = () => {
    const operation = {
      method: 'PUT',
      url: API.WORKSPACE.editHoliday(activeWorkspace.id, selectedHoliday.id),
    };
    const message = 'Updated holiday.';
    holidayRequest({
      operation,
      message,
    });
  };

  const setDate = (date) => {
    setFormData({ ...formData, date });
  };
  const setPaidHours = (paidHours) => {
    setFormData({ ...formData, paidHours });
  };
  const handleSelectAllMembers = () => {
    setFormData({ ...formData, members });
  };

  const membersList = useMemo(() => {
    if (selectedHoliday?.members?.length) {
      // Remove duplicates from selected and base members list
      const items = [...(selectedHoliday?.members || []), ...members]
        .filter((v, i, a) => a.findIndex((v2) => (v2.id === v.id)) === i);
      return items;
    }
    return members;
  }, [selectedHoliday, members]);

  return (
    <Modal
      open
      onClose={closeModal}
      className="flex-row align-c jstfy-c"
    >
      <Box
        id="HolidayModal"
        style={{
          width: 588,
          overFlowY: 'auto',
          padding: 32,
          background: '#fff',
          borderRadius: 8,
        }}
      >
        <h5 className="m-b16 text-600">
          {isEdit ? 'Edit Holiday' : 'Add Holiday'}
        </h5>
        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>
            <TextInput
              label="Holiday Name"
              value={formData.name}
              onChange={(e) => setFormValues(e)}
              placeholder="Required"
              error={!!formErrors?.name}
              helperText={formErrors?.name}
              background="#f9f9f9"
              name="name"
            />
          </Grid>
          <Grid item xs={12}>
            <div style={{ marginBottom: '16px' }}>
              <InputLabel sx={{ fontSize: '18px', color: '#212529', margin: 0 }} shrink htmlFor="holiday-date">
                Holiday Date
              </InputLabel>
              <div className="custom-date-icon">
                <DatePicker
                  format="M/d/yyyy"
                  id="holiday-date"
                  oneTap
                  value={formData.date}
                  error={!!formErrors?.date}
                  onChange={(date) => setDate(date)}
                  name="date"
                  placeholder="Required"
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ marginBottom: '16px' }}>
              <InputLabel sx={{ fontSize: '18px', color: '#212529', margin: 0 }} shrink htmlFor="paid-hours">
                Paid Hours
              </InputLabel>
              <div className="hide-date-icon">
                <DatePicker
                  format="HH:mm"
                  id="paid-hours"
                  value={formData.paidHours}
                  error={!!formErrors?.paidHours}
                  onChange={(paidHours) => setPaidHours(paidHours)}
                  name="paidHours"
                  placeholder="Required"
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <ChipSelect
              id="members"
              label="Add Members"
              selectedMembers={formData.members}
              handleChange={(e) => setFormValues(e)}
              members={membersList}
              placeholder="Required"
              selectAllBtn
              handleSelectAll={handleSelectAllMembers}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              sx={{ marginTop: '-16px', marginBottom: '16px' }}
              control={(
                <Checkbox
                  name="occursAnnually"
                  onChange={(e) => setFormValues(e)}
                  checked={formData.occursAnnually}
                />
              )}
              label="Occurs Annually"
            />
          </Grid>
        </Grid>
        <div className="holidayForm-btns" style={{ marginTop: 24 }}>
          <Button
            onClick={closeModal}
            color="secondary"
            sx={{
              marginRight: 2,
              textTransform: 'capitalize',
              border: 'inset',
              borderWidth: 1,
              borderColor: '#7D8698',
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ textTransform: 'capitalize' }}
            onClick={isEdit ? handleEditHoliday : handleCreateHoliday}
            disabled={disableSaveBtn}
          >
            { saving ? 'Saving...' : 'Save Changes' }
          </Button>
        </div>
        <div className="success-msg">{successMessage}</div>
      </Box>
    </Modal>
  );
}

HolidayModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  members: PropTypes.arrayOf(PropTypes.shape({})),
  getHolidays: PropTypes.func.isRequired,
  selectedHoliday: HolidayType,
};

export default HolidayModal;

/* eslint-disable no-nested-ternary */
import React, {
  useState, useCallback, useEffect, useContext,
} from 'react';
import { Button } from '@mui/material';
import UnfoldMore from '@mui/icons-material/UnfoldMore';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { UserContext } from '../../contexts/User';
import API from '../../api/endpoints';
import { sendRequest } from '../../api/shootAPI';
import NavigationLayout from '../../components/NavigationLayout';
import Toggle from '../../components/Toggle';
import { NotificationContext } from '../../contexts/Notification';
import useDebounce from '../../hooks/useDebounce';
import MemberNameEmailColumn from '../../components/table/MemberNameEmailColumn';
import CustomTable from '../../components/table/CustomTable';
import { webcamAuditPeriods } from '../../constants/webcamAudit';
import './webcamAudit.css';

const TABLE_HEADERS = [
  {
    label: 'User',
    sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'name',
    colSpan: 1.5,
  },
  {
    label: 'Screenshot Frequency',
    colSpan: 1,
  },
  {
    label: '',
    colSpan: 0.5,
  },
];

const CustomTableStyles = {
  container: {
    marginTop: 1,
  },
  header: {
    backgroundColor: 'var(--dark-10)',
    fontSize: '16px',
    '.MuiTableSortLabel-iconDirectionAsc': {
      transform: 'rotate(0deg) !important',
    },
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff !important',
    },
    '&:hover': {
      backgroundColor: 'var(--dark-10) !important',
    },
  },
  cell: {
    button: {
      textWrap: 'nowrap',
      color: 'var(--dark-2)',
      borderColor: 'var(--dark-4) !important',
    },
  },
};

function WebcamAudit() {
  const { search } = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(search);
  const page = query.get('page');
  const { displayNotification } = useContext(NotificationContext);
  const { activeWorkspace, setActiveWorkspace } = useContext(UserContext);
  const [members, setMembers] = useState([]);
  const [currentPage, setCurrentPage] = useState(page ? parseInt(page, 10) : 1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { debounce } = useDebounce();

  const getMembers = useCallback(async (params) => {
    if (activeWorkspace) {
      setIsLoading(true);
      const response = await sendRequest({
        method: 'get',
        url: API.WORKSPACE.webcamAuditLatest(activeWorkspace.id),
        params: {
          pageSize: 10,
          ...params,
        },
      });
      if (response.success) {
        const changeWebcamAuditsPerPeriod = async (newValue, memberId) => {
          const response = await sendRequest({
            method: 'put',
            url: API.WORKSPACE.memberUpdateWebcamAuditsPerPeriod(activeWorkspace.id, memberId),
            data: { webcamAuditsPerPeriod: newValue },
          });
          if (!response.success) {
            displayNotification({
              message: 'There was an issue updating webcam preferences. Please try again',
              severity: 'error',
            });
          }
        };

        const mappedMembers = response.data?.members.map((member) => {
          const options = webcamAuditPeriods.map((period) => ({
            label: period,
            onClick: () => changeWebcamAuditsPerPeriod(period, member?.id),
          }));

          return ({
            name: <MemberNameEmailColumn
              profilePicture={member?.user?.profilePicture}
              firstName={member.haveJoined ? member?.user?.firstName : ''}
              lastName={member.haveJoined ? member?.user?.lastName : ''}
              email={member?.user?.email}
              component="div"
            />,
            screenshotFrequency: <Toggle
              activeLabel={member?.webcamAuditsPerPeriod}
              options={options}
            />,
            actions: (
              <Link to={{
                pathname: '/members/activity/view',
                search: `?member=${member.id}`,
                state: { workspaceMember: member, activityType: 'Webcam Audit' },
              }}
              >
                <Button variant="outlined">View Activity</Button>
              </Link>
            ),
          });
        });
        setMembers(mappedMembers);
        setTotalPages(response.data?.pageInfo.totalPages ?? 1);
        setIsLoading(false);
      }
    }
  }, [activeWorkspace, displayNotification]);

  const fetchResults = async () => {
    const params = {
      page: currentPage,
      name: searchQuery,
    };
    await getMembers(params);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(debounce((query) => setSearchQuery(query), 500), []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    history.push(`?page=${page}`);
  };

  useEffect(() => {
    fetchResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchQuery]);

  const changeDefaultWebcamAuditsPerPeriod = async (newValue) => {
    const response = await sendRequest({
      method: 'put',
      url: API.WORKSPACE.workspaceUpdateWebcamAuditsPerPeriod(activeWorkspace.id),
      data: { webcamAuditsPerPeriod: newValue },
    });
    if (response.success) {
      const newActiveWorkspace = { ...activeWorkspace, webcamAuditsPerPeriod: newValue };
      setActiveWorkspace(newActiveWorkspace);
    } else {
      displayNotification({
        message: 'There was an issue updating webcam preferences. Please try again',
        severity: 'error',
      });
    }
  };

  const handleSort = debounce((sortKey, sortOrder) => {
    setIsLoading(true);
    return getMembers({
      page: currentPage,
      name: searchQuery,
      sortKey,
      sortOrder,
    });
  }, 250);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { getMembers(); }, []);

  return (
    <div>
      <NavigationLayout title="Webcam Audit">
        <div className="texts-section">
          <p className="large-bottom-margin size-xs">Control webcam audit settings for workspace members</p>
          <div className="large-bottom-margin">
            <h4 className="m-b12 size-s text-600">Organization Default</h4>
            <div className="size-xs">
              <Toggle
                activeLabel={activeWorkspace?.webcamAuditsPerPeriod}
                options={webcamAuditPeriods.map((period) => ({
                  label: period,
                  onClick: () => changeDefaultWebcamAuditsPerPeriod(period),
                }))}
              />
            </div>
          </div>
          <div className="in-block">
            <h4 className="m-b12 size-s text-600">Individual Settings</h4>
            <p className="size-xs">Override the organization default for specific members</p>
          </div>
          <div className="searchBox">
            <input type="text" placeholder="Search for user" onChange={(e) => handleSearch(e.target.value)} />
            <i className="fa fa-search searchIcon" />
          </div>
        </div>

        <CustomTable
          headers={TABLE_HEADERS}
          items={members}
          loading={isLoading}
          pages={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          handleSort={handleSort}
          styles={CustomTableStyles}
        />
      </NavigationLayout>
    </div>
  );
}

WebcamAudit.propTypes = {

};

export default WebcamAudit;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './sidebar.css';
import SubMenu from './submenu';

const linkArrayNamesSuperAdmin = [
  {
    routeName: '/admin/dashboard',
    text: 'Dashboard',
    enabled: true,
  },
  {
    routeName: '/admin/organizations',
    text: 'Organizations',
    enabled: true,
  },
  {
    routeName: '/admin/workspaces',
    text: 'Wrokspaces',
    enabled: true,
  },
  {
    routeName: '/admin/teams',
    text: 'Teams',
    enabled: true,
  },
  {
    routeName: '/admin/users',
    text: 'Users',
    enabled: true,
  },
  {
    routeName: '/admin/payments',
    text: 'Payments',
    enabled: true,
  },
  {
    routeName: '/admin/settings',
    text: 'Settings',
    enabled: true,
  },
];

const MenuLink = styled.li`
  ${(props) => !props.enabled && css`
  cursor: disabled;
  pointer-events: none;
  opacity: 0.8;
  `};
`;

function SuperSidebar({ children }) {
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line no-nested-ternary
  const linkArray = linkArrayNamesSuperAdmin;

  return (
    <>
      <div className="sidenav">
        <div className="nav-contents">
          <h3 className="administration">
            {' '}
            ADMINISTRATION
            {' '}
          </h3>
          <ul className="admin-nav">
            { linkArray.map((linkInfo) => (linkInfo?.subRoutes
              ? (
                <SubMenu
                  key={linkInfo.text}
                  linkInfo={linkInfo}
                  open={open}
                  setOpen={(info) => setOpen(info)}
                />
              )
              : (
                <MenuLink
                  key={linkInfo.routeName}
                  enabled={linkInfo.enabled}
                  className="link-item"
                >
                  <NavLink
                    exact
                    to={linkInfo.routeName}
                    activeClassName="active-link"
                    className="link-text"
                  >
                    {linkInfo.text}
                  </NavLink>
                </MenuLink>
              )))}
          </ul>
        </div>
      </div>
      {children}
    </>
  );
}

export { MenuLink, SuperSidebar };

SuperSidebar.propTypes = {
  children: PropTypes.elementType,
};

import React from 'react';
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

export const BootstrapInput = styled(InputBase)(({ background, theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  backgroundColor: background,
  '& .MuiInputBase-input': {
    borderRadius: '4px !important',
    border: '1px solid',
    borderColor: 'var(--sds-input-border)',
    backgroundColor: 'transparent !important',
    fontSize: 16,
    width: '100%',
    padding: '8px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiNativeSelect-icon': {
    top: '8px',
    right: '8px',
  },
}));

export function TextInput({
  label,
  className,
  helperText,
  error,
  required,
  background = 'white',
  id = 'bootstrap-input',
  fullWidth = true,
  ...props
}) {
  return (
    <FormControl sx={{ marginBottom: '16px' }} variant="standard" fullWidth={fullWidth}>
      {label
        && (
          <InputLabel sx={{ fontSize: '18px', color: '#212529' }} shrink htmlFor={id}>
            {label}
            {required && <span style={{ color: 'red', fontSize: '20px', marginLeft: '4px' }}>*</span>}
          </InputLabel>
        )}
      <BootstrapInput
        classes={className}
        background={background}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        id={id}
      />
      <FormHelperText error={error} style={{ margin: '5px 12px 0' }}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
}

TextInput.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  required: PropTypes.bool,
  background: PropTypes.string,
  fullWidth: PropTypes.bool,
};

function getOperatingSystem() {
  const { userAgent } = window.navigator;

  if (/Windows/.test(userAgent)) {
    return 'Windows';
  } if (/Macintosh|Mac OS/.test(userAgent)) {
    return 'MacOS';
  } if (/Linux/.test(userAgent)) {
    return 'Linux';
  } if (/Android/.test(userAgent)) {
    return 'Android';
  } if (/iOS|iPadOS|iPhone OS/.test(userAgent)) {
    return 'iOS';
  }
  return 'Unknown';
}

function getBrowser() {
  const { userAgent } = window.navigator;

  if (/Edg/.test(userAgent)) {
    return 'Microsoft Edge';
  } if (/Opera|OPR/.test(userAgent)) {
    return 'Opera';
  } if (/Chrome/.test(userAgent)) {
    return 'Google Chrome';
  } if (/Firefox/.test(userAgent)) {
    return 'Mozilla Firefox';
  } if (/Safari/.test(userAgent)) {
    return 'Safari';
  } if (/Trident/.test(userAgent)) {
    return 'Internet Explorer';
  }
  return 'Unknown';
}

export {
  getOperatingSystem,
  getBrowser,
};

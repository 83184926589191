import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Box, Button } from '@mui/material';
import { Description } from '../../components/styledComponents';
import RestoreIcon from '../../images/restore.png';

function ArchiveModal({
  title, description, policyName, setShowModal, callback, buttonName,
}) {
  return (
    <Modal open onClose={() => setShowModal(false)} className="flex-row align-c jstfy-c create-team-modal">
      <Box style={{
        width: 490,
        padding: 32,
        background: '#fff',
        borderRadius: 8,
      }}
      >
        <img src={RestoreIcon} width="48" height="48" alt="trash" />
        <div className="modal-body" style={{ padding: 0 }}>
          <Description
            fontSize={20}
            fontWeight={600}
            color="#0E1E40"
            marginTop={16}
          >
            {title}
          </Description>
          <Description
            fontSize={14}
            fontWeight={400}
            color="#293855"
            marginTop={6}
          >
            {description}
          </Description>
          <Description
            fontSize={14}
            fontWeight={400}
            color="#757575"
            marginTop={12}
          >
            Policy:
            <span style={{ fontWeight: 500, color: '#0E1E40', marginLeft: 10 }}>{policyName}</span>
          </Description>
        </div>

        <div className="modal-footer-customized">
          <Button
            sx={{
              marginRight: 2,
              textTransform: 'none',
              fontSize: '16px',
              height: '40px',
              width: '101px',
            }}
            variant="outlined"
            onClick={() => setShowModal(false)}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            sx={{
              textTransform: 'none',
              fontSize: '16px',
              height: '40px',
              width: '101px',
              backgroundColor: '#53A551',
              '&:hover': { backgroundColor: '#53A551' },
            }}
            variant="contained"
            onClick={() => callback()}
          >
            {buttonName}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

ArchiveModal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  policyName: PropTypes.string.isRequired,
  setShowModal: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  buttonName: PropTypes.string.isRequired,
};

export default ArchiveModal;

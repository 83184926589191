import React from 'react';
import PropTypes from 'prop-types';
import { GoogleMap } from '@react-google-maps/api';
import { ChildrenType } from '../../propTypes';

const containerStyle = {
  width: 'calc(100vw - 244px)',
  height: '100vh',
};

function Map({ lat, lng, children }) {
  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{ lat, lng }}
      zoom={15}
      options={{
        fullscreenControlOptions: {
          position: window.google.maps.ControlPosition.TOP_LEFT,
        },
      }}
    >
      {children}
    </GoogleMap>
  );
}

Map.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  children: ChildrenType,
};

export default React.memo(Map);

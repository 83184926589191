/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { styled } from '@mui/system';
import {
  Card, CardContent, CardHeader, Button,
} from '@mui/material';
import CustomTable from '../../components/table/CustomTable';
import Pagination from '../../components/pagination';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { NotificationContext } from '../../contexts/Notification';
import Last from '../../images/last.png';
import { formatDate } from '../../utils/time';

const CustomCard = styled(Card)`
    borderRadius: "8px",
    width: 30%,
    border: "1px solid var(--Dark-Dark-06, #E0E1E6)",
    boxShadow: "none"
`;

const FIXED_TABLE_HEADERS = [
  {
    label: 'Organization Name',
    sortable: false,
  },
  {
    label: 'Active Users',
    sortable: false,
  },
  {
    label: 'Inactive Users',
    sortable: false,
  },
  {
    label: 'Last Activity Date',
    sortable: false,
  },
  {
    label: 'Health Status',
    sortable: false,
  },
  {
    label: 'Total Workspaces',
    sortable: false,
  },
  {
    label: 'Total teams',
    sortable: false,
  },
  {
    label: 'Overdue Payments',
    sortable: false,
  },
];

function OrganizationsCard() {
  const { search } = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(search);
  const { displayNotification } = useContext(NotificationContext);
  const page = query.get('page');
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(page ? parseInt(page, 10) : 1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCounts, setTotalCounts] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const fetctOrganizations = async () => {
    setLoading(true);
    const { success, data } = await sendRequest({
      method: 'post',
      url: API.ORGANIZATION.organizations(),
      params: {
        pageSize,
        page: currentPage,
      },
      timeout: 30000,
    });
    if (success) {
      const tempData = [];
      data.organizations.forEach((item) => {
        const datum = {
          organizationName: item.name,
          activeUsers: item.activeUserCount,
          inactiveUsers: item.inactiveUserCount,
          lastActivityDate: formatDate(item.update_at),
          healthStatus: 'Good',
          totalWorkspaces: item.workspacesCount,
          totalTeams: item.teamCount,
          overduePayments: '12',
        };
        tempData.push(datum);
      });
      setTableData(tempData);
      setTotalPages(data?.pageInfo.totalPages ?? 1);
      setTotalCounts(data?.pageInfo.totalCounts ?? 0);
      setLoading(false);
    } else {
      displayNotification({
        message: 'There was an issue fetching organization data. Please try again',
        severity: 'error',
      });
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    history.push(`?page=${page}`);
  };

  useEffect(() => {
    fetctOrganizations();
  }, []);

  useEffect(() => {
    fetctOrganizations();
  }, [pageSize, currentPage]);

  return (
    <CustomCard className="daily-activities-card">
      <CardHeader
        className="table-card-header hours-card-header"
        title={(
          <div className="table-card-header-title d-flex gap-1 justify-content-between align-items-center">
            <div className="table-card-logo d-flex gap-1">
              <img
                src={Last}
                alt="info icon"
                style={{
                  width: '23px',
                  height: '34px',
                  marginTop: '4px',
                }}
              />
              <p>Organization Health Status</p>
            </div>
            <Button sx={{ border: '2px solid #E0E1E6', height: '24px', color: '#4469A1' }}>View All</Button>
          </div>
        )}
      />
      <CardContent className="table-card-content d-flex flex-column justify-content-between">
        <CustomTable headers={FIXED_TABLE_HEADERS} items={tableData} loading={loading} />
        <Pagination
          currentPage={currentPage}
          pageSize={pageSize}
          totalPages={totalPages}
          totalCounts={totalCounts}
          onPageChange={handlePageChange}
          setPageSize={setPageSize}
          styles={{ margin: 0 }}
        />
      </CardContent>
    </CustomCard>
  );
}

export default OrganizationsCard;

import React from 'react';
import { Checkbox } from '@mui/material';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import BalanceBox from './BalanceBox';

const Name = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #293855 !important;
  margin-left: 8px;
`;

function MemberBalanceRow({
  member, policies, getMembers,
}) {
  const { id } = member;
  const {
    firstName, lastName,
  } = member.user;
  const balances = member.ptoBalances;

  return (
    <tr
      className="balance-row"
      key={id}
    >
      <td key={`member${id}`} className="flex-row align-c">
        <div className="d-flex align-c">
          <Checkbox sx={{ padding: '0px' }} id={id} />
          <Name>
            {firstName}
            {' '}
            {lastName}
          </Name>
        </div>
      </td>
      {balances?.length
        ? (policies.map((policy) => {
          const match = balances.find((balance) => (balance.policyId === policy.id));
          if (match) {
            return (
              <BalanceBox match={match} getMembers={() => getMembers()} />
            );
          }
          return (<td id={policy.id}>{}</td>);
        })) : (policies.map((policy) => (
          <td id={policy.id}>{}</td>
        ))
        )}
    </tr>
  );
}

MemberBalanceRow.propTypes = {
  member: PropTypes.shape({
    id: PropTypes.number.isRequired,
    ptoBalances: PropTypes.arrayOf(PropTypes.shape({})),
    user: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  policies: PropTypes.arrayOf(PropTypes.shape({})),
  getMembers: PropTypes.func.isRequired,
};

export default MemberBalanceRow;

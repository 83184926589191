import React from 'react';
import PropTypes from 'prop-types';
import Popover from '@mui/material/Popover';
import styled from '@emotion/styled';
import { formatSeconds } from '../../utils/time';

// eslint-disable-next-line no-nested-ternary
const getAmPm = (hour) => (hour === 24 ? 'am' : hour >= 12 ? 'pm' : 'am');

const formatQualityTimeMessage = ({ activeSeconds }) => {
  if (activeSeconds === 0) return 'Offline';
  if (activeSeconds < 60) {
    return `Quality Time: < ${formatSeconds(60, { roundToNearestMinute: false })}`;
  }
  return `Quality Time: ${formatSeconds(activeSeconds, { roundToNearestMinute: false })}`;
};

const InfoCardTitle = styled.div`
  width: 100%;
  background: var(--dark-7);
  border-bottom: 1px solid var(--dark-6);
  padding: 4px 8px;
`;

function BarPopup({
  open, anchorEl, handlePopoverClose, hour, hourInfo,
}) {
  const { activeSeconds, focusMessage, color } = hourInfo;
  const startTime = `${(hour % 12) || 12}${getAmPm(hour)}`;
  const endTime = `${(hour % 12) + 1}${getAmPm(hour + 1)}`;
  // Need to darken gray text for 'Offline' bars and gray colored bars
  const textColor = color === 'var(--dark-5)'
    || color === 'transparent' ? 'var(--dark-4)' : color;

  return (
    <Popover
      id="mouse-over-popover"
      sx={{
        pointerEvents: 'none',
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: -10,
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <div>
        <InfoCardTitle>{`${startTime} - ${endTime}`}</InfoCardTitle>
        <div className="p-2">
          <div>
            <p style={{ color: textColor }} className="text-600">{focusMessage}</p>
          </div>
          <div>
            <p>{formatQualityTimeMessage({ activeSeconds })}</p>
          </div>
        </div>
      </div>
    </Popover>
  );
}

BarPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.shape({}),
  handlePopoverClose: PropTypes.func.isRequired,
  hour: PropTypes.number.isRequired,
  hourInfo: PropTypes.shape({
    activeSeconds: PropTypes.number.isRequired,
    focusMessage: PropTypes.string,
    color: PropTypes.string.isRequired,
  }),
};

export default BarPopup;

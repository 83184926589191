import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, IconButton, Select, MenuItem,
} from '@mui/material';
import { Images } from '../../common';

function Pagination({
  currentPage, pageSize, totalPages, totalCounts, onPageChange, setPageSize, styles,
}) {
  const { leftVectorPage, rightVectorPage } = Images;
  const pages = Array.from({ length: totalPages }, (_, index) => index + 1);
  const offset = (currentPage - 1) * pageSize + 1;
  const handlePrevClick = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const MAX_VISIBLE_PAGES = 5; // Adjust this value based on your design

  const visiblePages = pages.slice(
    Math.max(currentPage - Math.floor(MAX_VISIBLE_PAGES / 2), 0),
    Math.min(currentPage + Math.ceil(MAX_VISIBLE_PAGES / 2), totalPages),
  );

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        pt: 1,
        borderTop: '2px solid #ECECF0',
        margin: '-44px 32px 0 282px',
        ...styles,
      }}
    >
      <Box>
        <IconButton
          sx={{ mr: 1 }}
          onClick={handlePrevClick}
          disabled={currentPage === 1}
          size="small"
        >
          <img src={leftVectorPage} alt="prev" />
        </IconButton>
        {visiblePages.map((page) => (
          <Button
            variant="text"
            disabled={currentPage === page}
            onClick={() => onPageChange(page)}
            key={page}
            size="small"
            sx={{
              lineHeight: '17px',
              fontSize: '14px',
              fontWeight: 400,
              padding: '4px 8px',
              color: '#293855',
              minWidth: 'auto',
              '&.Mui-disabled': {
                color: '#293855',
                backgroundColor: '#E0E1E6',
                fontWeight: 500,
              },
            }}
          >
            { page }
          </Button>
        ))}
        <IconButton
          sx={{ ml: 1 }}
          onClick={handleNextClick}
          disabled={currentPage === totalPages}
          size="small"
        >
          <img src={rightVectorPage} alt="next" />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          marginRight: '16px',
        }}
      >
        <p>Rows Per Page:</p>
        <Select
          value={pageSize}
          onChange={(e) => setPageSize(e.target.value)}
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{
            '& div': {
              padding: '2px 24px 2px 8px',
            },
          }}
        >
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
        <div style={{
          border: '1px solid var(--dark-5)',
          height: '20px',
        }}
        />
        <p>
          {offset}
          -
          {Math.min(offset + pageSize - 1, totalCounts)}
          &nbsp;of&nbsp;
          {totalCounts}
        </p>
      </Box>
    </Box>
  );
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalCounts: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired,
  styles: PropTypes.shape({}),
};

export default Pagination;

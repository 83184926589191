import React, { useState } from 'react';
import ShootLogo from '../../images/blue-shoot-logo.png';
import {
  CloseIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  MenuIcon,
  TwitterIcon,
} from './Icons';

function Header() {
  const [opened, setOpened] = useState(false);
  let appUrl = window.location.href;

  if (process.env.REACT_APP_NODE_ENV !== 'development') {
    appUrl = appUrl.replace('www', 'app');
    appUrl = appUrl.replace('staging-home', 'staging-app');
  }

  return (
    <>
      <header className="header d-flex align-items-center">
        <div className="container d-flex align-items-center justify-content-between">
          <a className="d-flex" href="/">
            <img className="logo" src={ShootLogo} alt="logo" width="132" height="50" loading="lazy" />
          </a>
          <ul className="nav d-none d-md-flex">
            <li><a className="nav-link" href="/">Home</a></li>
            <li><a className="nav-link" href="/">Pricing</a></li>
            <li><a className="nav-link" href="/">Resources Center</a></li>
            <li><a className="nav-link" href="/">About</a></li>
            <li><a className="nav-link" href="/">Contact</a></li>
          </ul>
          <div className="d-none d-lg-flex align-items-center">
            <a className="nav-link" href={appUrl}>Log In</a>
            <a className="btn btn-primary btn-header" href={`${appUrl}signup`}>Get Started</a>
          </div>
          <button
            type="button"
            className="d-flex d-md-none pointer-cursor"
            onClick={() => setOpened(true)}
          >
            <span className="d-none">Open</span>
            <MenuIcon width="24" height="24" />
          </button>
        </div>
      </header>
      <div className={`mobile-menu ${opened ? 'show' : ''}`}>
        <div className="mobile-menu-wrapper container position-fixed d-flex flex-column">
          <div className="mobile-header d-flex align-items-center justify-content-between">
            <a className="d-flex" href="/">
              <img className="logo" src={ShootLogo} alt="logo" width="106" height="41" loading="lazy" />
            </a>
            <button
              type="button"
              className="d-flex d-md-none pointer-cursor"
              onClick={() => setOpened(false)}
            >
              <span className="d-none">Close</span>
              <CloseIcon width="24" height="24" />
            </button>
          </div>
          <ul className="nav mobile-nav flex-column">
            <li><a className="nav-link" href="/">Home</a></li>
            <li><a className="nav-link" href="/">Pricing</a></li>
            <li><a className="nav-link" href="/">Resources Center</a></li>
            <li><a className="nav-link" href="/">About</a></li>
            <li><a className="nav-link" href="/">Contact</a></li>
          </ul>
          <div className="mobile-menu-footer">
            <h6 className="mb-3">Find us on</h6>
            <div className="d-flex justify-content-between">
              <a href="/" target="_blank" className="d-flex">
                <span className="d-none">Linkedin</span>
                <LinkedinIcon width="24" height="24" />
              </a>
              <a href="/" target="_blank" className="d-flex">
                <span className="d-none">Twitter</span>
                <TwitterIcon width="24" height="24" />
              </a>
              <a href="/" target="_blank" className="d-flex">
                <span className="d-none">Facebook</span>
                <FacebookIcon width="24" height="24" />
              </a>
              <a href="/" target="_blank" className="d-flex">
                <span className="d-none">Instagram</span>
                <InstagramIcon width="24" height="24" />
              </a>
            </div>
            <p className="copyright mt-4 mb-3">© 2024 Shoot Corporation. All rights reserved</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;

import React from 'react';
import CenterCardLayout from '../../components/CenterCardLayout';

function InvalidInvitation() {
  return (
    <CenterCardLayout
      title="Invalid invitation code"
      description="Your invitation link is invalid."
    />
  );
}

export default InvalidInvitation;

import axios from 'axios';
import config from '../config';
import API from './endpoints';

export const shootAPI = axios.create({
  baseURL: config.BaseUrl,
  timeout: 10000,
});

export const sendRequest = async (options) => {
  try {
    const response = await shootAPI({ ...options });
    return { success: true, data: response?.data, status: response.status };
  } catch (error) {
    const value = !error?.response?.data || typeof error?.response?.data === 'string'
      ? {
        success: false,
        data: { error: 'There was an error with your request. Please try again.' },
        status: error?.response?.status,
      }
      : { success: false, data: error?.response?.data, status: error?.response?.status };

    // eslint-disable-next-line no-console
    console.log('Error response from API', value, error);
    return value;
  }
};

const handleUnauthorize = async () => {
  const refreshToken = localStorage.getItem('shoot_refresh_token');
  try {
    await sendRequest({
      method: 'post',
      url: API.AUTH.logout,
      headers: { Authorization: `Bearer ${refreshToken}` },
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Refresh Token Logout Failed');
  }

  shootAPI.defaults.headers.common.Authorization = null;
  localStorage.clear();
  window.location.href = '/';
};

const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('shoot_refresh_token');
  if (!refreshToken) {
    return null;
  }
  try {
    const response = await sendRequest({
      method: 'post',
      url: API.AUTH.refresh,
      headers: { Authorization: `Bearer ${refreshToken}` },
    });

    if (response.success) {
      const { userToken, accessToken, refreshToken } = response.data;

      if (accessToken) {
        localStorage.setItem('shoot_auth_token', accessToken);
      }
      if (refreshToken) {
        localStorage.setItem('shoot_refresh_token', refreshToken);
      }
      if (userToken) {
        localStorage.setItem('shoot_user_token', userToken);
      }

      return { userToken, accessToken, refreshToken };
    }
    return null;
  } catch (error) {
    return null;
  }
};

shootAPI.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Check if the error is due to an expired token
    if (error.response.status === 401
      && error.response.data.error === 'Token expired'
      && !originalRequest._retry
    ) {
      originalRequest._retry = true;

      try {
        // Call the refresh token function
        const refreshedTokens = await refreshAccessToken();
        if (refreshedTokens) {
          const { accessToken, userToken } = refreshedTokens;

          // Update the Authorization header with the new token
          if (accessToken) {
            shootAPI.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          } else {
            shootAPI.defaults.headers.common.Authorization = `Bearer ${userToken}`;
            originalRequest.headers.Authorization = `Bearer ${userToken}`;
          }

          // Retry the original request with the new token
          return shootAPI(originalRequest);
        }
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    } else if (error?.response?.status === 401 && error?.response?.data?.error !== 'Token expired') {
      handleUnauthorize();
    }

    return Promise.reject(error);
  },
);

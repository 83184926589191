import PropTypes from 'prop-types';

export const ActivityType = PropTypes.shape({
  screenshots: PropTypes.arrayOf(
    PropTypes.shape({
      fileName: PropTypes.string.isRequired,
      screen: PropTypes.string.isRequired,
    }),
  ),
  id: PropTypes.number.isRequired,
  memberId: PropTypes.number.isRequired,
  workspaceId: PropTypes.number.isRequired,
  mouseClick: PropTypes.number.isRequired,
  mouseWheel: PropTypes.number.isRequired,
  mouseMove: PropTypes.number.isRequired,
  keyDown: PropTypes.number.isRequired,
  secondsElapsed: PropTypes.number.isRequired,
  activeSeconds: PropTypes.number.isRequired,
  activityPercentage: PropTypes.number.isRequired,
  timeSlot: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
});

export const FormDataType = PropTypes.shape({
  first: PropTypes.string.isRequired,
  last: PropTypes.string.isRequired,
  avatar: PropTypes.instanceOf(File),
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object,
  latlng: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  confirmPassword: PropTypes.string.isRequired,
  organizationName: PropTypes.string.isRequired,
  awsAccessKeyId: PropTypes.string,
  awsSecretAccessKey: PropTypes.string,
  workspaceName: PropTypes.string.isRequired,
  workspaceDescription: PropTypes.string.isRequired,
  worsspaceMembers: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string.isRequired,
      accountType: PropTypes.string.isRequired,
    }),
  ),
  code: PropTypes.string.isRequired,
  hasError: PropTypes.bool.isRequired,
});

export const WebcamAuditType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  ),
  memberId: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  workspaceId: PropTypes.number.isRequired,
});

export const ChildrenType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
]);

export const UserLocationType = PropTypes.shape({
  formattedAddress: PropTypes.string.isRequired,
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  country: PropTypes.string,
  postalCode: PropTypes.string,
  administrativeAreaLevel1: PropTypes.string,
  locality: PropTypes.string,
  sublocality: PropTypes.string,
  streetAddress: PropTypes.string,
});

export const LocationViolationType = PropTypes.shape({
  UserLocationType,
  activityIds: PropTypes.arrayOf(PropTypes.number),
  memberId: PropTypes.number,
  workspaceId: PropTypes.number,
});

export const UserType = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  formattedAddress: PropTypes.string,
  userLocation: UserLocationType,
});

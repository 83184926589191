const screenshotsPerPeriodMapping = {
  None: 0,
  '1x': 1,
  '2x': 2,
  '3x': 3,
};

const blurScreenshotsMapping = {
  Off: false,
  On: true,
};

const NONE = 'None';
const ONCE = '1x';
const TWICE = '2x';
const THRICE = '3x';

const screenshotsPerPeriodTypes = {
  NONE,
  ONCE,
  TWICE,
  THRICE,
};

module.exports = {
  screenshotsPerPeriodMapping,
  screenshotsPerPeriodTypes,
  blurScreenshotsMapping,
};

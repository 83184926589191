import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const StyledError = styled.p`
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
`;

function Error({ msg }) {
  return (
    <StyledError className="auth-error">
      <i className="fa fa-exclamation-triangle" />
      {' '}
      &nbsp;
      <strong>{msg}</strong>
    </StyledError>
  );
}

Error.propTypes = {
  msg: PropTypes.string.isRequired,
};

export default Error;

/* eslint react/jsx-props-no-spreading: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, Link } from 'react-router-dom';

// Persists the query params of the current route through to the link
export function LinkWithQueryParams({ children, to, ...props }) {
  const { search } = useLocation();

  return (
    <Link to={to + search} {...props}>
      {children}
    </Link>
  );
}

LinkWithQueryParams.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string.isRequired,
};

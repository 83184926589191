import React, { useContext, useEffect, useState } from 'react';
// import styled from '@emotion/styled';
// import { Link } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { UserContext } from '../../contexts/User';
import { Images } from '../../common';
import API from '../../api/endpoints';
import { sendRequest } from '../../api/shootAPI';
import WorkspaceCard from './WorkspaceCard';
import useWorkspaces from '../../hooks/useWorkspaces';

// const CreateWorkspaceText = styled.text`
//   font-size: 18px;
//   text-align: 'center';
//   color: var(--green-3);
//   font-weight: 500;
//   margin-top: 3px;
// `;

function Workspaces() {
  const {
    userToken,
    user,
    redirect,
    workspaces,
    setWorkspaces,
    signOut,
  } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const { handleWorkspaceSelection } = useWorkspaces();

  // Get the user's data upon signing in
  useEffect(() => {
    // Redirect depending on logged in state
    if (!(userToken && user)) {
      redirect('/');
    }

    (async () => {
      setIsLoading(true);
      const response = await sendRequest({ method: 'get', url: API.USER.workspaces });
      if (response.success && response.data?.workspaces) {
        setWorkspaces(response.data.workspaces);
        setIsLoading(false);
      } else {
        // handle API errors
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="twofactor-main">
      <div>
        <div className="twofactor-img">
          <img src={Images.shootLogo} alt="Shoot-logo" />
        </div>
        <h2 style={{ marginTop: 12, marginBottom: 20, textAlign: 'center' }}>Select a Workspace</h2>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {isLoading ? (
            <div>
              <span style={{ display: 'none' }}>spinner</span>
              <CircularProgress sx={{ color: 'inherit' }} />
            </div>
          ) : workspaces?.map((workspace, index) => (
            <WorkspaceCard
              key={`workspace${workspace.id}`}
              name={workspace.name}
              image={workspace.picture}
              dataTestid={`workspace-${index + 1}`}
              description={workspace.description}
              onClick={() => handleWorkspaceSelection(workspace)}
            />
          ))}
          {Array.isArray(workspaces) && workspaces.length === 0 && (
            <>
              <p
                style={{ fontSize: 18, textAlign: 'center', marginTop: 20 }}
              >
                You do not belong to any workspaces yet.
              </p>
              <button
                type="button"
                className="back-link"
                onClick={signOut}
              >
                Sign out and return to login
              </button>
            </>
          )}
          {/* <Link to="/create"><CreateWorkspaceText>Create A New Workspace</CreateWorkspaceText></Link> */}
        </div>
      </div>
    </div>
  );
}

Workspaces.propTypes = {

};

export default Workspaces;

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import ImageViewer from 'react-simple-image-viewer';
import { Switch } from '@mui/material';
import { ActivityType } from '../../propTypes';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';

function Snapshot({ snapshot, blurScreenshots }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const memberId = queryParams.get('member');
  const { screenshots, activityPercentage, secondsElapsed } = snapshot.data;
  const [isBlur, setIsBlur] = useState(blurScreenshots);
  const [screensAreOpen, setScreensAreOpen] = useState(false); // Screenshots are being viewed (full screen)
  const [coverImgUrl, setCoverImgUrl] = useState('');

  useEffect(() => {
    if (screenshots.length) {
      sendRequest({
        method: 'GET',
        url: API.MEMBER.screenshot(memberId),
        params: { baseUrl: screenshots[0]?.url },
      }).then(({ success, data }) => {
        if (success) {
          setCoverImgUrl(data.url);
        }
      });
    }
  }, []);
  return (
    <>
      <article className="snapshot flex-col">
        <div
          style={{
            position: 'relative',
            height: '100%',
            width: '100%',
            background: 'var(--dark-5)',
            color: 'var(--dark-4)',
          }}
          className="snapshot-img-view flex-row align-c jstfy-c"
          onClick={() => setScreensAreOpen(true)}
          onKeyDown={() => setScreensAreOpen(true)}
          role="button"
          tabIndex={0}
        >
          <div className="snapshot-img-cover" />
          {coverImgUrl
            ? (
              <img
                src={coverImgUrl}
                width="100%"
                alt="Activity screenshot"
                style={{ borderRadius: '4px 4px 0 0', minHeight: 68 }}
                className={isBlur ? 'snapshot-img-blur' : ''}
              />
            )
            : (<p>No Screenshots</p>)}
          <div style={{ position: 'absolute', bottom: -9, width: '100%' }}>
            {/* <div className="screens-btn snap-text">
            {`${bucketPathsToImages.length} ${bucketPathsToImages.length === 1 ? 'screen' : 'screens'}`}
            </div> */}
          </div>
        </div>
        <div className="snapshot-details" style={{ marginTop: 'auto' }}>
          <div className="snap-text" style={{ padding: '4px 0px' }}>
            {snapshot.roundedStartTime}
            {' '}
            -
            {' '}
            {snapshot.roundedEndTime}
          </div>
          <div className="activity-bar-parent">
            <div
              className="activity-bar"
              style={{
                width: `${activityPercentage}%`,
                // eslint-disable-next-line no-nested-ternary
                background: activityPercentage > 50
                  ? '#64BA62'
                  : activityPercentage > 20 ? '#F6C643' : '#EE7C5C',
              }}
            />
          </div>
          <div className="snap-text" style={{ margin: 'auto' }}>
            {`${activityPercentage}% of ${
              secondsElapsed < 60
                ? `${secondsElapsed} seconds`
                : `${Math.round(secondsElapsed / 60)} minutes`
            }`}
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <Switch size="small" checked={isBlur} onChange={() => setIsBlur(!isBlur)} color="secondary" />
            <span className="snap-text">Blur Screenshot</span>
          </div>
        </div>
      </article>
      {screensAreOpen
        && (
          <ImageViewer
            disableScroll
            src={[coverImgUrl]}
            currentIndex={0}
            onClose={() => setScreensAreOpen(false)}
            backgroundStyle={{
              backgroundColor: 'rgba(0,0,0,0.9)',
              zIndex: 20,
            }}
            closeOnClickOutside
          />
        )}
    </>
  );
}

export default Snapshot;

Snapshot.propTypes = {
  snapshot: PropTypes.shape({
    data: ActivityType,
    localBlockTime: PropTypes.string.isRequired,
    localStartTime: PropTypes.string.isRequired,
    roundedEndTime: PropTypes.string.isRequired,
    roundedStartTime: PropTypes.string.isRequired,
  }),
  blurScreenshots: PropTypes.bool.isRequired,
};

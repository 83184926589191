/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import TrendDown from '../../images/trending_down.png';
import TrendUp from '../../images/trending_up.png';
import SimpleBar from '../stackChart/SimpleBar';

function processData(data, tooltip) {
  const monthMap = {};
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const result = [];

  const currentDate = new Date();
  const lastSixMonths = [];

  for (let i = 5; i >= 0; i--) {
    const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
    const yearMonth = `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
    lastSixMonths.push(yearMonth);
    monthMap[yearMonth] = 0;
  }

  data.forEach((datum) => {
    const date = new Date(datum.createdAt);
    const yearMonth = `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
    if (monthMap[yearMonth] !== undefined) {
      monthMap[yearMonth]++;
    }
  });

  // eslint-disable-next-line no-unused-vars
  let lastCount = 0;
  lastSixMonths.forEach((month, index) => {
    let change = 0;
    if (index > 0) {
      const previousCount = monthMap[lastSixMonths[index - 1]];
      if (previousCount === 0) {
        change = monthMap[month] === 0 ? 0 : 100;
      } else {
        change = ((monthMap[month] - previousCount) / previousCount) * 100;
      }
      change = parseFloat(change.toFixed(2));
    }
    result.push({
      month,
      [tooltip]: monthMap[month],
      change: change === 0 ? 0 : change,
    });
    lastCount = monthMap[month];
  });

  return result;
}

function parseEmptyDataElement() {
  return (
    <div className="total-change total-increase">
      <img src={TrendUp} alt="info icon" />
      <p>
        <span style={{ color: '#378A35' }}>
          0%
        </span>
        &nbsp;
        since last month
      </p>
    </div>
  );
}

function TotalCard({
  data, title, tooltip, color,
}) {
  const newData = processData(data, tooltip);
  return (
    <div className="total-card">
      <div className="total-info">
        <p className="total-title">{title}</p>
        <p className="total-number" style={{ marginTop: '18px', marginBottom: '18px' }}>{data.length}</p>
        {data.length === 0 ? parseEmptyDataElement() : (
          <div className="total-change total-increase">
            <img src={newData[newData.length - 1].change >= 0 ? TrendUp : TrendDown} alt="info icon" />
            <p>
              <span style={{ color: newData[newData.length - 1].change >= 0 ? '#378A35' : '#E7322C' }}>
                {newData[newData.length - 1].change}
                %
              </span>
              &nbsp;
              since last month
            </p>
          </div>
        )}
      </div>
      <div className="table-wrapper">
        <SimpleBar data={newData} color={color} tooltip={tooltip} />
      </div>
    </div>
  );
}

TotalCard.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    createdAt: PropTypes.string,
  })),
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default TotalCard;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { Card, CardContent, CardHeader } from '@mui/material';
import DownSVG from '../../images/dropdown-down.svg';
import RightSVG from '../../images/dropdown-right.svg';

const CustomCard = styled(Card)`
    borderRadius: "8px",
    width: 30%,
    border: "1px solid var(--Dark-Dark-06, #E0E1E6)",
    boxShadow: "none"
`;

function AuditCard({ data }) {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <CustomCard className="webcam-card">
      <CardHeader
        className="hours-card-header"
        title={(
          <div className="hours-card-header-title d-flex gap-1 align-items-center">
            {data.date}
          </div>
        )}
      />
      <CardContent className="activity-card-content d-flex flex-column justify-content-between">
        <div
          className="webcam-column webcam-menu"
          onClick={toggleOpen}
          onKeyDown={(e) => { if (e.key === 'Enter') toggleOpen(); }}
          role="button"
          tabIndex={0}
          style={{ borderBottom: '1px solid #E0E1E6' }}
        >
          <p className="webcam-first">
            {open ? (<img src={DownSVG} alt="info icon" />) : (<img src={RightSVG} alt="info icon" />)}
            Total
          </p>
          <p className="webcam-second">{data.Rejected}</p>
          <p className="webcam-third">{data.Taken}</p>
          <p className="webcam-firth">{data.Score}</p>
        </div>
        {open && (
          <div>
            {data.data.map((datum, index) => (
              <div key={index} className="webcam-column" style={{ padding: '10px 0px' }}>
                <div className="webcam-first webcam-category">
                  <p>{datum.Time}</p>
                </div>
                <p className="webcam-second">{datum.Rejected}</p>
                <p className="webcam-third">{datum.Taken}</p>
                <p className="webcam-firth">{datum.Score}</p>
              </div>
            ))}
          </div>
        )}
      </CardContent>
    </CustomCard>
  );
}

AuditCard.propTypes = {
  data: PropTypes.shape({
    date: PropTypes.string.isRequired,
    Taken: PropTypes.number.isRequired,
    Rejected: PropTypes.number.isRequired,
    Score: PropTypes.number.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
      Taken: PropTypes.number.isRequired,
      Rejected: PropTypes.number.isRequired,
      Score: PropTypes.number.isRequired,
      Time: PropTypes.string.isRequired,
    })),
  }),
};

export default AuditCard;

import React from 'react';
import PropTypes from 'prop-types';
import { Navbar } from './navbar';
import { Sidebar } from './sidebar';
import { ChildrenType } from '../propTypes';

function NavigationLayout({ children, title, subtitle }) {
  return (
    <>
      <Navbar />
      <Sidebar />
      <div className="screen-wrapper">
        <div className="screen-header">
          <div className="screen-header-container d-flex justify-content-between position-relative">
            <div>
              <h3
                className="header-manage-member"
                style={{ fontWeight: 600 }}
                data-testid="title"
              >
                {title}
              </h3>
            </div>
          </div>
          <p
            className="main-sub-text"
            data-testid="subtitle"
          >
            {subtitle}
          </p>
          {/* Insert components for the page here, to the right of the sidebar */}
          {children}
        </div>
      </div>
    </>
  );
}

export default NavigationLayout;

NavigationLayout.propTypes = {
  children: ChildrenType,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  TextField,
  InputAdornment,
  Popover,
  FormControl,
  Stack,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
  Autocomplete,
  Button,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
// eslint-disable-next-line import/no-extraneous-dependencies
import { debounce } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
// eslint-disable-next-line max-len
import {
  getFilterConfig, getFilterValueForMultiSelected, getFilterValueForSearchQuery, getFilterValueForStatus,
} from '../../utils/fillter';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';

// eslint-disable-next-line react/prop-types
function FilterSearch({ anchorEl, handleClose }) {
  const { pathname } = useLocation();
  const history = useHistory();
  const filterConfigs = getFilterConfig(pathname);
  const [searchQuery, setSearchQuery] = useState('');
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [workspaceOptions, setWorkspaceOptions] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);

  const [selectedItems, setSelectedItems] = useState({
    organization: [],
    workspace: [],
    leader: [],
    team: [],
  });
  const [status, setStatus] = useState({
    active: false,
    nonActive: false,
  });

  const handleFilterChange = debounce((value) => {
    setSearchQuery(value);
  }, 100);

  const handleStatusChange = (event) => {
    setStatus({
      ...status,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSelectionChange = (key, value) => {
    setSelectedItems({
      ...selectedItems,
      [key]: value,
    });
  };

  const applyFilter = () => {
    const statusParam = getFilterValueForStatus(status);
    const searchQueryParam = getFilterValueForSearchQuery(searchQuery);
    const organizationParam = getFilterValueForMultiSelected(selectedItems.organization);
    const workspaceParam = getFilterValueForMultiSelected(selectedItems.workspace);
    const teamParam = getFilterValueForMultiSelected(selectedItems.team);
    const queryParams = [];
    if (statusParam !== null) queryParams.push(`status=${statusParam}`);
    if (searchQueryParam) queryParams.push(`search=${searchQueryParam}`);
    if (organizationParam) queryParams.push(`organization=${organizationParam}`);
    if (workspaceParam) queryParams.push(`workspace=${workspaceParam}`);
    if (teamParam) queryParams.push(`team=${teamParam}`);
    const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
    history.push(queryString);
    handleClose();
  };

  const clearFilter = () => {
    setSearchQuery('');
    setStatus({ active: false, nonActive: false });
    setSelectedItems({
      organization: [],
      workspace: [],
      leader: [],
      team: [],
    });
    handleClose();
  };

  const parseFilterSelectElement = (config, options) => (
    <FormControl>
      <FormGroup>
        <FormLabel
          style={{
            margin: '10px', fontSize: '16px', fontWeight: 'bolder', textTransform: 'capitalize',
          }}
        >
          {config}
        </FormLabel>
        <Autocomplete
          multiple
          options={options}
          sx={{ width: '100%' }}
          getOptionLabel={(option) => option.label}
          onChange={(event, value) => handleSelectionChange(config, value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={`Filter by ${config}`}
              variant="outlined"
              size="small"
              fullWidth
            />
          )}
        />
      </FormGroup>
    </FormControl>
  );

  const fetchAllOrganizations = async () => {
    const { success, data } = await sendRequest({
      method: 'get',
      url: API.ORGANIZATION.filterOrganizationAll(),
      timeout: 30000,
    });
    if (success) {
      const options = data.organizations?.map((item) => ({
        id: item.id,
        label: item.name,
      }));
      setOrganizationOptions(options);
    }
  };
  const fetchAllWorkspaces = async () => {
    const { success, data } = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.filterWorkspaceAll(),
      timeout: 30000,
    });
    if (success) {
      const options = data.workspaces?.map((item) => ({
        id: item.id,
        label: item.name,
      }));
      setWorkspaceOptions(options);
    }
  };
  const fetchAllTeams = async () => {
    const { success, data } = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.filterTeamAll(),
      timeout: 30000,
    });
    if (success) {
      const options = data.teams?.map((item) => ({
        id: item.id,
        label: item.name,
      }));
      setTeamOptions(options);
    }
  };

  useEffect(() => {
    fetchAllOrganizations();
    fetchAllWorkspaces();
    fetchAllTeams();
  }, []);
  const leaderOptions = [
    {
      id: 1,
      name: 'Midas',
    },
    {
      id: 2,
      name: 'Niki',
    },
  ];
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Stack spacing={1} padding={2}>
        <FormControl>
          <TextField
            placeholder="Search..."
            variant="outlined"
            size="small"
            fullWidth
            value={searchQuery}
            onChange={(e) => handleFilterChange(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: '#707070' }} />
                </InputAdornment>
              ),
            }}
            sx={{ borderRadius: '30px' }}
          />
        </FormControl>
        {filterConfigs.status && (
          <FormControl>
            <FormGroup>
              <FormLabel style={{ margin: '10px', fontSize: '16px', fontWeight: 'bolder' }}>Status</FormLabel>
              <Stack direction="row" spacing={2}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={status.active}
                      onChange={handleStatusChange}
                      name="active"
                    />
                  )}
                  label="Active"
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={status.nonActive}
                      onChange={handleStatusChange}
                      name="nonActive"
                    />
                  )}
                  label="Non-Active"
                />
              </Stack>
            </FormGroup>
          </FormControl>
        )}
        {filterConfigs.organization
          && organizationOptions.length !== 0
          && parseFilterSelectElement('organization', organizationOptions)}
        {filterConfigs.workspace
          && workspaceOptions.length !== 0
          && parseFilterSelectElement('workspace', workspaceOptions)}
        {filterConfigs.team
          && teamOptions.length !== 0
          && parseFilterSelectElement('team', teamOptions)}
        {filterConfigs.leader
          && leaderOptions.length !== 0
          && parseFilterSelectElement('leader', leaderOptions)}
      </Stack>
      <Stack direction="row" spacing={2} justifyContent="space-between" width="100%" padding={2}>
        <Button variant="contained" onClick={applyFilter} fullWidth>
          Apply
        </Button>
        <Button variant="outlined" onClick={clearFilter} fullWidth>
          Cancel
        </Button>
      </Stack>
    </Popover>
  );
}

export default FilterSearch;

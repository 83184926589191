/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { NotificationContext } from '../../contexts/Notification';
import './index.css';

const CustomBox = styled(Box)`
  width: 168px;
  height: 168px;
  border-radius: 8px;
`;

function AvatarUploader({ id, defaultAvatar, onChange }) {
  const { displayNotification } = useContext(NotificationContext);
  const [fileInfo, setFileInfo] = useState(defaultAvatar);

  const handleImageUpload = (event) => {
    const avatar = event.target.files[0];
    setFileInfo(URL.createObjectURL(avatar));
    onChange(avatar);
    displayNotification({
      message: 'Uploaded Successfully',
      severity: 'success',
    });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const avatar = Array.from(event.dataTransfer.files)[0];
    if (
      avatar.type === 'image/png'
      || avatar.type === 'image/jpeg'
      || avatar.type === 'image/svg+xml'
      || avatar.type === 'image/gif'
    ) {
      setFileInfo(URL.createObjectURL(avatar));
      onChange(avatar);
      displayNotification({
        message: 'Uploaded Successfully',
        severity: 'success',
      });
    } else {
      displayNotification({
        message: 'Not Supported',
        severity: 'error',
      });
    }
  };

  return (
    <>
      <input
        type="file"
        accept=".jpeg, .png, .gif, .svg"
        onChange={handleImageUpload}
        className="photo-upload-btn"
        id="contained-button-file"
      />
      {fileInfo ? (
        <CustomBox
          className="avatar"
          id={id}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <img src={fileInfo} width="100%" height="100%" alt="avatar" />
          <div type="div" className="overlay">
            <div className="position-relative p-8">
              <div className="avatar-btn-group">
                <label htmlFor="contained-button-file">
                  <div className="avatar-related-btn">
                    <div className="bg-avatar-btn" />
                    <EditOutlinedIcon sx={{ color: '#fff' }} />
                  </div>
                </label>
                <button
                  type="button"
                  className="avatar-related-btn"
                  onClick={() => { setFileInfo(''); onChange(''); }}
                  aria-label="Delete Avatar"
                >
                  <div className="bg-avatar-btn" />
                  <DeleteOutlinedIcon sx={{ color: '#fff' }} />
                </button>
              </div>
            </div>
          </div>
        </CustomBox>
      ) : (
        <CustomBox
          sx={{
            border: '1px dashed var(--dark-4)',
            padding: '51px 12px 0 12px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            gap: '8px',
          }}
          id={id}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <FileUploadOutlinedIcon sx={{ color: '#00155A' }} />
          <p
            style={{
              fontSize: '10px',
              lineHeight: '12px',
              color: '#131414',
              fontWeight: 'bold',
              marginBottom: '-8px',
            }}
          >
            <span>Drop your file here or </span>
            <label htmlFor="contained-button-file">
              <span
                style={{
                  cursor: 'pointer',
                  color: '#4683B4',
                  textDecoration: 'underline',
                }}
              >
                Browse
              </span>
            </label>
          </p>
          <p
            style={{
              fontSize: '10px',
              lineHeight: '14px',
              color: '#757575',
              textWrap: 'wrap',
            }}
          >
            Supports JPEG, PNG, GIF, SVG documents
          </p>
        </CustomBox>
      )}
    </>
  );
}

AvatarUploader.propTypes = {
  id: PropTypes.string,
  defaultAvatar: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AvatarUploader;

import React, { useEffect, useContext } from 'react';
import Button from '../../components/Button';
import CenterCardLayout from '../../components/CenterCardLayout';
import { openDesktopLink } from '../../utils/routing';
import { UserContext } from '../../contexts/User';

function OpenShootApp() {
  const {
    userToken, accessToken, redirect,
  } = useContext(UserContext);

  useEffect(() => {
    if (!userToken) redirect('/');
    if (!accessToken) redirect('/workspaces');
  }, [userToken, accessToken]);

  return (
    <div className="text-center">
      <CenterCardLayout
        title="Open in the Shoot App"
        description="You can sign into this workspace
      through the Shoot Desktop App."
      >
        <p className="size-s text-500">
          Looking for the admin dashboard?
          Ask your workspace administrator to make you an admin of this workspace.
        </p>
        <a href={openDesktopLink()}>
          <Button
            fullWidth
            variant="green"
            className="m-t20"
            href={`${openDesktopLink()}?userToken=${userToken}`}
          >
            Open Desktop App
          </Button>
        </a>
      </CenterCardLayout>
      <Button
        variant="link"
        onClick={() => redirect('/workspaces')}
        className="m-t20 in-block"
        style={{ fontSize: 14 }}
      >
        Return to available workspaces
      </Button>
    </div>
  );
}

export default OpenShootApp;

const AccrualSchedule = {
  none: {
    text: 'None',
    description: 'Time off will not be automatically accrued for this policy',
  },
  annual: {
    text: 'Annual',
    description: 'Hours are accrued when the member joins the policy, and on January 1st of every year',
  },
  worked_hours: {
    text: 'Hours Worked',
    description: 'Hours are accrued when time worked is marked as paid',
  },
  date_of_join: {
    text: 'Policy Joined Date',
    description: `Hours are accrued when the member is added to the policy, 
        at a prorated amount for the year. Hours are also accrued on January 1st of every year.`,
  },
};

const NegativeBalanceItem = [
  {
    text: 'Yes',
    value: true,
    description: 'Members may request time off even if it lowers their balance to below 0',
  },
  {
    text: 'No',
    value: false,
    description: 'Members cannot request time off if it will bring their balance below 0',
  },
];

const ApprovalRequiredItem = [
  {
    text: 'Yes',
    value: true,
    description: 'Requests must be manually approved by a manager or team lead',
  },
  {
    text: 'No',
    value: false,
    description: 'Requests are automatically approved and applied without a manager\'s approval',
  },
];

const BalanceRolloverItem = [
  {
    text: 'Yes',
    value: true,
    description: 'Any remaining balance will be kept on January 1st',
  },
  {
    text: 'No',
    value: false,
    description: 'Any remaining time off will be removed on January 1st',
  },
];

const PaidItem = [
  {
    text: 'Paid',
    value: true,
    description: 'Time in approved requests will be counted towards amounts owed',
  },
  {
    text: 'Unpaid',
    value: false,
    description: 'Hours in approved time off requests will not count towards amounts owed',
  },
];

module.exports = {
  AccrualSchedule,
  NegativeBalanceItem,
  ApprovalRequiredItem,
  BalanceRolloverItem,
  PaidItem,
};

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal, Box, Button } from '@mui/material';
import { sendRequest } from '../../../api/shootAPI';
import API from '../../../api/endpoints';
import { UserContext } from '../../../contexts/User';
import { NotificationContext } from '../../../contexts/Notification';
import TrashIcon from '../../../images/trash.png';
import { Description } from '../../../components/styledComponents';

function DesignationDeleteModal({
  closeModal,
  getData,
  designation,
}) {
  const { activeWorkspace } = useContext(UserContext);
  const { displayNotification } = useContext(NotificationContext);

  const handleDeleteRequest = async () => {
    closeModal();
    const { success } = await sendRequest({
      method: 'delete',
      url: API.WORKSPACE.deleteDesignation(activeWorkspace.id, designation.id),
    });
    if (success) {
      setTimeout(() => {
        displayNotification({
          message: 'Designation has been deleted successfully.',
          severity: 'success',
        });
        getData();
      }, 500);
    } else {
      displayNotification({
        message: 'There was an issue deleting designation.',
        severity: 'error',
      });
    }
  };

  return (
    <Modal open onClose={closeModal} className="flex-row align-c jstfy-c create-team-modal">
      <Box style={{
        width: 462,
        padding: 32,
        background: '#fff',
        borderRadius: 8,
      }}
      >
        <img src={TrashIcon} width="48" height="48" alt="trash" />
        <div className="modal-body" style={{ padding: 0 }}>
          <Description
            fontSize={20}
            fontWeight={600}
            color="#0E1E40"
            marginTop={16}
          >
            Are you sure you want to delete this designation?
          </Description>
          <Description
            fontSize={14}
            fontWeight={400}
            color="#293855"
            marginTop={6}
          >
            You’re about to delete the “
            <span style={{ fontWeight: 600 }}>{designation.name}</span>
            ” designation and this action can not be undone.
          </Description>
        </div>

        <div className="modal-footer-customized">
          <Button
            sx={{
              marginRight: 2,
              textTransform: 'none',
              fontSize: '16px',
              height: '40px',
              width: '101px',
            }}
            variant="outlined"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            sx={{
              textTransform: 'none',
              fontSize: '16px',
              height: '40px',
              width: '101px',
              backgroundColor: '#CC4525',
              '&:hover': { backgroundColor: '#CC4525' },
            }}
            variant="contained"
            onClick={handleDeleteRequest}
          >
            Delete
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

DesignationDeleteModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  getData: PropTypes.func.isRequired,
  designation: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default DesignationDeleteModal;

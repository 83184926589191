import React from 'react';
import PropTypes from 'prop-types';
import RSuiteDatePicker from 'rsuite/DatePicker';
import 'rsuite/dist/rsuite-no-reset.min.css';
import '../dateRangePicker/dateRangePicker.css';

function DatePicker({
  className, format = 'EEE, dd MMM yyyy', isDisabledPast = false, ...props
}) {
  const disablePastDates = (date) => {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    return today > date;
  };

  return (
    <RSuiteDatePicker
      className={className}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      format={format}
      defaultValue={new Date()}
      cleanable={false}
      shouldDisableDate={isDisabledPast ? disablePastDates : undefined}
    />
  );
}

DatePicker.propTypes = {
  className: PropTypes.string,
  format: PropTypes.string,
  isDisabledPast: PropTypes.bool,
};

export default DatePicker;

/* eslint-disable max-len */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Box, Button,
} from '@mui/material';
import CheckedIcon from '../../images/checked.svg';
import { sendRequest } from '../../api/shootAPI';
import { Description } from '../../components/styledComponents';
import API from '../../api/endpoints';
import { UserContext } from '../../contexts/User';
import { NotificationContext } from '../../contexts/Notification';

function ApproveRequestModal({
  closeModal, request, getData,
}) {
  const { member } = useContext(UserContext);
  const {
    displayNotification,
  } = useContext(NotificationContext);
  const [saving, setSaving] = useState(false);
  const startDate = new Date(request.startDate);
  const endDate = new Date(request.endDate);

  const dateOptions = {
    weekday: 'short',
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  };

  const handleApprove = async () => {
    setSaving(true);
    const response = await sendRequest({
      method: 'put',
      url: API.WORKSPACE.approveOffTimeRequest(member.workspaceId, request.id),
    });
    if (response.success) {
      displayNotification({
        message: 'Time off request approved successfully.',
        severity: 'success',
      });
      getData();
    } else {
      displayNotification({
        message: 'An error occurs while approving data.',
        severity: 'error',
      });
    }
    setSaving(false);
  };

  return (
    <Modal open onClose={closeModal} className="flex-row align-c jstfy-c create-team-modal">
      <Box style={{
        width: 519,
        padding: 32,
        background: '#fff',
        borderRadius: 8,
      }}
      >
        <div className="modal-body" style={{ padding: 0 }}>
          <img src={CheckedIcon} alt="check icon" />
          <Description
            fontSize={20}
            fontWeight={600}
            color="#0E1E40"
            marginTop={16}
          >
            Are you sure you want to approve this request?
          </Description>
          <Description
            fontSize={14}
            fontWeight={400}
            color="#293855"
            marginTop={8}
          >
            You’re about to approve the “
            <span className="text-600">{startDate.toLocaleDateString('en-US', dateOptions)}</span>
            {' '}
            to
            <span className="text-600">{endDate.toLocaleDateString('en-US', dateOptions)}</span>
            ” requested PTO for “
            <span className="text-600">{`${request.member.user.firstName} ${request.member.user.lastName}`}</span>
            ”. You can deny this request again if needed.
          </Description>
        </div>
        <div
          className="modal-footer-customized d-flex justify-content-start mt-3"
        >
          <Button
            sx={{
              marginRight: 2,
              textTransform: 'none',
              fontSize: '16px',
              height: '40px',
              width: '101px',
            }}
            variant="outlined"
            onClick={() => {
              closeModal();
            }}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            sx={{
              textTransform: 'none',
              fontSize: '16px',
              height: '40px',
              width: '154px',
              backgroundColor: 'var(--green-3, #53A551)',
              color: '#FFFFFF',
              '&:hover': { backgroundColor: 'var(--green-3, #53A551)' },
            }}
            variant="contained"
            disabled={saving}
            onClick={async () => {
              await handleApprove();
              closeModal();
            }}
          >
            {saving ? 'Approving...' : 'Approve'}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

ApproveRequestModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  getData: PropTypes.func.isRequired,
  request: PropTypes.shape({
    id: PropTypes.number.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    member: PropTypes.shape({
      user: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
};

export default ApproveRequestModal;

/* eslint-disable no-nested-ternary */
import React, {
  useContext, useEffect, useState,
} from 'react';
import './dashboard.css';
import PaymentsCard from './PaymentsCard';
import WorkspacesCard from './WorkspacesCard';
import ActivitiesCard from './ActivitiesCard';
import RenewalsCard from './RenewalsCard';
import OrganizationsCard from './OrganizationsCard';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { NotificationContext } from '../../contexts/Notification';
import { Layout } from '../admin/Layout';
import TotalCard from '../../components/admin/TotalCard';

function Dashboard() {
  const { displayNotification } = useContext(NotificationContext);
  const [organizations, setOrganizations] = useState([]);
  const [activeUsers, setActiveUsers] = useState([]);
  const [nonActiveUsers, setNonActiveUsers] = useState([]);
  const [teams, setTeams] = useState([]);

  const fetchTeams = async () => {
    const { success, data } = await sendRequest({
      method: 'post',
      url: API.WORKSPACE.getAllTeams(),
      timeout: 30000,
    });
    if (success) {
      setTeams(data.teams);
    } else {
      displayNotification({
        message: 'There was an issue fetching team data. Please try again',
        severity: 'error',
      });
    }
  };

  const fetchOrganizations = async () => {
    const { success, data } = await sendRequest({
      method: 'post',
      url: API.ORGANIZATION.organizations(),
      timeout: 30000,
    });
    if (success) {
      setOrganizations(data.organizations);
    } else {
      displayNotification({
        message: 'There was an issue fetching organization data. Please try again',
        severity: 'error',
      });
    }
  };

  const fetchUsers = async () => {
    const { success, data } = await sendRequest({
      method: 'post',
      url: API.USER.users(),
      timeout: 30000,
    });
    if (success) {
      const tempUsers = data.users;
      const active = [];
      const nonActive = [];
      tempUsers.forEach((item) => {
        if (item.verifiedEmail) {
          active.push(item);
        } else {
          nonActive.push(item);
        }
      });
      setActiveUsers(active);
      setNonActiveUsers(nonActive);
    } else {
      displayNotification({
        message: 'There was an issue fetching user data. Please try again',
        severity: 'error',
      });
    }
  };

  useEffect(() => {
    fetchTeams();
    fetchOrganizations();
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <div className="screen-wrapper">
        <div className="screen-header">
          <div className="total-card-wrapper">
            <TotalCard
              data={organizations}
              title="Total Organizations"
              tooltip="New Organizations"
              color="#0064FF"
            />
            <TotalCard
              data={teams}
              title="Total Teams"
              tooltip="New Teams"
              color="#F19235"
            />
            <TotalCard
              data={activeUsers}
              title="Total Active Users"
              tooltip="Active Users"
              color="#9451A5"
            />
            <TotalCard
              data={nonActiveUsers}
              title="Total Non-Active Users"
              tooltip="Non-Active Users"
              color="#FF6A6A"
            />
          </div>
          <div className="two-card-container">
            <PaymentsCard />
            <WorkspacesCard />
          </div>
          <div className="two-card-container">
            <ActivitiesCard />
            <RenewalsCard />
          </div>
          <div className="one-card-container">
            <OrganizationsCard />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Dashboard;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import NavigationLayout from '../../components/NavigationLayout';
import Authentication from './Authentication';
import SettingsTab from './Settings';
import TimeOff from './TimeOff';
import DesignationsTab from './designations';
import DepartmentsTab from './departments';
import Downloads from './Downloads';
import WorkingHours from './WorkingHours';

const StyledCard = styled(Card)`
    border-radius: 8px;
    background: var(--Dark-Dark-11-White, #FFF);
    box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 3px 5px 0px rgba(9, 30, 66, 0.20);
    padding-top: 8px;
    min-height: calc(100vh - 200px);
`;

const StyledTab = styled(Tab)`
    text-transform: capitalize;
    color: var(--Dark-Dark-03, #546077);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;

    &.Mui-selected {
        color: var(--Dark-Dark-01, #0E1E40);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
    }
`;

function CustomTabPanel(props) {
  const {
    children, value, index, p = 4,
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
      <Box sx={{ p }}>
        {children}
      </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  p: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([null]),
  ]),
};

function Settings() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <NavigationLayout title="Settings & Permissions">
        <div style={{ marginTop: 24 }}>
          <StyledCard>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="settings tab"
                sx={{
                  '& .MuiTabs-indicator': {
                    backgroundColor: 'var(--Blue-Blue-01, #213D69)',
                  },
                }}
              >
                <StyledTab label="Settings" id="tab_setting" />
                <StyledTab label="Permissions" id="tab_permission" />
                <StyledTab label="Authentications" id="tab_authentication" />
                <StyledTab label="Downloads" id="tab_downloads" />
                <StyledTab label="Time Off" id="tab_time_off" />
                <StyledTab label="Departments" id="tab_departments" />
                <StyledTab label="Designations" id="tab_designation" />
                <StyledTab label="Working Hours" id="tab_working_hours" />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <SettingsTab />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              Permissions
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Authentication />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <Downloads />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4} p={0}>
              <TimeOff />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={5} p={0}>
              <DepartmentsTab />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={6} p={0}>
              <DesignationsTab />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={7} p={0}>
              <WorkingHours />
            </CustomTabPanel>
          </StyledCard>
        </div>
      </NavigationLayout>
    </div>
  );
}

Settings.propTypes = {

};

export default Settings;

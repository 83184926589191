export const getActivityColor = (activityPercentage) => {
  const percentage = parseInt(activityPercentage, 10);
  if (typeof percentage !== 'number' || Number.isNaN(percentage)) return '#546077';
  // eslint-disable-next-line no-nested-ternary
  return activityPercentage > 50
    ? '#64BA62' : activityPercentage > 20 ? '#F6C643' : '#EE7C5C';
};

export const formatActivityPercentage = (activeSeconds, secondsElapsed) => {
  const activityPercentage = ((activeSeconds / secondsElapsed) * 100);
  return activityPercentage ? activityPercentage.toFixed(0) : null;
};

/**
 * Takes an array of member activity and returns an object with a summary of the data combined
 * @param {Object[]} activity - Array of member activity
 */
export const calculateActivitySummary = (activity) => {
  const initialValue = {
    totalMouseClick: 0,
    totalMouseWheel: 0,
    totalMouseMove: 0,
    totalKeyDown: 0,
    totalActiveSeconds: 0,
    totalSecondsElapsed: 0,
    totalActivityPercentage: null,
  };

  const allData = activity.reduce((data, snapshot) => ({
    totalMouseClick: data.totalMouseClick + snapshot.mouseClick,
    totalMouseWheel: data.totalMouseWheel + snapshot.mouseWheel,
    totalMouseMove: data.totalMouseMove + snapshot.mouseMove,
    totalKeyDown: data.totalKeyDown + snapshot.keyDown,
    totalActiveSeconds: data.totalActiveSeconds + snapshot.activeSeconds,
    totalSecondsElapsed: data.totalSecondsElapsed + snapshot.secondsElapsed,
  }), initialValue);

  return {
    ...allData,
    totalActivityPercentage: formatActivityPercentage(allData.totalActiveSeconds, allData.totalSecondsElapsed),
  };
};

import React, { useContext, useEffect, useState } from 'react';
import { Navbar, Sidebar } from '../../components';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { UserContext } from '../../contexts/User';
import MemberInfoRows from './MemberInfoRows';
import './activity.css';

function Members() {
  const { activeWorkspace } = useContext(UserContext);
  const [members, setMembers] = useState([]);

  useEffect(() => {
    (async () => {
      if (activeWorkspace) {
        const response = await sendRequest({ method: 'get', url: API.WORKSPACE.members(activeWorkspace.id) });
        if (response.success) {
          setMembers(response.data?.members ?? []);
        } else {
          // handle API errors
        }
      }
    })();
  }, []);

  return (
    <div>
      <Navbar />
      <Sidebar />
      <div className="screen-wrapper">
        <div className="screen-header">
          <div className="screen-header-container d-flex justify-content-between position-relative">
            <h3 className="header-manage-member">Activity</h3>
            {/* <Button
              title="Export Data of all users"
              className="btn-outline-success exportAllBtn position-absolute"
              onClick={() => {
                // this.setState({ showExportAllReportSelectionModel: true });
              }}
            >
              Export All
            </Button> */}
          </div>
        </div>
        <div>
          <div className="member-container my-3">
            {/* {
              this.props.passwordResetMsg
                ? (
                  <Alert variant="warning">
                    {this.props.passwordResetMsg}
                  </Alert>
                ) : <div />
            } */}
          </div>
          <div className="member-table-wrapper">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col" className="header-title text-center">
                    Activity Report
                  </th>
                </tr>
              </thead>
              <tbody>
                {members?.length
                  ? <MemberInfoRows workspaceMembers={members} />
                  : (
                    <tr>
                      <td style={{ padding: '100px' }} colSpan={8} className="text-center">No Match Record</td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

Members.propTypes = {

};

export default Members;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Popover } from '@mui/material';

function TeamEditColumn({ deleteTeam, editTeam }) {
  const [teamEditAnchorEl, setTeamEditAnchorEl] = useState(null);

  const openTeamEdit = Boolean(teamEditAnchorEl);
  const teamEditId = openTeamEdit ? 'simple-popover' : undefined;

  return (
    <div>
      <button
        type="button"
        label="edit-dropdown-button"
        onClick={(e) => setTeamEditAnchorEl(e.currentTarget)}
      >
        <i className="fa fa-ellipsis-h dropdown-options" />
      </button>
      <Popover
        id={teamEditId}
        open={openTeamEdit}
        anchorEl={teamEditAnchorEl}
        onClose={() => setTeamEditAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => {
          editTeam();
          setTeamEditAnchorEl(null);
        }}
        >
          Edit Team
        </MenuItem>
        <MenuItem
          sx={{ color: '#CC4525' }}
          onClick={() => {
            deleteTeam();
            setTeamEditAnchorEl(null);
          }}
        >
          Delete Team
        </MenuItem>
      </Popover>
    </div>
  );
}

export default TeamEditColumn;

TeamEditColumn.propTypes = {
  deleteTeam: PropTypes.func.isRequired,
  editTeam: PropTypes.func.isRequired,
};

import React, { useState } from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { TextInput } from '../../components/muiCustomized/TextInput';
import API from '../../api/endpoints';
import { sendRequest } from '../../api/shootAPI';
import CenterCardLayout from '../../components/CenterCardLayout';

function EmailForResetPassword() {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [emailSuccessful, setEmailSuccessful] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email) {
      const response = await sendRequest({
        method: 'get',
        url: API.USER.resetPassword,
        params: { email },
      });
      if (response.success) {
        setEmailSuccessful(true);
      } else {
        setErrors({ ...response.data });
      }
    }
  };

  if (emailSuccessful) {
    return (
      <CenterCardLayout
        title="Email Sent"
        description="A password reset link will be sent to the email provided if an account is associated with it."
      >
        <Link className="color-text" to="/">
          <Button
            fullWidth
            className="nextStep-btn"
            variant="contained"
          >
            Return to menu
          </Button>
        </Link>
      </CenterCardLayout>
    );
  }

  return (
    <CenterCardLayout
      title="Reset Password"
      description="Enter your email to reset your password."
      errors={Object.values(errors)}
    >
      <div className="float-none m-auto span-4-of-6">
        <TextInput
          name="email"
          value={email}
          onChange={(e) => { setEmail(e.target.value); }}
          placeholder="you@example.com"
          autoComplete="on"
          data-testid="email-input"
        />
        <Button
          sx={{ margin: '-12px 0 32px' }}
          fullWidth
          className="nextStep-btn"
          variant="contained"
          onClick={handleSubmit}
        >
          {' '}
          Send Link
        </Button>
        <p className="font-15">
          <span className="m-r4 color-dark3">Have an account?</span>
          <Link className="color-text" to="/">sign in</Link>
        </p>
      </div>
    </CenterCardLayout>
  );
}

export default EmailForResetPassword;

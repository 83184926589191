/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, useLocation } from 'react-router';
import { UserContext } from '../contexts/User';
import { accountTypeLevel } from '../constants/accountTypes';

export function PrivateRoute({ component, accountLevel = 1, ...rest }) {
  const location = useLocation();
  const { user, accessToken, member } = useContext(UserContext);

  if (user && accessToken) {
    // Don't redirect if the user has already signed in
    if (
      member
      && accountTypeLevel[member.accountType] >= accountTypeLevel['Workspace admin']
    ) return <Route {...rest} component={component} />;
    if (
      member
        && accountLevel === 2 && accountTypeLevel[member.accountType] === accountTypeLevel['Team Leader']
    ) return <Route {...rest} component={component} />;
    if (
      member
        && accountLevel === 3 && accountTypeLevel[member.accountType] === accountTypeLevel['Primary owner']
    ) return <Route {...rest} component={component} />;
    return <Redirect to="/workspaces" />;
  }
  // Don't redirect if a new user navigates directly to the signup page (likely from email invite)
  if (location.pathname === '/signup') return <Route {...rest} component={component} />;
  return <Redirect to="/" />;
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  accountLevel: PropTypes.number,
};

import React, {
  useState, useEffect, useContext, useCallback,
} from 'react';
import {
  Button,
  Box,
} from '@mui/material';
import CenterCardLayout from '../../components/CenterCardLayout';
import ShootLogo from '../../images/shootLogo.png';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { UserContext } from '../../contexts/User';
import WorkspaceCardWithRadio from './WorkspaceCardWithRadio';
import useDebounce from '../../hooks/useDebounce';
import useWorkspaces from '../../hooks/useWorkspaces';

function WorkspacesForSuperAdmin() {
  const {
    userToken,
    user,
    redirect,
    workspaces,
    setWorkspaces,
  } = useContext(UserContext);
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState();
  const [searchQuery, setSearchQuery] = useState('');
  const { handleWorkspaceSelection } = useWorkspaces();
  const { debounce } = useDebounce();

  // Get the user's data upon signing in
  useEffect(() => {
    // Redirect depending on logged in state
    if (!(userToken && user && user.role === 'Superadmin')) {
      redirect('/');
    }
  }, []);

  useEffect(() => {
    sendRequest({
      method: 'GET',
      url: API.USER.workspacesForSuperAdmin,
      params: { searchQuery },
    }).then(({ success, data }) => {
      if (success) {
        setSelectedWorkspaceId();
        setWorkspaces(data.workspaces);
      }
    });
  }, [searchQuery]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(debounce((query) => setSearchQuery(query), 500), []);

  const headerImage = <img src={ShootLogo} alt="shoot-logo" height={64} width={64} style={{ marginBottom: 24 }} />;

  return (
    <CenterCardLayout
      title="Select a Workspace"
      description="Please select a workspace from below as a superadmin"
      headerImage={headerImage}
      className="width-448"
    >
      <Box className="flex-col gap-4">
        <div className="flex-col">
          <div className="searchBox">
            <input
              type="text"
              placeholder="Search Workspace"
              className="width-full"
              onChange={(e) => handleSearch(e.target.value)}
            />
            <i className="fa fa-search searchIcon" />
          </div>
          <div
            className="flex-col gap-2 text-left overflow-auto"
            style={{ height: '205px', marginTop: '8px' }}
          >
            {workspaces?.map((workspace) => (
              <WorkspaceCardWithRadio
                key={`workspace${workspace.id}`}
                name={workspace.name}
                image={workspace.picture}
                description={workspace.description}
                checked={selectedWorkspaceId === workspace.id}
                onClick={() => setSelectedWorkspaceId(workspace.id)}
              />
            ))}
          </div>
        </div>
        <Button
          fullWidth
          variant="contained"
          sx={{
            fontSize: 16,
            fontWeight: 600,
            textTransform: 'unset',
            '&: hover': {
              color: '#ffffff',
            },
            padding: '16px',
          }}
          disabled={!selectedWorkspaceId}
          onClick={() => handleWorkspaceSelection(
            workspaces.find(
              (workspace) => workspace.id === selectedWorkspaceId,
            ),
          )}
        >
          Enter Workspace
        </Button>
      </Box>
    </CenterCardLayout>
  );
}

export default WorkspacesForSuperAdmin;

const webcamAuditPeriods = [
  'None',
  'Hourly',
  'Daily',
  'Weekly',
];

export {
  webcamAuditPeriods,
};

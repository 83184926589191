import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Radio } from '@mui/material';
import { Images } from '../../common';

const WorkspaceWrapper = styled.div`
  width: 100%;
  height: 63px;
  border: 1px solid var(--dark-6);
  border-radius: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 1px 2px 0px #00000017;
`;

const ImageWrapper = styled.div`
  height: 41px;
  width: 41px;
  margin: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-image: ${(props) => (props.src ? `url(${props.src})` : 'none')};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
`;

const Separator = styled.div`
  border-right: 1px solid var(--dark-6);
  height: 40px;
`;

const RadioBox = styled(Radio)`
  width: 24px;
  height: 24px;
  margin: auto 8px;
`;

function WorkspaceCardWithRadio({
  name, description, image, checked, onClick,
}) {
  const { shootLogo } = Images;
  return (
    <WorkspaceWrapper onClick={onClick}>
      <ImageWrapper src={image ?? shootLogo} />
      <Separator />
      <div style={{ padding: '8.55px 0 8.34px 12.45px', flexGrow: 1 }}>
        <span className="text-600" data-testid="name">{name}</span>
        <br />
        <p className="p-small" data-testid="description">{description}</p>
      </div>
      <RadioBox
        name="workspaceRadio"
        checked={checked}
      />
    </WorkspaceWrapper>
  );
}

WorkspaceCardWithRadio.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default WorkspaceCardWithRadio;

import React,
{
  useContext,
  useRef,
  useEffect,
  useState,
} from 'react';
import { Button, CircularProgress } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../contexts/User';
import CenterCardLayout from '../../components/CenterCardLayout';
import { openDesktopLink } from '../../utils/routing';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';

function Desktop() {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const loginSubmitRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [userToken, setUserToken] = useState('');

  const desktopSignin = async () => {
    setIsLoading(true);
    const { data } = await sendRequest({
      method: 'post',
      url: API.AUTH.desktopSignin,
    });

    setUserToken(data.userToken);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!user) {
      return history.push('/');
    }
    desktopSignin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CenterCardLayout
      title="Welcome back! 👋"
      description="Click the link below to return to the desktop app"
    >
      <div className="float-none m-auto span-4-of-6">
        <div>
          <a href={`${openDesktopLink()}?userToken=${userToken}`}>
            <Button
              fullWidth
              className="nextStep-btn"
              variant="contained"
              disabled={isLoading}
              ref={loginSubmitRef}
            >
              {isLoading ? (
                <CircularProgress
                  size={20}
                  sx={{ color: 'inherit' }}
                />
              ) : 'Open desktop app'}
            </Button>
          </a>
        </div>
      </div>
    </CenterCardLayout>
  );
}

export default Desktop;

import React, {
  useContext, useRef, useState, useEffect,
} from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { Button } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import { TextInput } from '../../components/muiCustomized/TextInput';
import { UserContext } from '../../contexts/User';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import CenterCardLayout from '../../components/CenterCardLayout';
import useQueryParams from '../../hooks/useQueryParams';

function Login() {
  const history = useHistory();
  const queryParams = useQueryParams();
  const {
    signIn, startTwoFactor, userToken, user,
  } = useContext(UserContext);
  const passwordRef = useRef();
  const loginSubmitRef = useRef();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});

  const handleRedirect = (role) => {
    // If redirect query param is passed, there is another route that requires the user to
    // sign in before proceeding. After signing in, we should redirect back to this route
    // For example, the organization signup page requires a user to sign in before creating a new organization
    // After signing in, we redirect back to the organization sign up page
    const redirect = queryParams.get('redirect');
    if (redirect) return history.push(`/${redirect}`);
    if (role === 'Superadmin') { return history.push('/admin/dashboard'); }
    return history.push('/workspaces');
  };

  const handleSuccess = async (response) => {
    const token = response.credential;

    const result = await sendRequest({
      method: 'post',
      url: API.AUTH.google,
      data: {
        token,
      },
    });

    if (result.success) {
      if (result.data?.userToken) {
        const { userToken, refreshToken, role } = result.data;
        // Sets the user context which signs the user in
        signIn({ userToken, refreshToken });
        return handleRedirect(role);
      }
    }
    setErrors({ ...result.data });
  };

  useEffect(() => {
    if (userToken && user) {
      handleRedirect(user.role);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, user]);

  const onSubmitHandler = async () => {
    const response = await sendRequest({
      method: 'post',
      url: API.AUTH.authenticate,
      data: {
        email,
        password,
      },
    });

    if (response.success) {
      if (response.data?.userToken) {
        const { userToken, refreshToken, role } = response.data;
        // Sets the user context which signs the user in
        signIn({ userToken, refreshToken });
        return handleRedirect(role);
      }
      // If the token is only a twoFactorToken, send user to the two factor menu
      if (response.data?.twoFactorToken) {
        const { twoFactorToken } = response.data;
        const redirect = queryParams.get('redirect');
        return startTwoFactor({ twoFactorToken, redirect });
      }
    }
    setErrors({ ...response.data });
  };

  return (
    <CenterCardLayout
      title="Sign In"
      description="Enter your email address and password."
      errors={Object.values(errors)}
      testId="login-form"
    >
      <div className="float-none m-auto span-4-of-6">
        <TextInput
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="you@example.com"
          autoComplete="on"
          data-testid="email-input"
          onKeyDown={(event) => {
            const key = event.keyCode || event.which;
            if (key === 13) {
              event.preventDefault();
              passwordRef.current.focus();
            }
          }}
        />
        <TextInput
          name="password"
          inputRef={passwordRef}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="password"
          data-testid="password-input"
          onKeyPress={(event) => {
            const key = event.keyCode || event.which;
            if (key === 13) {
              event.preventDefault();
              loginSubmitRef.current.click();
            }
          }}
        />
        <div className="large-bottom-margin">
          <Button
            fullWidth
            className="nextStep-btn"
            variant="contained"
            onClick={() => onSubmitHandler()}
            ref={loginSubmitRef}
          >
            Login
          </Button>
        </div>
        <div className="large-bottom-margin">
          <GoogleLogin
            useOneTap
            onSuccess={(credentialResponse) => {
              handleSuccess(credentialResponse);
            }}
            onError={() => {
              console.log('Login Failed');
            }}
          />
        </div>
        <p className="font-15 m-b4">
          <span className="m-r4 color-dark3">Forgot password?</span>
          <Link className="color-text" to="/reset-password/email">Reset Password</Link>
          {/* <Link to="/getStarted">Forgot which email you used?</Link> */}
        </p>
      </div>
    </CenterCardLayout>
  );
}

export default Login;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

function OutlinedSelect({ children, ...props }) {
  return (
    <TextField
      {...props}
      size="small"
      InputProps={{ style: { fontSize: 14, padding: 1.44 } }}
      select
    >
      {children}
    </TextField>
  );
}

OutlinedSelect.propTypes = {
  children: PropTypes.node,
};

export default OutlinedSelect;

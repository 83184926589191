import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Popover } from '@mui/material';

function EditColumn({ options }) {
  const [editAnchorEl, setEditAnchorEl] = useState(null);

  const openEdit = Boolean(editAnchorEl);
  const editId = openEdit ? 'simple-popover' : undefined;
  const handleClick = (action) => {
    setEditAnchorEl(null);
    action();
  };

  return (
    <span>
      <button
        type="button"
        label="edit-dropdown-button"
        onClick={(e) => setEditAnchorEl(e.currentTarget)}
      >
        <i className="fa fa-ellipsis-h dropdown-options" />
      </button>
      <Popover
        id={editId}
        open={openEdit}
        anchorEl={editAnchorEl}
        onClose={() => setEditAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options.map((option, i) => (
          <MenuItem key={i} onClick={() => handleClick(option.action)} disabled={!option.enabled}>
            {option.label}
          </MenuItem>
        ))}
      </Popover>
    </span>
  );
}

export default EditColumn;

EditColumn.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})),
};

/* eslint-disable no-console */
/* eslint-disable max-len */
import React from 'react';
import { SuperSidebar } from '../../components/sidebar/SuperSidebar';
import { SuperNavbar } from '../../components/navbar/SuperNabvar';
import './layout.css';

// eslint-disable-next-line react/prop-types
export function Layout({ children }) {
  return (
    <>
      <SuperNavbar />
      <SuperSidebar />
      {children}
    </>
  );
}

import PropTypes from 'prop-types';

export default {
  allDay: PropTypes.bool,
  createdAt: PropTypes.string,
  denyReason: PropTypes.string,
  endDate: PropTypes.string,
  id: PropTypes.number,
  memberId: PropTypes.number,
  policyId: PropTypes.number,
  reason: PropTypes.string,
  startDate: PropTypes.string,
  status: PropTypes.oneOf(['Submitted', 'Approved', 'Denied']),
  totalHours: PropTypes.number,
  updatedAt: PropTypes.string,
  workspaceId: PropTypes.number,
};

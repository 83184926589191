import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { MenuItem, Popover } from '@mui/material';
import { sendRequest } from '../../api/shootAPI';
import { UserContext } from '../../contexts/User';
import API from '../../api/endpoints';

const MemberDropdown = styled.button`
  padding: 12px 9px;
  border: 1px solid #ECECF0;
  border-radius: 4px;
  width: 316px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Avatar = styled.div`
  padding: 10px;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  color: white;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  background-color: #53A55116;
  color: #53A551;
  li:nth-of-type(4n-1) & {
    background-color: #53A55116;
    color: #53A551;
  }
  li:nth-of-type(4n-2) & {
    background-color: #3880C216;
    color: #3880C2;
  }
  li:nth-of-type(4n-3) & {
    background-color: #F26B1F16;
    color: #F26B1F;
  }
  li:nth-of-type(4n) & {
    background-color: #E9338A16;
    color: #E9338A;
  }
`;

const Name = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #293855 !important;
`;

const Email = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #293855 !important;
`;

function NameEmailDiv({ firstName, lastName, email }) {
  return (
    <div className="flex-row align-c text-left">
      <Avatar>
        {
          (firstName && lastName && `${firstName?.slice(0, 1)}${lastName?.slice(0, 1)}`)
          ?? `${email?.slice(0, 2).toUpperCase()}`
        }
      </Avatar>
      <div className="pl-2">
        <Name>
          {firstName}
          {' '}
          {lastName}
        </Name>
        <Email>
          {email}
          {' '}
        </Email>
      </div>
    </div>
  );
}

function MemberNameEmailSelector({ memberId }) {
  const { activeWorkspace } = useContext(UserContext);
  const [members, setMembers] = useState([]);
  const [currentMember, setCurrentMember] = useState({});
  const [editAnchorEl, setEditAnchorEl] = useState(null);
  const history = useHistory();

  const openEdit = Boolean(editAnchorEl);
  const editId = openEdit ? 'simple-popover' : undefined;

  const handleClick = (memberId) => {
    setEditAnchorEl(null);
    history.push(`/insiderThreats/${memberId}`);
  };

  const getMembers = useCallback(async () => {
    if (activeWorkspace) {
      const response = await sendRequest({ method: 'get', url: API.WORKSPACE.members(activeWorkspace.id) });
      if (response.success) {
        setMembers(response.data?.members ?? []);
        setCurrentMember(response.data?.members.find((member) => member.id === memberId));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeWorkspace]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { getMembers(); }, []);

  return (
    <div>
      { currentMember?.user?.firstName ? (
        <div>
          <MemberDropdown
            type="button"
            label="edit-dropdown-button"
            onClick={(e) => setEditAnchorEl(e.currentTarget)}
          >
            <NameEmailDiv
              firstName={currentMember.user.firstName}
              lastName={currentMember.user.lastName}
              email={currentMember.user.email}
            />
            <i className="fa fa-sort-down" />
          </MemberDropdown>
          <Popover
            id={editId}
            open={openEdit}
            anchorEl={editAnchorEl}
            onClose={() => setEditAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            sx={{ maxHeight: '400px' }}
          >
            {members.map((member, i) => (
              <MenuItem
                className="member-selector"
                key={i}
                onClick={() => handleClick(member.id)}
                selected={member.id === memberId}
              >
                <NameEmailDiv
                  firstName={member.user.firstName}
                  lastName={member.user.lastName}
                  email={member.user.email}
                />
              </MenuItem>
            ))}
          </Popover>
        </div>
      ) : null}
    </div>
  );
}

MemberNameEmailSelector.propTypes = {
  memberId: PropTypes.number.isRequired,
};

NameEmailDiv.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

export default MemberNameEmailSelector;

import { convertSecToTimeFormat, convertDateToTimeStr } from './time';
/* eslint-disable max-len */
export const getTotalHours = (members) => {
  let totalSeconds = 0;
  let activeSeconds = 0;
  if (members.length > 0) {
    members.forEach((member) => {
      if (member.dailyActivity.length > 0) {
        member.dailyActivity.forEach((item) => {
          totalSeconds += item.totalSeconds;
          activeSeconds += item.activeSeconds;
        });
      }
    });
  }
  const workedSeconds = { totalSeconds, activeSeconds };
  return workedSeconds;
};

export const generateActivityReport = (members, startDate, endDate) => {
  // Convert startDate and endDate to Date objects
  const start = new Date(startDate.getTime());
  const end = new Date(endDate.getTime());
  start.setHours(23, 59, 59, 999);
  end.setHours(23, 59, 59, 999);
  const newEnd = end.setDate(end.getDate() + 1);
  const result = {};

  // Initialize result object for each date within the range
  for (let d = new Date(start); d < end; d.setDate(d.getDate() + 1)) {
    const dateStr = d.toISOString().split('T')[0];
    result[dateStr] = {
      deepFocusedSeconds: 0,
      focusedSeconds: 0,
      activeSeconds: 0,
      totalSeconds: 0,
      deepFocusedActiveSeconds: 0,
      focusedActiveSeconds: 0,
      activeActiveSeconds: 0,
      totalActiveSeconds: 0,
    };
  }

  // Loop through members and their daily activities
  members.forEach((member) => {
    if (member.dailyActivity) {
      member.dailyActivity.forEach((activity) => {
        const activityDate = new Date(activity.date);
        const newActivityDate = new Date(activityDate.setDate(activityDate.getDate() + 1));
        if (newActivityDate >= start && newActivityDate <= newEnd) {
          const dateStr = activityDate.toISOString().split('T')[0];
          const activityPercentage = (activity.activeSeconds * 100) / activity.totalSeconds;
          const seconds = activity.totalSeconds;
          const seconds2 = activity.activeSeconds;

          if (activityPercentage > 51) {
            result[dateStr].deepFocusedSeconds += seconds;
            result[dateStr].deepFocusedActiveSeconds += seconds2;
          } else if (activityPercentage > 20) {
            result[dateStr].focusedSeconds += seconds;
            result[dateStr].focusedActiveSeconds += seconds2;
          } else {
            result[dateStr].activeSeconds += seconds;
            result[dateStr].activeActiveSeconds += seconds2;
          }
          result[dateStr].totalSeconds += activity.totalSeconds;
          result[dateStr].totalActiveSeconds += seconds2;
        }
      });
    }
  });

  return result;
};

export const transformToGraphData = (report) => {
  const data = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [date, hours] of Object.entries(report)) {
    const dateStr = new Date(date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
    data.push({
      date: dateStr,
      'Deep Focused': Math.round(hours.deepFocusedSeconds),
      Focused: Math.round(hours.focusedSeconds),
      Active: Math.round(hours.activeSeconds),
      'Work Break': 0,
    });
  }
  return data;
};

export const transformToTableData = (report) => {
  const data = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [date, hours] of Object.entries(report)) {
    const dateStr = new Date(date).toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
    data.push({
      date: dateStr,
      deepFocused: Math.round(hours.deepFocusedSeconds),
      deepFocusedActivity: hours.deepFocusedSeconds === 0 ? 0 : Math.round((hours.deepFocusedActiveSeconds * 100) / hours.deepFocusedSeconds),
      focused: Math.round(hours.focusedSeconds),
      focusedActivity: hours.focusedSeconds === 0 ? 0 : Math.round((hours.focusedActiveSeconds * 100) / hours.focusedSeconds),
      active: Math.round(hours.activeSeconds),
      activeActivity: hours.activeSeconds === 0 ? 0 : Math.round((hours.activeActiveSeconds * 100) / hours.activeSeconds),
      totalSeconds: hours.totalSeconds,
      totalActivity: hours.totalSeconds === 0 ? 0 : Math.round((hours.totalActiveSeconds * 100) / hours.totalSeconds),
      workBreak: 0,
    });
  }
  return data;
};

export const exportTableData = (report) => {
  const data = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [date, hours] of Object.entries(report)) {
    const dateStr = new Date(date).toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
    data.push({
      date: dateStr,
      deepFocused: convertSecToTimeFormat(hours.deepFocusedSeconds),
      deepFocusedActivity: hours.deepFocusedSeconds === 0 ? 0 : Math.round((hours.deepFocusedActiveSeconds * 100) / hours.deepFocusedSeconds),
      focused: convertSecToTimeFormat(hours.focusedSeconds),
      focusedActivity: hours.focusedSeconds === 0 ? 0 : Math.round((hours.focusedActiveSeconds * 100) / hours.focusedSeconds),
      active: convertSecToTimeFormat(hours.activeSeconds),
      activeActivity: hours.activeSeconds === 0 ? 0 : Math.round((hours.activeActiveSeconds * 100) / hours.activeSeconds),
      totalSeconds: convertSecToTimeFormat(hours.totalSeconds),
      totalActivity: hours.totalSeconds === 0 ? 0 : Math.round((hours.totalActiveSeconds * 100) / hours.totalSeconds),
      workBreak: convertSecToTimeFormat(0),
    });
  }
  return data;
};

export const generateWebcamReport = (webcamAudits, webcamViolations, startDate, endDate) => {
  // Convert startDate and endDate to Date objects
  const start = new Date(startDate);
  const end = new Date(endDate);
  start.setHours(23, 59, 59, 999);
  end.setHours(23, 59, 59, 999);
  const result = {};

  // Initialize result object for each date within the range
  for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
    const dateStr = d.toISOString().split('T')[0];
    result[dateStr] = {
      webcamAudits: [],
      webcamViolations: [],
    };
  }

  // Populate webcamAudits
  webcamAudits.forEach((audit) => {
    const auditDate = new Date(audit.timestamp).toISOString().split('T')[0];
    if (result[auditDate]) {
      result[auditDate].webcamAudits.push(audit);
    }
  });

  // Populate webcamViolations
  webcamViolations.forEach((violation) => {
    const violationDate = new Date(violation.createdAt).toISOString().split('T')[0];
    if (result[violationDate]) {
      result[violationDate].webcamViolations.push(violation);
    }
  });

  return result;
};

export const transformToGraphAuditData = (report) => {
  const data = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [date, results] of Object.entries(report)) {
    const dateStr = new Date(date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
    data.push({
      date: dateStr,
      Audits: results.webcamAudits.length,
      Violations: results.webcamViolations.length,
    });
  }
  return data;
};

export const transformToTableAuditData = (webcamAudits, startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  start.setHours(0, 0, 0, 0);
  end.setHours(23, 59, 59, 999);

  const result = {};

  // Initialize result object for each date within the range
  for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
    const dateStr = new Date(d).toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
    result[dateStr] = {
      date: dateStr,
      Rejected: 0,
      Taken: 0,
      Score: 0,
      data: [],
    };
  }

  // Process webcamAudits data
  webcamAudits.forEach((audit) => {
    const auditDate = new Date(audit.timestamp).toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
    const datum = {
      Taken: 0,
      Rejected: 0,
      Score: 0,
      Time: '',
    };
    datum.Time = convertDateToTimeStr(audit.timestamp);
    if (result[auditDate]) {
      if (audit.status === 'Complete') {
        result[auditDate].Taken += audit.images.length;
        datum.Taken = audit.images.length;
        datum.Rejected = 0;
        datum.Score = 100;
      } else {
        result[auditDate].Rejected += audit.images.length;
        datum.Rejected = audit.images.length;
        datum.Taken = 0;
        datum.Score = 0;
      }
    }
    result[auditDate].data.push(datum);
  });

  // Calculate Score for each date and convert result to array
  const resultArray = Object.keys(result).map((date) => {
    const total = result[date].Taken + result[date].Rejected;
    if (total > 0) {
      result[date].Score = (result[date].Taken / total) * 100;
    } else {
      result[date].Score = 0;
    }
    return result[date];
  });

  return resultArray;
};

export const generateLocationReport = (locationViolations, startDate, endDate) => {
  // Convert startDate and endDate to Date objects
  const start = new Date(startDate);
  const end = new Date(endDate);
  start.setHours(23, 59, 59, 999);
  end.setHours(23, 59, 59, 999);
  const result = {};

  // Initialize result object for each date within the range
  for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
    const dateStr = d.toISOString().split('T')[0];
    result[dateStr] = {
      locationViolations: [],
    };
  }

  // Populate webcamViolations
  locationViolations.forEach((violation) => {
    const violationDate = new Date(violation.createdAt).toISOString().split('T')[0];
    if (result[violationDate]) {
      result[violationDate].locationViolations.push(violation);
    }
  });

  return result;
};

export const transformToGraphLocationData = (report) => {
  const data = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [date, results] of Object.entries(report)) {
    const dateStr = new Date(date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
    data.push({
      date: dateStr,
      Violations: results.locationViolations.length,
    });
  }
  return data;
};

export const transformToTableLocationData = (report, members) => {
  const data = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [date, results] of Object.entries(report)) {
    const dateStr = new Date(date).toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
    const newViolations = [];
    const violationMembers = [];
    results.locationViolations.forEach((violation) => {
      const newDateStr = convertDateToTimeStr(violation.createdAt);
      let userName = '';
      if (members.length > 0) {
        const filteredMember = members.find((member) => member.id === violation.memberId);
        userName = filteredMember.user.fullName;
        if (!violationMembers.find((item) => item === filteredMember.id)) {
          violationMembers.push(filteredMember.id);
        }
      }
      newViolations.push({
        date: newDateStr,
        violationType: 'Physical Safeguard Breach - Location',
        user: userName,
      });
    });
    data.push({
      date: dateStr,
      violations: newViolations,
      violationMembers,
    });
  }
  return data;
};

export const transformToExportLocationData = (report, members) => {
  const data = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [date, results] of Object.entries(report)) {
    const dateStr = new Date(date).toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
    results.locationViolations.forEach((violation) => {
      const newDateStr = dateStr + convertDateToTimeStr(violation.createdAt);
      let userName = '';
      if (members.length > 0) {
        const filteredMember = members.find((member) => member.id === violation.memberId);
        userName = filteredMember.user.fullName;
      }
      data.push({
        date: newDateStr,
        violationType: 'Physical Safeguard Breach - Location',
        user: userName,
      });
    });
  }
  return data;
};

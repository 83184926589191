/* eslint-disable no-nested-ternary */
import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import {
  Button, CircularProgress, Box, Checkbox,
} from '@mui/material';
import './settings.css';
import { CSVLink } from 'react-csv';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { UserContext } from '../../contexts/User';
import { NotificationContext } from '../../contexts/Notification';
import MemberBalanceRow from './MemberBalanceRow';
import useDebounce from '../../hooks/useDebounce';
import ImportModal from './ImportModal';

function TimeoffBalancePanel() {
  const { activeWorkspace } = useContext(UserContext);
  const {
    displayNotification,
  } = useContext(NotificationContext);
  const [members, setMembers] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const { debounce } = useDebounce();
  const headers = [
    {
      label: 'User Email',
      key: 'email',
    },
    {
      label: 'User Name',
      key: 'name',
    },
    {
      label: 'Policy',
      key: 'policy',
    },
    {
      label: 'Balance',
      key: 'balance',
    },
  ];

  const data = useMemo(() => {
    const res = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const member of members) {
      // eslint-disable-next-line no-restricted-syntax
      for (const policy of policies) {
        const match = member.ptoBalances.find((balance) => (balance.policyId === policy.id));
        res.push({
          email: member.user.email,
          name: `${member.user.firstName} ${member.user.lastName}`,
          policy: policy.name,
          balance: match ? match.balance : '',
        });
      }
    }
    return res;
  }, [members, policies]);

  const getMembers = useCallback(async (params) => {
    if (activeWorkspace) {
      setIsLoading(true);
      const resMember = await sendRequest({
        method: 'get',
        url: API.WORKSPACE.membersBalances(activeWorkspace.id),
        params,
      });
      const resPolicy = await sendRequest({
        method: 'get',
        url: API.WORKSPACE.offTimePolicies(activeWorkspace.id),
      });
      if (resPolicy.success && resMember.success) {
        setPolicies(resPolicy.data.ptoPolicies);
        setMembers(resMember.data?.members ?? []);
        setIsLoading(false);
      } else {
        displayNotification({
          message: 'An error occurs while fetching data.',
          severity: 'error',
        });
        setIsLoading(false);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeWorkspace]);

  const fetchResults = async () => {
    const params = {
      name: searchQuery,
    };
    await getMembers(params);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(debounce((query) => setSearchQuery(query), 500), []);

  useEffect(() => {
    fetchResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { getMembers(); }, []);

  return (
    <Box
      sx={{
        marginTop: '-3px',
        padding: '16px',
      }}
    >
      {showImportModal && (
        <ImportModal
          closeModal={() => setShowImportModal(false)}
          getMembers={() => getMembers()}
          data={data}
          headers={headers}
        />
      )}
      <Box className="d-flex justify-content-between align-items-center">
        <div>
          <h6 className="time-off-subheader">
            Time off balances
          </h6>
          <p className="mt-1">
            Set up time off balances for members
          </p>
        </div>
        <div className="d-flex">
          <div className="searchBox">
            <input
              type="text"
              style={{ width: '233px' }}
              placeholder="Search Members"
              onChange={(e) => handleSearch(e.target.value)}
            />
            <i className="fa fa-search searchIcon" />
          </div>
          <CSVLink
            data={data}
            headers={headers}
            filename="timeOffBalance.csv"
          >
            <Button
              sx={{
                marginLeft: '8px',
                font: 'Inter',
                fontSize: '16px',
                lineHeight: '16px',
                fontWeight: '500',
                textTransform: 'none',
                backgroundColor: 'tranparent',
                color: 'var(--green-3, #53A551)',
                padding: '12px 24px',
              }}
              variant="link"
              component="span"
            >
              Export
            </Button>
          </CSVLink>
          <Button
            sx={{
              marginLeft: '8px',
              font: 'Inter',
              fontSize: '16px',
              lineHeight: '16px',
              fontWeight: '500',
              border: '1px solid',
              textTransform: 'none',
              borderRadius: '4px',
              backgroundColor: 'var(--green-3, #53A551)',
              color: '#FFFFFF',
              padding: '12px 24px',
              '&:hover': { backgroundColor: 'var(--green-3, #53A551)' },
            }}
            variant="link"
            component="span"
            onClick={() => setShowImportModal(true)}
          >
            Import
          </Button>
        </div>
      </Box>
      <Box className="mt-4">
        <table className="table">
          <thead>
            {policies.length > 0 && (
              <tr>
                <th scope="col">
                  <Checkbox sx={{ padding: '0px', marginRight: '8px' }} />
                  Member
                </th>
                {policies.map((policy) => (
                  <th key={policy.id} scope="col">{policy.name}</th>
                ))}
              </tr>
            )}
          </thead>
          <tbody>
            {isLoading
              ? (
                <tr>
                  <td style={{ padding: '100px' }} colSpan={8} className="text-center">
                    <span style={{ display: 'none' }}>spinner</span>
                    <CircularProgress sx={{ color: 'inherit' }} />
                  </td>
                </tr>
              )
              : members?.length
                ? members.map((member) => (
                  <MemberBalanceRow
                    member={member}
                    policies={policies}
                    getMembers={() => getMembers()}
                  />
                ))
                : (
                  <tr>
                    <td style={{ padding: '100px' }} colSpan={8} className="text-center">There are no data yet</td>
                  </tr>
                )}
          </tbody>
        </table>
      </Box>
    </Box>
  );
}

export default TimeoffBalancePanel;

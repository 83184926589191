/* eslint-disable no-else-return */
import React, { useState } from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import BalanceEditModal from './BalanceEditModal';

function BalanceBox({
  match, getMembers,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [onMouse, setOnMouse] = useState(false);
  // eslint-disable-next-line max-len
  const hours = Math.floor(match.balance / 60) < 10 ? `0${Math.floor(match.balance / 60)}` : Math.floor(match.balance / 60);
  const minutes = match.balance % 60 < 10 ? `0${match.balance % 60}` : match.balance % 60;

  const buttonProps = {
    sx: {
      marginLeft: '8px',
      font: 'Inter',
      fontSize: '12px',
      lineHeight: '12px',
      fontWeight: '500',
      textTransform: 'none',
      backgroundColor: 'tranparent',
      padding: '2px',
    },
    variant: 'link',
    component: 'span',
  };

  const buttonColor = isEditing ? '#EB6240' : 'var(--green-3, #53A551)';
  const buttonBorder = isEditing ? '#EB6240' : 'var(--green-3, #53A551)';

  const handleButtonClick = () => {
    setIsEditing(!isEditing);
  };

  return (
    <td
      key={match.policyId}
      style={{ position: 'relative' }}
      onMouseEnter={() => setOnMouse(true)}
      onMouseLeave={() => setOnMouse(false)}
    >
      {hours}
      :
      {minutes}
      {onMouse && (
        <Button
          sx={{
            ...buttonProps.sx,
            border: `0.5px solid ${buttonBorder}`,
            color: buttonColor,
          }}
          onClick={handleButtonClick}
        >
          {isEditing ? 'Cancel' : 'Edit'}
        </Button>
      )}
      {onMouse && (
        <BalanceEditModal
          match={match}
          isEditing={isEditing}
          getMembers={() => getMembers()}
        />
      )}
    </td>
  );
}

BalanceBox.propTypes = {
  match: PropTypes.shape({
    id: PropTypes.number.isRequired,
    balance: PropTypes.number.isRequired,
    policyId: PropTypes.number.isRequired,
  }).isRequired,
  getMembers: PropTypes.func.isRequired,
};

export default BalanceBox;

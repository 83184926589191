import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Modal, Box, Button } from '@mui/material';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { NotificationContext } from '../../contexts/Notification';
import TrashIcon from '../../images/trash.png';

const Description = styled.p`
    font-size: ${(props) => `${props.fontSize}px`};
    color: ${(props) => props.color};
    font-weight: ${(props) => props.fontWeight};
    margin-top: ${(props) => `${props.marginTop}px`};
`;

function DeleteTeamModal({
  closeModal,
  onTeamDeleted,
  name,
  managers,
  members,
  teamId,
  workspaceId,
}) {
  const { displayNotification } = useContext(NotificationContext);
  const handleDeleteTeam = async () => {
    const { success } = await sendRequest({
      method: 'delete',
      url: API.WORKSPACE.deleteTeam(workspaceId, teamId),
    });
    if (success) {
      setTimeout(() => {
        displayNotification({
          message: 'Team has been deleted successfully.',
          severity: 'success',
        });
        onTeamDeleted();
        closeModal();
      }, 500);
    } else {
      displayNotification({
        message: 'There was an issue deleting team.',
        severity: 'error',
      });
    }
  };

  return (
    <Modal open onClose={closeModal} className="flex-row align-c jstfy-c create-team-modal">
      <Box style={{
        width: 490,
        padding: 32,
        background: '#fff',
        borderRadius: 8,
      }}
      >
        <img src={TrashIcon} width="48" height="48" alt="trash" />
        <div className="modal-body" style={{ padding: 0 }}>
          <Description
            fontSize={20}
            fontWeight={600}
            color="#0E1E40"
            marginTop={16}
          >
            Are you sure you want to delete this team?
          </Description>
          <Description
            fontSize={14}
            fontWeight={400}
            color="#293855"
            marginTop={6}
          >
            You’re about to delete “
            <span style={{ fontWeight: 600 }}>{name}</span>
            ”. This action cannot be undone.
          </Description>
          <Description
            fontSize={14}
            fontWeight={400}
            color="#757575"
            marginTop={12}
          >
            Manager:
            <span style={{ fontWeight: 500, color: '#0E1E40', marginLeft: 10 }}>{managers[0].name}</span>
          </Description>
          <Description
            fontSize={14}
            fontWeight={400}
            color="#757575"
          >
            Members:
            <span style={{ fontWeight: 500, color: '#0E1E40', marginLeft: 7 }}>{members.length}</span>
          </Description>
        </div>

        <div className="modal-footer-customized">
          <Button
            sx={{
              marginRight: 2,
              textTransform: 'none',
              fontSize: '16px',
              height: '40px',
              width: '101px',
            }}
            variant="outlined"
            onClick={closeModal}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            sx={{
              textTransform: 'none',
              fontSize: '16px',
              height: '40px',
              width: '101px',
              backgroundColor: '#CC4525',
              '&:hover': { backgroundColor: '#CC4525' },
            }}
            variant="contained"
            onClick={handleDeleteTeam}
          >
            Delete
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

DeleteTeamModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onTeamDeleted: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  managers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired),
  members: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired),
  teamId: PropTypes.number.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

export default DeleteTeamModal;

import React, { useEffect, useState } from 'react';
import ShootLogo from '../../images/blue-shoot-logo.png';
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  MinusIcon,
  PlusIcon,
  TwitterIcon,
} from './Icons';

function Footer() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <footer className="container footer">
      <div className="row">
        <div className="col-12 col-md-5">
          <a className="d-flex mb-4" href="/">
            <img src={ShootLogo} alt="logo" className="logo" width="132" height="50" loading="lazy" />
          </a>
          <p className="info mb-4 footer-summary">
            Italic shadow pen draft fill reesizing strikethrough share.
            Figma thumbnail vector scale align frame duplicate.
          </p>
          <div className="d-none d-sm-flex mb-4">
            <a href="/" target="_blank" className="d-flex me-3">
              <span className="d-none">Linkedin</span>
              <LinkedinIcon width="24" height="24" />
            </a>
            <a href="/" target="_blank" className="d-flex me-3">
              <span className="d-none">Twitter</span>
              <TwitterIcon width="24" height="24" />
            </a>
            <a href="/" target="_blank" className="d-flex me-3">
              <span className="d-none">Facebook</span>
              <FacebookIcon width="24" height="24" />
            </a>
            <a href="/" target="_blank" className="d-flex me-3">
              <span className="d-none">Instagram</span>
              <InstagramIcon width="24" height="24" />
            </a>
          </div>
        </div>
        <div className="col-12 col-md-7">
          <div className="footer-menus d-flex justify-content-between flex-column flex-sm-row">
            <details open={!isMobile}>
              <summary className="mb-2 d-flex justify-content-between align-items-center">
                Company
                <PlusIcon className="icon icon-plus" width="20" height="20" />
                <MinusIcon className="icon icon-minus" width="20" height="20" />
              </summary>
              <ul className="nav mobile-nav flex-column">
                <li><a className="nav-link" href="/">About us</a></li>
                <li><a className="nav-link" href="/">Contact us</a></li>
                <li><a className="nav-link" href="/">Careers</a></li>
                <li><a className="nav-link" href="/">Press</a></li>
              </ul>
            </details>
            <details open={!isMobile}>
              <summary className="mb-2 d-flex justify-content-between align-items-center">
                Product
                <PlusIcon className="icon icon-plus" width="20" height="20" />
                <MinusIcon className="icon icon-minus" width="20" height="20" />
              </summary>
              <ul className="nav mobile-nav flex-column">
                <li><a className="nav-link" href="/">Features</a></li>
                <li><a className="nav-link" href="/">Pricing</a></li>
                <li><a className="nav-link" href="/">News</a></li>
                <li><a className="nav-link" href="/">Help desk</a></li>
                <li><a className="nav-link" href="/">Support</a></li>
              </ul>
            </details>
            <details open={!isMobile}>
              <summary className="mb-2 d-flex justify-content-between align-items-center">
                Services
                <PlusIcon className="icon icon-plus" width="20" height="20" />
                <MinusIcon className="icon icon-minus" width="20" height="20" />
              </summary>
              <ul className="nav mobile-nav flex-column">
                <li><a className="nav-link" href="/">Menu Goes Here</a></li>
                <li><a className="nav-link" href="/">Menu Goes Here</a></li>
                <li><a className="nav-link" href="/">Menu Goes Here</a></li>
                <li><a className="nav-link" href="/">Menu Goes Here</a></li>
              </ul>
            </details>
            <details open={!isMobile}>
              <summary className="mb-2 d-flex justify-content-between align-items-center">
                Legal
                <PlusIcon className="icon icon-plus" width="20" height="20" />
                <MinusIcon className="icon icon-minus" width="20" height="20" />
              </summary>
              <ul className="nav mobile-nav flex-column">
                <li><a className="nav-link" href="/">Privacy Policy</a></li>
                <li><a className="nav-link" href="/">Terms & Conditions</a></li>
                <li><a className="nav-link" href="/">Return Policy</a></li>
              </ul>
            </details>
          </div>
          <div className="d-block d-sm-none mb-3">
            <h6 className="mb-3">Find us on</h6>
            <div className="d-flex justify-content-between">
              <a href="/" target="_blank" className="d-flex">
                <span className="d-none">Linkedin</span>
                <LinkedinIcon width="24" height="24" />
              </a>
              <a href="/" target="_blank" className="d-flex">
                <span className="d-none">Twitter</span>
                <TwitterIcon width="24" height="24" />
              </a>
              <a href="/" target="_blank" className="d-flex">
                <span className="d-none">Facebook</span>
                <FacebookIcon width="24" height="24" />
              </a>
              <a href="/" target="_blank" className="d-flex">
                <span className="d-none">Instagram</span>
                <InstagramIcon width="24" height="24" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <p className="copyright mt-4">
        © 2024 Shoot Corporation. All rights reserved
      </p>
    </footer>
  );
}

export default Footer;

import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Returns an object you can use to get a query parameter.
 * Exmaple:
 * const query = useQueryParams();
 * query.get("paramName") => returns a query parameter if present
 */
function useQueryParams() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default useQueryParams;

import React, {
  useState, useContext,
} from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { NotificationContext } from '../../contexts/Notification';
import { UserContext } from '../../contexts/User';
import TimeoffActivePanel from './TimeoffActivePanel';
import TimeoffArchivePanel from './TimeoffArchivePanel';

const StyledTab = styled(Tab)`
    text-transform: capitalize;
    color: var(--Dark-Dark-03, #546077);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 0 16px 8px 16px;

    &.Mui-selected {
        color: var(--Blue-Blue-02, #315285);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }
`;

function CustomTabPanel(props) {
  const {
    children, value, index, p = 4,
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`timeoff-tabpanel-${index}`}
      aria-labelledby={`timeoff-tab-${index}`}
    >
      {value === index && (
      <Box sx={{ p }}>
        {children}
      </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  p: PropTypes.number,
};

function TimeoffPolicyPanel() {
  const [value, setValue] = useState(0);
  const [policies, setPolicies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const {
    displayNotification,
  } = useContext(NotificationContext);
  const { activeWorkspace } = useContext(UserContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getPolicies = async () => {
    setIsLoading(true);
    const res = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.offTimePolicies(activeWorkspace.id),
    });
    if (res.success) {
      setPolicies(res.data.ptoPolicies);
    } else {
      displayNotification({
        message: 'An error occurs while fetching PTO policies.',
        severity: 'error',
      });
    }
    setIsLoading(false);
  };

  return (
    <div>
      <div style={{ marginTop: 16 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: 'max-content' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="timeoff tab"
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: 'var(--Blue-Blue-02, #315285)',
              },
              '&.MuiTabs-root': {
                minHeight: '28px',
              },
              '& .MuiButtonBase-root': {
                minHeight: '28px',
              },
            }}
          >
            <StyledTab label="Active" id="tab_timeoff_active" />
            <StyledTab label="Archived" id="tab_timeoff_archived" />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0} p={2}>
          <TimeoffActivePanel isLoading={isLoading} policies={policies} getPolicies={getPolicies} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1} p={2}>
          <TimeoffArchivePanel isLoading={isLoading} policies={policies} getPolicies={getPolicies} />
        </CustomTabPanel>
      </div>
    </div>
  );
}

TimeoffPolicyPanel.propTypes = {

};

export default TimeoffPolicyPanel;

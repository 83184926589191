/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Box, Grid,
} from '@mui/material';
import { getTimeString } from '../../utils/time';
import { Description, StyledCard } from '../../components/styledComponents';

function RequestViewModal({
  policies, balances, selectedRequest, closeModal,
}) {
  const dateOptions = {
    weekday: 'short',
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  };
  const start = new Date(selectedRequest.startDate);
  const end = new Date(selectedRequest.endDate);
  const startDate = start.toLocaleDateString('en-US', dateOptions);
  const endDate = end.toLocaleDateString('en-US', dateOptions);

  const selectedPolicy = policies.find((item) => item.id === selectedRequest.policyId);
  const requested = selectedRequest.totalHours;
  const balance = balances.find((item) => item.policyId === selectedRequest.policyId);
  let currentBalance = 0;
  let remaining = 0;
  if (balance) {
    currentBalance = getTimeString(balance.balance);
    remaining = getTimeString(balance.balance - requested);
  } else {
    currentBalance = getTimeString(0);
    remaining = getTimeString(0);
  }

  return (
    <Modal open onClose={() => closeModal(false)} className="flex-row align-c jstfy-c create-team-modal">
      <Box style={{
        width: 1044,
        padding: 32,
        background: '#fff',
        borderRadius: 8,
      }}
      >
        <div className="modal-body" style={{ padding: 0 }}>
          <Description
            fontSize={20}
            fontWeight={600}
            color="#0E1E40"
            marginTop={0}
          >
            Request Time Off
          </Description>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Description
                fontSize={14}
                fontWeight={500}
                color="#293855"
                marginTop={12}
              >
                Policy
              </Description>
              <Description
                fontSize={14}
                fontWeight={600}
                color="#293855"
                marginTop={4}
              >
                {selectedPolicy.name}
              </Description>
              <Description
                fontSize={14}
                fontWeight={500}
                color="#293855"
                marginTop={16}
              >
                Time Span
              </Description>
              <Description
                fontSize={14}
                fontWeight={600}
                color="#293855"
                marginTop={4}
              >
                {startDate}
                <span style={{ margin: '0px 4px', fontWeight: '500' }}>to</span>
                {endDate}
              </Description>
              <Description
                fontSize={14}
                fontWeight={500}
                color="#293855"
                marginTop={16}
              >
                Total Time Off Hours
              </Description>
              <Description
                fontSize={14}
                fontWeight={600}
                color="#293855"
                marginTop={4}
              >
                {getTimeString(selectedRequest.totalHours)}
              </Description>
              <Description
                fontSize={14}
                fontWeight={500}
                color="#293855"
                marginTop={16}
              >
                {selectedRequest.status === 'Denied' ? ('Deny Reason') : ('Time Off Reason')}
              </Description>
              <Description
                fontSize={14}
                fontWeight={600}
                color="#293855"
                marginTop={4}
              >
                {selectedRequest.status === 'Denied' ? (selectedRequest.denyReason) : (selectedRequest.reason)}
              </Description>
              <Description
                fontSize={14}
                fontWeight={500}
                color="#293855"
                marginTop={16}
              >
                Status
              </Description>
              <Description
                fontSize={14}
                fontWeight={600}
                color="#293855"
                marginTop={4}
              >
                {selectedRequest.status}
              </Description>
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ paddingLeft: '24px', paddingTop: '38px', borderLeft: '1px solid #E0E1E6' }}>
                <Box sx={{ paddingBottom: '16px', borderBottom: '1px solid #E0E1E6' }}>
                  <StyledCard>
                    <span>Current Balance</span>
                    <span>{currentBalance}</span>
                  </StyledCard>
                  <StyledCard>
                    <span>Hours Requested</span>
                    <span>{getTimeString(selectedRequest.totalHours)}</span>
                  </StyledCard>
                </Box>
                <Box sx={{ paddingTop: '16px' }}>
                  <StyledCard>
                    <span>Remaining Balance</span>
                    <span>{remaining}</span>
                  </StyledCard>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Box>
    </Modal>
  );
}

RequestViewModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  policies: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired),
  balances: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    balance: PropTypes.number.isRequired,
    policyId: PropTypes.number.isRequired,
  }).isRequired),
  selectedRequest: PropTypes.shape({
    id: PropTypes.number.isRequired,
    totalHours: PropTypes.number.isRequired,
    policyId: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    reason: PropTypes.string.isRequired,
    denyReason: PropTypes.string,
    allDay: PropTypes.bool.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
  }),
};

export default RequestViewModal;

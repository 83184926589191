import React from 'react';
import PropTypes from 'prop-types';
import { AppBar } from '@mui/material';
import { Images } from '../../common';

function FormLayout({
  heading, subheading, currentStep, children,
}) {
  const { shootLogo } = Images;

  return (
    <form className="organizationSignup-form">
      <AppBar
        position="static"
        sx={{ boxShadow: 'none' }}
        color="inherit"
        className="navbar-center"
        data-testid="shoot-brand"
      >
        <div className="flex-row align-c">
          <img
            src={shootLogo}
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt="shoot-logo"
          />
          <h4 className="navitem-brand font-weight-bold text-700 m-l8" style={{ color: '#163E6C' }}>Shoot</h4>
        </div>

      </AppBar>
      <h3 className="heading" data-testid="heading">{heading}</h3>
      <p className="subheading" data-testid="subheading">{subheading}</p>
      <div className="steps-bar" data-testid="steps-bar">
        <div className={`circle-bar ${currentStep > 1 ? 'done' : 'current'}`} data-testid="step1" />
        <div className={`line-bar ${currentStep > 1 && 'done'}`} />
        <div
          className={`circle-bar ${currentStep > 2 ? 'done' : `${currentStep === 2 && 'current'}`}`}
          data-testid="step2"
        />
        <div className={`line-bar ${currentStep > 2 && 'done'}`} />
        <div
          className={`circle-bar ${currentStep > 3 ? 'done' : `${currentStep === 3 && 'current'}`}`}
          data-testid="step3"
        />
      </div>
      <div className="steps-label" data-testid="steps-label">
        <p className="step-label done">Create your account</p>
        <p className="step-label">Setup your organization</p>
        <p className="step-label">Create a workspace</p>
      </div>
      {children}
    </form>
  );
}

FormLayout.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  currentStep: PropTypes.number.isRequired,
};

export default FormLayout;

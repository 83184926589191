import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import BarPopup from './BarPopup';

const ColorBar = styled.div`
  position: relative;
  width: calc(100% / 24);
  background: ${(props) => props.color};
  height: 16px;
  display: inline-block;

  border-top-left-radius: ${(props) => (props.isAfterSameColor ? '0' : '10px')};
  border-bottom-left-radius: ${(props) => (props.isAfterSameColor ? '0' : '10px')};

  border-top-right-radius: ${(props) => (props.isBeforeSameColor ? '0' : '10px')};
  border-bottom-right-radius: ${(props) => (props.isBeforeSameColor ? '0' : '10px')};
  border: 1px solid ${(props) => props.color};
  ${(props) => props.color !== 'transparent' && css`
    cursor: pointer;
    :hover {
      filter: brightness(85%);
    }
  `}
`;

const HourLine = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  height: ${(props) => (props.longLine ? '100%' : '30%')};
  border: 1px solid #E9ECF0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HourLabel = styled.div`
  margin-top: 10px;
  position: absolute;
  color: var(--dark-4);
`;

function Bar({
  hourInfo, hour, isAfterSameColor, isBeforeSameColor,
}) {
  const hourLabel = (hour % 6 === 0) && (hour !== 0) ? ((hour % 12) || 12) : null;
  const AMPM = (hour >= 12 ? 'pm' : 'am');
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ColorBar
        color={hourInfo.color}
        isAfterSameColor={isAfterSameColor}
        isBeforeSameColor={isBeforeSameColor}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {' '}
        {
        hourLabel && (
        <HourLine longLine={hourLabel}>
          <HourLabel className="size-xxs">{`${hourLabel}${AMPM}`}</HourLabel>
        </HourLine>
        )
        }
      </ColorBar>
      <BarPopup
        hour={hour}
        hourInfo={hourInfo}
        handlePopoverClose={handlePopoverClose}
        open={!!anchorEl}
        anchorEl={anchorEl}
      />
    </>
  );
}

Bar.propTypes = {
  hourInfo: PropTypes.shape({
    color: PropTypes.string.isRequired,
  }).isRequired,
  hour: PropTypes.number.isRequired,
  isAfterSameColor: PropTypes.bool.isRequired,
  isBeforeSameColor: PropTypes.bool.isRequired,
};

export default Bar;

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import RSuiteDateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite-no-reset.min.css';
import './dateRangePicker.css';

const today = new Date();

export const predefinedDateRanges = [
  {
    label: 'Today',
    value: [today, today],
    placement: 'left',
  },
  {
    label: 'Yesterday',
    value: [new Date(moment(today).subtract(1, 'days')), new Date(moment(today).subtract(1, 'days'))],
    placement: 'left',
  },
  {
    label: 'Last 7 Days',
    value: [new Date(moment(today).subtract(6, 'days')), today],
    placement: 'left',
  },
  {
    label: 'Last Week',
    value: [
      new Date(moment(today).subtract(1, 'weeks').startOf('week')),
      new Date(moment(today).subtract(1, 'weeks').endOf('week'))],
    placement: 'left',
  },
  {
    label: 'This Month',
    value: [new Date(moment(today).startOf('month')), today],
    placement: 'left',
  },
  {
    label: 'Last Month',
    value: [
      new Date(moment(today).subtract(1, 'months').startOf('month')),
      new Date(moment(today).subtract(1, 'months').endOf('month'))],
    placement: 'left',
  },
];

function DateRangePicker({
  isWeekRange, className, hoverRange = '', placement = 'bottomEnd', ...props
}) {
  return (
    isWeekRange
      ? (
        <RSuiteDateRangePicker
          className={className}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          format="EEE, dd MMM yyyy"
          cleanable={false}
          oneTap
          showOneCalendar
          hoverRange={hoverRange}
          ranges={[]}
        />
      ) : (
        <RSuiteDateRangePicker
          className={className}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          format="EEE, dd MMM yyyy"
          cleanable={false}
          placement={placement}
          ranges={predefinedDateRanges}
          defaultValue={[today, today]}
        />
      )
  );
}

DateRangePicker.propTypes = {
  className: PropTypes.string,
  isWeekRange: PropTypes.bool,
  hoverRange: PropTypes.string,
  placement: PropTypes.string,
};

export default DateRangePicker;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { Button, Box } from '@mui/material';
import Resizer from 'react-image-file-resizer';
import PropTypes from 'prop-types';
import avatarIcon from '../../images/avatarIcon.svg';
import './uploadResizedPhoto.css';

function UploadResizedPhoto({ onChange, label }) {
  const [originalImage, setOriginalImage] = useState();
  const [resizedImage, setResizedImage] = useState();
  const [avatarUrl, setAvatarUrl] = useState();

  const resizeImage = (file) => new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      132,
      132,
      'PNG',
      100,
      0,
      (file) => {
        resolve(file);
      },
      'file',
    );
  });

  useEffect(() => {
    if (!originalImage) {
      return;
    }

    try {
      resizeImage(originalImage).then((file) => {
        setResizedImage(file);
        setAvatarUrl(URL.createObjectURL(file));
      });
    } catch (err) {
      console.error(err);
    }
  }, [originalImage]);

  useEffect(() => onChange(resizedImage), [resizedImage]);

  const handleImageUpload = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setOriginalImage(selectedFile);
    }
  };

  return (
    <div className="photo-wrapper">
      <Box
        sx={{
          width: '132px',
          height: '132px',
          borderRadius: '8px',
          bgcolor: '#F4F6F7',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '16px',
        }}
      >
        {avatarUrl ? (
          <img src={avatarUrl} alt="Profile" className="photo-preview" />
        ) : (
          <img src={avatarIcon} alt="Avatar Icon" />
        )}
      </Box>
      <input
        type="file"
        accept="originalImage/*"
        onChange={handleImageUpload}
        className="photo-upload-btn"
        id="contained-button-file"
        data-testid="file-upload"
      />
      <label htmlFor="contained-button-file">
        <Button
          sx={{
            marginLeft: 'auto',
            font: 'Inter',
            fontSize: '14px',
            lineHeight: '24px',
            fontWeight: '600',
            border: '1px solid',
            textTransform: 'none',
            borderRadius: '4px',
            borderColor: '#213D069',
            color: '#213D69',
            padding: '7px 29px',
          }}
          variant="link"
          color="primary"
          component="span"
        >
          {label}
        </Button>
      </label>
    </div>
  );
}

UploadResizedPhoto.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default UploadResizedPhoto;

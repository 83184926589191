import React from 'react';
import styled from '@emotion/styled';
import { Button, Box } from '@mui/material';
import WindowIcon from '../../images/windows.png';
import AppleIcon from '../../images/apple-logo.png';
import WindowSvg from '../../images/windows.svg';
import AppleSvg from '../../images/apple.svg';
import { Description } from '../../components/styledComponents';

const IconImg = styled.img`
  width: 20px;
  margin-right: 10px;
`;

const version = process.env.REACT_APP_VERSION;

const winDownloadLinkEnvVariables = {
  os: 'windows',
  app_name: process.env.REACT_APP_NODE_ENV === 'production'
    ? `Shoot+Setup+${version}.exe` : `Shoot+Staging+Setup+${version}.exe`,
};

const macDownloadLinkEnvVariables = {
  os: 'mac',
  app_name: process.env.REACT_APP_NODE_ENV !== 'production'
    ? `Shoot+Staging-${version}.dmg` : `Shoot-${version}.dmg`,
};

const windowAppLink = `${process.env.REACT_APP_DOWNLOAD_LINK}`
  .replace('{{ENV}}', process.env.REACT_APP_NODE_ENV)
  .replace('{{OS}}', winDownloadLinkEnvVariables.os)
  .replace('{{APP_NAME}}', winDownloadLinkEnvVariables.app_name);
const macAppLink = `${process.env.REACT_APP_DOWNLOAD_LINK}`
  .replace('{{ENV}}', process.env.REACT_APP_NODE_ENV)
  .replace('{{OS}}', macDownloadLinkEnvVariables.os)
  .replace('{{APP_NAME}}', macDownloadLinkEnvVariables.app_name);

function Downloads() {
  return (
    <div>
      <h6 className="fw-semibold m-b8">Download Desktop App</h6>
      <p>Maximize productivity with the Shoot app. Track your time efficiently.</p>
      <div className="flex-row justify-content-start" style={{ marginTop: 28 }}>
        <Box style={{
          width: 532,
          padding: 32,
          background: '#fff',
          borderRadius: '8px',
          marginRight: '32px',
          border: '1px solid #E0E1E6',
        }}
        >
          <img src={WindowIcon} width="48" height="48" alt="trash" />
          <div className="modal-body" style={{ padding: 0 }}>
            <Description
              fontSize={24}
              fontWeight={600}
              color="#0E1E40"
              marginTop={16}
            >
              Shoot for Windows
            </Description>
            <Description
              fontSize={14}
              fontWeight={400}
              color="#293855"
              marginTop={4}
            >
              Designed for
              <b style={{ margin: '0px 4px' }}>
                Windows 10
              </b>
              or newer
            </Description>
          </div>

          <div className="modal-footer-customized">
            <a href={windowAppLink}>
              <Button
                sx={{
                  textTransform: 'none',
                  fontSize: '16px',
                  height: '56px',
                  width: '293px',
                  borderRadius: '8px',
                  marginTop: '16px',
                  backgroundColor: '#53A551',
                  '&:hover': { backgroundColor: '#53A551' },
                }}
                variant="contained"
              >
                <IconImg src={WindowSvg} />
                Download for Windows
              </Button>
            </a>
          </div>
        </Box>
        <Box style={{
          width: 532,
          padding: 32,
          background: '#fff',
          borderRadius: '8px',
          border: '1px solid #E0E1E6',
        }}
        >
          <img src={AppleIcon} width="48" height="48" alt="trash" />
          <div className="modal-body" style={{ padding: 0 }}>
            <Description
              fontSize={24}
              fontWeight={600}
              color="#0E1E40"
              marginTop={16}
            >
              Shoot for macOS
            </Description>
            <Description
              fontSize={14}
              fontWeight={400}
              color="#293855"
              marginTop={4}
            >
              Designed for
              <b style={{ margin: '0px 4px' }}>
                macOS 11
              </b>
              or newer
            </Description>
          </div>

          <div className="modal-footer-customized">
            <a href={macAppLink}>
              <Button
                sx={{
                  textTransform: 'none',
                  fontSize: '16px',
                  height: '56px',
                  width: '293px',
                  borderRadius: '8px',
                  marginTop: '16px',
                  backgroundColor: '#53A551',
                  '&:hover': { backgroundColor: '#53A551' },
                }}
                variant="contained"
              >
                <IconImg src={AppleSvg} />
                Download for macOS
              </Button>
            </a>
          </div>
        </Box>
      </div>
    </div>
  );
}

export default Downloads;

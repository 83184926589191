import React from 'react';
import styled from '@emotion/styled';
import FormLayout from '../../components/FormLayout';
import SearchForS3Img from './assets/search-for-s3.png';
import CreateBucketImg from './assets/create-bucket.png';
import SetupBucketImg from './assets/setup-bucket.png';
import SearchIamImg from './assets/search-iam.png';
import ClickIamUsers from './assets/click-iam-users.png';
import CreateIamUser from './assets/create-iam-user.png';
import EnterUserName from './assets/enter-user-name.png';
import CreateIamPolicy from './assets/create-policy.png';
import EditIamPolicy from './assets/edit-policy.png';
import FinishPolicy from './assets/finish-creating-policy.png';
import AttachPolicy from './assets/attach-policy-user.png';
import SecurityCredentials from './assets/security-credentials.png';
import CreateAccessKey from './assets/create-access-key.png';
import DownloadKeys from './assets/download-keys.png';

const Image = styled.img`
  width: 100%;
  margin: 0 20px;
  border-radius: 12px;
  box-shadow: 0px 1px 4px 0px #0000003d;
`;

function AWSSetupInstructions() {
  return (
    <FormLayout
      style={{ width: '100%' }}
      heading="Setup your AWS storage"
      subheading="Shoot offers screenshot and webcam audit storage through your own AWS account
      or our servers. This guide will walk through setting up your own AWS storage."
    >
      <div style={{
        width: '85%',
        margin: 'auto',
        borderTop: '1px solid var(--dark-5)',
        padding: 20,
      }}
      >
        <section>
          <h4 className="fw-semibold">Step 1. Login to AWS and search for &quot;S3&quot;</h4>
          <h6 className="m-b20">
            Login to your AWS console and search for &quot;S3&quot; in the search bar at the top of the page.
            Click on &quot;S3&quot;.
          </h6>
          <Image src={SearchForS3Img} alt="search for AWS S3" />
        </section>
        <section style={{ marginTop: 40 }}>
          <h4 className="fw-semibold">Step 2. Create a new S3 bucket</h4>
          <h6 className="m-b20">
            Click on &quot;Create Bucket&quot;
          </h6>
          <Image src={CreateBucketImg} alt="search for AWS S3" />
        </section>
        <section style={{ marginTop: 40 }}>
          <h4 className="fw-semibold">Step 3. Setup the new S3 bucket</h4>
          <h6 className="m-b20">
            <ol>
              <li className="m-t8">
                Enter the name of your bucket (this can be anything).
                Write this name down--we will need the name of the bucket again in a later step.
              </li>
              <li>
                <span className="fw-bold">
                  Enter your bucket name exactly as you entered it into the organization sign up page
                </span>
              </li>
              <li>
                <span className="fw-bold">
                  Important:
                  {' '}
                </span>
                Under &quot;AWS Region&quot;, set the region to &quot;US East (N. Virginia) us-east-1&quot;
              </li>
              <li>
                All other settings can be set to defaults.
                Scroll to the bottom of the page and click &quot;Create Bucket&quot;
              </li>
            </ol>
          </h6>
          <Image src={SetupBucketImg} alt="search for AWS S3" />
        </section>
        <section style={{ marginTop: 40 }}>
          <h4 className="fw-semibold">Step 4. Search for &quot;IAM&quot; at the top of the AWS console</h4>
          <h6 className="m-b20">
            Click on &quot;IAM&quot;
          </h6>
          <Image src={SearchIamImg} alt="search for AWS S3" />
        </section>
        <section style={{ marginTop: 40 }}>
          <h4 className="fw-semibold">Step 5. Click &quot;Users&quot; on the left hand side</h4>
          <h6 className="m-b20">
            In the left-hand panel, click on the &quot;users&quot; option
          </h6>
          <Image src={ClickIamUsers} alt="search for AWS S3" />
        </section>
        <section style={{ marginTop: 40 }}>
          <h4 className="fw-semibold">Step 6. Click &quot;Create user&quot; on the right hand side</h4>
          <h6 className="m-b20">
            Create a new IAM user by clicking the orange &quot;Create User&quot; button on the right-hand side
          </h6>
          <Image src={CreateIamUser} alt="search for AWS S3" />
        </section>
        <section style={{ marginTop: 40 }}>
          <h4 className="fw-semibold">Step 7. Enter a descriptive name for the IAM user</h4>
          <h6 className="m-b20">
            The name of the IAM user can be anything. After you are finished, click &quot;Next&quot;
          </h6>
          <Image src={EnterUserName} alt="search for AWS S3" />
        </section>
        <section style={{ marginTop: 40 }}>
          <h4 className="fw-semibold">Step 8. Click on &quot;Attach policies directly&quot;</h4>
          <h6 className="m-b20">
            After clickin on &quot;Attach policies directly&quot; in the top right corner,
            click the &quot;Create Policy&quot; button
          </h6>
          <Image src={CreateIamPolicy} alt="search for AWS S3" />
        </section>
        <section style={{ marginTop: 40 }}>
          <h4 className="fw-semibold">Step 9. Create the policy</h4>
          <h6 className="m-b20">
            Click on the &quot;JSON&quot; button in the top right corner and then
            paste the following snippet into the Policy editor replacing &quot;S3_BUCKET_NAME&quot; with the name
            of the bucket you created in Step 3. Be sure to leave the &quot;/*&quot; at the end.
            <pre className="m-t8">
              {JSON.stringify({
                Version: '2012-10-17',
                Statement: [
                  {
                    Effect: 'Allow',
                    Action: 's3:*',
                    Resource: 'arn:aws:s3:::S3_BUCKET_NAME/*',
                  },
                ],
              }, undefined, 2)}
            </pre>

            After entering the policy, click &quot;Next&quot;.
          </h6>
          <Image src={EditIamPolicy} alt="search for AWS S3" />
        </section>
        <section style={{ marginTop: 40 }}>
          <h4 className="fw-semibold">Step 10. Finish creating the policy</h4>
          <h6 className="m-b20">
            Enter a policy name (this can be anything) and then click &quot;Create policy&quot;
          </h6>
          <Image src={FinishPolicy} alt="search for AWS S3" />
        </section>
        <section style={{ marginTop: 40 }}>
          <h4 className="fw-semibold">Step 11. Attach the policy to the user</h4>
          <h6 className="m-b20">
            Return to the previous tab in your browser from Step 8 (creating IAM User). Refresh the list of
            policies and search for the new policy name we created in Step 9. Check the box next to this policy
            to add it to the user, then click &quot;Next&quot;, then finish by clicking
            &quot;Create User&quot; on the next page.
          </h6>
          <Image src={AttachPolicy} alt="search for AWS S3" />
        </section>
        <section style={{ marginTop: 40 }}>
          <h4 className="fw-semibold">Step 12. Generate your AWS keys</h4>
          <h6 className="m-b20">
            Click on the newly created user in the table. On this page, select &quot;Security Credentials&quot;.
            Then scroll down to &quot;Access keys&quot;. Click on the &quot;Create access key&quot; button.
          </h6>
          <Image src={SecurityCredentials} alt="search for AWS S3" />
        </section>
        <section style={{ marginTop: 40 }}>
          <h4 className="fw-semibold">Step 13. Finish creating your AWS keys</h4>
          <h6 className="m-b20">
            To create your access keys, click on the &quot;other&quot; option at the bottom of the page.
            On the next page, give the keys a descriptive name (this can be anything), then click
            &quot;Create access key&quot;.
          </h6>
          <Image src={CreateAccessKey} alt="search for AWS S3" />
        </section>
        <section style={{ marginTop: 40 }}>
          <h4 className="fw-semibold">Step 14. Finish!</h4>
          <h6 className="m-b20">
            The final page contains your AWS access key and secret key. Return to the Shoot sign up page
            and enter these keys to connect your data storage. Please do not share these keys with anyone else!
            If you would like to save a copy, you can click &quot;Download .csv file&quot;.
            {' '}
            <span className="fw-bold">
              After leaving this page,
              you will not be able to access these keys again.
            </span>
          </h6>
          <Image src={DownloadKeys} alt="search for AWS S3" />
        </section>
      </div>
    </FormLayout>
  );
}

export default AWSSetupInstructions;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Chip, Box, InputBase } from '@mui/material';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

const BootstrapInput = styled(InputBase)(() => ({
  flexGrow: 1,
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& input': {
    padding: '0',
  },
}));

export function ChipInput({
  id,
  label,
  className,
  helperText,
  error,
  required,
  fullWidth = true,
  values,
  setValues,
  ...props
}) {
  const [inputValue, setInputValue] = useState('');

  const handleAddValue = (event) => {
    if ((event.key === 'Enter' || event.key === ',') && inputValue.trim()) {
      const newValues = inputValue.split(',').map((value) => value.trim()).filter((value) => value);
      setValues([...values, ...newValues]);
      setInputValue('');
      event.preventDefault();
    }
  };

  const handleDeleteValue = (valueToDelete) => {
    setValues(values.filter((value) => value !== valueToDelete));
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <FormControl sx={{ marginBottom: '16px' }} variant="standard" fullWidth={fullWidth}>
      {label
        && (
          <InputLabel sx={{ fontSize: '18px', color: '#212529' }} shrink htmlFor={id}>
            {label}
            {required && <span style={{ color: 'red', fontSize: '20px', marginLeft: '4px' }}>*</span>}
          </InputLabel>
        )}
      <Box
        sx={{
          'label + &': {
            marginTop: '24px',
          },
          display: 'flex',
          gap: '4px',
          alignItems: 'center',
          flexWrap: 'wrap',
          borderRadius: '4px !important',
          backgroundColor: 'transparent !important',
          border: '1px solid',
          borderColor: 'var(--sds-input-border)',
          fontSize: 16,
          padding: '8px 12px',
          transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          '&:focus-within': {
            boxShadow: 'rgba(83, 165, 81, 0.25) 0 0 0 0.2rem',
            borderColor: '#53A551',
          },
        }}
      >
        {values.map((value, index) => (
          <Chip
            key={index}
            label={value}
            size="small"
            onDelete={() => handleDeleteValue(value)}
          />
        ))}
        <BootstrapInput
          id={id}
          classes={className}
          variant="outlined"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleAddValue}
          autoComplete="off"
        />
      </Box>
      <FormHelperText error={error} style={{ margin: '5px 12px 0' }}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
}

ChipInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  required: PropTypes.bool,
  values: PropTypes.arrayOf(PropTypes.string),
  setValues: PropTypes.func,
  fullWidth: PropTypes.bool,
};

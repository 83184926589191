/* eslint-disable no-nested-ternary */
import React, {} from 'react';
import './CreateOrganization.css';
import { SuperNavbar } from './SuperNabar';
import { SuperSidebar } from '../../components/sidebar/SuperSidebar';
import CreateOrganizationForm from './CreateOrganizationForm';

function CreateOrganization() {
  return (
    <>
      <SuperNavbar />
      <div className="dashboard-container">
        <div className="SuperSidebar-container">
          <SuperSidebar />
        </div>
        <div className="content-area">
          <CreateOrganizationForm />
        </div>
      </div>
    </>
  );
}

export default CreateOrganization;

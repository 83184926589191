/* eslint-disable no-nested-ternary */
import React, {
  useContext, useEffect, useState,
} from 'react';
import { styled } from '@mui/system';
import {
  Card, CardContent, CardHeader, Button,
} from '@mui/material';
import moment from 'moment-timezone';
import { CSVLink } from 'react-csv';
import './reports.css';
import { Navbar, Sidebar } from '../../components';
import ChartBars from '../../components/stackChart/ChartBars';
import DateRangePicker from '../../components/dateRangePicker';
import AuditCard from './AuditCard';
import TimeCard from './TimeCard';
import FilterModal from './FilterModal';
import { UserContext } from '../../contexts/User';
import { NotificationContext } from '../../contexts/Notification';
import { sendRequest } from '../../api/shootAPI';
import { getDatesInRange } from '../../utils/time';
import { generateWebcamReport, transformToGraphAuditData, transformToTableAuditData } from '../../utils/report';
import API from '../../api/endpoints';
import TimerIcon1 from '../../images/timer-2-line-3.png';

const CustomCard = styled(Card)`
    borderRadius: "8px",
    width: 30%,
    border: "1px solid var(--Dark-Dark-06, #E0E1E6)",
    boxShadow: "none"
`;

const headers = [
  {
    label: 'Date',
    key: 'date',
  },
  {
    label: 'Rejected',
    key: 'Rejected',
  },
  {
    label: 'Taken',
    key: 'Taken',
  },
  {
    label: 'Score',
    key: 'Score',
  },
];

const dataKeys = [
  {
    key: 'Audits',
    fill: '#213D69',
  },
  {
    key: 'Violations',
    fill: '#5A81BC',
  },
];

function WebcamAudits() {
  const today = new Date();
  const { displayNotification } = useContext(NotificationContext);
  const { activeWorkspace } = useContext(UserContext);
  const [date, setDate] = useState([new Date(moment(today).subtract(6, 'days')), today]);
  const [members, setMembers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [webcamAudits, setWebcamAudits] = useState([]);
  const [webcamViolations, setWebcamViolations] = useState([]);
  const [teamId, setTeamId] = useState(0);
  const [memberId, setMemberId] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [displayText, setDisplayText] = useState('All');

  const fetchTeams = async () => {
    const [startDate, endDate] = date;
    const dates = getDatesInRange(startDate, endDate);
    const startOfDay = moment(dates[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const endOfDay = moment(dates.at(-1)).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    const { success, data } = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.createTeam(activeWorkspace.id),
      timeout: 30000,
      params: {
        start: startOfDay,
        end: endOfDay,
        searchQuery: '',
      },
    });
    if (success) {
      setTeams(data.teams);
    } else {
      displayNotification({
        message: 'There was an issue fetching team data in this workspace. Please try again',
        severity: 'error',
      });
    }
  };

  const fetchMembers = async () => {
    const { success, data } = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.members(activeWorkspace.id),
      params: {
        page: 1,
        pageSize: 1000,
      },
    });
    if (success) {
      setMembers(data.members);
    } else {
      displayNotification({
        message: 'There was an issue fetching members data in this workspace. Please try again',
        severity: 'error',
      });
    }
  };

  const fetch = async (url, filter) => {
    const [startDate, endDate] = date;
    const dates = getDatesInRange(startDate, endDate);
    const startOfDay = moment(dates[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const endOfDay = moment(dates.at(-1)).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    const { success, data } = await sendRequest({
      method: 'get',
      url,
      params: {
        start: startOfDay,
        end: endOfDay,
      },
    });
    if (success) {
      // eslint-disable-next-line chai-friendly/no-unused-expressions
      filter === 'Audit' ? setWebcamAudits(data) : setWebcamViolations(data);
    } else {
      displayNotification({
        message: 'There was an issue fetching data in this workspace. Please try again',
        severity: 'error',
      });
    }
  };

  const fetchAllData = () => {
    fetch(API.WORKSPACE.workspaceWebcamAudits(activeWorkspace.id), 'Audit');
    fetch(API.WORKSPACE.workspaceWebcamViolations(activeWorkspace.id), 'Violation');
  };

  const fetchData = (filterMemberId) => {
    fetch(API.MEMBER.webcamAudits(filterMemberId));
    fetch(API.MEMBER.webcamViolations(filterMemberId));
  };

  const handleSearch = async (filterTeamId, filterMemberId) => {
    setTeamId(filterTeamId);
    setMemberId(filterMemberId);
    if (filterTeamId === 0 && filterMemberId === 0) {
      setDisplayText('All');
      fetchAllData();
    } else if (filterTeamId !== 0) {
      const filteredTeam = teams.find((team) => team.id === filterTeamId);
      setDisplayText(filteredTeam.name);
      const teamAudits = [];
      const teamViolations = [];
      filteredTeam.teamMates.forEach((mate) => {
        if (mate.member.webcamAudits.length > 0) {
          mate.member.webcamAudits.forEach((item) => { teamAudits.push(item); });
        }
        if (mate.member.webcamViolations.length > 0) {
          mate.member.webcamViolations.forEach((item) => { teamViolations.push(item); });
        }
      });
      setWebcamAudits(teamAudits);
      setWebcamViolations(teamViolations);
    } else {
      const filterMember = members.find((item) => item.id === filterMemberId);
      setDisplayText(`${filterMember.user.firstName} ${filterMember.user.lastName}`);
      fetchData(filterMemberId);
    }
  };

  useEffect(() => {
    if (teamId === 0 && memberId === 0) {
      setDisplayText('All');
      fetchAllData();
    } else if (teamId !== 0) {
      const filteredTeam = teams.find((team) => team.id === teamId);
      setDisplayText(filteredTeam.name);
      const teamAudits = [];
      const teamViolations = [];
      filteredTeam.teamMates.forEach((member) => {
        if (member.webcamAudits.length > 0) {
          member.webcamAudits.forEach((item) => { teamAudits.push(item); });
        }
        if (member.webcamViolations.length > 0) {
          member.webcamViolations.forEach((item) => { teamViolations.push(item); });
        }
      });
      setWebcamAudits(teamAudits);
      setWebcamViolations(teamViolations);
    } else {
      const filterMember = members.find((item) => item.id === memberId);
      setDisplayText(`${filterMember.user.firstName} ${filterMember.user.lastName}`);
      fetchData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    fetchTeams();
    fetchMembers();
    fetchAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const result = generateWebcamReport(webcamAudits, webcamViolations, date[0], date[1]);
    setChartData(transformToGraphAuditData(result));
    setTableData(transformToTableAuditData(webcamAudits, date[0], date[1]));
    setExportData(transformToTableAuditData(webcamAudits, date[0], date[1]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webcamAudits, webcamViolations]);

  return (
    <>
      <Navbar />
      <Sidebar />
      <div className="screen-wrapper">
        <div className="screen-header">
          <div className="screen-header-container d-flex justify-content-between position-relative">
            <div className="w-100 d-flex justify-content-between">
              <h3 className="header-manage-member w-100" style={{ fontWeight: 600 }}>
                Webcam Audit Results:
                &nbsp;
                {displayText}
              </h3>
              <div className="team-engagement-header-actions">
                <DateRangePicker
                  value={date}
                  onChange={(_date) => setDate(_date)}
                />
                <FilterModal
                  handleSearch={handleSearch}
                  members={members}
                  teams={teams}
                />
              </div>
            </div>
          </div>
          <div
            className="cards-container d-flex justify-content-start position-relative"
            style={{ gap: '2%' }}
          >
            <TimeCard
              title="TOTAL NUMBER OF AUDITS"
              value={webcamAudits.length}
              color="#53A551"
              icon={TimerIcon1}
              key={1}
            />
            <TimeCard
              title="TOTAL NUMBER OF VIOLATIONS"
              value={webcamViolations.length}
              color="#53A551"
              icon={TimerIcon1}
              key={2}
            />
          </div>
          <div className="chart-card">
            <CustomCard>
              <CardHeader
                className="hours-card-header"
                title={(
                  <div className="hours-card-header-title d-flex gap-1 align-items-center">
                    METRICS NAME
                  </div>
                )}
              />
              <CardContent
                sx={{ height: '323px' }}
                className="d-flex flex-column justify-content-between"
              >
                <ChartBars data={chartData} dataKeys={dataKeys} />
              </CardContent>
            </CustomCard>
          </div>
          <div className="screen-header-container reports-header d-flex justify-content-between position-relative">
            <div className="w-100 d-flex justify-content-between">
              <h4
                className="header-manage-member w-100"
                style={{ fontWeight: 500, fontSize: 28 }}
              >
                Daily Webcam Audit
              </h4>
              <CSVLink
                data={exportData}
                headers={headers}
                filename="Report.csv"
              >
                <Button className="export-table-btn">Export Table</Button>
              </CSVLink>
            </div>
          </div>
          <div className="webcam-container">
            <div className="webcam-header">
              <p className="webcam-first">Audit Time</p>
              <p className="webcam-second">Rejected</p>
              <p className="webcam-third">Taken</p>
              <p className="webcam-firth">Score</p>
            </div>
            {tableData.length > 0 && tableData.map((data, index) => (
              <AuditCard data={data} key={index} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default WebcamAudits;

import React, {
  useState, useEffect, useContext,
} from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import CircularProgress from '@mui/material/CircularProgress';
import { Button } from '@mui/material';
import { Navbar, Sidebar } from '../../components';
import './teamEngagement.css';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { UserContext } from '../../contexts/User';
import DatePicker from '../../components/datePicker';
import TeamMemberCard from './TeamMemberCard';
import TeamEngagementScoreCard from './TeamEngagementScoreCard';
import TeamActivityCard from './TeamActivityCard';
import { accountTypeLevel } from '../../constants/accountTypes';
import TimezoneSelect from '../../components/selectors/TimezoneSelect';
import ScheduleEmailModal from './ScheduleEmailModal';

function TeamView() {
  const { teamId } = useParams();
  const {
    activeWorkspace, redirect, member, user,
  } = useContext(UserContext);
  const [team, setTeam] = useState({});
  const [date, setDate] = useState(new Date());
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState('Members timezone');

  const fetchTeam = async () => {
    setIsLoading(true);
    const { success, data } = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.getTeam(activeWorkspace.id, teamId),
      timeout: 300000,
      params: {
        selectedTimezone,
        utcStart: moment(date).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      },
    });
    if (success) {
      setTeam(data.team);
      setIsLoading(false);
    }
  };

  const handleSelectDate = (_date) => {
    setDate(_date);
  };

  const handleSelectTimezone = async (timezone) => {
    setSelectedTimezone(timezone);
  };

  useEffect(() => {
    const isWorkspaceAdmin = accountTypeLevel[member.accountType] >= accountTypeLevel['Workspace admin'];
    if (isWorkspaceAdmin) return;
    const isTeamManager = member.teamMates.some((mate) => mate.teamId === parseInt(teamId, 10));
    if (!isTeamManager) redirect('/workspaces');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchTeam();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, selectedTimezone]);

  const timezoneOptions = [
    {
      heading: 'Members timezone',
      description: 'Members timezone',
      value: 'Members timezone',
    },
    {
      heading: 'Your timezone',
      description: user.timezone,
      value: user.timezone,
    },
    {
      heading: 'Workspace timezone',
      description: activeWorkspace.timezone ?? 'Etc/UTC',
      value: activeWorkspace.timezone ?? 'Etc/UTC',
    },
  ];

  return (
    <div>
      {showScheduleModal && (
        <ScheduleEmailModal
          closeModal={() => setShowScheduleModal(false)}
          teamId={teamId}
        />
      )}
      <Navbar />
      <Sidebar />
      <div className="screen-wrapper">
        <div className="screen-header">
          <button
            type="button"
            className="back-button"
            onClick={() => redirect('/members/team-engagement')}
          >
            Back
          </button>
          <div className="screen-header-container d-flex justify-content-between gap-4">
            <h3 className="team-header-manage-member">
              {team.name}
              {' '}
              Engagement
            </h3>
            <div className="team-engagement-header-actions">
              <Button
                sx={{
                  marginLeft: 'auto',
                  font: 'Inter',
                  fontSize: '16px',
                  textTransform: 'none',
                }}
                variant="contained"
                onClick={() => {
                  setShowScheduleModal(true);
                }}
              >
                Schedule Email
              </Button>
              <DatePicker
                style={{ width: 246 }}
                oneTap
                value={date}
                onChange={(_date) => handleSelectDate(_date)}
              />
              <TimezoneSelect
                value={selectedTimezone}
                label="Timezone"
                onChange={(e) => handleSelectTimezone(e.target.value)}
                options={timezoneOptions}
              />
            </div>
          </div>
        </div>

        <div className="screen-body mt-4">
          {
            isLoading && (
            <div className="loader-container">
              <CircularProgress sx={{ color: 'inherit' }} />
            </div>
            )
          }
          { !isLoading && (
          <>
            <div className="row">
              <div className="col-md-6">
                <TeamMemberCard team={team} />
              </div>
              <div className="col-md-6">
                <TeamEngagementScoreCard team={team} />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <TeamActivityCard
                  team={team}
                  timezone={selectedTimezone}
                  curDate={moment(date).startOf('day').format('YYYY-MM-DD HH:mm:ss')}
                />
              </div>
            </div>
          </>
          )}
        </div>
      </div>
    </div>
  );
}

export default TeamView;

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Images } from '../../common';

const WorkspaceWrapper = styled.div`
  width: 100%;
  border: 1px solid var(--dark-6);
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  box-shadow: 0px 1px 2px 0px #00000017;
  overflow: hidden;
`;

const ImageWrapper = styled.div`
  height: 40px;
  width: 48px;
  margin: 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-image: ${(props) => (props.src ? `url(${props.src})` : 'none')};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
`;

const Separator = styled.div`
  border-right: 1px solid var(--dark-6);
  height: 40px;
`;

function WorkspaceCard({
  name, description, image, dataTestid, onClick,
}) {
  const { shootLogo } = Images;
  return (
    <WorkspaceWrapper onClick={onClick} data-testid={dataTestid}>
      <ImageWrapper src={image ?? shootLogo} />
      <Separator />
      <div style={{ marginLeft: 16, padding: '6px 8px 6px 0' }}>
        <span className="text-600" data-testid="name">{name}</span>
        <br />
        <p className="p-large" data-testid="description">{description}</p>
      </div>
    </WorkspaceWrapper>
  );
}

WorkspaceCard.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.string,
  dataTestid: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default WorkspaceCard;

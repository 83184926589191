import React, { useState, useRef, useContext } from 'react';
import Webcam from 'react-webcam';
import { Button, Modal } from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { NotificationContext } from '../../contexts/Notification';

function FaceRegister({ closeModal, setPath }) {
  const webcamRef = useRef(null);
  const [saving, setSaving] = useState(false);
  const { displayNotification } = useContext(NotificationContext);

  const captureImage = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    return imageSrc;
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  };

  const handleRegister = async () => {
    setSaving(true);
    const imageBlob = dataURItoBlob(captureImage());
    const formData = new FormData();
    formData.append('image', imageBlob, 'face.jpg');

    try {
      const response = await sendRequest({
        method: 'post',
        url: API.USER.faceRegister,
        data: formData,
      });
      if (response.success) {
        // const { avatar, userToken } = response.data;
        // setFormData({ ...formData, avatar });
        // setUserAccount(userToken);
        setPath(response.data.faceIdData.image_path);
        displayNotification({
          message: response.data.message,
          severity: 'success',
        });
        closeModal();
      } else {
        displayNotification({
          message: response.data.error,
          severity: 'error',
        });
      }
    } catch (error) {
      displayNotification({
        message: error.response.data.error,
        severity: 'error',
      });
    }

    setSaving(false);
  };

  return (
    <Modal
      open
      onClose={closeModal}
      className="flex-row align-c jstfy-c"
      data-testid="face-id-setup"
    >
      <Box style={{
        width: 384,
        padding: 32,
        background: '#fff',
        borderRadius: 8,
      }}
      >
        <h5 className="m-b20 text-600" data-testid="modal-title">Register Face</h5>
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width={320}
          height={240}
          className="m-b20"
        />
        {/* <button type="button" onClick={handleRegister}>Register Face</button> */}
        <Button
          variant="contained"
          color="success"
          onClick={handleRegister}
          sx={{ marginRight: 2, width: '100px' }}
          disabled={saving}
        >
          { saving ? 'Saving...' : 'Save' }
        </Button>
        <Button
          onClick={closeModal}
          color="secondary"
          variant="outlined"
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
}

FaceRegister.propTypes = {
  closeModal: PropTypes.func.isRequired,
  setPath: PropTypes.func.isRequired,
};

export default FaceRegister;

import _ShootLogo from '../images/shootLogo.png';
import _ShootLoader from '../images/shoot-loader.gif';
import _Step1Users from '../images/step1-users.png';
import _AWSLogo from '../images/awsLogo.png';
import _sortIcon from '../images/sortIcon.svg';
import _datePickerIcon from '../images/datePickerIcon.svg';
import _datePickerIconGreen from '../images/datePickerIconGreen.svg';
import _downloadIcon from '../images/downloadIcon.svg';
import _signInRegisterImage from '../images/Bitmap.svg';
import _leftVectorPage from '../images/left.svg';
import _rightVectorPage from '../images/right.svg';
import _UserSortIcon from '../images/userSortIcon.png';
import _CalenderIcon from '../images/calenderIcon.svg';
import _FullShootLogo from '../images/blue-shoot-logo.png';
import _infoIcon from '../images/infoIcon.svg';
import _checkIcon from '../images/checkIcon.svg';
import _infoBorderIcon from '../images/icon-info.svg';
import _arrowRightIcon from '../images/icon-arrow-right.svg';
import _arrowLeftIcon from '../images/icon-arrow-left.svg';
// import _profilePic from '../images/profilePic.svg';
// import _calendar_today from '../images/calendar_today.svg'
// import _setting from '../images/settings.svg'
// import _QualityHours from '../images/QualityHours.svg'
// import _HoursWorked from '../images/HoursWorked.svg'
// import _Activity from '../images/Activity.svg'
// import _upIcon from '../images/upIcon.svg'

const images = {
  shootLogo: _ShootLogo,
  shootLoader: _ShootLoader,
  step1Users: _Step1Users,
  awsLogo: _AWSLogo,
  sortIcon: _sortIcon,
  datePickerIcon: _datePickerIcon,
  datePickerIconGreen: _datePickerIconGreen,
  downloadIcon: _downloadIcon,
  signInRegisterImage: _signInRegisterImage,
  leftVectorPage: _leftVectorPage,
  rightVectorPage: _rightVectorPage,
  userSortIcon: _UserSortIcon,
  calenderIcon: _CalenderIcon,
  fullShootLogo: _FullShootLogo,
  infoIcon: _infoIcon,
  checkIcon: _checkIcon,
  arrowRightIcon: _arrowRightIcon,
  arrowLeftIcon: _arrowLeftIcon,
  infoBorderIcon: _infoBorderIcon,
  // profilePic: _profilePic,
  // calendar_today: _calendar_today,
  // setting: _setting,
  // QualityHours: _QualityHours,
  // HoursWorked: _HoursWorked,
  // Activity: _Activity,
  // upIcon: _upIcon
};

export default images;

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  Box,
} from '@mui/material';

const Avatar = styled.figure`
  margin: 0;
  padding: 8px 5px;
  border-radius: 50%;
  min-width: 36px;
  min-height: 36px;
  color: white;
  text-align: center;
  ${(props) => props.src && `
    background-size: cover;
    background-image: url(${props.src});
  `}

  table tbody tr:nth-of-type(4n-1) & {
    background-color: #53A55116;
    color: #53A551;
  }
  table tbody tr:nth-of-type(4n-2) & {
    background-color: #3880C216;
    color: #3880C2;
  }
  table tbody tr:nth-of-type(4n-3) & {
    background-color: #F26B1F16;
    color: #F26B1F;
  }
  table tbody tr:nth-of-type(4n) & {
    background-color: #E9338A16;
    color: #E9338A;
  }
`;

const Name = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #293855 !important;
`;

const Email = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #293855 !important;
`;

function MemberNameEmailColumn({
  firstName, lastName, email, profilePicture, component = 'td',
}) {
  return (
    <Box as={component} className="flex-row align-c">
      <Avatar src={profilePicture}>
        {
          !profilePicture && (
            (firstName && lastName && `${firstName?.slice(0, 1)}${lastName?.slice(0, 1)}`)
            ?? `${email?.slice(0, 2).toUpperCase()}`
          )
        }
      </Avatar>
      <span className="pl-4">
        <Name>
          {`${firstName} ${lastName}`}
        </Name>
        {' '}
        <Email>
          {email}
        </Email>
      </span>
    </Box>
  );
}

MemberNameEmailColumn.propTypes = {
  profilePicture: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string.isRequired,
  component: PropTypes.string,
};

export default MemberNameEmailColumn;

import React, { useMemo } from 'react';
import { CardContent, CardHeader, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import Images from '../../common/images';
import CustomCard from './CustomCard';

function TeamEngagementScoreCard({ team }) {
  const averageScore = useMemo(() => {
    const totalScore = team?.teamMates?.reduce((score, mate) => {
      const { totalSeconds = 0, activeSeconds = 0 } = mate.member?.dailyActivity[0] || {};
      return score + Math.round((activeSeconds * 100) / totalSeconds || 0);
    }, 0);
    const teamSize = team.teamMates?.length || 0;
    return teamSize > 0 ? Math.round(totalScore / teamSize) : 0;
  }, [team]);

  return (
    <CustomCard>
      <CardHeader
        className="team-card-header"
        title={(
          <div className="team-card-header-title d-flex gap-1 align-items-center">
            Overall Engagement Score
            <Tooltip
              arrow
              placement="right"
              title="A measure of collective involvement and enthusiasm,
                reflecting the team's commitment and productivity.
                Higher scores signify stronger engagement"
            >
              <img src={Images.infoBorderIcon} alt="info" />
            </Tooltip>
          </div>
        )}
      />
      <CardContent sx={{ height: '164px' }} className="pb-2 d-flex flex-column justify-content-between">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <div className="average-activity-value">
              {averageScore}
              %
            </div>
            <div className="average-activity-text mt-1">
              Average
            </div>
          </div>
          <div>
            <div className="d-flex align-items-center justify-content-end">
              Deep Focus (51% - 100%)
              <div className="ml-1 color-circle color-deep" />
            </div>
            <div className="d-flex align-items-center justify-content-end">
              Focused (21% - 50%)
              <div className="ml-1 color-circle color-normal" />
            </div>
            <div className="d-flex align-items-center justify-content-end">
              Low (1% - 20%)
              <div className="ml-1 color-circle color-low" />
            </div>
            <div className="d-flex align-items-center justify-content-end">
              Work Break
              <div className="ml-1 color-circle color-work-break" />
            </div>
          </div>
        </div>
        <div className="d-flex">
          <div className="mr-1 progress-label">0%</div>
          <div className="flex-grow-1 position-relative">
            <div className="progress-value mb-1" style={{ marginLeft: `${averageScore}%` }}>
              {`${averageScore}%`}
            </div>
            <div className="engagement-progress-bar">
              <Tooltip
                placement="top"
                title="Organization average range: 45% - 55%"
                componentsProps={{
                  tooltip: {
                    sx: {
                      width: '150px',
                      textAlign: 'center',
                    },
                  },
                }}
              >
                <div className="middle-area" />
              </Tooltip>
              <Tooltip
                placement="top"
                title={`Team average: ${averageScore}%`}
              >
                <div
                  className="value-bar"
                  style={{ left: `${averageScore - 2}%` }}
                >
                  <div className="value-stick" />
                </div>
              </Tooltip>
            </div>
            <div className="progress-arrow" style={{ marginLeft: `${averageScore}%` }} />
          </div>
          <div className="ml-1 progress-label">100%</div>
        </div>
      </CardContent>
    </CustomCard>
  );
}

TeamEngagementScoreCard.propTypes = {
  team: PropTypes.shape({
    teamMates: PropTypes.arrayOf(
      PropTypes.shape({
        memberId: PropTypes.number.isRequired,
        member: PropTypes.shape({
          user: PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
            profilePicture: PropTypes.string.isRequired,
          }),
          dailyActivity: PropTypes.arrayOf(
            PropTypes.shape({
              totalSeconds: PropTypes.number,
              activeSeconds: PropTypes.number,
            }),
          ),
        }),
      }),
    ),
  }),
};

export default TeamEngagementScoreCard;

import React from 'react';
import PropTypes from 'prop-types';
import { AppBar } from '@mui/material';
import { Images } from '../common';

function FormLayout({
  heading, subheading, children, style,
}) {
  const { shootLogo } = Images;

  return (
    <form className="organizationSignup-form" style={style}>
      <AppBar
        position="static"
        sx={{ boxShadow: 'none' }}
        color="inherit"
        className="navbar-center"
      >
        <div className="flex-row align-c">
          <img
            src={shootLogo}
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt="shoot-logo"
          />
          <h4 className="navitem-brand font-weight-bold text-700 m-l8" style={{ color: '#163E6C' }}>Shoot</h4>
        </div>

      </AppBar>
      <div style={{ width: 420 }}>
        <h3 className="heading">{heading}</h3>
        <p className="subheading" style={{ marginBottom: 34 }}>{subheading}</p>
      </div>
      {children}
    </form>
  );
}

FormLayout.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  style: PropTypes.shape({

  }),
};

export default FormLayout;

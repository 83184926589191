import React, { useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Button, Box, Grid, MenuItem, FormControl, FormControlLabel, Checkbox, InputLabel, Select,
} from '@mui/material';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { UserContext } from '../../contexts/User';
import { BootstrapInput, TextInput } from '../../components/muiCustomized/TextInput';
import { ChipSelect } from '../../components/muiCustomized/ChipSelect';
import SelectInput from '../../components/muiCustomized/CustomMenuSelect';
import {
  AccrualSchedule,
  ApprovalRequiredItem,
  BalanceRolloverItem,
  NegativeBalanceItem,
  PaidItem,
} from '../../constants/offTimePolicy';

const FORM_DEFAULT = {
  name: '',
  teams: [],
  addTeams: true,
  members: [],
  addMembers: true,
  scheduleOfAccrual: '',
  hoursAccrued: 1,
  hoursWorked: 24,
  maxAccrualAmount: 0,
  allowNegativeBalance: '',
  approvalRequired: '',
  balanceRollover: '',
  paid: '',
};

function TimeOffPolicyModal({
  closeModal, getPolicies, teams, members, policy = null,
}) {
  const { activeWorkspace } = useContext(UserContext);
  const [formData, setFormData] = useState(policy ? {
    ...policy,
    teams: teams.filter((team) => policy.teams.findIndex((val) => val.id === team.id) >= 0),
    members: members.filter((member) => policy.members.findIndex((val) => val.id === member.id) >= 0),
  } : FORM_DEFAULT);
  const [formErrors, setFormErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState();
  const [saving, setSaving] = useState(false);
  const disableSaveBtn = useMemo(() => (
    !(formData.name
    && formData.scheduleOfAccrual
    && (typeof formData.allowNegativeBalance === 'boolean')
    && (typeof formData.approvalRequired === 'boolean')
    && (typeof formData.balanceRollover === 'boolean')
    && (typeof formData.paid === 'boolean')) || saving
  ), [formData, saving]);
  const buttonText = policy ? 'Save Changes' : 'Save Policy';

  const handleForm = (e) => {
    if (e.target.type === 'checkbox') {
      setFormData({ ...formData, [e.target.name]: e.target.checked });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleAddOffTimePolicy = async () => {
    setSaving(true);
    setFormErrors({});
    const response = await sendRequest({
      method: 'post',
      url: API.WORKSPACE.createTimeOffPolicy(activeWorkspace.id),
      data: formData,
    });
    if (response.success) {
      setSuccessMessage('Created new time off policy.');
      setFormData(FORM_DEFAULT);
      getPolicies();
    } else {
      setFormErrors(response.data);
    }
    setSaving(false);
  };

  const handleUpdateOffTimePolicy = async () => {
    setSaving(true);
    setFormErrors({});
    const response = await sendRequest({
      method: 'put',
      url: API.WORKSPACE.updateOffTimePolicy(activeWorkspace.id, policy.id),
      data: formData,
    });
    if (response.success) {
      setSuccessMessage('Successfully updated time off policy.');
      getPolicies();
      closeModal();
    } else {
      setFormErrors(response.data);
    }
    setSaving(false);
  };

  const handleSelectAllTeams = () => {
    setFormData({ ...formData, teams });
  };

  const handleSelectAllMembers = () => {
    setFormData({ ...formData, members });
  };

  return (
    <Modal
      open
      onClose={closeModal}
      className="flex-row align-c jstfy-c"
    >
      <Box
        id="offTimePolicyModal"
        style={{
          width: 588,
          maxHeight: 768,
          overflowY: 'auto',
          padding: 32,
          background: '#fff',
          borderRadius: 8,
        }}
      >
        <h5 className="m-b16 text-600">
          { policy ? 'Edit time off policy' : 'Add time off policy' }
        </h5>
        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>
            <TextInput
              label="Policy Name"
              value={formData.name}
              onChange={handleForm}
              placeholder="Required"
              error={!!formErrors?.name}
              helperText={formErrors?.name}
              background="#f9f9f9"
              name="name"
            />
          </Grid>
          <Grid item xs={12}>
            <ChipSelect
              id="teams"
              label="Add Teams"
              selectedMembers={formData.teams}
              handleChange={handleForm}
              members={teams}
              placeholder="Optional"
              selectAllBtn
              handleSelectAll={handleSelectAllTeams}
            />
            <FormControlLabel
              sx={{ marginTop: '-16px', marginBottom: '16px' }}
              control={(
                <Checkbox
                  name="addTeams"
                  onChange={handleForm}
                  checked={formData.addTeams}
                />
)}
              label="Automatically add new teams to this policy"
            />
          </Grid>
          <Grid item xs={12}>
            <ChipSelect
              id="members"
              label="Add Members"
              selectedMembers={formData.members}
              handleChange={handleForm}
              members={members}
              placeholder="Optional"
              selectAllBtn
              handleSelectAll={handleSelectAllMembers}
            />
            <FormControlLabel
              sx={{ marginTop: '-16px', marginBottom: '16px' }}
              control={(
                <Checkbox
                  name="addMembers"
                  onChange={handleForm}
                  checked={formData.addMembers}
                />
              )}
              label="Automatically add new members to this policy"
            />
          </Grid>
          <Grid item xs={12} className="mb-3">
            <FormControl fullWidth>
              <InputLabel
                sx={{
                  fontSize: '18px',
                  color: '#212529',
                  transform: 'translate(0, -1.5px) scale(0.75)',
                }}
                shrink
                htmlFor="scheduleOfAccrual"
              >
                Schedule of Accrual
              </InputLabel>
              <Select
                id="scheduleOfAccrual"
                name="scheduleOfAccrual"
                value={formData.scheduleOfAccrual}
                label="Schedule of Accrual"
                onChange={handleForm}
                input={(
                  <BootstrapInput
                    sx={{
                      '& .MuiInputBase-input': {
                        border: '1.5px solid',
                        borderColor: 'var(--sds-input-border)',
                      },
                    }}
                    background="#F9F9F9"
                  />
                )}
                renderValue={(selected) => {
                  if (!selected) {
                    return (
                      <InputLabel
                        sx={{
                          fontSize: '16px',
                          color: '#212529',
                          lineHeight: '20px',
                          opacity: '40%',
                          marginBottom: '0px',
                        }}
                      >
                        Required
                      </InputLabel>
                    );
                  }
                  return (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      { AccrualSchedule[selected].text}
                    </Box>
                  );
                }}
                displayEmpty
              >
                {
                    Object.keys(AccrualSchedule).map((key) => (
                      <MenuItem
                        key={`accrualSchedule-${key}`}
                        value={key}
                        className="flex-column align-items-start"
                        sx={{}}
                      >
                        <Box sx={{
                          color: '#061232',
                          fontFamily: 'Inter',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: 500,
                          lineHeight: '20px',
                        }}
                        >
                          {AccrualSchedule[key].text}
                        </Box>
                        <Box sx={{
                          color: 'var(--dark-3, #546077)',
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          lineHeight: '18px',
                          textWrap: 'wrap',
                          maxWidth: '492px',
                        }}
                        >
                          {AccrualSchedule[key].description}
                        </Box>
                      </MenuItem>
                    ))
                }
              </Select>
            </FormControl>
          </Grid>
          {formData.scheduleOfAccrual === 'worked_hours'
          && (
          <Grid item xs={12}>
            <InputLabel
              sx={{
                fontSize: '18px',
                color: '#212529',
                transform: 'translate(0, -1.5px) scale(0.75)',
              }}
              shrink
              htmlFor="scheduleOfAccrual"
            >
              Amount Accrued
            </InputLabel>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '-10px',
                gap: '8px',
                color: 'var(--dark-3, #546077)',
                textAlign: 'right',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
              }}
            >
              <TextInput
                value={formData.hoursAccrued}
                onChange={handleForm}
                placeholder="Required"
                error={!!formErrors?.hoursAccrued}
                helperText={formErrors?.hoursAccrued}
                background="#f9f9f9"
                name="hoursAccrued"
                type="number"
                sx={{
                  maxWidth: '85px',
                }}
                fullWidth={false}
              />
              <Box sx={{ marginBottom: '21px' }}>
                hour(s) accrued for every
              </Box>
              <TextInput
                value={formData.hoursWorked}
                onChange={handleForm}
                placeholder="Required"
                error={!!formErrors?.hoursWorked}
                helperText={formErrors?.hoursWorked}
                background="#f9f9f9"
                name="hoursWorked"
                type="number"
                sx={{
                  maxWidth: '85px',
                }}
                fullWidth={false}
              />
              <Box sx={{ marginBottom: '21px' }}>
                hours worked
              </Box>
            </Box>
          </Grid>
          )}
          {formData.scheduleOfAccrual && formData.scheduleOfAccrual !== 'none'
          && (
          <Grid item xs={12}>
            <TextInput
              label="Maximum Accrual Amount (Hours Per Year)"
              value={formData.maxAccrualAmount}
              onChange={handleForm}
              placeholder="Required"
              error={!!formErrors?.maxAccrualAmount}
              helperText={formErrors?.maxAccrualAmount}
              background="#f9f9f9"
              name="maxAccrualAmount"
            />
          </Grid>
          )}
          <Grid item xs={6} className="mb-3">
            <SelectInput
              id="allowNegativeBalance"
              label="Allow Negative Balances"
              menuItems={NegativeBalanceItem}
              menuWidth="202px"
              value={formData.allowNegativeBalance}
              handleForm={handleForm}
            />
          </Grid>
          <Grid item xs={6} className="mb-3">
            <SelectInput
              id="approvalRequired"
              label="Requires Approval"
              menuItems={ApprovalRequiredItem}
              menuWidth="202px"
              value={formData.approvalRequired}
              handleForm={handleForm}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              id="balanceRollover"
              label="Balance Rolls Over Annually"
              menuItems={BalanceRolloverItem}
              menuWidth="202px"
              value={formData.balanceRollover}
              handleForm={handleForm}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              id="paid"
              label="Paid or Unpaid"
              menuItems={PaidItem}
              menuWidth="202px"
              value={formData.paid}
              handleForm={handleForm}
            />
          </Grid>
        </Grid>
        <div className="newMemberForm-btns" style={{ marginTop: 24 }}>
          <Button
            onClick={closeModal}
            color="secondary"
            sx={{ marginRight: 2 }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={policy ? handleUpdateOffTimePolicy : handleAddOffTimePolicy}
            disabled={disableSaveBtn}
          >
            { saving ? 'Saving...' : buttonText }
          </Button>
        </div>
        <div className="success-msg">{successMessage}</div>
      </Box>
    </Modal>
  );
}

TimeOffPolicyModal.propTypes = {
  policy: PropTypes.shape({
    id: PropTypes.number.isRequired,
    teams: PropTypes.arrayOf(PropTypes.shape({
    })),
    members: PropTypes.arrayOf(PropTypes.shape({
    })),
  }),
  closeModal: PropTypes.func.isRequired,
  getPolicies: PropTypes.func.isRequired,
  teams: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })),
  members: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })),
};

export default TimeOffPolicyModal;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-fallthrough */
/* eslint-disable max-len */
import React, {
  useState, useEffect, useContext, useMemo,
  useCallback,
} from 'react';
import {
  Box, Button,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import NavigationLayout from '../../components/NavigationLayout';
import CustomTable from '../../components/table/CustomTable';
import DropDown from '../../components/dropdown';
import theme from './theme';
import TabsWithPanels from '../../components/tabsWithPanel';
import RequestModal from './RequestModal';
import RequestEditModal from './RequestEditModal';
import RequestViewModal from './RequestViewModal';
import RequestDeleteModal from '../../components/modals/RequestDeleteModal';
import { UserContext } from '../../contexts/User';
import { accountTypeLevel } from '../../constants/accountTypes';
import useQueryParams from '../../hooks/useQueryParams';
import { Description } from '../../components/styledComponents';

const FIXED_TABLE_HEADERS = [
  {
    label: 'Policy Name',
    sortable: true,
  },
  {
    label: 'Reason',
  },
  {
    label: 'Start',
  },
  {
    label: 'End',
  },
  {
    label: 'Hours Requested',
    sortable: true,
  },
  {
    label: 'Status',
    sortable: true,
  },
  {
    label: 'Requested On',
  },
  {
    label: 'Action',
  },
];

function PanelAction({ handleClick, disabled = false }) {
  return (
    <Box alignSelf="center" marginLeft="auto" py="8px">
      <Button
        disabled={disabled}
        variant="contained"
        color="secondary"
        onClick={() => handleClick()}
        sx={{
          backgroundColor: '#53A551', color: 'white', textTransform: 'unset',
        }}
      >
        Request Time Off
      </Button>
    </Box>
  );
}

function PtoRequest() {
  const {
    member, accessToken, redirect, updateTokens,
  } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [policies, setPolicies] = useState([]);
  const [balances, setBalances] = useState([]);
  const [requests, setRequests] = useState([]);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const getData = async () => {
    try {
      setLoading(true);
      const response = await sendRequest({
        method: 'get', url: API.WORKSPACE.getOffTimeData(member.workspaceId, member.id),
      });
      if (response.data) {
        setPolicies(response.data.ptoPolicies);
        setBalances(response.data.ptoBalances);
        setRequests(response.data.ptoRequests);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableData = useMemo(() => {
    const allData = [];
    const submittedData = [];
    const approvedData = [];
    const deniedData = [];
    const dateOptions = {
      weekday: 'short',
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    };
    requests?.forEach((request) => {
      const policy = policies?.find((policy) => policy.id === request.policyId);
      const startDate = new Date(request.startDate);
      const endDate = new Date(request.endDate);
      const createDate = new Date(request.createdAt);
      const hours = Math.floor(request.totalHours / 60) < 10 ? `0${Math.floor(request.totalHours / 60)}` : Math.floor(request.totalHours / 60);
      const minutes = request.totalHours % 60 < 10 ? `0${request.totalHours % 60}` : request.totalHours % 60;
      let options = [];
      switch (request.status) {
        case 'Submitted':
          options = [
            {
              text: 'View Request',
              handleClick: () => {
                setSelectedRequest(request);
                setShowViewModal(true);
              },
            },
            {
              text: 'Edit Request',
              handleClick: () => {
                setSelectedRequest(request);
                setShowEditModal(true);
              },
            },
            {
              text: 'Delete Request',
              handleClick: () => {
                setSelectedRequest(request);
                setShowDeleteModal(true);
              },
            },
          ];
          break;
        case 'Approved':
          options = [
            {
              text: 'View Request',
              handleClick: () => {
                setSelectedRequest(request);
                setShowViewModal(true);
              },
            },
          ];
          break;
        default:
          options = [
            {
              text: 'View Request',
              handleClick: () => {
                setSelectedRequest(request);
                setShowViewModal(true);
              },
            },
            {
              text: 'Delete Request',
              handleClick: () => {
                setSelectedRequest(request);
                setShowDeleteModal(true);
              },
            },
            {
              text: 'Resubmit Request',
              handleClick: () => {
                setSelectedRequest(request);
                setShowEditModal(true);
              },
            },
          ];
      }
      const datum = {
        policyName: policy ? policy.name : '',
        reason: request.reason,
        start: startDate.toLocaleDateString('en-US', dateOptions),
        end: endDate.toLocaleDateString('en-US', dateOptions),
        hours: `${hours}:${minutes}`,
        status: request.status,
        requestOn: createDate.toLocaleDateString('en-US', dateOptions),
        action: (<DropDown title="Actions" options={options} />),
      };
      allData.push(datum);
      switch (request.status) {
        case 'Submitted':
          submittedData.push(datum);
          break;
        case 'Approved':
          approvedData.push(datum);
          break;
        default:
          deniedData.push(datum);
      }
    });
    return {
      allData,
      submittedData,
      approvedData,
      deniedData,
    };
  }, [requests, policies]);

  const TABS = [
    { label: 'Submitted', length: tableData.submittedData.length },
    { label: 'Approved', length: tableData.approvedData.length },
    { label: 'Denied', length: tableData.deniedData.length },
    { label: 'All', length: tableData.allData.length },
  ];

  const tabPanels = TABS?.map((tab) => {
    switch (tab.label) {
      case 'Submitted':
        return <CustomTable headers={FIXED_TABLE_HEADERS} items={tableData.submittedData} loading={loading} />;
      case 'Approved':
        return <CustomTable headers={FIXED_TABLE_HEADERS} items={tableData.approvedData} loading={loading} />;
      case 'Denied':
        return <CustomTable headers={FIXED_TABLE_HEADERS} items={tableData.deniedData} loading={loading} />;
      default:
        return <CustomTable headers={FIXED_TABLE_HEADERS} items={tableData.allData} loading={loading} />;
    }
  });
  const title = accountTypeLevel[member?.accountType] >= accountTypeLevel['Workspace admin']
    ? 'Personal Time Off Request' : 'Time Off Request';
  const queryParams = useQueryParams();
  const queryAccessToken = queryParams.get('accessToken');

  useEffect(() => {
    if (!accessToken && !queryAccessToken) {
      redirect('/workspaces');
    }
    if (queryAccessToken) {
      updateTokens({ accessToken: queryAccessToken });
    }
  }, []);

  useEffect(() => {
    if (accessToken && queryAccessToken) {
      redirect('/pto/requests');
    }
  }, [accessToken, queryAccessToken, redirect]);

  const handleCloseModal = (refresh = true) => {
    setShowEditModal(false);
    setShowViewModal(false);
    setShowDeleteModal(false);
    setShowRequestModal(false);
    if (refresh) {
      getData();
    }
  };

  const dateOptions = {
    weekday: 'short',
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  };

  const handleDeleteModal = useCallback(async () => {
    const { success } = await sendRequest({
      method: 'delete',
      url: API.WORKSPACE.deleteOffTimeRequest(member.workspaceId, selectedRequest.id),
    });
    setShowDeleteModal(false);
    if (success) {
      getData();
      return true;
    }
    return false;
  }, [member.workspaceId, selectedRequest]);

  return (
    accessToken && member && (
    <ThemeProvider theme={theme}>
      <NavigationLayout title={title}>
        <TabsWithPanels tabItems={TABS} tabPanels={tabPanels} PanelAction={<PanelAction handleClick={() => setShowRequestModal(true)} disabled={loading} />} />
      </NavigationLayout>
      {showRequestModal && (
      <RequestModal
        closeModal={handleCloseModal}
        getData={getData}
        balances={balances}
        requests={requests}
        policies={policies}
        setLoading={setLoading}
      />
      )}
      {showEditModal && (
      <RequestEditModal
        closeModal={handleCloseModal}
        balances={balances}
        requests={requests}
        policies={policies}
        selectedRequest={selectedRequest}
        setLoading={setLoading}
      />
      )}
      {showViewModal && (
      <RequestViewModal
        closeModal={handleCloseModal}
        balances={balances}
        policies={policies}
        selectedRequest={selectedRequest}
      />
      )}
      {showDeleteModal && (
      <RequestDeleteModal
        closeModal={() => setShowDeleteModal(false)}
        handleDelete={handleDeleteModal}
        successMessage="Request has been deleted successfully."
        errorMessage="There was an issue deleting the request."
      >
        <>
          <Description
            fontSize={20}
            fontWeight={600}
            color="#0E1E40"
            marginTop={16}
          >
            Are you sure to delete this request?
          </Description>
          <Description
            fontSize={14}
            fontWeight={400}
            color="#293855"
            marginTop={6}
          >
            You’re about to delete the “
            <span style={{ fontWeight: 600 }}>
              { new Date(selectedRequest?.startDate).toLocaleDateString('en-US', dateOptions) }
            </span>
            <span style={{ margin: '0px 4px' }}>to</span>
            <span style={{ fontWeight: 600 }}>
              { new Date(selectedRequest?.endDate).toLocaleDateString('en-US', dateOptions) }
            </span>
            ” requested PTO and you have to apply again if needed.
          </Description>
        </>

      </RequestDeleteModal>
      )}
    </ThemeProvider>
    )
  );
}

PanelAction.propTypes = {
  handleClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default PtoRequest;

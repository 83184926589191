/* eslint-disable max-len */
import React from 'react';
import {
  Dialog, DialogContent, DialogActions,
  Button, TextField, IconButton, InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import SuperAdminDelete from '../../images/SuperAdminDelete.png';

function DeleteConfirmationModal({
  // eslint-disable-next-line react/prop-types
  open, handleClose, label, type, handleDelete,
}) {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <Dialog open={open} onClose={handleClose}>
      <img src={SuperAdminDelete} alt="info icon" style={{ width: '10%', marginLeft: '25px', marginTop: '30px' }} />
      <p style={{
        fontWeight: 'bold',
        fontSize: '20px',
        marginLeft: '25px',
        marginTop: '18px',
      }}
      >
        Are you sure you want to delete this
        {' '}
        {type}
        ?
      </p>
      <DialogContent>
        <p style={{ marginBottom: '15px', fontSize: '16px' }}>
          You’re about to delete the “
          <spot style={{ fontWeight: 'bold' }}>{label}</spot>
          ”
          {' '}
          {type}
          {' '}
          and this action cannot be undone. Please enter the password to proceed.
        </p>
        <TextField
          required
          label="Required"
          type={showPassword ? 'text' : 'password'}
          variant="outlined"
          fullWidth
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '12px',
              backgroundColor: '#f5f5f5',
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <DialogActions sx={{ marginBottom: '45px' }}>
        <Button
          sx={{
            position: 'absolute',
            left: '4%',
            border: 'solid 1px lightgray',
            color: 'slategray',
          }}
          onClick={handleClose}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          sx={{
            position: 'absolute',
            left: '20%',
          }}
          onClick={handleDelete}
          variant="contained"
          color="error"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteConfirmationModal;

import React, { useState, useMemo, useContext } from 'react';
import { useParams } from 'react-router-dom';
import {
  CardContent, CardHeader, Tooltip, Button,
} from '@mui/material';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { UserContext } from '../../contexts/User';
import Images from '../../common/images';
import CustomCard from './CustomCard';
import Avatar from '../../components/avatar';
import ActivityBar from '../../components/activityBar';
import HomeWork from '../../components/icons/HomeWork';
import AccessTime from '../../components/icons/AccessTime';
import CameraAt from '../../components/icons/CameraAt';
import clockImg from '../../images/clock.svg';
import qualityImg from '../../images/quality.svg';
import scoreImg from '../../images/score.svg';
import { convertSecToTimeFormat } from '../../utils/time';

const ActivityCard = styled.div`
    display: flex;
    padding: 12px 16px 40px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    border-radius: 8px;
    border: ${(props) => `1px solid ${props.isManager ? 'var(--blue-5, #769BD3)' : 'var(--dark-7, #ECECF0)'}`};
    background: ${(props) => (props.isManager
    ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), #D8E6FC'
    : '#FFF')};
    box-shadow: ${(props) => (props.isManager
    ? '0px 0px 8px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.04)'
    : '0px 0px 8px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.04)')};
`;

function TeamActivityCard({ team, timezone, curDate }) {
  const { teamId } = useParams();
  const { redirect } = useContext(UserContext);
  const [searchStr, setSearchStr] = useState('');
  const searchRes = useMemo(() => (
    team.teamMates?.filter((mate) => {
      const userName = `${mate.member?.user.firstName} ${mate.member?.user.lastName}`;
      return userName.toLowerCase().includes(searchStr.toLowerCase());
    })
  ), [searchStr, team.teamMates]);

  const handleSearch = (event) => {
    setSearchStr(event.target.value);
  };

  return (
    <CustomCard>
      <CardHeader
        className="team-card-header"
        title={(
          <div className="team-card-header-title d-flex gap-1 align-items-center">
            Team Members Activity
            <Tooltip
              arrow
              placement="right"
              title="Track the participation and contributions of team members to assess their
                involvement and productivity in various projects."
            >
              <img src={Images.infoBorderIcon} alt="info" />
            </Tooltip>
          </div>
        )}
        action={(
          <div className="searchBox">
            <input
              type="text"
              placeholder="Search Team Members"
              className="team-engagement-search-input"
              onChange={handleSearch}
            />
            <i className="fa fa-search searchIcon team-engagement-search-icon" />
          </div>
        )}
      />
      <CardContent className="pb-3 d-flex flex-column justify-content-between" style={{ gap: '10px' }}>
        {
          searchRes?.map((mate) => {
            const userName = mate.member?.user.firstName
              && mate.member?.user.lastName
              ? `${mate.member?.user.firstName} ${mate.member?.user.lastName}`
              : mate.member?.user.email;
            const { totalSeconds = 0, activeSeconds = 0 } = mate.member?.dailyActivity[0] || {};
            const totalActivityPercentage = Math.round((activeSeconds * 100) / totalSeconds || 0);
            const backgroundColor = totalActivityPercentage >= 75 ? '#53A55116' : '#F5C0B025';
            const color = totalActivityPercentage >= 75 ? 'rgba(83, 165, 81, 1)' : 'rgba(204, 69, 37, 1)';
            // eslint-disable-next-line no-nested-ternary
            const activityColor = totalActivityPercentage >= 51
              ? 'green'
              : totalActivityPercentage > 21
                ? 'yellow'
                : 'red';
            return (
              <ActivityCard key={mate.memberId} isManager={mate.role === 'Manager'}>
                <div className="d-flex justify-content-between size-xs width-max">
                  <div className="d-flex align-items-center">
                    <Avatar
                      src={mate.member?.user.profilePicture}
                      name={(mate.member && mate.member.user.firstName ? mate.member.user.firstName[0] : 'N')
                        + (mate.member && mate.member.user.lastName ? mate.member.user.lastName[0] : 'A')}
                      width={32}
                      height={32}
                      backgroundColor={backgroundColor}
                      color={color}
                    />
                    <div className="ml-2 mr-3">
                      {`${userName} ${mate.role === 'Manager' ? ' • Leader' : ''}`}
                    </div>
                    <div className="card-col-img">
                      <HomeWork color={mate.member?.locationViolations.length > 0 ? 'red' : 'green'} />
                      <AccessTime color={activityColor} />
                      <CameraAt color={mate.member?.webcamViolations.length > 0 ? 'red' : 'green'} />
                    </div>
                  </div>
                  <div className="d-flex  gap-4 align-items-center">
                    <div className="d-flex align-items-center">
                      <img src={clockImg} alt="total hours" className="mr-1" />
                      <div>
                        {`Total Hours: ${convertSecToTimeFormat(totalSeconds)}`}
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <img src={qualityImg} alt="quality hours" className="mr-1" />
                      <div>
                        {`Quality Hours: ${convertSecToTimeFormat(activeSeconds)}`}
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <img src={scoreImg} alt="score" className="mr-1" />
                      <div>{`Engagement Score: ${totalActivityPercentage ?? 0}%`}</div>
                    </div>
                    <Button
                      className="export-table-btn"
                      onClick={() => { redirect(`/members/team-engagement/${teamId}/member/${mate.memberId}`); }}
                    >
                      View Report
                    </Button>
                  </div>
                </div>
                <div className="width-max">
                  <ActivityBar
                    snapshots={mate.member?.activity}
                    curDate={curDate}
                    timezone={timezone !== 'Members timezone' ? timezone : mate.member?.user.timezone}
                  />
                </div>
              </ActivityCard>
            );
          })
        }
      </CardContent>
    </CustomCard>
  );
}

TeamActivityCard.propTypes = {
  team: PropTypes.shape({
    teamMates: PropTypes.arrayOf(
      PropTypes.shape({
        memberId: PropTypes.number.isRequired,
        member: PropTypes.shape({
          user: PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
            profilePicture: PropTypes.string.isRequired,
          }),
          activity: PropTypes.arrayOf(
            PropTypes.shape({}),
          ),
          dailyActivity: PropTypes.arrayOf(
            PropTypes.shape({
              totalSeconds: PropTypes.number,
              activeSeconds: PropTypes.number,
            }),
          ),
          locationViolations: PropTypes.arrayOf(
            PropTypes.shape({}),
          ),
          webcamViolations: PropTypes.arrayOf(
            PropTypes.shape({}),
          ),
        }),
      }),
    ),
  }),
  timezone: PropTypes.string.isRequired,
  curDate: PropTypes.string.isRequired,
};

export default TeamActivityCard;

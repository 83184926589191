import React from 'react';
import ReactDOM from 'react-dom/client';
// The import order of CSS files is very important. Bootstrap must be first
// so it can be overrided by our css files
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SDSProvider, materialTheme } from 'shoot-design-system';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import App from './App';

const emoCache = createCache({
  key: 'emo-cache-key',
  prepend: true,
});

ReactDOM
  .createRoot(document.getElementById('root'))
  .render(
    <BrowserRouter>
      <SDSProvider>
        <CacheProvider value={emoCache}>
          <ThemeProvider theme={createTheme(materialTheme())}>
            <App />
          </ThemeProvider>
        </CacheProvider>
      </SDSProvider>
    </BrowserRouter>,
  );

import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';

function CameraAt({ color = 'green' }) {
  // eslint-disable-next-line no-nested-ternary
  const color1 = color === 'green' ? '#53A551' : color === 'yellow' ? '#F19E41' : '#CC4525';
  // eslint-disable-next-line no-nested-ternary
  const color2 = color === 'green' ? '#A6EAA5' : color === 'yellow' ? '#FBE48E' : '#F5C0B0';
  const title = color === 'green' ? 'Audit - No Violation' : 'Audit - With Violation';

  return (
    <Tooltip title={title}>
      <svg
        width="32"
        height="32"
        style={{ cursor: 'pointer' }}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="16" cy="16" r="15.5" fill={color1} />
        <circle cx="16" cy="16" r="15.5" fill="white" fillOpacity="0.9" />
        <circle cx="16" cy="16" r="15.5" stroke={color2} />
        <g clipPath="url(#clip0_7541_11578)">
          <path
            d={`M22.6665 9.33268H20.0248L18.4998 7.66602H13.4998L11.9748 9.33268H9.33317C8.4165
            9.33268 7.6665 10.0827 7.6665 10.9993V20.9993C7.6665 21.916 8.4165 22.666 9.33317
            22.666H22.6665C23.5832 22.666 24.3332 21.916 24.3332 20.9993V10.9993C24.3332 10.0827
            23.5832 9.33268 22.6665 9.33268ZM22.6665 20.9993H9.33317V10.9993H12.7082L14.2332
            9.33268H17.7665L19.2915 10.9993H22.6665V20.9993ZM15.9998 11.8327C13.6998 11.8327
            11.8332 13.6993 11.8332 15.9993C11.8332 18.2993 13.6998 20.166 15.9998 20.166C18.2998
            20.166 20.1665 18.2993 20.1665 15.9993C20.1665 13.6993 18.2998 11.8327 15.9998
            11.8327ZM15.9998 18.4993C14.6248 18.4993 13.4998 17.3743 13.4998 15.9993C13.4998
            14.6243 14.6248 13.4993 15.9998 13.4993C17.3748 13.4993 18.4998 14.6243 18.4998
            15.9993C18.4998 17.3743 17.3748 18.4993 15.9998 18.4993Z`}
            fill={color1}
          />
        </g>
        <defs>
          <clipPath id="clip0_7541_11578">
            <rect width="20" height="20" fill="white" transform="translate(6 6)" />
          </clipPath>
        </defs>
      </svg>
    </Tooltip>
  );
}

CameraAt.propTypes = {
  color: PropTypes.oneOf(['green', 'yellow', 'red']),
};

export default CameraAt;

/* eslint-disable no-nested-ternary */
import React, {
  useState, useEffect, useCallback, useContext,
} from 'react';
// import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import UnfoldMore from '@mui/icons-material/UnfoldMore';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { UserContext } from '../../contexts/User';
import API from '../../api/endpoints';
import { sendRequest } from '../../api/shootAPI';
import { screenshotsPerPeriodMapping, blurScreenshotsMapping } from '../../constants/screenshotsPerPeriod';
import { Navbar, Sidebar } from '../../components';
import Toggle from '../../components/Toggle';
import { NotificationContext } from '../../contexts/Notification';
import useDebounce from '../../hooks/useDebounce';
import CustomTable from '../../components/table/CustomTable';
import MemberNameEmailColumn from '../../components/table/MemberNameEmailColumn';
import Pagination from '../../components/pagination';

const TABLE_HEADERS = [
  {
    label: 'User',
    sortable: true,
    sortIcon: UnfoldMore,
    sortKey: 'name',
    colSpan: 1.25,
  },
  {
    label: 'Screenshot Frequency',
    colSpan: 1.5,
  },
  {
    label: 'Blur Screenshots',
    colSpan: 0.5,
  },
  {
    label: '',
    colSpan: 0.5,
  },
];

const CustomTableStyles = {
  container: {
    marginTop: 1,
  },
  header: {
    backgroundColor: 'var(--dark-10)',
    fontSize: '16px',
    '.MuiTableSortLabel-iconDirectionAsc': {
      transform: 'rotate(0deg) !important',
    },
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff !important',
    },
    '&:hover': {
      backgroundColor: 'var(--dark-10) !important',
    },
  },
  cell: {
    button: {
      textWrap: 'nowrap',
      color: 'var(--dark-2)',
      borderColor: 'var(--dark-4) !important',
    },
  },
};

function ScreenshotFrequency() {
  const { search } = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(search);
  const page = query.get('page');
  const { displayNotification } = useContext(NotificationContext);
  const { activeWorkspace, setActiveWorkspace } = useContext(UserContext);
  const [members, setMembers] = useState([]);
  const [currentPage, setCurrentPage] = useState(page ? parseInt(page, 10) : 1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCounts, setTotalCounts] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [sort, setSort] = useState(false);
  const [sortKey, setSortKey] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const { debounce } = useDebounce();

  const getMembers = useCallback(async (params) => {
    if (activeWorkspace) {
      setIsLoading(true);
      const response = await sendRequest({
        method: 'get',
        url: API.WORKSPACE.members(activeWorkspace.id),
        params,
      });
      if (response.success) {
        const changeScreenshotsPerPeriod = async (newValue, memberId) => {
          const response = await sendRequest({
            method: 'put',
            url: API.WORKSPACE.memberUpdateScreenshotsPerPeriod(activeWorkspace.id, memberId),
            data: { screenshotsPerPeriod: newValue },
          });
          if (!response.success) {
            displayNotification({
              message: 'There was an issue updating screenshot preferences. Please try again',
              severity: 'error',
            });
          }
        };

        const changeBlurScreenshots = async (newValue, memberId) => {
          const response = await sendRequest({
            method: 'put',
            url: API.WORKSPACE.memberUpdateBlurScreenshots(activeWorkspace.id, memberId),
            data: { blurScreenshots: newValue },
          });
          if (response.success) {
            getMembers();
          } else {
            displayNotification({
              message: 'There was an issue updating screenshot preferences. Please try again',
              severity: 'error',
            });
          }
        };

        const mappedMembers = response.data?.members.map((member) => ({
          name: <MemberNameEmailColumn
            profilePicture={member?.user?.profilePicture}
            firstName={member.haveJoined ? member?.user?.firstName : ''}
            lastName={member.haveJoined ? member?.user?.lastName : ''}
            email={member?.user?.email}
            component="div"
          />,
          screenshotFrequency: <Toggle
            activeLabel={Object.keys(screenshotsPerPeriodMapping).find(
              (key) => screenshotsPerPeriodMapping[key] === member?.screenshotsPerPeriod,
            )}
            options={[
              { label: 'None', onClick: () => { changeScreenshotsPerPeriod(0, member?.id); } },
              { label: '1x', onClick: () => { changeScreenshotsPerPeriod(1, member?.id); } },
              { label: '2x', onClick: () => { changeScreenshotsPerPeriod(2, member?.id); } },
              { label: '3x', onClick: () => { changeScreenshotsPerPeriod(3, member?.id); } },
            ]}
          />,
          blurScreenshots: <Toggle
            sx={{}}
            activeLabel={Object.keys(blurScreenshotsMapping).find(
              (key) => blurScreenshotsMapping[key] === member?.blurScreenshots,
            )}
            options={[
              { label: 'Off', onClick: () => { changeBlurScreenshots(false, member?.id); } },
              { label: 'On', onClick: () => { changeBlurScreenshots(true, member?.id); } },
            ]}
          />,
          actions: (
            <Link to={{
              pathname: '/members/activity/view',
              search: `?member=${member.id}`,
              state: { workspaceMember: member, activityType: 'Screenshots' },
            }}
            >
              <Button variant="outlined">View Activity</Button>
            </Link>
          ),
        }));
        setMembers(mappedMembers);
        setTotalPages(response.data?.pageInfo.totalPages ?? 1);
        setTotalCounts(response.data?.pageInfo.totalCounts ?? 0);
        setIsLoading(false);

        if (response.data?.totalPages < currentPage) {
          setCurrentPage(1);
        }
      }
    }
  }, [activeWorkspace, displayNotification]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(debounce((query) => setSearchQuery(query), 500), []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    history.push(`?page=${page}`);
  };

  const handlePageSizeChange = (pageSize) => {
    setCurrentPage(1);
    setTotalPages(Math.ceil(totalCounts / pageSize));
    setPageSize(pageSize);
  };

  useEffect(() => {
    getMembers({
      page: currentPage,
      name: searchQuery,
      sortKey,
      sortOrder,
      pageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchQuery, sort, pageSize]);

  const changeDefaultScreenshotsPerPeriod = async (newValue) => {
    const response = await sendRequest({
      method: 'put',
      url: API.WORKSPACE.workspaceUpdateScreenshotsPerPeriod(activeWorkspace.id),
      data: { screenshotsPerPeriod: newValue },
    });
    if (response.success) {
      const newActiveWorkspace = { ...activeWorkspace, screenshotsPerPeriod: newValue };
      setActiveWorkspace(newActiveWorkspace);
    } else {
      displayNotification({
        message: 'There was an issue updating screenshot preferences. Please try again',
        severity: 'error',
      });
    }
  };

  const changeDefaultBlurScreenshots = async (newValue) => {
    const response = await sendRequest({
      method: 'put',
      url: API.WORKSPACE.workspaceUpdateBlurScreenshots(activeWorkspace.id),
      data: { blurScreenshots: newValue },
    });
    if (response.success) {
      const newActiveWorkspace = { ...activeWorkspace, blurScreenshots: newValue };
      setActiveWorkspace(newActiveWorkspace);
    } else {
      displayNotification({
        message: 'There was an issue updating blur screenshots. Please try again',
        severity: 'error',
      });
    }
  };
  const handleSort = debounce((sortKey, sortOrder) => {
    setIsLoading(true);
    setSort(!sort);
    setSortKey(sortKey);
    setSortOrder(sortOrder);
  }, 250);

  return (
    <div>
      <Navbar />
      <Sidebar />
      <div className="screen-wrapper">
        <div className="screen-header">
          <div className="screen-header-container d-flex justify-content-between position-relative">
            <div>
              <h3
                className="header-manage-member"
                style={{ fontWeight: 600 }}
                data-testid="title"
              >
                Screenshot Frequency
              </h3>
            </div>
          </div>
          <div className="texts-section">
            <p className="mb-3">
              Manage screenshot settings for members
            </p>
            <div className="large-bottom-margin">
              <h4 className="mb-2 size-s text-600">Organization Default Settings</h4>
              <div className="d-flex">
                <div className="d-flex align-items-center mr-2">
                  <p className="text-500">Control the numbers of screenshots taken in a 10 minute period</p>
                </div>
                <div className="size-xs">
                  <Toggle
                    activeLabel={Object.keys(screenshotsPerPeriodMapping).find(
                      (key) => screenshotsPerPeriodMapping[key] === activeWorkspace?.screenshotsPerPeriod,
                    )}
                    options={[
                      { label: 'None', onClick: () => { changeDefaultScreenshotsPerPeriod(0); } },
                      { label: '1x', onClick: () => { changeDefaultScreenshotsPerPeriod(1); } },
                      { label: '2x', onClick: () => { changeDefaultScreenshotsPerPeriod(2); } },
                      { label: '3x', onClick: () => { changeDefaultScreenshotsPerPeriod(3); } },
                    ]}
                  />
                </div>
              </div>
              <div className="d-flex mt-2">
                <div className="d-flex align-items-center mr-2">
                  <p className="text-500">Automatically blur screenshots upon capture for every individual</p>
                </div>
                <div className="size-xs">
                  <Toggle
                    activeLabel={Object.keys(blurScreenshotsMapping).find(
                      (key) => blurScreenshotsMapping[key] === activeWorkspace?.blurScreenshots,
                    )}
                    options={[
                      { label: 'Off', onClick: () => { changeDefaultBlurScreenshots(false); } },
                      { label: 'On', onClick: () => { changeDefaultBlurScreenshots(true); } },
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className="in-block">
              <h4 className="m-b12 size-s text-600">Individual Settings</h4>
              <p className="size-xs">Override the organization default for specific members</p>
            </div>
            <div className="searchBox">
              <input type="text" placeholder="Search for user" onChange={(e) => handleSearch(e.target.value)} />
              <i className="fa fa-search searchIcon" />
            </div>
          </div>
          <CustomTable
            headers={TABLE_HEADERS}
            items={members}
            loading={isLoading}
            handleSort={handleSort}
            styles={CustomTableStyles}
          />
          <Pagination
            currentPage={currentPage}
            pageSize={pageSize}
            totalPages={totalPages}
            totalCounts={totalCounts}
            onPageChange={handlePageChange}
            setPageSize={handlePageSizeChange}
            styles={{ margin: 0 }}
          />
        </div>
      </div>
    </div>

  );
}

export default ScreenshotFrequency;

import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import {
  Select,
  MenuItem,
  Box,
  Chip,
} from '@mui/material';
import { BootstrapInput } from './TextInput';

export function ChipSelect({
  label,
  members,
  handleChange,
  selectedMembers,
  className,
  id,
  handleSelectAll,
  placeholder = 'required',
  selectAllBtn = false,
  ...props
}) {
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 46 * 4.5 + 13,
        width: 250,
      },
    },
  };

  return (
    <FormControl sx={{ marginBottom: '16px' }} variant="standard" fullWidth>
      {selectAllBtn
            && (
            <Box
              sx={{
                color: 'var(--green-3, #53A551)',
                textAlign: 'right',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '20px',
                position: 'absolute',
                right: 0,
                top: 0,
                cursor: 'pointer',
              }}
              onClick={handleSelectAll}
            >
              Select All
            </Box>
            )}
      <InputLabel
        sx={{
          fontSize: '18px',
          color: '#212529',
        }}
        shrink
        htmlFor={id}
      >
        {label}
      </InputLabel>
      <Select
        id={id}
        name={id}
        className={className}
        displayEmpty
        multiple
        value={selectedMembers}
        onChange={handleChange}
        input={(
          <BootstrapInput
            sx={{
              '& .MuiInputBase-input': {
                border: '1.5px solid',
                borderColor: 'var(--sds-input-border)',
              },
            }}
            background="#f9f9f9"
          />
        )}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return (
              <InputLabel
                sx={{
                  fontSize: '16px',
                  color: '#212529',
                  lineHeight: '20px',
                  opacity: '40%',
                  marginBottom: '0px',
                }}
              >
                {placeholder}
              </InputLabel>
            );
          }
          return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value.id}
                  label={value.name}
                  size="small"
                  onDelete={() => {
                    const filtered = selected.filter((item) => item !== value);
                    const e = { target: { value: filtered, name: id } };
                    handleChange(e);
                  }}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                  }}
                />
              ))}
            </Box>
          );
        }}
        MenuProps={MenuProps}
        sx={{
          '& .MuiSvgIcon-root': {
            right: '7px',
          },
        }}
        /* eslint-disable react/jsx-props-no-spreading */
        {...props}
      >
        {members.map((member) => (
          <MenuItem
            key={`${id}-${member.id}`}
            value={member}
            sx={{
              '&.Mui-selected': {
                color: '#061232',
                opacity: 0.5,
                background: 'white',
              },
            }}
          >
            {member.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

ChipSelect.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  selectedMembers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  placeholder: PropTypes.string,
  selectAllBtn: PropTypes.bool,
  handleSelectAll: PropTypes.func,
};

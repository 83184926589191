import React, {
  useContext, useEffect, useState,
} from 'react';
import { Box } from '@mui/material';
import moment from 'moment-timezone';
import TabPageHeader from './TabPageHeader';
import CustomTable from '../../components/table/CustomTable';
import DropDown from '../../components/dropdown';
import API from '../../api/endpoints';
import { sendRequest } from '../../api/shootAPI';
import { UserContext } from '../../contexts/User';
import { NotificationContext } from '../../contexts/Notification';
import HolidayModal from './HolidayModal';
import DeleteModal from './DeleteModal';

function HolidaysPanel() {
  const { activeWorkspace } = useContext(UserContext);
  const { displayNotification } = useContext(NotificationContext);
  const [holidays, setHolidays] = useState([]);
  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddHolidayModal, setShowAddHolidayModal] = useState(false);
  const [showEditHolidayModal, setShowEditHolidayModal] = useState(false);
  const [showDeleteHolidayModal, setShowDeleteHolidayModal] = useState(false);
  const [selectedHoliday, setSelectedHoliday] = useState(null);

  const getHolidays = async () => {
    const res = await sendRequest({
      method: 'GET',
      url: API.WORKSPACE.offTimeHolidays(activeWorkspace.id),
    });
    if (res.success) {
      const offTimeHolidays = res.data.ptoHolidays
        .map((holiday) => {
          if (holiday?.members?.length) {
            return {
              ...holiday,
              members: holiday.members.map((member) => ({
                id: member.memberId,
                name: member.user.fullName,
              })),
            };
          }
          return holiday;
        });
      setHolidays(offTimeHolidays);
    } else {
      displayNotification({
        message: 'An error occurs while fetching PTO Holidays.',
        severity: 'error',
      });
    }
  };
  const getMembers = async () => {
    const res = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.members(activeWorkspace.id),
    });
    if (res.success) {
      const membersArr = res.data.members.map((member) => {
        const name = member?.user?.firstName && member?.user?.lastName
          ? `${member.user.firstName} ${member.user.lastName}`
          : member.user.email;
        return {
          id: member.id,
          name,
        };
      });
      setMembers(membersArr);
    } else {
      displayNotification({
        message: 'An error occurs while fetching teams.',
        severity: 'error',
      });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    Promise
      .all([getHolidays(), getMembers()])
      .finally(() => setIsLoading(false));
  }, []);

  const FIXED_TABLE_HEADERS = [
    {
      label: 'Holiday',
      sortable: true,
    },
    {
      label: 'Members',
      sortable: true,
    },
    {
      label: 'Date',
      sortable: true,
    },
    {
      label: 'Action',
    },
  ];
  const options = (holiday) => [
    {
      text: 'Edit Holiday',
      fontColor: '#061232',
      handleClick: () => {
        setSelectedHoliday(holiday);
        setShowEditHolidayModal(true);
      },
    },
    {
      text: 'Delete Holiday',
      fontColor: '#061232',
      handleClick: () => {
        setSelectedHoliday(holiday);
        setShowDeleteHolidayModal(true);
      },
    },
  ];

  const deleteHolidayPolicy = async () => {
    const { success } = await sendRequest({
      method: 'delete',
      url: API.WORKSPACE.deleteHoliday(activeWorkspace.id, selectedHoliday.id),
    });
    if (success) {
      setShowDeleteHolidayModal(false);
      displayNotification({
        message: 'Holiday has been deleted successfully.',
        severity: 'success',
      });
      getHolidays();
      getMembers();
    } else {
      displayNotification({
        message: 'There was an issue deleting a holiday.',
        severity: 'error',
      });
    }
  };

  return (
    <Box className="p-16">
      { showAddHolidayModal
        && (
          <HolidayModal
            closeModal={() => setShowAddHolidayModal(false)}
            members={members}
            getHolidays={getHolidays}
          />
        )}
      { showEditHolidayModal
        && (
          <HolidayModal
            closeModal={() => setShowEditHolidayModal(false)}
            members={members}
            getHolidays={getHolidays}
            selectedHoliday={selectedHoliday}
          />
        )}
      { showDeleteHolidayModal
        && (
          <DeleteModal
            policyName={selectedHoliday.name}
            setShowModal={setShowDeleteHolidayModal}
            callback={() => deleteHolidayPolicy(selectedHoliday.id)}
            type="holiday"
            date={`which occurs in ${moment(selectedHoliday.date).utc().format('M/D/YY')}`}
          />
        )}
      <TabPageHeader
        title="Holidays"
        subtitle="Set up holidays for time off"
        buttonName="Add Holiday"
        onClickBtn={() => setShowAddHolidayModal(true)}
      />
      <Box className="mt-4">
        <CustomTable
          headers={FIXED_TABLE_HEADERS}
          items={
            holidays.map((holiday) => (
              {
                name: holiday.name,
                members: holiday.members.length || 'None',
                Date: (
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '4px',
                  }}
                  >
                    { holiday.occursAnnually && (
                    <button type="button">
                      {/* eslint-disable-next-line */}
                      <svg width="16" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_8016_4149)">
                          {/* eslint-disable-next-line */}
                          <path d="M8.25002 4.0013V6.0013L10.9167 3.33464L8.25002 0.667969V2.66797C5.30335 2.66797 2.91669 5.05464 2.91669 8.0013C2.91669 9.04797 3.22335 10.0213 3.74335 10.8413L4.71669 9.86797C4.41669 9.31464 4.25002 8.67463 4.25002 8.0013C4.25002 5.79464 6.04335 4.0013 8.25002 4.0013ZM12.7567 5.1613L11.7834 6.13463C12.0767 6.69463 12.25 7.32797 12.25 8.0013C12.25 10.208 10.4567 12.0013 8.25002 12.0013V10.0013L5.58335 12.668L8.25002 15.3346V13.3346C11.1967 13.3346 13.5834 10.948 13.5834 8.0013C13.5834 6.95464 13.2767 5.9813 12.7567 5.1613V5.1613Z" fill="#546077" />
                        </g>
                        <defs>
                          <clipPath id="clip0_8016_4149">
                            <rect width="16" height="16" fill="white" transform="translate(0.25)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                    )}
                    <p>{moment(holiday.date).utc().format('ddd, MMM D, YYYY')}</p>
                  </Box>
                ),
                action: (
                  <DropDown title="Actions" options={options(holiday)} />
                ),
              }
            ))
          }
          loading={isLoading}
        />
      </Box>
    </Box>
  );
}

export default HolidaysPanel;

import React from 'react';
import { Images } from '../common';

export function GraphicsPane() {
  const { step1Users } = Images;
  return (
    <div className="signup-graphics-pane" style={{ flex: 1 }}>
      <div className="graphics-wrapper">
        <div className="graphic-header">
          <div className="graphic-split-header">
            {/* <div className="find-team">
                <span>Looking to join an existing workspace?</span>
                <Link to="/" className="login-to-workspace">Login into workspace.</Link>
              </div> */}
          </div>
          <div className="signup-graphics-container">
            <div className="signup-graphics">
              <img
                alt="signup-graphics"
                src={step1Users}
                className="img-fluid"
                height="300px"
                width="550px"
                title="ShooterApp"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, {
  useContext, useState, useRef, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Modal, Box, Button } from '@mui/material';
import { CSVLink } from 'react-csv';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { UserContext } from '../../contexts/User';
import DateRangePicker from '../../components/dateRangePicker';
import { getDatesInRange, convertSecToTimeFormat } from '../../utils/time';
import InfoIconSVG from '../../images/infoIcon.svg';

function ExportAllModal({ closeModal }) {
  const [date, setDate] = useState([new Date(), new Date()]);
  const csvAnchor = useRef(null);
  const [csvData, setCsvData] = useState([]);
  const [isExporting, setIsExporting] = useState(false);
  const { activeWorkspace, user } = useContext(UserContext);

  const handleExportAll = async () => {
    setIsExporting(true);
    const [startDate, endDate] = date;
    const dates = getDatesInRange(startDate, endDate);
    const startOfDay = moment(dates[0]).startOf('day').tz(user.timezone).utc()
      .format('YYYY-MM-DD HH:mm:ss');
    const endOfDay = moment(dates.at(-1)).endOf('day').tz(user.timezone).utc()
      .format('YYYY-MM-DD HH:mm:ss');
    const csvRawData = [['Member', 'Email', 'Department', 'Location']
      .concat(dates, ['Total Time', 'Average Activity'])];
    const response = await sendRequest({
      method: 'get',
      timeout: 60000,
      url: API.WORKSPACE.membersActivityExport(activeWorkspace.id),
      params: { start: startOfDay, end: endOfDay },
    });
    if (response.success) {
      const members = response.data?.members ?? [];
      members.forEach((member) => {
        const userName = member.user.firstName ? member.user.firstName.concat(' ', member.user.lastName) : '';
        const csvRowData = [userName, member.user.email, member.department, member.user.timezone];
        const datesActiveTime = [];
        let totalSecondsElapsed = 0;
        let totalActiveSeconds = 0;
        dates.forEach((day) => {
          // get total active time for each date
          const startDay = moment(moment(day).startOf('day').tz(user.timezone).utc()
            .format('YYYY-MM-DD HH:mm:ss'));
          const endDay = moment(moment(day).endOf('day').tz(user.timezone).utc()
            .format('YYYY-MM-DD HH:mm:ss'));
          const initialdateActivity = { secondsElapsed: 0, activeSeconds: 0 };
          const dateActivity = member.activity.reduce(
            (acc, activity) => {
              // Check if the activity time is within the local day range
              const compareDay = moment(activity.startDate).tz(user.timezone).utc();
              if ((compareDay.isBefore(endDay) && compareDay.isAfter(startDay))
                || (compareDay.isSame(startDay) || compareDay.isSame(endDay))) {
                return {
                  secondsElapsed: acc.secondsElapsed + activity.secondsElapsed,
                  activeSeconds: acc.activeSeconds + activity.activeSeconds,
                };
              }
              return { ...acc };
            },
            initialdateActivity,
          );
          totalSecondsElapsed += dateActivity.secondsElapsed;
          totalActiveSeconds += dateActivity.activeSeconds;
          datesActiveTime.push(moment.utc(dateActivity.secondsElapsed * 1000).format('H:mm:ss'));
        });
        const totalSecondsElapsedFormatted = convertSecToTimeFormat(totalSecondsElapsed);
        const avgActivityPercentage = (((totalActiveSeconds / totalSecondsElapsed) || 0) * 100);
        const formattedAvgActivityPercentage = `${avgActivityPercentage.toFixed(0)}%`;
        csvRawData.push((csvRowData.concat(
          datesActiveTime,
          [totalSecondsElapsedFormatted, formattedAvgActivityPercentage],
        )));
      });
      setCsvData(csvRawData);
    } else {
      // handle API errors
    }
    setIsExporting(false);
  };

  useEffect(() => {
    if (csvData.length > 0) {
      csvAnchor.current.link.click();
    }
  }, [csvData]);

  const [startDate, endDate] = date;
  return (
    <Modal
      open
      onClose={closeModal}
      className="flex-row align-c jstfy-c"
      data-testid="export-all-timesheets-modal"
    >
      <Box style={{
        width: 482,
        padding: 32,
        background: '#fff',
        borderRadius: 8,
      }}
      >
        <h5 className="m-b16 text-600" data-testid="modal-title">Export All Timesheets</h5>
        <div className="info-section" data-testid="info-section">
          <img src={InfoIconSVG} alt="info icon" />
          <p className="info-text">
            Select the timesheet date range you wish to export
          </p>
        </div>
        <DateRangePicker
          value={date}
          onChange={(_date) => setDate(_date)}
          data-testid="date-range-picker"
        />
        <div className="exportAllForm-btns" style={{ marginTop: 24 }}>
          <Button
            onClick={closeModal}
            color="secondary"
            sx={{ marginRight: 2 }}
            data-testid="cancel"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleExportAll}
            disabled={isExporting}
            data-testid="export"
          >
            {isExporting ? 'Exporting...' : 'Export'}
          </Button>
          <CSVLink
            data={csvData}
            ref={csvAnchor}
            filename={`Activity_${moment(startDate).subtract(1, 'day').format('MM-DD-YYYY')}_to_${moment(endDate)
              .format('MM-DD-YYYY')}.csv`}
          />
        </div>
      </Box>
    </Modal>
  );
}

ExportAllModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ExportAllModal;

/* eslint-disable no-nested-ternary */
import React, {
  useContext, useEffect, useState,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { sendRequest } from '../../../api/shootAPI';
import API from '../../../api/endpoints';
import { NotificationContext } from '../../../contexts/Notification';
import { formatDate } from '../../../utils/time';
import TotalCard from '../../../components/admin/TotalCard';
import AdminDataTable from '../../../components/admin/AdminDataTable';
import TeamspaceM from '../../../images/TeamspaceM.png';
import { TEAMS_TABLE_HEADERS } from '../../../utils/adminDataTableHeaders';
import Action from '../../../components/admin/Action';
import { Layout } from '../Layout';
import { parseFilterParams } from '../../../utils/fillter';

function Teams() {
  const teamMenuItems = [
    {
      label: 'View Team',
      link: '/organization/switch',
    },
    {
      label: 'Edit Team',
      link: '/admin/organization/create',
    },
    {
      label: 'Delete',
      modal: true,
      color: '#E53935',
    },
  ];
  const { displayNotification } = useContext(NotificationContext);
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const page = query.get('page');
  const filterParams = parseFilterParams(query, 'user');
  const userId = query.get('userId');
  const [teams, setTeams] = useState([]);
  const [totalTeams, setTotalTeams] = useState([]);
  const [activeTeams, setActiveTeams] = useState([]);
  const [nonActiveTeams, setNonActiveTeams] = useState([]);
  const [totalMembers, setTotalMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(page ? parseInt(page, 10) : 1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCounts, setTotalCounts] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    history.push(`?page=${page}`);
  };

  const getTotalMembers = (teams) => teams.map((team) => team.members).flat();
  const fetchTeams = async () => {
    setLoading(true);
    const { success, data } = await sendRequest({
      method: 'post',
      url: API.WORKSPACE.getAllTeams(),
      data: {
        pageSize,
        page: currentPage,
        userId,
        filters: filterParams,
      },
      timeout: 30000,
    });
    if (success) {
      const filteredTeams = data.teams?.map((item) => ({
        teamName: item.name,
        workspaceName: item.workspaceName,
        organizationName: item.organizationName,
        totalUsers: item.membersCount,
        teamLeader: 'Jacob Jones',
        teamStatus: item.status ? 'Active' : 'InActive',
        lastActivityDate: formatDate(item.updatedAt),
        action: <Action label={item.name} menuItems={teamMenuItems} type="team" />,
      }));
      setTeams(filteredTeams);
      setTotalPages(data?.pageInfo.totalPages ?? 1);
      setTotalCounts(data?.pageInfo.totalCounts ?? 0);
      setLoading(false);
      setTotalTeams(data.totalTeams);
      setActiveTeams(data.totalTeams?.filter((item) => item.status));
      setNonActiveTeams(data.totalTeams?.filter((item) => !item.status));
      setTotalMembers(getTotalMembers(data.totalTeams));
    } else {
      displayNotification({
        message: 'There was an issue fetching user data. Please try again',
        severity: 'error',
      });
    }
  };

  useEffect(() => {
    fetchTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, currentPage, search]);

  const cardData = [
    {
      id: 1,
      data: totalTeams,
      title: 'Total Teams',
      tooltip: 'Total Teams',
      color: '#0064FF',
    },
    {
      id: 2,
      data: activeTeams,
      title: 'Total Active Teams',
      tooltip: 'Total Active Teams',
      color: '#F19235',
    },
    {
      id: 3,
      data: nonActiveTeams,
      title: 'Total Non-Active Teams',
      tooltip: 'Total Non-Active Teams',
      color: '#9457A5',
    },
    {
      id: 4,
      data: totalMembers,
      title: 'Total Users',
      tooltip: 'Total Users',
      color: '#FF6A6A',
    },
  ];

  return (
    <Layout>
      <div className="screen-wrapper">
        <div className="screen-header">
          <div className="total-card-wrapper">
            {cardData && cardData.map((item, index) => (
              <TotalCard
                key={index}
                data={item.data}
                title={item.title}
                tooltip={item.tooltip}
                color={item.color}
              />
            ))}
          </div>
          <div className="one-card-container">
            <AdminDataTable
              heading="teams"
              data={teams}
              title="Teams Management"
              icon={TeamspaceM}
              headers={TEAMS_TABLE_HEADERS}
              loading={loading}
              currentPage={currentPage}
              totalPages={totalPages}
              totalCounts={totalCounts}
              pageSize={pageSize}
              onPageSize={setPageSize}
              onCurrentPage={handlePageChange}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Teams;

import React, { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/system';
import {
  Card, CardContent, CardHeader, Button,
} from '@mui/material';
import CustomTable from '../../components/table/CustomTable';
import Overdue from '../../images/overdue.png';
import { NotificationContext } from '../../contexts/Notification';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';

const CustomCard = styled(Card)`
    borderRadius: "8px",
    width: 30%,
    border: "1px solid var(--Dark-Dark-06, #E0E1E6)",
    boxShadow: "none"
`;

const FIXED_TABLE_HEADERS = [
  {
    label: 'Organization Name',
    sortable: true,
  },
  {
    label: 'Due Date',
    sortable: true,
  },
  {
    label: 'Amount Date',
    sortable: true,
  },
  {
    label: 'Contact',
    sortable: true,
  },
  {
    label: 'Status',
    sortable: true,
  },
];

function PaymentsCard() {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { displayNotification } = useContext(NotificationContext);

  const fetchOrganizations = async () => {
    setLoading(true);
    const { success, data } = await sendRequest({
      method: 'post',
      url: API.ORGANIZATION.organizations(),
      timeout: 30000,
    });
    if (success) {
      const tempData = [];
      data.organizations.forEach((item) => {
        const datum = {
          organizationName: item.name,
          dueDate: '07/21/2024',
          amountDue: '$2500.00',
          contact: 'Jane Doe',
          status: 'Overdue',
        };
        tempData.push(datum);
      });
      const limitedData = tempData.slice(0, 3);
      setTableData(limitedData);
      setLoading(false);
    } else {
      displayNotification({
        message: 'There was an issue fetching organization data. Please try again',
        severity: 'error',
      });
    }
  };
  useEffect(() => {
    fetchOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomCard className="daily-activities-card">
      <CardHeader
        className="table-card-header hours-card-header"
        title={(
          <div className="table-card-header-title d-flex gap-1 justify-content-between align-items-center">
            <div className="table-card-logo d-flex gap-1">
              <img
                src={Overdue}
                alt="info icon"
                style={{
                  width: '23px',
                  height: '34px',
                  marginTop: '4px',
                }}
              />
              <p>Overdue Payments</p>
            </div>
            <Button sx={{ border: '2px solid #E0E1E6', height: '24px', color: '#4469A1' }}>View All</Button>
          </div>
        )}
      />
      <CardContent className="table-card-content d-flex flex-column justify-content-between">
        <CustomTable headers={FIXED_TABLE_HEADERS} items={tableData} loading={loading} />
      </CardContent>
    </CustomCard>
  );
}

export default PaymentsCard;

export const USERS_TABLE_HEADERS = [
  {
    label: 'User Name',
    sortable: false,
  },
  {
    label: 'Total Teams',
    sortable: false,
  },
  {
    label: 'Total Workspaces',
    sortable: false,
  },
  {
    label: 'Total Organization',
    sortable: false,
  },
  {
    label: 'User Status',
    sortable: false,
  },
  {
    label: 'Last Activity Date',
    sortable: false,
  },
  {
    label: 'Action',
    sortable: false,
  },
];

export const TEAMS_TABLE_HEADERS = [
  {
    label: 'Team Name',
    sortable: false,
  },
  {
    label: 'Workspace Name',
    sortable: false,
  },
  {
    label: 'Organization Name',
    sortable: false,
  },
  {
    label: 'Total Users',
    sortable: false,
  },
  {
    label: 'Team Leader',
    sortable: false,
  },
  {
    label: 'Team Status',
    sortable: false,
  },
  {
    label: 'Last Activity Date',
    sortable: false,
  },
  {
    label: 'Action',
    sortable: false,
  },
];
export const WORKSPACES_TABLE_HEADERS = [
  {
    label: 'Workspace Name',
    sortable: false,
  },
  {
    label: 'Organization Name',
    sortable: false,
  },
  {
    label: 'Total Teams',
    sortable: false,
  },
  {
    label: 'Total Users',
    sortable: false,
  },
  {
    label: 'Workspace Status',
    sortable: false,
  },
  {
    label: 'Last Activity Date',
    sortable: false,
  },
  {
    label: 'Action',
    sortable: false,
  },
];

export const ORGANIZATIONS_TABLE_HEADERS = [
  {
    label: 'Organization Name',
    sortable: false,
  },
  {
    label: 'Total Workspaces',
    sortable: false,
  },
  {
    label: 'Total Users',
    sortable: false,
  },
  {
    label: 'Current Plan',
    sortable: false,
  },
  {
    label: 'Health Status',
    sortable: false,
  },
  {
    label: 'Organization Status',
    sortable: false,
  },
  {
    label: 'Last Activity Date',
    sortable: false,
  },
  {
    label: 'Action',
    sortable: false,
  },
];

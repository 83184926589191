import React, { createContext, useMemo, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';
import PropTypes from 'prop-types';

export const NotificationContext = createContext();

export function NotificationProvider({ children }) {
  const [notification, setNotification] = useState(null);

  const displayNotification = ({
    severity,
    message,
    autoHideDuration = 6000,
    verticalAlign = 'bottom',
    horizontalAlign = 'center',
  }) => setNotification({
    autoHideDuration, severity, message, verticalAlign, horizontalAlign,
  });

  const hideNotification = () => setNotification(null);

  const contextValue = useMemo(() => ({
    displayNotification,
  }), []);

  return (
    <NotificationContext.Provider value={contextValue}>
      {notification && (
        <Snackbar
          open={!!notification}
          autoHideDuration={notification?.autoHideDuration}
          onClose={hideNotification}
          anchorOrigin={{ vertical: notification?.verticalAlign, horizontal: notification?.horizontalAlign }}
        >
          <Alert onClose={hideNotification} severity={notification?.severity} data-testid="alert">
            {notification?.message}
          </Alert>
        </Snackbar>
      )}
      {children}
    </NotificationContext.Provider>
  );
}

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';

function AccessTime({ color = 'green' }) {
  // eslint-disable-next-line no-nested-ternary
  const color1 = color === 'green' ? '#53A551' : color === 'yellow' ? '#F19E41' : '#CC4525';
  // eslint-disable-next-line no-nested-ternary
  const color2 = color === 'green' ? '#A6EAA5' : color === 'yellow' ? '#FBE48E' : '#F5C0B0';
  // eslint-disable-next-line max-len, no-nested-ternary
  const title = color === 'green' ? 'Work Hours - High Productivity' : color === 'yellow' ? 'Work Hours - Mid Productivity' : 'Work Hours - Low Productivity';

  return (
    <Tooltip title={title}>
      <svg
        width="32"
        height="32"
        style={{ cursor: 'pointer' }}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="16" cy="16" r="15.5" fill={color1} />
        <circle cx="16" cy="16" r="15.5" fill="white" fillOpacity="0.9" />
        <circle cx="16" cy="16" r="15.5" stroke={color2} />
        <g clipPath="url(#clip0_7541_11575)">
          <path
            d={`M15.9915 7.66602C11.3915 7.66602 7.6665 11.3993 7.6665 15.9993C7.6665
            20.5993 11.3915 24.3327 15.9915 24.3327C20.5998 24.3327 24.3332 20.5993
            24.3332 15.9993C24.3332 11.3993 20.5998 7.66602 15.9915 7.66602ZM15.9998
            22.666C12.3165 22.666 9.33317 19.6827 9.33317 15.9993C9.33317 12.316 12.3165
            9.33268 15.9998 9.33268C19.6832 9.33268 22.6665 12.316 22.6665 15.9993C22.6665
            19.6827 19.6832 22.666 15.9998 22.666ZM16.4165 11.8327H15.1665V16.8327L19.5415
            19.4577L20.1665 18.4327L16.4165 16.2077V11.8327Z`}
            fill={color1}
          />
        </g>
        <defs>
          <clipPath id="clip0_7541_11575">
            <rect width="20" height="20" fill="white" transform="translate(6 6)" />
          </clipPath>
        </defs>
      </svg>
    </Tooltip>
  );
}

AccessTime.propTypes = {
  color: PropTypes.oneOf(['green', 'yellow', 'red']),
};

export default AccessTime;

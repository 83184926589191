import React, {
  useState, useEffect, useContext, useCallback,
} from 'react';
import { Button } from '@mui/material';
import moment from 'moment-timezone';
import CircularProgress from '@mui/material/CircularProgress';
import { Navbar, Sidebar } from '../../components';
import CreateTeamModal from './CreateTeamModal';
import './teamEngagement.css';
import TeamCard from './TeamCard';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { UserContext } from '../../contexts/User';
import { NotificationContext } from '../../contexts/Notification';
import useDebounce from '../../hooks/useDebounce';
import { accountTypeLevel } from '../../constants/accountTypes';
import { getDatesInRange } from '../../utils/time';
import DateRangePicker from '../../components/dateRangePicker';

function TeamEngagement() {
  const today = new Date();
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const { displayNotification } = useContext(NotificationContext);
  const { activeWorkspace, member } = useContext(UserContext);
  const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);
  const [cards, setCards] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const { debounce } = useDebounce();
  // [TODO] filtering by date can be added in the future
  const [date, setDate] = useState([startOfMonth, endOfMonth]);

  const fetchTeams = async () => {
    setIsLoading(true);
    const [startDate, endDate] = date;
    const dates = getDatesInRange(startDate, endDate);
    const startOfDay = moment(dates[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const endOfDay = moment(dates.at(-1)).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    const { success, data: { teams } } = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.createTeam(activeWorkspace.id),
      timeout: 60000,
      params: {
        start: startOfDay,
        end: endOfDay,
        searchQuery,
      },
    });
    if (!success) {
      displayNotification({
        message: 'There was an issue fetching team data in this workspace. Please try again',
        severity: 'error',
      });
      return;
    }

    setCards(teams.map(({ id, name, teamMates }) => ({
      id,
      name,
      percentage: Math.round(teamMates.reduce((score, mate) => {
        const { totalActiveSeconds, totalTimeSeconds } = mate.member.dailyActivity.reduce((totals, activity) => {
          // eslint-disable-next-line no-param-reassign
          totals.totalActiveSeconds += activity.activeSeconds || 0;
          // eslint-disable-next-line no-param-reassign
          totals.totalTimeSeconds += activity.totalSeconds || 0;
          return totals;
        }, { totalActiveSeconds: 0, totalTimeSeconds: 0 });

        const matePercentage = Math.round((totalActiveSeconds * 100) / totalTimeSeconds || 0);
        return score + matePercentage;
      }, 0) / (teamMates.length || 1)),

      managers: teamMates
        .filter((member) => member.role === 'Manager')
        .map((manager) => ({
          id: manager.memberId,
          name: manager.member && manager.member.user
            ? `${manager.member.user.firstName} ${manager.member.user.lastName}`
            : 'Unknown Name',
        })),
      members: teamMates
        .filter((member) => member.role === 'Member')
        .map((mate) => ({
          id: mate.memberId,
          name: mate.member && mate.member.user
            ? `${mate.member.user.firstName} ${mate.member.user.lastName}`
            : 'Unknown Name',
        })),
      webcamViolations: teamMates
        .some(({ member }) => member && member.webcamViolations && member.webcamViolations.length > 0),
      locationViolations: teamMates
        .some(({ member }) => member && member.locationViolations && member.locationViolations.length > 0),
    })));

    setIsLoading(false);
  };

  useEffect(() => {
    fetchTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, date]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(debounce((query) => setSearchQuery(query), 500), []);

  return (
    <div>
      {showCreateTeamModal && (
        <CreateTeamModal
          onTeamCreated={fetchTeams}
          closeModal={() => setShowCreateTeamModal(false)}
          workspaceId={activeWorkspace.id}
        />
      )}
      <Navbar />
      <Sidebar />
      <div className="screen-wrapper">
        <div className="screen-header">
          <div className="screen-header-container d-flex justify-content-between position-relative gap-4">
            <h3 className="team-header-manage-member">Team Engagement</h3>
            <div className="team-engagement-header-actions">
              { accountTypeLevel[member.accountType] >= accountTypeLevel['Workspace admin']
              && (
              <Button
                sx={{
                  marginLeft: 'auto',
                  font: 'Inter',
                  fontSize: '16px',
                  textTransform: 'none',
                }}
                variant="outlined"
                onClick={() => {
                  setShowCreateTeamModal(true);
                }}
              >
                Create Team
              </Button>
              ) }
              <div className="searchBox">
                <input type="text" placeholder="Search Team" onChange={(e) => handleSearch(e.target.value)} />
                <i className="fa fa-search searchIcon" />
              </div>
            </div>
          </div>
          <div className="flex-row justify-content-between align-e">
            <p>Assess team performance and engagement</p>
            <DateRangePicker
              value={date}
              onChange={(_date) => setDate(_date)}
              className="w-auto"
            />
          </div>
        </div>

        <div className="screen-body">
          <div className="team-engagement-body">
            {
              cards.map(({
                id,
                name,
                percentage,
                managers,
                members,
                webcamViolations,
                locationViolations,
              }) => (
                <TeamCard
                  key={id}
                  teamId={id}
                  name={name}
                  onTeamDeleted={fetchTeams}
                  onTeamEdited={fetchTeams}
                  percentage={percentage}
                  managers={managers}
                  members={members}
                  workspaceId={activeWorkspace.id}
                  webcamViolations={webcamViolations}
                  locationViolations={locationViolations}
                />
              ))
            }
          </div>
          {
            isLoading && (
            <div className="loader-container">
              <CircularProgress sx={{ color: 'inherit' }} />
            </div>
            )
          }
        </div>
      </div>
    </div>
  );
}

export default TeamEngagement;

import React, { useState, useContext } from 'react';
import {
  Button, Box, FormControl, FormLabel,
} from '@mui/material';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { UserContext } from '../../contexts/User';
import { NotificationContext } from '../../contexts/Notification';

const StyledInput = styled.input`
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    padding: 13px 16px;
    border-radius: 8px;
    color: #0E1E40;
    border: 1px solid #E0E1E6;
    margin-bottom: 12px;

    &:focus {
      border-color: #315285;
    }
  `;

const StyledText = styled.textarea`
  width: 100%;
  height: 89px;
  font-size: 16px;
  font-weight: 400;
  padding: 13px 16px;
  border-radius: 8px;
  color: #0E1E40;
  border: 1px solid #E0E1E6;
  margin-bottom: 12px;

  &:focus {
    border-color: #315285;
  }
`;

function BalanceEditModal({
  match, isEditing, getMembers,
}) {
  const { activeWorkspace } = useContext(UserContext);
  const {
    displayNotification,
  } = useContext(NotificationContext);
  // eslint-disable-next-line max-len
  const hours = Math.floor(match.balance / 60) < 10 ? `0${Math.floor(match.balance / 60)}` : Math.floor(match.balance / 60);
  const minutes = match.balance % 60 < 10 ? `0${match.balance % 60}` : match.balance % 60;
  const [balance, setBalance] = useState(`${hours}:${minutes}`);
  const [balanceValue, setBalanceValue] = useState(0);
  const [reason, setReason] = useState('');

  const handleBalance = (e) => {
    const { value } = e.target;
    setBalance(value);
    const balanceInputs = value.split(':');
    if (balanceInputs[0] && balanceInputs[1]) {
      setBalanceValue(parseInt(balanceInputs[0], 10) * 60 + parseInt(balanceInputs[1], 10));
    } else {
      setBalanceValue(0);
    }
  };

  const handleEdit = async () => {
    const params = {
      balance: match,
      balanceValue,
      reason,
    };
    if (activeWorkspace) {
      const response = await sendRequest({
        method: 'put',
        url: API.WORKSPACE.editOffTimeBalance(activeWorkspace.id),
        data: params,
      });
      if (response.success) {
        displayNotification({
          message: 'Edit the balance value successfully.',
          severity: 'success',
        });
        getMembers();
      } else {
        displayNotification({
          message: 'An error occurs while editing data.',
          severity: 'error',
        });
      }
    }
  };

  if (isEditing) {
    return (
      <Box
        width={312}
        height={293}
        sx={{
          padding: '16px',
          border: '1px solid #E0E1E6',
          position: 'absolute',
          top: '36px',
          backgroundColor: 'white',
          zIndex: '1',
          borderRadius: '4px',
        }}
      >
        <FormControl sx={{ width: '100%' }}>
          <FormLabel
            sx={{
              color: '#293855',
              fontSize: '14px',
            }}
          >
            Amount
          </FormLabel>
          <StyledInput onChange={(e) => handleBalance(e)} value={balance} />
        </FormControl>
        <FormControl sx={{ width: '100%' }}>
          <FormLabel
            sx={{
              color: '#293855',
              fontSize: '14px',
            }}
          >
            Why are you modifying this?
          </FormLabel>
          <StyledText onChange={(e) => setReason(e.target.value)} value={reason} placeholder="Required" />
        </FormControl>
        <Button
          sx={{
            font: 'Inter',
            fontSize: '16px',
            lineHeight: '16px',
            fontWeight: '500',
            border: '1px solid',
            textTransform: 'none',
            borderRadius: '4px',
            backgroundColor: 'var(--green-3, #53A551)',
            opacity: (balanceValue && reason) ? '1' : '0.5',
            color: '#FFFFFF',
            padding: '12px 24px',
            width: '100%',
          }}
          onClick={() => handleEdit()}
          variant="link"
          component="span"
          disabled={!balanceValue || !reason}
        >
          Apply
        </Button>
      </Box>
    );
  }
}

BalanceEditModal.propTypes = {
  match: PropTypes.shape({
    id: PropTypes.number.isRequired,
    balance: PropTypes.number.isRequired,
    policyId: PropTypes.number.isRequired,
  }).isRequired,
  isEditing: PropTypes.bool.isRequired,
  getMembers: PropTypes.func.isRequired,
};

export default BalanceEditModal;

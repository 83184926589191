/* eslint-disable no-nested-ternary */
import React, {
  useContext, useEffect, useState, useMemo, useCallback,
} from 'react';
import { useParams } from 'react-router-dom';
import UnfoldMore from '@mui/icons-material/UnfoldMore';
import moment from 'moment-timezone';
import { styled } from '@mui/system';
import { Chip } from '@mui/material';
import { UserContext } from '../../contexts/User';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
// import ThreatsRow from './ThreatsRow';
import NavigationLayout from '../../components/NavigationLayout';
import Toggle from '../../components/Toggle';
import MemberNameEmailSelector from '../../components/selectors/MemberNameEmailSelector';
import CustomTable from '../../components/table/CustomTable';
import EditColumn from './EditColumn';
import ResolveModal from '../../components/LocationHistory/ResolveModal';

const CustomTableStyles = {
  container: {
    marginTop: 1,
  },
  header: {
    backgroundColor: 'var(--dark-10)',
    fontSize: '14px',
    '.MuiTableSortLabel-iconDirectionAsc': {
      transform: 'rotate(0deg) !important',
    },
    '&:nth-of-type(3)': {
      textAlign: 'center',
    },
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff !important',
    },
    '&:hover': {
      backgroundColor: 'var(--dark-10) !important',
    },
  },
  cell: {
    button: {
      textWrap: 'nowrap',
      color: 'var(--dark-2)',
      borderColor: 'var(--dark-4) !important',
      '&:disabled': {
        color: 'var(--dark-5)',
        borderColor: 'var(--dark-7) !important',
        cursor: 'not-allowed',
        pointerEvents: 'all !important',
      },
    },
    '&:nth-of-type(3)': {
      textAlign: 'center',
    },
  },
};

const TABLE_HEADERS = [
  {
    label: 'Date',
    colSpan: 1,
    sortable: true,
    sortKey: 'date',
    sortIcon: UnfoldMore,
  },
  {
    label: 'Threat',
    colSpan: 2,
  },
  {
    label: 'Status',
    colSpan: 0.5,
  },
  {
    label: '',
    colSpan: 0.5,
  },
];

const getColorStyles = (type) => {
  switch (type) {
    case 'Unresolved':
      return {
        backgroundColor: 'rgba(221, 73, 73, 0.2)',
        color: 'rgba(221, 73, 73, 1)',
      };
    default:
      return {
        backgroundColor: 'rgba(83, 165, 81, 0.2)',
        color: 'rgba(83, 165, 81, 1)',
      };
  }
};

const StyledChip = styled(Chip)(({ type }) => {
  const { backgroundColor, color } = getColorStyles(type);

  return {
    backgroundColor,
    color,
    fontWeight: 500,
    width: 'max-content',
  };
});

function MemberThreats() {
  const { activeWorkspace } = useContext(UserContext);
  const params = useParams();
  const memberId = parseInt(params.memberId, 10);
  const [view, setView] = useState('Unresolved');
  const [violations, setViolations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedViolation, setSelectedViolation] = useState(null);

  const request = useCallback(async (params) => {
    setIsLoading(true);
    const response = await sendRequest({
      method: 'get',
      url: API.WORKSPACE.memberInsiderThreats(activeWorkspace.id, memberId),
      params: {
        status: view,
        ...params,
      },
    });
    if (response.success) {
      const results = response?.data?.violations || [];
      setViolations(results);
      setIsLoading(false);
    }
  }, [view, activeWorkspace.id, memberId]);

  const resolve = useCallback(async () => {
    await sendRequest({
      method: 'put',
      url: API.WORKSPACE.resolveThreat(selectedViolation.id),
      params: { type: selectedViolation.type },
    });
    await request();
  }, [selectedViolation, request]);

  const violationRow = useMemo(() => {
    if (!violations?.length) {
      return [];
    }

    return violations.map((violation) => {
      const violationType = `Physical Safeguard Breach - ${
        violation.type === 'Location' ? violation.type : `Webcam ${violation.type}`
      }`;

      return ({
        date: moment(violation.createdAt).format('hh:mm A · MM/DD/YYYY'),
        threat: violationType,
        status: <StyledChip
          label={violation.status}
          type={violation.status}
        />,
        actions: <EditColumn options={[
          {
            label: 'Resolve',
            action: () => {
              setIsModalOpen(true);
              setSelectedViolation(violation);
            },
            enabled: violation.status !== 'Resolved',
          },
        ]}
        />,
      });
    });
  }, [violations]);

  useEffect(() => {
    request();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view, memberId]);

  return (
    <NavigationLayout title="Insider Threats">
      <div className="large-bottom-margin">
        <h4 className="m-b12 mt-4 size-s text-600">Filter Issues</h4>
        <div className="d-flex justify-content-between">
          <div className="size-xs">
            <Toggle
              activeLabel={view}
              options={[
                { label: 'Unresolved', onClick: () => { setView('Unresolved'); } },
                { label: 'Resolved', onClick: () => { setView('Resolved'); } },
              ]}
            />
          </div>
          <MemberNameEmailSelector memberId={memberId} />
        </div>
        <CustomTable
          headers={TABLE_HEADERS}
          items={violationRow}
          loading={isLoading}
          styles={CustomTableStyles}
        />
        {isModalOpen && (
        <ResolveModal
          closeModal={() => setIsModalOpen(false)}
          handleResolve={() => resolve()}
          isAll={false}
        />
        )}
      </div>
      <div />
    </NavigationLayout>
  );
}

export default MemberThreats;

import React, { useRef, useState, useMemo } from 'react';
import { Button } from '@mui/material';
import { Link, useLocation, useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { TextInput } from '../../components/muiCustomized/TextInput';
import API from '../../api/endpoints';
import { sendRequest } from '../../api/shootAPI';
import CenterCardLayout from '../../components/CenterCardLayout';

const PasswordRequirement = styled.li`
  color: ${(props) => (props.valid ? 'var(--success)' : 'var(--dark-5)')};
  transition: all ease-in-out 100ms;
`;

function ResetPassword() {
  const buttonRef = useRef(null);
  const confirmRef = useRef(null);
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const history = useHistory();

  const passwordRequirements = useMemo(() => ({
    characterMin: password && /^(?=.{8,})/.test(password),
    oneLowerAndUpper: /^(?=.*[a-z])(?=.*[A-Z])/.test(password),
    oneNumber: /^(?=.*[0-9])/.test(password),
    confirm: (password && confirm && password === confirm),
  }), [password, confirm]);

  const handleSubmit = async () => {
    const token = location.search?.split('=')[1];
    const response = await sendRequest({
      method: 'put',
      url: API.USER.resetPassword,
      data: { newPassword: password, token },
    });
    if (response.success) {
      history.push('/');
    } else {
      setErrors({ ...response.data });
    }
  };

  return (
    <CenterCardLayout
      title="Reset Password"
      description="Enter your new password."
      errors={Object.values(errors)}
    >
      <div className="float-none m-auto span-4-of-6">
        <TextInput
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="New password"
          data-testid="new-password"
          onKeyDown={(event) => {
            const key = event.keyCode || event.which;
            if (key === 13) {
              event.preventDefault();
              confirmRef.current.focus();
            }
          }}
        />
        <TextInput
          type="password"
          value={confirm}
          onChange={(e) => setConfirm(e.target.value)}
          placeholder="Confirm password"
          data-testid="confirm-password"
          onKeyDown={(event) => {
            const key = event.keyCode || event.which;
            if (key === 13) {
              event.preventDefault();
              buttonRef.current.click();
            }
          }}
        />
        <ul style={{
          fontSize: 13, marginTop: -8, marginBottom: 8, textAlign: 'left', paddingLeft: 25,
        }}
        >
          <PasswordRequirement valid={passwordRequirements.characterMin}>
            At least 8 characters
          </PasswordRequirement>
          <PasswordRequirement valid={passwordRequirements.oneLowerAndUpper}>
            One lowercase & one uppercase letter
          </PasswordRequirement>
          <PasswordRequirement valid={passwordRequirements.oneNumber}>
            At least one number
          </PasswordRequirement>
          <PasswordRequirement valid={passwordRequirements.confirm}>
            Passwords match
          </PasswordRequirement>
        </ul>
        <Button
          fullWidth
          variant="contained"
          className="nextStep-btn"
          ref={buttonRef}
          disabled={!Object.values(passwordRequirements).every((requirement) => !!requirement)}
          onClick={handleSubmit}
          data-testid="reset-password"
        >
          {' '}
          Reset
        </Button>
        <p className="font-15" style={{ marginTop: 32 }}>
          <span className="m-r4 color-dark3">Have an account?</span>
          <Link className="color-text" to="/">Sign in</Link>
          {/* <Link to="/getStarted">Forgot which email you used?</Link> */}
        </p>
      </div>
    </CenterCardLayout>
  );
}

export default ResetPassword;

import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal, Box, Button } from '@mui/material';
import { TextInput } from '../../../components/muiCustomized/TextInput';
import { sendRequest } from '../../../api/shootAPI';
import API from '../../../api/endpoints';
import { NotificationContext } from '../../../contexts/Notification';

function EditDepartmentModal({
  department,
  isEditing,
  closeModal,
  onDepartmentSaved,
  workspaceId,
}) {
  const { displayNotification } = useContext(NotificationContext);
  const [isSaving, setIsSaving] = useState(false);
  const [name, setName] = useState(department?.name ?? '');
  const [formErrors, setFormErrors] = useState({});

  const handleSaveDepartment = async () => {
    setIsSaving(true);
    const { data, success } = await sendRequest({
      method: 'post',
      url: API.WORKSPACE.department(workspaceId),
      data: {
        name,
        id: department?.id ?? null,
      },
    });
    if (success) {
      onDepartmentSaved();
      closeModal();
      displayNotification({
        message: 'Department saved successfully.',
        severity: 'success',
      });
    } else {
      setFormErrors(data);
      displayNotification({
        message: 'Error occurs while saving department.',
        severity: 'error',
      });
    }
    setIsSaving(false);
  };

  return (
    <Modal open onClose={closeModal} className="flex-row align-c jstfy-c create-team-modal">
      <Box style={{
        width: 489,
        padding: 32,
        background: '#fff',
        borderRadius: 8,
      }}
      >
        <div className="modal-header">
          <h5 className="m-b16 text-600">
            {isEditing ? 'Edit ' : 'Add '}
            {' '}
            Department
          </h5>
        </div>
        <div className="modal-body p-0">
          <div className="input-wrapper">
            <TextInput
              label="Team Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Required"
              error={!!formErrors?.name}
              helperText={formErrors?.name}
            />
          </div>
        </div>

        <div className="modal-footer border-0 p-0 mt-2">
          <Button
            sx={{
              marginRight: 2,
              textTransform: 'none',
              fontSize: '16px',
              height: '40px',
              padding: '12px 16px 12px 16px',
            }}
            variant="outlined"
            onClick={closeModal}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            sx={{
              textTransform: 'none',
              fontSize: '16px',
              height: '40px',
              padding: '12px 16px 12px 16px',
            }}
            variant="contained"
            onClick={handleSaveDepartment}
            disabled={isSaving || !name || (department ? department.name === name : false)}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            {isSaving ? 'Saving...' : isEditing ? 'Save Changes' : 'Save Department'}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

EditDepartmentModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onDepartmentSaved: PropTypes.func.isRequired,
  department: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    workspaceId: PropTypes.number.isRequired,
  }),
  isEditing: PropTypes.bool.isRequired,
  workspaceId: PropTypes.number.isRequired,
};

export default EditDepartmentModal;

import React from 'react';
import Header from './Header';
import Footer from './Footer';
import HeroImage from '../../images/hero-image.png';
import ExploreImage from '../../images/explore-image.png';
import DeployImage from '../../images/deploy-image.png';
import SignupImage from '../../images/signup-image.png';
import Netflix from '../../images/netflix.png';
import Nike from '../../images/nike.png';
import Slack from '../../images/slack.png';
import Microsoft from '../../images/microsoft.png';
import Shopify from '../../images/shopify.png';
import Intel from '../../images/intel.png';
import RadialBG from '../../images/radial.png';
import Dots from '../../images/dots.png';
import AvatarImage from '../../images/avatar.png';
import './index.css';

import {
  AdaptationIcon,
  AirbnbLogo,
  ArrowIcon,
  ChatIcon,
  CheckIcon,
  CloudIcon,
  CreditCardIcon,
  DataIcon,
  DeleteIcon,
  FastIcon,
  HostIcon,
  HubspotLogo,
  KeywordIcon,
  LocationIcon,
  QuoteMark,
  ServerIcon,
  StrapiLogo,
} from './Icons';

function Homepage() {
  return (
    <div className="position-relative">
      <Header />
      <div>
        <section className="hero container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <h1 className="mb-4">
                Boost Remote Team
                {' '}
                <span className="orange">Productivity</span>
                {' '}
                with Secure Time Tracking
              </h1>
              <p className="mb-3">
                Enhanced employee engagement with robust security,
                seamless background time-tracking, and team and individual productivity reporting.
                Shoot is your ultimate tool for managing remote teams efficiently and effectively.
              </p>
              <div className="d-flex align-items-center py-4 flex-wrap">
                <a className="btn btn-primary" href="/">Sign Up</a>
                <a className="btn btn-link d-none d-md-flex" href="/">
                  Explore Service
                  <ArrowIcon className="ms-2" width="18" height="18" />
                </a>
              </div>
              <div className="info billing-info d-flex flex-wrap mb-4 mb-md-0 flex-column flex-lg-row">
                <span className="d-flex align-items-center me-2 mb-2 mb-lg-0">
                  <CheckIcon className="me-1" width="20" height="20" />
                  Get 14 days free trial
                </span>
                <span className="d-flex align-items-center me-2 mb-2 mb-lg-0">
                  <CheckIcon className="me-1" width="20" height="20" />
                  No credit card is required
                </span>
                <span className="d-flex align-items-center me-2 mb-2 mb-lg-0">
                  <CheckIcon className="me-1" width="20" height="20" />
                  Cancel anytime
                </span>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <img className="hero-image" src={HeroImage} alt="Hero" width="525" height="530" loading="lazy" />
            </div>
          </div>
        </section>
        <section className="partners container">
          <img src={Netflix} alt="Netflix" />
          <img src={Nike} alt="Nike" />
          <img src={Slack} alt="Slack" />
          <img src={Microsoft} alt="Microsoft" />
          <img src={Shopify} alt="Shopify" />
          <img src={Intel} alt="Intel" />
        </section>
        <section className="signup container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <h2 className="mb-3">
                Get Started with Shoot Today and Transform Your Remote Team Management!
              </h2>
              <p className="mb-4">
                Transform the way you manage remote teams with Shoot
                – your go-to solution for efficient and effective remote team management. Sign up now!
              </p>
              <div className="info">
                <h6 className="mb-3">This 14-day free trial includes upgrades like:</h6>
                <span className="info-item d-flex mb-3">
                  <CheckIcon className="me-2" width="20" height="20" />
                  Effortlessly track your team’s work hours directly from your desktop.
                </span>
                <span className="info-item d-flex mb-3">
                  <CheckIcon className="me-2" width="20" height="20" />
                  Seamlessly manage your entire team from one central platform.
                </span>
                <span className="info-item d-flex mb-3">
                  <CheckIcon className="me-2" width="20" height="20" />
                  Access comprehensive and detailed timesheets for precise time tracking and reporting.
                </span>
                <span className="info-item d-flex mb-3">
                  <CheckIcon className="me-2" width="20" height="20" />
                  Protect your business with advanced monitoring tools to detect and prevent insider threats.
                </span>
              </div>
            </div>
            <div className="col-12 col-md-6 d-flex">
              <img
                className="ms-auto hero-image"
                src={SignupImage}
                alt="Hero"
                width="510"
                height="604"
                loading="lazy"
              />
            </div>
          </div>
        </section>
        <section className="features container">
          <div className="d-flex flex-column align-items-center text-center mb-4">
            <h2 className="mb-3">
              Streamline Your Workflow with Advanced Features
            </h2>
            <p>Effortless Time Management, Real-Time Tracking, and Secure Communication</p>
          </div>
          <div className="row py-3">
            <div className="col-12 col-md-4">
              <div className="features-item d-flex flex-column">
                <FastIcon className="mb-3" width="40" height="40" />
                <h6 className="mb-2">Efficient Time Tracking</h6>
                <p className="info">
                  Track work hours and vacation time effortlessly with our discreet,
                  accurate time tracker that runs in the background and provides secure,
                  precise reports.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="features-item d-flex flex-column shadow">
                <LocationIcon className="mb-3" width="40" height="40" />
                <h6 className="mb-2">Efficient Time Tracking</h6>
                <p className="info">
                  Track work hours and vacation time effortlessly with our discreet,
                  accurate time tracker that runs in the background and provides secure,
                  precise reports.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="features-item d-flex flex-column">
                <ChatIcon className="mb-3" width="40" height="40" />
                <h6 className="mb-2">Efficient Time Tracking</h6>
                <p className="info">
                  Track work hours and vacation time effortlessly with our discreet,
                  accurate time tracker that runs in the background and provides secure,
                  precise reports.
                </p>
              </div>
            </div>
          </div>
          <a href="/" className="btn btn-primary mx-auto mt-4 d-none d-md-flex">Get Started</a>
        </section>
        <section className="testimonial">
          <div className="container position-realative">
            <QuoteMark width="142" height="116" className="quote-mark position-absolute" />
            <div className="row">
              <div className="col-12 col-md-6">
                <h2 className="mb-2">Real Stories from Real Customers</h2>
                <p className="mb-4 pb-3">Get inspired by these stories.</p>
                <div className="testimonial-card ms-auto mb-4 testimonial-card-1">
                  <HubspotLogo width="112" height="32" />
                  <div className="testimonial-info d-flex">
                    <QuoteMark className="me-3" width="16" height="13" />
                    <p className="w-0">
                      Shoot has transformed how we manage our remote team.
                      From vacation tracking to real-time employee location monitoring,
                      I trust Shoot to provide me with insights into the security and productivity of my business.
                    </p>
                  </div>
                  <div className="testimonial-writer d-flex align-items-center">
                    <img className="me-3" src={AvatarImage} alt="John Doe" />
                    <div className="d-flex flex-column">
                      <h6>John Doe</h6>
                      <p>Vice President, GoPro</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="testimonial-card testimonial-card-2 mb-4">
                  <AirbnbLogo width="112" height="32" />
                  <div className="testimonial-info d-flex">
                    <QuoteMark className="me-3" width="16" height="13" />
                    <p className="w-0">
                      In the world of finance, security is paramount.
                      With Shoot, I know who is using our machines,
                      when they are online, and where they are in the world.
                      I am far more confident in the security of our remote teams thanks to Shoot.
                    </p>
                  </div>
                  <div className="testimonial-writer d-flex align-items-center">
                    <img className="me-3" src={AvatarImage} alt="Jane Cooper" />
                    <div className="d-flex flex-column">
                      <h6>Jane Cooper</h6>
                      <p>CEO, Airbnb</p>
                    </div>
                  </div>
                </div>
                <div className="testimonial-card testimonial-card-3">
                  <StrapiLogo width="112" height="32" />
                  <div className="testimonial-info d-flex">
                    <QuoteMark className="me-3" width="16" height="13" />
                    <p className="w-0">
                      I used to pay for multiple tools to accomplish
                      what Shoot provides in one seamless,
                      easy to deploy package. Game changer!
                    </p>
                  </div>
                  <div className="testimonial-writer d-flex align-items-center">
                    <img className="me-3" src={AvatarImage} alt="Kristin Watson" />
                    <div className="d-flex flex-column">
                      <h6>Kristin Watson</h6>
                      <p>Co-Founder, Strapi</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="explore container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 d-none d-md-flex">
              <img className="hero-image" src={ExploreImage} alt="Hero" width="570" height="533" loading="lazy" />
            </div>
            <div className="col-12 col-md-6">
              <h2 className="mb-3">Explore Shoot with Confidence</h2>
              <p className="mb-4">
                Discover all features risk-free and get acquainted with our platform before making a commitment.
              </p>
              <div className="d-flex d-md-none">
                <img
                  className="hero-image"
                  src={ExploreImage}
                  alt="Hero"
                  width="570"
                  height="533"
                  loading="lazy"
                />
              </div>
              <h6 className="mb-3">Start Your Free 14-Day Trial</h6>
              <span className="d-flex info info-item mb-3">
                <CreditCardIcon className="me-2" width="20" height="20" />
                No credit card is required
              </span>
              <span className="d-flex info info-item mb-4">
                <KeywordIcon className="me-2" width="20" height="20" />
                Enjoy full access to all the features Shoot has to offer.
              </span>
              <h6 className="mb-3">Explore in a Safe Environment</h6>
              <span className="d-flex info info-item mb-3">
                <DataIcon className="me-2" width="20" height="20" />
                Use our demo data to explore and test all features.
              </span>
              <span className="d-flex info info-item mb-4">
                <ServerIcon className="me-2" width="20" height="20" />
                Secure and hosted environment designed for easy exploration.
              </span>
            </div>
          </div>
        </section>
        <section className="deploy container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <h2 className="mb-3">Deploy and Customize Your Solution</h2>
              <p className="mb-4">
                Transition smoothly from exploration to a tailored,
                fully-hosted deployment that meets your specific needs.
              </p>
              <div className="d-flex d-md-none">
                <img
                  className="hero-image"
                  src={DeployImage}
                  alt="Hero"
                  width="570"
                  height="537"
                  loading="lazy"
                />
              </div>
              <h6 className="mb-3">Deploy Your Own Solution</h6>
              <span className="d-flex info info-item mb-3">
                <DeleteIcon className="me-2" width="20" height="20" />
                Once ready, delete all dummy data with a few clicks.
              </span>
              <span className="d-flex info info-item mb-4">
                <AdaptationIcon className="me-2" width="20" height="20" />
                Deploy your own hosted solution seamlessly.
              </span>
              <h6 className="mb-3">Explore in a Safe Environment</h6>
              <span className="d-flex info info-item mb-3">
                <HostIcon className="me-2" width="20" height="20" />
                Prefer to host in your own environment? No problem!
              </span>
              <span className="d-flex info info-item mb-4">
                <CloudIcon className="me-2" width="20" height="20" />
                Shoot easily deploys to Google Cloud, AWS, and colo environments.
              </span>
            </div>
            <div className="col-12 col-md-6 d-none d-md-flex">
              <img className="hero-image" src={DeployImage} alt="Hero" width="570" height="537" loading="lazy" />
            </div>
          </div>
        </section>
        <section className="start position-relative">
          <img className="background" src={RadialBG} alt="background" />
          <img className="dots position-absolute top-left" src={Dots} alt="dots" />
          <img className="dots position-absolute bottom-right" src={Dots} alt="dots" />
          <div className="container position-relative text-center d-flex align-items-center flex-column">
            <h2 className="mb-2">Start Your Free Trial and Streamline Your Workflow!</h2>
            <p className="mb-4 pb-3">
              Sign up today to access Shoot’s powerful features and give
              your team the tools they need to excel. Experience the full
              potential of our platform risk-free and start optimizing your processes with ease.
            </p>
            <a className="btn btn-primary" href="/">Get Started</a>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Homepage;

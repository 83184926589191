import React, { useContext } from 'react';
import { AppBar, MenuItem } from '@mui/material';
import { useHistory } from 'react-router-dom';
import './navbar.css';
import styled from '@emotion/styled';
import { Dropdown } from '../muiCustomized/Dropdown';
import ShootLogo from '../../images/blue-shoot-logo.png';
import UserSVG from '../../images/user.svg';
import MyAccount from '../../images/myAccount.svg';
import SwitchAccount from '../../images/switchAccount.svg';
import LogOut from '../../images/logout.svg';
import { UserContext } from '../../contexts/User';

const Avatar = styled.div`
  border-radius: 6px;
  width: 39px;
  height: 39px;
  background: var(--dark-7);
  color: var(--dark-3);
  ${(props) => props.src && `
    background-size: cover;
    background-image: url(${props.src});
  `}
`;

const CustomizedMenuItem = styled(MenuItem)`
  && {
    background-color: white;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &&:hover {
    background-color: var(--dark-8);
  }
`;

export function Navbar() {
  const {
    user, signOut,
  } = useContext(UserContext);
  const history = useHistory();
  return (
    <div className="navbar-holder flex-row justify-content-between">
      <AppBar position="static" sx={{ boxShadow: 'none' }} color="inherit">
        <div className="navbar-between">
          <img
            src={ShootLogo}
            height="33"
            className="d-inline-block align-top"
            alt="shoot-logo"
          />
          <div className="user-menu">
            <div className="content">
              <h3 style={{ textAlign: 'right', marginTop: 3 }}>SIGNED IN AS</h3>
              <Dropdown
                displayEmpty
                renderValue={() => `${user.firstName} ${user.lastName}`}
                setValue={() => {}}
              >
                <CustomizedMenuItem dense onClick={() => history.push('/account')}>
                  <img src={MyAccount} alt="my account" />
                  My Account
                </CustomizedMenuItem>
                <CustomizedMenuItem dense>
                  <img src={SwitchAccount} alt="switch accounts" />
                  Switch Accounts
                </CustomizedMenuItem>
                <CustomizedMenuItem dense onClick={signOut}>
                  <img src={LogOut} alt="log out" />
                  <span style={{ color: 'var(--red-2)' }}>Log Out</span>
                </CustomizedMenuItem>
              </Dropdown>
            </div>
            <div className="user-menu-avatar">
              <Avatar
                className="flex-row align-c jstfy-c"
                src={user.profilePicture || UserSVG}
              />
            </div>
          </div>
        </div>
      </AppBar>
    </div>
  );
}

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  Modal, Box, Button, FormControl, FormLabel,
} from '@mui/material';
import { CSVLink } from 'react-csv';
import UploadIcon from '../../images/upload.png';
import FileIcon from '../../images/csvfile.png';
import RemoveIcon from '../../images/remove.png';
import { sendRequest } from '../../api/shootAPI';
import API from '../../api/endpoints';
import { UserContext } from '../../contexts/User';
import { NotificationContext } from '../../contexts/Notification';
import { getTimeString } from '../../utils/time';

const Description = styled.p`
    font-size: ${(props) => `${props.fontSize}px`};
    color: ${(props) => props.color};
    font-weight: ${(props) => props.fontWeight};
    margin-top: ${(props) => `${props.marginTop}px`};
`;

const StyledText = styled.textarea`
  width: 100%;
  height: 89px;
  font-size: 16px;
  font-weight: 400;
  padding: 13px 16px;
  border-radius: 8px;
  color: #0E1E40;
  border: 1px solid #E0E1E6;
  margin-bottom: 12px;

  &:focus {
    border-color: #315285;
  }
`;

const StyledBlock = styled.div`
  border: 2px dashed #ccc;
  border-radius: 4px;
  display: flex;
  padding: 0px 15px;
  align-items: center;
  justify-content: ${(props) => props.justifyContent};
  cursor: pointer;
  margin-top: 20px;
  height: 48px;
`;

function ImportModal({
  closeModal, data, headers, getMembers,
}) {
  const { activeWorkspace } = useContext(UserContext);
  const {
    displayNotification,
  } = useContext(NotificationContext);
  const [reason, setReason] = useState('');
  const [file, setFile] = useState();
  const [array, setArray] = useState([]);
  const fileInputRef = useRef(null);
  const fileReader = new FileReader();

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  const csvFileToArray = (string) => {
    const newString = string.replaceAll('\r', '');
    const csvHeader = newString.slice(0, newString.indexOf('\n')).split(',');
    const csvRows = newString.slice(newString.indexOf('\n') + 1).split('\n');

    const array = csvRows.map((i) => {
      const values = i.split(',');
      const obj = csvHeader.reduce((object, header, index) => {
        const newHeader = header.replaceAll('"', '');
        const newValue = values[index].replaceAll('"', '');
        let updatedHeader = '';
        switch (newHeader) {
          case 'Balance':
            updatedHeader = 'balance';
            break;
          case 'Policy':
            updatedHeader = 'policy';
            break;
          case 'User Email':
            updatedHeader = 'email';
            break;
          default:
            updatedHeader = 'name';
        }
        return { ...object, [updatedHeader]: newValue };
      }, {});
      return obj;
    });
    setArray(array);
  };

  const handleImport = () => {
    fileReader.onload = (event) => {
      const csvOutput = event.target.result;
      csvFileToArray(csvOutput);
    };

    fileReader.readAsText(file);
  };

  const handleConfirm = async () => {
    if (activeWorkspace) {
      closeModal();
      const response = await sendRequest({
        method: 'post',
        url: API.WORKSPACE.editMembersBalances(activeWorkspace.id),
        data: array,
      });
      if (response.success) {
        displayNotification({
          message: 'Time off balances updated successfully.',
          severity: 'success',
        });
        getMembers();
      } else {
        displayNotification({
          message: 'An error occurs while editing data.',
          severity: 'error',
        });
      }
    }
  };

  return (
    <Modal open onClose={closeModal} className="flex-row align-c jstfy-c create-team-modal">
      <Box style={{
        width: 588,
        padding: 32,
        background: '#fff',
        borderRadius: 8,
      }}
      >
        <div className="modal-body" style={{ padding: 0 }}>
          <Description
            fontSize={20}
            fontWeight={600}
            color="#0E1E40"
            marginTop={0}
          >
            Import Balances
          </Description>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Description
              fontSize={14}
              fontWeight={500}
              color="#0E1E40"
              marginTop={10}
            >
              Upload File
            </Description>
            <CSVLink
              data={data}
              headers={headers}
              filename="timeOffBalance.csv"
            >
              <Description
                fontSize={14}
                fontWeight={500}
                color="#53A551"
                marginTop={10}
              >
                Download Spreadsheet Template
              </Description>
            </CSVLink>
          </div>
          <form>
            {file ? (
              <StyledBlock justifyContent="space-between">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={FileIcon} style={{ width: '14px', height: '20px', marginRight: '4px' }} alt="File" />
                  <span>{file.name}</span>
                </div>
                <img
                  src={RemoveIcon}
                  style={{ width: '18px', marginRight: '4px' }}
                  alt="Remove"
                  onClick={() => {
                    setFile();
                    setArray([]);
                  }}
                />
              </StyledBlock>
            ) : (
              <StyledBlock justifyContent="center" onClick={handleFileInputClick}>
                <img src={UploadIcon} style={{ width: '14px', marginRight: '4px' }} alt="Upload" />
                <span>Drop your .csv, .xls or .xlsx file here or </span>
                <span
                  style={{
                    textDecoration: 'underline',
                    textDecorationColor: '#4469A1',
                    color: '#4469A1',
                    marginLeft: '4px',
                  }}
                >
                  Browse
                </span>
                <input
                  ref={fileInputRef}
                  type="file"
                  id="csvFileInput"
                  style={{ display: 'none' }}
                  onChange={(e) => { setFile(e.target.files[0]); }}
                  accept=".csv"
                />
              </StyledBlock>
            )}
          </form>
          {array.length > 0 && (
            <div
              style={{
                height: '430px',
                overflow: 'auto',
                margin: '15px 0px',
              }}
            >
              <table
                className="table"
              >
                <thead>
                  <tr>
                    <th scope="col">
                      User
                    </th>
                    <th scope="col">
                      Policy
                    </th>
                    <th scope="col">
                      Old
                    </th>
                    <th scope="col">
                      New
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {array.map((upload, index) => {
                    // eslint-disable-next-line max-len
                    const match = data.find((datum) => (datum.email === upload.email && datum.policy === upload.policy));
                    if (match) {
                      return (
                        <tr key={index}>
                          <td>{upload.name}</td>
                          <td>{upload.policy}</td>
                          <td>
                            {getTimeString(match.balance)}
                          </td>
                          <td>
                            {getTimeString(upload.balance)}
                          </td>
                        </tr>
                      );
                    }
                    return (
                      <tr key={index}>
                        <td>{upload.name}</td>
                        <td>{upload.policy}</td>
                        <td>{}</td>
                        <td>{upload.balance}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
          <FormControl sx={{ width: '100%', marginTop: '12px' }}>
            <FormLabel
              sx={{
                color: '#293855',
                fontSize: '14px',
              }}
            >
              Reason for Balance Adjustment
            </FormLabel>
            <StyledText onChange={(e) => setReason(e.target.value)} value={reason} placeholder="Required" />
          </FormControl>
        </div>

        <div
          className="modal-footer-customized"
          style={{
            display: 'flex',
            justifyContent: 'right',
          }}
        >
          <Button
            sx={{
              marginRight: 2,
              textTransform: 'none',
              fontSize: '16px',
              height: '40px',
              width: '101px',
            }}
            variant="outlined"
            onClick={closeModal}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            sx={{
              textTransform: 'none',
              fontSize: '16px',
              height: '40px',
              width: '101px',
              backgroundColor: 'var(--green-3, #53A551)',
              color: '#FFFFFF',
              '&:hover': { backgroundColor: 'var(--green-3, #53A551)' },
            }}
            variant="contained"
            onClick={() => (array.length > 0 ? handleConfirm() : handleImport())}
          >
            {array.length > 0 ? 'Confirm' : 'Import'}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

ImportModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  getMembers: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
  headers: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
};

export default ImportModal;

import React, {
  useLayoutEffect, useState, useRef, useContext, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import styled from '@emotion/styled';
import HomeIcon from '../../images/icon-house.svg';
import AlertIcon from '../../images/icon-alert.svg';
import { formatTime } from '../../utils/time';
import { LocationViolationType, UserType } from '../../propTypes';
import { sendRequest } from '../../api/shootAPI';
import { UserContext } from '../../contexts/User';
import API from '../../api/endpoints';
import { ADDRESSS_HOME, ADDRESS_AWAY } from '../../constants/geolocation';
import ResolveModal from './ResolveModal';
import './locationHistory.css';

const SidebarWrapper = styled.div`
  width: 244px;
  height: 100vh;
  border: 1px solid var(--dark-6);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-family: Inter;
  position: relative;
`;

const StyledLink = styled.a`
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -14px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid var(--dark-3); /* Color of the arrow */
    transform: translateY(-50%);
  }
  color: var(--dark-3);
  font-size: 14px;
  text-decoration: underline;
  line-height: 20px;
  font-weight: 500;
  position: relative;
`;

const PersonalInfo = styled.div`
  padding: 19px 23px;
  border-bottom: 1px solid var(--dark-6);
  background: var(--dark-9);
  display: flex;
  border: 0px, 0px, 1px, 0px;
  cursor: pointer;
  border-bottom: 1px solid var(--Dark-Dark-06, #E0E1E6);
`;

export const IconWrapper = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border: 0.75px solid rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 1.5px 0px rgba(0, 0, 0, 0.25);
  background: ${(props) => props.backgroundColor};
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

const HistoryWrapper = styled.div`
  display: flex;
  cursor: pointer;

  &:hover {
    background: var(--dark-9);
  }
  padding: 23px;
`;

const Button = styled.button`
  border-radius: 3px;
  border: 1px solid var(--dark-4);
  background: var(--white);
  width: 145px;
  height: 30px;
  color: var(--dark-2);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;

  &:hover {
    border: 1px solid var(--dark-2);
  }
`;

const BottomButtonWrapper = styled.div`
  padding: 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 244px;
  background: #fff;
  border-top: 1px solid var(--dark-6);
`;

const resolveLocationViolation = async (locationViolationIds, workspaceId, setResolving, onSuccess) => {
  setResolving(true);
  const response = await sendRequest({
    method: 'put',
    url: API.WORKSPACE.resolveLocationViolation(workspaceId),
    data: { locationViolationIds },
  });
  setResolving(false);
  if (response.success) {
    onSuccess(true);
  }
};

function HistoryItem({
  locationViolation, clickLocation, onResolve, selected,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { activeWorkspace } = useContext(UserContext);
  const [isResolving, setIsResolving] = useState(false);

  return (
    <HistoryWrapper
      onClick={() => clickLocation(locationViolation, ADDRESS_AWAY)}
      className={selected && 'selectedLocation'}
      data-testid="violation-history"
    >
      <IconWrapper
        width="20px"
        height="20px"
        backgroundColor="var(--Red-Red-02, #CC4525)"
        data-testid="alert-icon"
      >
        <img src={AlertIcon} alt="alert icon" />
      </IconWrapper>
      <Box sx={{ marginLeft: '6px' }}>
        <Typography
          sx={{
            color: 'var(--Red-Red-01, #B0361A)',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '13.896px',
          }}
          data-testid="away-from-home"
        >
          Away from home
        </Typography>
        <Typography
          sx={{
            color: 'var(--Dark-Dark-02, #293855)',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '14px',
            marginTop: '4px',
          }}
          data-testid="violation-startTime"
        >
          {formatTime(locationViolation.createdAt)}
        </Typography>
        <Typography
          sx={{
            color: 'var(--Dark-Dark-02, #293855)',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '14px',
            marginTop: '4px',
          }}
          data-testid="violation-duration"
        >
          {`${locationViolation.activityIds.length * 10} mins`}
        </Typography>
        <Typography
          sx={{
            color: 'var(--Dark-Dark-02, #293855)',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '14px',
            marginTop: '7px',
          }}
          data-testid="violation-address"
        >
          {locationViolation.formattedAddress}
        </Typography>
        <Button
          disabled={isResolving}
          style={{
            marginTop: '16px',
          }}
          onClick={(e) => {
            setIsModalOpen(true);
            e.stopPropagation();
          }}
          data-testid="resolve"
        >
          {isResolving ? 'Resolving' : 'Resolve'}
        </Button>
      </Box>
      {isModalOpen && (
        <ResolveModal
          closeModal={() => setIsModalOpen(false)}
          // eslint-disable-next-line max-len
          handleResolve={() => resolveLocationViolation([locationViolation.id], activeWorkspace.id, setIsResolving, onResolve)}
          isAll={false}
        />
      )}
    </HistoryWrapper>
  );
}

HistoryItem.propTypes = {
  locationViolation: LocationViolationType,
  clickLocation: PropTypes.func,
  onResolve: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

function Sidebar({
  user, locationViolations, handleClose, clickLocation, onResolve, selectedLocation,
}) {
  const { formattedAddress } = user.userLocation;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [historyWrapperPos, setHistoryWrapperPos] = useState({ top: 0, left: 0 });
  const [isResolving, setIsResolving] = useState(false);
  const locationViolationIds = useMemo(() => (
    locationViolations
      ? locationViolations.map((locationViolation) => locationViolation.id) : []
  ), [locationViolations]);
  const divRef = useRef(null);
  const { activeWorkspace } = useContext(UserContext);

  useLayoutEffect(() => {
    if (divRef.current) {
      const rect = divRef.current.getBoundingClientRect();
      setHistoryWrapperPos({
        top: rect.top,
        left: rect.left,
      });
    }
  }, []);

  return (
    <SidebarWrapper>
      <Box sx={{ padding: '16px 37px' }}>
        <StyledLink
          className="back-link"
          onClick={() => handleClose()}
          data-testid="back-link"
        >
          Geo Dashboard
        </StyledLink>
      </Box>
      <PersonalInfo
        onClick={() => clickLocation(user.userLocation, ADDRESSS_HOME)}
        className={selectedLocation === -1 && 'selectedLocation'}
      >
        <IconWrapper
          width="30px"
          height="30px"
          backgroundColor="var(--Green-Green-04, #64BA62)"
          data-testid="home-icon"
        >
          <img src={HomeIcon} alt="home" />
        </IconWrapper>
        <Box sx={{ marginLeft: '15px' }}>
          <Typography
            sx={{
              color: 'rgba(37, 109, 35, 1)',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '14px',
              letterSpacing: 0,
              textAlign: 'left',
              marginBottom: '4px',
            }}
            data-testid="name"
          >
            {`${user.firstName} ${user.lastName}`}
          </Typography>
          <Typography
            sx={{
              color: 'var(--dark-2)',
              fontSize: '12px',
              fontWeight: 700,
              lineHeight: '14px',
              letterSpacing: 0,
              textAlign: 'left',
              marginBottom: '4px',
            }}
            data-testid="home-address-label"
          >
            Home Address
          </Typography>
          <Typography
            sx={{
              color: 'var(--dark-2)',
              fontFamily: 'Inter',
              fontSize: '12px',
              fontWeight: 400,
              lineHeight: '14px',
              letterSpacing: 0,
              textAlign: 'left',
            }}
            data-testid="address"
          >
            {formattedAddress}
          </Typography>
        </Box>
      </PersonalInfo>
      <Box>
        <Typography sx={{
          color: 'var(--Dark-Dark-02, #293855)',
          fontSize: '13.896px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '13.896px',
          padding: '17px 23px',
        }}
        >
          {`Location History (${locationViolations.length})`}
        </Typography>
        {
          !locationViolations.length
          && (
            <Typography sx={{ textAlign: 'center', padding: '10px' }} data-testid="no-violation">
              No location violations.
            </Typography>
          )
        }
        {
          !!locationViolations.length
          && (
          <Box
            sx={{
              height: `calc(100vh - ${historyWrapperPos.top}px - 62px)`,
              overflowY: 'auto',
            }}
            ref={divRef}
          >
            {
            locationViolations.map((v) => (
              <HistoryItem
                key={v.id}
                locationViolation={v}
                clickLocation={clickLocation}
                onResolve={onResolve}
                selected={v.id === selectedLocation}
              />
            ))
          }
          </Box>
          )
        }
        <BottomButtonWrapper>
          <Button
            disabled={!locationViolations.length || isResolving}
            onClick={() => {
              setIsModalOpen(true);
            }}
            data-testid="resolve-all"
          >
            {isResolving ? 'Resolving' : 'Resolve All'}
          </Button>
        </BottomButtonWrapper>
      </Box>
      {isModalOpen && (
        <ResolveModal
          closeModal={() => setIsModalOpen(false)}
          // eslint-disable-next-line max-len
          handleResolve={() => resolveLocationViolation(locationViolationIds, activeWorkspace.id, setIsResolving, onResolve)}
          isAll
        />
      )}
    </SidebarWrapper>
  );
}

Sidebar.propTypes = {
  user: UserType,
  locationViolations: PropTypes.arrayOf(LocationViolationType),
  handleClose: PropTypes.func.isRequired,
  clickLocation: PropTypes.func.isRequired,
  onResolve: PropTypes.func.isRequired,
  selectedLocation: PropTypes.number.isRequired,
};

export default Sidebar;

import React, {
  useState,
} from 'react';
import {
  Box, Paper, Tab, Tabs,
} from '@mui/material';
import PropTypes from 'prop-types';

function TabsWithPanels({ tabItems, tabPanels, PanelAction }) {
  const [tab, setTab] = useState(0);

  return (
    <Paper sx={{
      maxHeight: '756px', marginTop: '24px', borderRadius: '8px',
    }}
    >
      <Box display="flex" sx={{ flexDirection: { sm: 'column', md: 'row' }, height: '56px' }}>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={tab}
          onChange={(e, nv) => setTab(nv)}
        >
          {
            tabItems.map((tab) => (
              <Tab
                key={tab.label}
                label={`${tab.label} (${tab.length})`}
                style={{ textTransform: 'unset', fontWeight: '600', fontSize: '16px' }}
              />
            ))
          }

        </Tabs>
        {
            PanelAction
        }
      </Box>
      {
          tabPanels.map((element, index) => (
            <div
              key={index}
              role="tabpanel"
              hidden={tab !== index}
            >
              {tab === index && (
                element
              )}
            </div>
          ))
      }
    </Paper>
  );
}

TabsWithPanels.propTypes = {
  tabItems: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    length: PropTypes.number.isRequired,
  })),
  tabPanels: PropTypes.arrayOf(PropTypes.element),
  PanelAction: PropTypes.element,
};

export default TabsWithPanels;
